import React, { useEffect } from "react";
import Intercom from "react-intercom";
import lodash from "lodash";

const RenderIntercom = () => {
  const currentUserInLocalStorage = JSON.parse(localStorage.getItem("user"));
  const impersonate = localStorage.getItem("impersonate");

  useEffect(() => {
    // SHUTDOWN INTERCOM IF IMPERSONATE
    if (window.Intercom && impersonate) {
      window.Intercom("shutdown");
    }
  }, [impersonate]);

  if (
    !impersonate &&
    !window.location.href.includes("schedules?personId") &&
    currentUserInLocalStorage &&
    currentUserInLocalStorage?.success &&
    !lodash.isEmpty(currentUserInLocalStorage?.success)
  ) {
    const currentUser = currentUserInLocalStorage?.success?.user;
    const churches = currentUserInLocalStorage?.success?.user?.churches || [];
    const churchForIntercom =
      Array.isArray(churches) && Boolean(churches.length) ? churches[0] : [];
    const intercomAppId =
      currentUser?.env !== "production" ? "hhmdmicj" : "mrw4okeh";
    const userId = currentUser.env + "_" + currentUser.id;
    return (
      <Intercom
        appID={intercomAppId}
        user_id={userId || 0}
        email={currentUser.email || ""}
        name={currentUser.name || ""}
        user_hash={currentUser.hashed_id || ""}
        created_at={currentUser.created_timestamp}
        api_base={"https://api-iam.intercom.io"}
        company={
          lodash.isEmpty(churchForIntercom)
            ? {}
            : {
                company_id: currentUser.env + "_" + churchForIntercom?.id,
                name: churchForIntercom?.name,
                created_at: churchForIntercom?.created_timestamp,
              }
        }
      />
    );
  }

  return null;
};

export default RenderIntercom;
