import React from "react";
import {
  Button,
  Dialog,
  Icon,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import "./../../styles/components/more/integrations.scss";
import Pastorsline from "./Pastorsline";
import { userService } from "./../services/UserService";
import "./../../styles/components/choose-number-modal.scss";

export default function ConnectAccountModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAuthorize = () => {
    userService.authorizePco().then(
      (user) => {},
      (error) => console.log(error)
    );

    return;
  };

  return (
    <div>
      <Button
        color="primary"
        variant="text"
        onClick={handleClickOpen}
        classes={{
          root: "btn",
          label: "label",
          textPrimary: "text-primary",
        }}
      >
        <span className="forgot-pw">Read more</span>
      </Button>
      <Dialog
        fullScreen={window.innerWidth < 600 && true}
        open={open}
        onClose={handleClose}
        aria-labelledby="connect-account-dialog-title"
        aria-describedby="connect-account-dialog-description"
        classes={{
          root: "connect-account-modal",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogTitle id="connect-account-dialog-title" className="title">
          How it works?
          <Icon onClick={handleClose} className="close-icon">
            close
          </Icon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="connect-account-dialog-description"
            className="modal-content"
          >
            {props.connect === "pastorsline" &&
              "If you connect, your PastorsLine number will be imported to be used in your Confirmed.Church account."}
            {props.connect === "pco" && (
              <span>
                Once integrated, your services and people will be synced with
                Confirmed.Church. As soon as you send the email service request
                to your unconfirmed people in Planning Center Services, we will
                send the confirmation text message within 10 minutes (skipped if
                they haven't already confirmed via email). The text follow-up
                service reminders would be sent 1 month, 1 week, 1 day and 1
                minute before your people is scheduled to serve.
              </span>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className={
            props.connect === "pastorsline"
              ? "action choose-number-wrapper"
              : "action"
          }
        >
          {props.connect === "pastorsline" && <Pastorsline />}
          {props.connect === "pco" && (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleAuthorize}
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary",
              }}
            >
              Connect
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
