import { ACTION_TYPES } from "../actions/types";
const initialState = {
  showPlanFilterIcon: null,
  showStatusFilterIcon: null,
};

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SHOW_PLAN_FILTER_ICON:
      return Object.assign({}, state, {
        showPlanFilterIcon: action.payload,
      });
    case ACTION_TYPES.SHOW_STATUS_FILTER_ICON:
      return Object.assign({}, state, {
        showStatusFilterIcon: action.payload,
      });
    default:
      return state;
  }
};

export default organizationReducer;
