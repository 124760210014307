import React from "react";
import {
  Button,
  Hidden,
  Icon,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  List,
  ListItem,
  Divider,
} from "@material-ui/core";
import queryString from "query-string";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import Moment from "react-moment";
import HeaderServices from "./HeaderServices";
import { authHeader } from "./../helpers/AuthHeader";
import { ReactComponent as AcceptedIcon } from "../../icons/accepted.svg";
import "../../styles/components/services/schedules.scss";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
Moment.globalFormat = "MMMM DD, YYYY hh:mm A";
Moment.globalLocal = false;

class Schedules extends React.Component {
  constructor(props) {
    super(props);

    const values = queryString.parse(this.props.location.search);

    this.state = {
      personId: values.personId ? values.personId : "",
      schedules: [],
      expired: false,
      loading: false,
      showConfirms: [],
      showDeclines: [],
    };

    this.reload = this.reload.bind(this);
  }

  componentDidMount() {
    this.reload();
  }
  handleConfirm(scheduleId, confirm) {
    this.setState({ loading: true });
    //if decline show decline first
    if (confirm === 0) {
      const { showDeclines, showConfirms } = this.state;
      showDeclines[scheduleId] = true;
      showConfirms[scheduleId] = false;
      this.setState({ showDeclines: showDeclines, showConfirms: showConfirms });
    }
    fetch(
      API_URL +
        "scheduling/confirm/" +
        this.state.personId +
        "/" +
        scheduleId +
        "/" +
        confirm,
      {
        headers: authHeader(),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          schedules: data.schedules,
          expired: data.expired,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  toggleConfirm(scheduleId) {
    const { showConfirms } = this.state;
    showConfirms[scheduleId] = showConfirms[scheduleId]
      ? !showConfirms[scheduleId]
      : true;
    this.setState({ showConfirms: showConfirms });
  }

  reload() {
    this.setState({ loading: true });
    fetch(API_URL + "show-schedules/" + this.state.personId, {
      headers: authHeader(),
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          schedules: data.schedules,
          expired: data.expired,
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({ loading: false });
      });
  }

  render() {
    const {
      schedules,
      expired,
      loading,
      showConfirms,
      showDeclines,
    } = this.state;

    return (
      <div className="schedules page-wrapper with-scroll p-0">
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>

        <HeaderServices
          hasReload="true"
          reload={this.reload}
          title={
            !expired && schedules.length > 0
              ? schedules[0].attributes.organization_name +
                " > " +
                schedules[0].attributes.person_name
              : "Schedules"
          }
        />

        <div className="schedule-page-wrapper">
          <Hidden smDown>
            <Typography className="schedule-heading" variant="h5">
              Schedules
            </Typography>
          </Hidden>
          {!expired && schedules.length === 0 && (
            <p className="text-center">No Schedules Left</p>
          )}
          {expired && <p className="text-center">Link Expired</p>}
          {!expired && schedules.length > 0 && (
            <div className="mt-5">
              <Hidden smDown>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow className="table-header">
                      <TableCell></TableCell>
                      <TableCell style={{ width: "15%" }}>
                        Service Type
                      </TableCell>
                      <TableCell style={{ width: "15%" }}>Date</TableCell>
                      <TableCell style={{ width: "10%" }}>Team</TableCell>
                      <TableCell style={{ width: "10%" }}>Position</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {schedules.map((row) => (
                      <TableRow
                        key={row.id}
                        className={`table-body ${
                          row.attributes.status === "U" ? "opacity-low" : ""
                        }`}
                      >
                        <TableCell></TableCell>
                        <TableCell>
                          <span>{row.attributes.service_type_name}</span>
                        </TableCell>
                        <TableCell>
                          <span>
                            <Moment>
                              {row.attributes.sort_date.replace("Z", "")}
                            </Moment>
                          </span>
                        </TableCell>
                        <TableCell>
                          <span>{row.attributes.team_name}</span>
                        </TableCell>
                        <TableCell>
                          <span>{row.attributes.team_position_name}</span>
                        </TableCell>
                        <TableCell>
                          <div className="flexer-start">
                            {row.attributes.status === "U" && (
                              <div id={"row-" + row.id + "-action"}>
                                {!showConfirms[row.id] &&
                                  !showDeclines[row.id] && (
                                    <>
                                      <div className="flexer">
                                        <Icon className="icon action-icon help-icon">
                                          help
                                        </Icon>
                                        <span>Unconfirmed</span>
                                      </div>
                                      <div
                                        onClick={() =>
                                          this.toggleConfirm(row.id)
                                        }
                                        className="take-action"
                                      >
                                        Take Action
                                      </div>
                                    </>
                                  )}
                                {showConfirms[row.id] && !showDeclines[row.id] && (
                                  <div>
                                    <Button
                                      className="action-btn accept"
                                      classes={{
                                        label: "accepted-label",
                                      }}
                                      component={Link}
                                      onClick={() =>
                                        this.handleConfirm(row.id, 1)
                                      }
                                    >
                                      Accept
                                    </Button>

                                    <Button
                                      className="action-btn decline"
                                      classes={{
                                        label: "decline-label",
                                      }}
                                      component={Link}
                                      onClick={() =>
                                        this.handleConfirm(row.id, 0, "d")
                                      }
                                    >
                                      Decline
                                    </Button>
                                  </div>
                                )}
                              </div>
                            )}
                            {showDeclines[row.id] && (
                              <div className="flexer">
                                <Icon className="icon cross-icon">clear</Icon>
                                <span className="ml-1">Declined</span>
                              </div>
                            )}
                            {row.attributes.status === "C" && (
                              <div className="flexer">
                                <AcceptedIcon />
                                <span className="ml-1">Accepted</span>
                              </div>
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Hidden>
              <Hidden mdUp>
                <div className="mobile-page-wrapper">
                  {schedules.map((row, idx) => (
                    <div key={idx}>
                      <List
                        disablePadding
                        className={`p-3 ${
                          row.attributes.status === "U"
                            ? "opacity-low-mobile"
                            : ""
                        }`}
                      >
                        <ListItem disableGutters>
                          {row.attributes.service_type_name}
                        </ListItem>
                        <ListItem disableGutters>
                          <Moment>
                            {row.attributes.sort_date.replace("Z", "")}
                          </Moment>
                        </ListItem>
                        <ListItem disableGutters>
                          {row.attributes.team_name}{" "}
                          {row.attributes.team_position_name
                            ? "(" + row.attributes.team_position_name + ")"
                            : ""}{" "}
                        </ListItem>
                        <ListItem disableGutters className="pull-right">
                          {row.attributes.status === "U" && (
                            <div
                              id={"list-" + row.id + "-action"}
                              className="text-center"
                            >
                              {!showConfirms[row.id] && !showDeclines[row.id] && (
                                <>
                                  <div className="flexer">
                                    <Icon className="icon action-icon help-icon">
                                      help
                                    </Icon>
                                    <span>Unconfirmed</span>
                                  </div>
                                  <div
                                    onClick={() => this.toggleConfirm(row.id)}
                                    className="take-action"
                                  >
                                    Take Action
                                  </div>
                                </>
                              )}
                              {showConfirms[row.id] && !showDeclines[row.id] && (
                                <div>
                                  <Button
                                    className="action-btn accept"
                                    component={Link}
                                    onClick={() =>
                                      this.handleConfirm(row.id, 1)
                                    }
                                  >
                                    Accept
                                  </Button>

                                  <Button
                                    className="action-btn decline mt-3"
                                    component={Link}
                                    onClick={() =>
                                      this.handleConfirm(row.id, 0)
                                    }
                                  >
                                    Decline
                                  </Button>
                                </div>
                              )}
                            </div>
                          )}
                          {showDeclines[row.id] && (
                            <div className="flexer">
                              <Icon className="icon cross-icon">clear</Icon>
                              <span className="ml-1">Declined</span>
                            </div>
                          )}
                          {row.attributes.status === "C" && (
                            <div className="flexer">
                              <AcceptedIcon />
                              <span className="ml-1">Accepted</span>
                            </div>
                          )}
                        </ListItem>
                      </List>
                      <Divider />
                    </div>
                  ))}
                </div>
              </Hidden>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Schedules;
