import React from "react";
import queryString from "query-string";
import { Typography, Button } from "@material-ui/core";
import { userService } from "../../components/services/UserService";
import "../../styles/components/dashboard/planning-center.scss";

class Bitly extends React.Component {
  constructor(props) {
    super(props);

    //console.log(this.props.location);
    const values = queryString.parse(this.props.location.search);

    this.state = {
      error: values.error ? true : false,
    };

    if (values.code) {
      userService.authorizeBitlyWith(values.code).then(
        (user) => {
          window.location.href = "/integrations?d=" + Date.now();
          /*const { from } = { from: { pathname: "/dashboard" } };
						this.props.history.push(from);*/
        },
        (error) => console.log(error)
      );
    } else if (values.error) {
      console.log("show error here" + values.error);
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    window.location.href = "/integrations?d=" + Date.now();

    return;
  }

  render() {
    const error = this.state.error;
    return (
      <div className="planning-center authorise">
        <div className="pco">
          <span className="logo-wrapper">
            <img src="./images/bitly-integration.svg" alt="" width="100" />
          </span>
          <Typography>
            {error
              ? "There was an error integrating, please try again"
              : "It is integrating now"}
            . Once integrated, Confirmed.Church will use your bitly account to
            shoten urls in your messages.
          </Typography>
          {error && (
            <Button
              color="primary"
              variant="contained"
              // component={Link}
              onClick={this.handleClick}
              to="#"
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary",
              }}
            >
              Try Again
            </Button>
          )}
        </div>
      </div>
    );
  }
}
export default Bitly;
