import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  group: {
    margin: theme.spacing(1, 0),
  },
}));
function PCRadio() {
  const classes = useStyles();
  const [value, setValue] = React.useState("chosed");

  function handleChange(event) {
    setValue(event.target.value);
  }

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend" variant="h3">
          How to integrate with Planning Center
        </FormLabel>
        <RadioGroup
          aria-label="integration-with-planning-center"
          name="integration-with-planning-center"
          className={classes.group}
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="all"
            control={<Radio />}
            classes={{
              root: "label",
            }}
            label="All folders and servics"
          />

          <FormControlLabel
            value="chosed"
            control={<Radio />}
            classes={{
              root: "label",
            }}
            label="Choose services and folders"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default PCRadio;
