const API_URL = process.env.REACT_APP_API_URL;

export const twilioService = {
  numbers,
};

function numbers(country, selectBy, search, partOfNum, numberType) {
  return fetch(
    API_URL +
      "numbers?country=" +
      country +
      "&selectBy=" +
      selectBy +
      "&search=" +
      search +
      "&partOfNum=" +
      partOfNum +
      "&numberType=" +
      numberType
  )
    .then((res) => res.json())
    .then((data) => {
      return data;
    })
    .catch(console.log);
}
