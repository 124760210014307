import React from "react";
import ReactDataGrid from "react-data-grid";
import "../../styles/components/more/billing.scss";
class LogsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 1 };
    this.rowGetter = this.rowGetter.bind(this);
    this.scrollListener = () => {
      if (
        Math.ceil(this.canvas.clientHeight + this.canvas.scrollTop) >=
        this.canvas.scrollHeight
      ) {
        const {
          loadMoreLogs,
          totalLogs,
          currentLogsCount,
          loadMoreLoading,
        } = this.props;
        const { page } = this.state;
        if (totalLogs > currentLogsCount && !loadMoreLoading) {
          let newpage = page + 1;
          const params = `limit=20&page=${newpage}`;
          this.setState({ page: newpage });
          loadMoreLogs(params);
        }
      }
    };
    this.canvas = null;
  }
  componentDidMount() {
    this.canvas = document.querySelector(".react-grid-Canvas");
    this.canvas.addEventListener("scroll", this.scrollListener);
    this._mounted = true;
  }

  componentWillUnmount() {
    if (this.canvas) {
      this.canvas.removeEventListener("scroll", this.scrollListener);
    }
  }
  getRows() {
    return this.props.rows;
  }

  getSize() {
    return this.getRows().length;
  }
  rowGetter(rowIndex) {
    let rows = this.getRows();
    let _row = rows[rowIndex];
    return _row;
  }

  render() {
    const { columns, rows } = this.props;
    return (
      <div className="react-grid-logs-table">
        {Boolean(rows?.length) && Boolean(columns?.length) && (
          <ReactDataGrid
            columns={columns}
            rowGetter={this.rowGetter}
            rowsCount={rows?.length}
            enableCellSelect={false}
            rowHeight={50}
            minColumnWidth={130}
          />
        )}
      </div>
    );
  }
}

export default LogsTable;
