import React from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
  Typography,
} from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";
import Logo from "./../components/Logo";
import queryString from "query-string";
import { userLogin } from "../services/api";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    this.state = {
      email: "",
      password: "",
      loading: false,
      error: {},
      showPassword: false,
      message: values.message ? values.message : "",
      flashMessage: "",
      verifyUrl: null,
    };
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return this.props.history.push(`/dashboard`);
    }
  }

  handleFlash = () => {
    this.setState({ flashMessage: "" });
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const { email, password } = this.state;
      // stop here if form is invalid
      if (!email && !password) {
        const errorMessage = {
          password: "this field is required",
          email: "this field is required",
        };
        return this.setState({ error: errorMessage });
      } else if (!password || !email) {
        if (!email && password) {
          const errorObj = {};
          errorObj.email = "this field is required";
          return this.setState({ error: errorObj });
        }
        if (email && !password) {
          const errorObj = {};
          errorObj.password = "this field is required";
          return this.setState({ error: errorObj });
        }
      }
      if (email && password) {
        this.setState({
          loading: true,
          error: {},
          flashMessage: "",
          verifyUrl: null,
        });
        const data = await userLogin({ email, password });
        if (data.success) {
          this.setState({ loading: false });
          localStorage.setItem("user", JSON.stringify(data));
          if (window.parent) {
            window.parent.postMessage(
              {
                event: "user",
                user: data,
              },
              "*"
            );
          }
          this.redirectToApp();
        }
      }
    } catch (err) {
      if (!err?.response?.data?.email) {
        const errorObj = {
          password: err?.response?.data?.error,
          email: err?.response?.data?.error,
        };
        this.setState({ error: errorObj, loading: false });
      } else if (err?.response?.data?.email) {
        const { error, email } = err?.response?.data;
        const url = "/verify-email?email=" + encodeURIComponent(email);
        this.setState({ flashMessage: error, verifyUrl: url, loading: false });
      }
    }
  };

  redirectToApp = () => {
    if (this.props.location?.state?.from?.pathname) {
      return this.props.history.push(
        this.props?.location?.state?.from?.pathname
      );
    } else {
      this.props.history.push(`/dashboard?${1}`);
    }
  };

  render() {
    const {
      email,
      password,
      loading,
      error,
      showPassword,
      message,
      flashMessage,
      verifyUrl,
    } = this.state;
    return (
      <div className="page-wrapper login">
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
        <div className="auth-wrapper">
          <div className="login-page">
            {flashMessage && (
              <div className="flash-message mb-1">
                <Typography>
                  {flashMessage}{" "}
                  {verifyUrl ? (
                    <Link to={verifyUrl} className="text-primary">
                      <span className="label">Verify Email</span>
                    </Link>
                  ) : (
                    ""
                  )}
                </Typography>
                <Icon className="close-icon" onClick={this.handleFlash}>
                  close
                </Icon>
              </div>
            )}
            {message === "success" && (
              <div className="success center mb">
                Activation email sent. Please activate to login here.
              </div>
            )}
            {message === "invalid" && (
              <div className="error center mb">Invalid link.</div>
            )}
            <div className="logo-wrapper">
              <Logo href="/" className="logo" />
              <h3>Login</h3>
            </div>
            <form name="form" onSubmit={this.handleSubmit}>
              <TextField
                id="filled-uncontrolled"
                label="Email Or Username"
                name="email"
                value={email}
                onChange={this.handleChange}
                //defaultValue="yourname@email.com"
                margin="normal"
                variant="filled"
                error={error.email}
                helperText={error.email ? error.email : ""}
                FormHelperTextProps={{
                  classes: {
                    root: "helper-text",
                  },
                }}
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
              />
              <TextField
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                      >
                        <Icon>
                          {showPassword ? "visibility" : "visibility_off"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type={showPassword ? "text" : "password"}
                id="filled-uncontrolled"
                label="Password"
                name="password"
                value={password}
                onChange={this.handleChange}
                error={error.password}
                helperText={error.password ? error.password : ""}
                margin="normal"
                variant="filled"
                FormHelperTextProps={{
                  classes: {
                    root: "helper-text",
                  },
                }}
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
              />
              <div>
                <Button
                  color="primary"
                  variant="text"
                  component={Link}
                  to="/forgot"
                  classes={{
                    root: "btn",
                    label: "label",
                    textPrimary: "text-primary",
                  }}
                >
                  <span className="forgot-pw">Forgot your password?</span>
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  /*component={Link}
            to='/dashboard'*/
                  type="submit"
                  classes={{
                    root: "btn",
                    label: "label",
                    containedPrimary: "contained-primary",
                  }}
                >
                  Login
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  component={Link}
                  to="/signup"
                  classes={{
                    root: "btn",
                    label: "label",
                    textPrimary: "text-primary",
                  }}
                >
                  <span className="light">Don't have an account?</span> &nbsp;
                  Create account
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="auth-banner">
          <img src="images/auth-banner.png" alt="" />
        </div>
      </div>
    );
  }
}

export default LoginPage;
