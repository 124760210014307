import React from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import queryString from "query-string";
import { Grid, RadioGroup, Icon } from "@material-ui/core";
import HeaderServices from "./HeaderServices";
import ServiceListDesktop from "./ServiceListDesktop";
import FoldersList from "./FoldersList";
import ServiceTabs from "./ServiceTabs";
import PeopleListDesktop from "./PeopleListDesktop";
import { authHeader } from "./../helpers/AuthHeader";
import { userService } from "./UserService";
import "../../styles/components/responsive-design.scss";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class Services extends React.Component {
  constructor(props) {
    super(props);

    const values = this.props.location
      ? queryString.parse(this.props.location.search)
      : [];

    this.state = {
      folder: "Upcoming Services",
      folderId: 1,
      serviceId: values.service ? values.service : 0,
      service: values.serviceName ? values.serviceName : "",
      planId: values.plan ? values.plan : 0,
      plan: values.planName ? values.planName : "",
      people: values.personName ? values.personName : "",
      personId: values.personId ? values.personId : "",
      type: "",
      service_loading: false,
      serviceTypes: [],
      plan_loading: false,
      planList: [],
      people_loading: false,
      peoplePerDay: [],
      folder_loading: false,
      folderList: [{ id: 1, status: "close", title: "Upcoming Services" }],
    };
    this.handleFolder = this.handleFolder.bind(this);
    this.handleService = this.handleService.bind(this);
    this.handlePlan = this.handlePlan.bind(this);
  }

  handleFolder = (event) => {
    var arr = event.target.value.split("::");
    var folders = this.state.folderList;
    // eslint-disable-next-line array-callback-return
    folders.map((f, i) => {
      if (f.id === arr[1]) {
        folders[i].status = "close";
      } else {
        folders[i].status = "open";
      }
    });
    this.setState({ folder: arr[0], folderId: arr[1], folderList: folders });
    this.refreshServices(false, arr[1]);
  };
  handleService = (event) => {
    var arr = event.target.value.split("::");
    this.setState({ service: arr[0], serviceId: arr[1] });
    this.refreshWeeklyService(false, arr[1], true);
  };
  handlePlan = (event) => {
    var arr = event.target.value.split("::");
    this.setState({ plan: arr[0], planId: arr[1] });
    this.refreshLog(false, arr[1], true);
  };
  handlePeople = (event) => {
    var arr = event.target.value.split("::");
    this.setState({ people: arr[0] });
  };

  refreshFolders(reload) {
    if (reload) {
      this.props.showSync();
    }
    var _this = this;
    _this.setState({ folder_loading: true });
    fetch(API_URL + "scheduling/folders", {
      headers: authHeader(),
    })
      .then((res) => res.json())
      .then(
        (data) => {
          if (data.error) {
            userService.refreshToken();
            _this.refreshFolders(true);
            return;
          }
          var folders = _this.state.folderList;
          data.map((f, i) => {
            return (folders[i + 1] = f);
          });
          _this.setState({ folderList: folders, folder_loading: false });
        },
        (error) => {
          _this.setState({ folder_loading: false });
        }
      )
      .catch(console.log);
  }

  refreshServices(reload, folderId) {
    if (reload) {
      this.props.showSync();
    }
    folderId = folderId ? folderId : this.state.folderId;
    var _this = this;
    // cache.getItem("serviceTabs" + folderId, function (err, data) {
    //   if (!reload && data) {
    //     _this.setState({ serviceTypes: data });
    //   } else {
    _this.setState({ service_loading: true });
    fetch(
      API_URL +
        "scheduling/services" +
        (folderId && folderId !== 1 ? "/" + folderId : ""),
      {
        headers: authHeader(),
      }
    )
      .then((res) => res.json())
      .then(
        (data) => {
          if (data.error) {
            userService.refreshToken();
            _this.refreshServices(true);
            return;
          }
          //console.log(data);
          _this.setState({ serviceTypes: data, service_loading: false });
          if (data.length > 0) {
            _this.setState({
              service: data[0]["title"],
              serviceId: data[0]["id"],
            });
            //if(data[0]['confirmed'] > 0) {
            _this.refreshWeeklyService(true, null, true);
            //}
          } else {
            _this.setState({ service: "", serviceId: "", planList: [] });
          }
          // cache.setItem("serviceTabs" + folderId, data, function (err) { });
        },
        (error) => {
          _this.setState({ service_loading: false });
        }
      )
      .catch(console.log);
    //   }
    // });
  }

  refreshWeeklyService(reload, serviceId, auto) {
    if (!auto) {
      this.props.showSync();
    }
    serviceId = serviceId ? serviceId : this.state.serviceId;
    const { type } = this.state;
    var _this = this;
    // cache.getItem("weeklyWorship" + serviceId + type, function (err, data) {
    // if (!reload && data) {
    //   _this.setState({ planList: data });
    //   _this.setState({ plan: "", planId: "", peoplePerDay: [] });
    //   if (data.length > 0) {
    //     _this.setState({ plan: data[0]["title"], planId: data[0]["id"] });
    //     _this.refreshLog(false, data[0]["id"], true);
    //   }
    // } else {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    _this.setState({ plan_loading: true });
    return fetch(
      `${API_URL}scheduling/plans/${serviceId}?type=${type}`,
      requestOptions
    )
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          userService.refreshToken();
          _this.refreshWeeklyService(true, null, true);
          _this.setState({ plan_loading: false });
          return;
        }
        _this.setState({ planList: data, plan_loading: false });
        if (data.length > 0) {
          _this.setState({ plan: data[0]["title"], planId: data[0]["id"] });
          //if(data[0]['confirmed'] > 0) {
          _this.refreshLog(true, data[0]["id"], true);
          //}
        } else {
          _this.setState({ plan: "", planId: "", peoplePerDay: [] });
        }
        // cache.setItem(
        //   "weeklyWorship" + serviceId + type,
        //   data,
        //   function (err) { }
        // );
      })
      .catch(console.log);
    // }
    // });
  }

  refreshLog(reload, planId, auto) {
    if (!auto) {
      this.props.showSync();
    }
    planId = planId ? planId : this.state.planId;
    const { serviceId, service, type } = this.state;
    var _this = this;
    // cache.getItem(
    //   "logPeople" + serviceId + planId + type,
    //   function (err, data) {
    //     if (!reload && data) {
    //       _this.setState({ peoplePerDay: data });
    //     } else {
    _this.setState({ people_loading: true });
    fetch(
      API_URL +
        "logs-people/" +
        planId +
        "/" +
        type +
        "?serviceId=" +
        serviceId +
        "&serviceName=" +
        service,
      {
        headers: authHeader(),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        _this.setState({ peoplePerDay: data, people_loading: false });
        // cache.setItem(
        //   "logPeople" + serviceId + planId + type,
        //   data,
        //   function (err) { }
        // );
      })
      .catch(console.log);
    //     }
    //   }
    // );
  }

  componentDidMount() {
    this.refreshFolders();
    this.refreshServices();
  }

  render() {
    const {
      folder,
      folderId,
      type,
      service,
      serviceId,
      planId,
      plan,
      people,
      serviceTypes,
      service_loading,
      planList,
      plan_loading,
      peoplePerDay,
      people_loading,
      folderList,
      folder_loading,
    } = this.state;
    const folderTitle = (
      <span className="left-content">
        <Icon className="icon">folder_open</Icon>
        {folder}
      </span>
    );
    return (
      <div className="page-wrapper">
        <div className="desktop-design services">
          <Grid container className="service-container-wrapper">
            <Grid item md={3} className="service-content folders">
              <HeaderServices
                title="Services"
                hasReload
                reload={() => this.refreshFolders(true)}
                //hasSearch
              />
              <div className={folder_loading ? "sweet-loading" : ""}>
                <ClipLoader
                  css={override}
                  sizeUnit={"px"}
                  size={150}
                  color={"#7BC807"}
                  loading={folder_loading}
                />
              </div>
              <div className="lists">
                <RadioGroup
                  className="services-wrapper"
                  aria-label="folder"
                  name="folder"
                  value={folder + "::" + folderId}
                  onChange={this.handleFolder}
                >
                  {folderList.map((data, idx) => (
                    <FoldersList
                      title={data.title}
                      id={data.id}
                      status={data.status}
                      key={idx}
                    />
                  ))}
                </RadioGroup>
              </div>
            </Grid>
            <Grid item md={3} className="service-content service-types">
              <HeaderServices
                title={folderTitle}
                //hasSearch
                hasReload
                reload={() => this.refreshServices(true)}
              />
              <div className={service_loading ? "sweet-loading" : ""}>
                <ClipLoader
                  css={override}
                  sizeUnit={"px"}
                  size={150}
                  color={"#7BC807"}
                  loading={service_loading}
                />
              </div>
              <div className="lists">
                <RadioGroup
                  className="services-wrapper"
                  aria-label="service"
                  name="service"
                  value={service + "::" + serviceId}
                  onChange={this.handleService}
                >
                  {serviceTypes.map((data, idx) => (
                    <ServiceListDesktop
                      title={data.title}
                      id={data.id}
                      confirmed={data.confirmed}
                      total={data.total}
                      key={idx}
                      //status={data.status}
                    />
                  ))}
                </RadioGroup>
              </div>
            </Grid>

            <Grid item md={3} className="service-content service-plans">
              <HeaderServices
                title={service}
                //hasSearch
                hasReload
                reload={() => this.refreshWeeklyService(true)}
              />
              <div className={plan_loading ? "sweet-loading" : ""}>
                <ClipLoader
                  css={override}
                  sizeUnit={"px"}
                  size={150}
                  color={"#7BC807"}
                  loading={plan_loading}
                />
              </div>
              <div className="lists">
                <RadioGroup
                  className="services-wrapper"
                  aria-label="plan"
                  name="plan"
                  value={plan + "::" + planId}
                  onChange={this.handlePlan}
                >
                  {planList.map((data, idx) => (
                    <ServiceListDesktop
                      title={data.title}
                      id={data.id}
                      confirmed={data.confirmed}
                      total={data.total}
                      key={idx}
                      //status={data.status}
                    />
                  ))}
                </RadioGroup>
              </div>
            </Grid>

            <Grid item md={3} className="service-content service-people">
              <HeaderServices
                title={plan}
                //hasSearch
                hasReload
                reload={() => this.refreshLog(true)}
              />
              <div className={people_loading ? "sweet-loading" : ""}>
                <ClipLoader
                  css={override}
                  sizeUnit={"px"}
                  size={150}
                  color={"#7BC807"}
                  loading={people_loading}
                />
              </div>

              <div className="lists">
                <RadioGroup
                  className="services-wrapper"
                  aria-label="people"
                  name="people"
                  value={people}
                  onChange={this.handlePeople}
                >
                  {peoplePerDay.map((data, idx) => (
                    <PeopleListDesktop
                      type={type}
                      plan={planId}
                      personId={data.id}
                      name={data.name}
                      status={data.status}
                      position={data.position}
                      number={data.phone}
                      message={data.message ? data.message : false}
                      error_message={
                        data.error_message ? data.error_message : false
                      }
                      key={idx}
                    />
                  ))}
                </RadioGroup>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="mobile-design">
          <HeaderServices
            title="Services"
            //hasReload
            //hasSearch
          />
          <div className={service_loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={service_loading}
            />
          </div>
          <ServiceTabs serviceList={serviceTypes} folderList={folderList} />
        </div>
      </div>
    );
  }
}

export default Services;
