import React from "react";
import { Checkbox, Typography, FormControlLabel } from "@material-ui/core";
import "./../styles/atoms/person-single.scss";

export default function PersonSingle(props) {
  return (
    <div className="person-single">
      <FormControlLabel
        checked={props.selected}
        control={<Checkbox className="checkbox" />}
      />
      <div>
        <Typography className="name">{props.name}</Typography>
        <Typography className="service-day">
          {props.service}/{props.day}
        </Typography>
      </div>
    </div>
  );
}
