import React from "react";
import { withStyles } from "@material-ui/core/styles";
import cn from "classnames";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Button,
  Dialog,
  Icon,
  Grid,
  TextField,
  MenuItem,
  Menu,
  Checkbox,
  ListItemText,
  Typography,
  IconButton,
} from "@material-ui/core";
import "./../../styles/components/more/billing.scss";

const styles = (theme) => ({
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 30,
    alignItems: "center",
  },
  pageTitle: {
    fontSize: 24,
  },
  selectStyledButton: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f2f2f2",
    borderBottom: "1px solid #707070",
    borderRadius: 0,
    color: "#999",
    fontSize: 13,
    height: 50,
    width: "100%",
    textTransform: "none",
    padding: "0 8px",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "absolute",
    bottom: 45,
    left: 30,
    right: 30,
  },
  containedLabel: {
    color: "#fff",
  },
  searchBtn: {
    marginLeft: 20,
  },
});

class LogsFilterModal extends React.Component {
  constructor(props) {
    super(props);

    const { classes } = props;
    this.state = {
      classes: classes,
      open: false,
      fromDate: props.state.fromDate,
      toDate: props.state.toDate,
      selectStatus: props.state.selectStatus,
      service: props.state.service,
      from: props.state.from,
      to: props.state.to,
      status: props.state.status,
      initial: props.state,
    };

    this.handleClickStatusSelect = this.handleClickStatusSelect.bind(this);
    this.handleCloseStatusSelect = this.handleCloseStatusSelect.bind(this);
    this.handleFromDateChange = this.handleFromDateChange.bind(this);
    this.handleToDateChange = this.handleToDateChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  handleClickStatusSelect = (event) => {
    this.setState({ selectStatus: event.currentTarget });
  };

  handleChange = (event) => {
    this.setState({ [event.currentTarget.id]: event.currentTarget.value });
    this.props.handleChange(event);
  };

  handleCheck = (event) => {
    var status = this.state.status;
    status[event.currentTarget.value] = event.currentTarget.checked;
    this.setState({ status: status });
    this.props.handleCheck(event);
  };

  handleCloseStatusSelect = () => {
    this.setState({ selectStatus: null });
  };
  handleFromDateChange(e, date) {
    this.setState({ fromDate: date });
    this.props.fromDateChange(e);
  }
  handleToDateChange(e, date) {
    this.setState({ toDate: date });
    this.props.toDateChange(e);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleReset = () => {
    this.setState({
      fromDate: this.state.initial.fromDate,
      toDate: this.state.initial.toDate,
      selectStatus: this.state.initial.selectStatus,
      service: this.state.initial.service,
      from: this.state.initial.from,
      to: this.state.initial.to,
      status: this.state.initial.status,
      open: false,
    });
    this.props.handleReset();
  };

  handleSearch = () => {
    this.setState({ open: false });
    this.props.handleSearch();
  };

  render() {
    const {
      classes,
      fromDate,
      toDate,
      from,
      to,
      service,
      selectStatus,
      open,
      status,
    } = this.state;
    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={this.handleClickOpen}
          classes={{
            root: "btn",
            label: "label",
            containedPrimary: "contained-primary search-button",
          }}
        >
          <Icon>search</Icon>
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          classes={{
            root: "add-new-card delete",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <div className={classes.modalHeader}>
            <Typography
              component={"h3"}
              className={cn("page-title", classes.pageTitle)}
            >
              Search Logs
            </Typography>
            <IconButton className="close-icon" onClick={this.handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          <Grid container spacing={2} className="container">
            <Grid item style={{ width: "50%" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="date-picker"
                  disableToolbar
                  variant="inline"
                  invalidDateMessage=""
                  margin="normal"
                  id="from-date-picker"
                  placeholder="From date"
                  format="MM/dd/yyyy"
                  value={fromDate}
                  onChange={this.handleFromDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item style={{ width: "50%" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className="date-picker"
                  disableToolbar
                  variant="inline"
                  invalidDateMessage=""
                  margin="normal"
                  id="to-date-picker"
                  placeholder="To date"
                  format="MM/dd/yyyy"
                  value={toDate}
                  onChange={this.handleToDateChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <TextField
                id="service"
                fullWidth
                variant="filled"
                placeholder="Service"
                value={service}
                onChange={this.handleChange}
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <TextField
                id="from"
                fullWidth
                variant="filled"
                placeholder="From"
                value={from}
                onChange={this.handleChange}
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <TextField
                id="to"
                fullWidth
                variant="filled"
                placeholder="To"
                value={to}
                onChange={this.handleChange}
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
              />
            </Grid>
            <Grid item style={{ width: "100%" }}>
              <Button
                className={classes.selectStyledButton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={this.handleClickStatusSelect}
              >
                <span>Status</span> <Icon>arrow_drop_down</Icon>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={selectStatus}
                keepMounted
                open={Boolean(selectStatus)}
                onClose={this.handleCloseStatusSelect}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                getContentAnchorEl={null | undefined}
                classes={{
                  list: "status-select-menu",
                }}
              >
                <MenuItem>
                  <Checkbox
                    value="delivered"
                    checked={status.delivered}
                    onChange={this.handleCheck}
                  />
                  <ListItemText className="label" primary="Delivered" />
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    value="sent"
                    checked={status.sent}
                    onChange={this.handleCheck}
                  />
                  <ListItemText className="label" primary="Sent" />
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    value="queued"
                    checked={status.queued}
                    onChange={this.handleCheck}
                  />
                  <ListItemText className="label" primary="Queued" />
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    value="failed"
                    checked={status.failed}
                    onChange={this.handleCheck}
                  />
                  <ListItemText className="label" primary="Failed" />
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    value="undelivered"
                    checked={status.undelivered}
                    onChange={this.handleCheck}
                  />
                  <ListItemText className="label" primary="Undelivered" />
                </MenuItem>
                <MenuItem>
                  <Checkbox
                    value="other"
                    checked={status.other}
                    onChange={this.handleCheck}
                  />
                  <ListItemText className="label" primary="Other" />
                </MenuItem>
              </Menu>
            </Grid>
            <div className={classes.footer}>
              <Button
                color="primary"
                variant="text"
                onClick={this.handleClose}
                classes={{
                  root: "btn",
                  label: "label",
                  textPrimary: "text-primary",
                }}
              >
                <span className="forgot-pw">Cancel</span>
              </Button>
              <span>
                <Button
                  variant="outlined"
                  color="primary"
                  classes={{
                    root: "btn",
                    label: "label",
                    outlined: "outlined",
                  }}
                  onClick={this.handleReset}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  //type="submit"
                  onClick={this.handleSearch}
                  classes={{
                    root: cn("btn", classes.searchBtn),
                    label: cn("label", classes.containedLabel),
                    containedPrimary: "contained-primary",
                  }}
                >
                  Search
                </Button>
              </span>
            </div>
          </Grid>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(LogsFilterModal);
