import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { MenuItem, Icon, Typography } from "@material-ui/core";
import Avatar from "react-avatar";
import { ReactComponent as PeopleIcon } from "../../icons/people-icon.svg";
import { ReactComponent as MyProfileIcon } from "../../icons/profile-icon.svg";
import { ReactComponent as BillingIcon } from "../../icons/billing-icon.svg";
import { ReactComponent as IntegrationsIcon } from "../../icons/integration-icon.svg";
import { ReactComponent as ScheduleIcon } from "../../icons/schedule-icon.svg";
import { ReactComponent as TemplateIcon } from "../../icons/template-icon.svg";
import { ReactComponent as KeywordIcon } from "../../icons/keyword-icon.svg";
import { ReactComponent as LogoutIcon } from "../../icons/logout-icon.svg";
import { ReactComponent as MoneyIcon } from "../../icons/money-icon.svg";
import { ReactComponent as CouponIcon } from "../../icons/coupon-icon.svg";
import { ReactComponent as LogIcon } from "../../icons/logs.svg";
import { userService } from "./../services/UserService";
import { formatPhoneNumber, logout, isCordovaApp } from "../../helpers/helpers";
import TooltipWhenOverflow from "../HelperComponents/TooltipWhenOverflow";
import "../../styles/components/more/pages-submenu.scss";

class PagesSubMenu extends React.Component {
  handleLogout = () => {
    logout();
    this.props.history.push("/login");
  };

  stopImpersonate = (e) => {
    e.preventDefault();
    userService.stopImpersonate();
    return;
  };

  render() {
    const { currentUserInfo, phoneNumber, globalLoading } = this.props;
    const isCordova = isCordovaApp();
    var impersonate = localStorage.getItem("impersonate");
    return (
      <div className="sub-menu">
        <MenuItem className="menuitem-wrapper name-title">
          <Avatar
            name={currentUserInfo?.name}
            email={currentUserInfo?.email}
            className="avatar"
          />
          <div>
            <h3 className="name">
              <TooltipWhenOverflow
                maxWidth={150}
                text={currentUserInfo?.first_name}
              >
                {currentUserInfo?.first_name}
              </TooltipWhenOverflow>
            </h3>
            <h5 className="name" style={{ fontSize: 12 }}>
              <TooltipWhenOverflow
                maxWidth={150}
                text={currentUserInfo?.churches[0]?.name}
              >
                {currentUserInfo?.churches[0]?.name}
              </TooltipWhenOverflow>
            </h5>
          </div>
        </MenuItem>
        <div className="menuitem-wrapper number">
          {Boolean(currentUserInfo?.is_admin) && !impersonate ? (
            <MenuItem className="menuitem">
              <Typography className="number-text">
                <span>Admin</span>
              </Typography>
            </MenuItem>
          ) : (
            <MenuItem
              className={
                globalLoading || phoneNumber
                  ? "menuitem"
                  : "menuitem addNumberLink"
              }
              component={NavLink}
              to="/number"
            >
              <Typography className="number-text">
                {globalLoading || phoneNumber ? (
                  <span>
                    {formatPhoneNumber(
                      phoneNumber?.number,
                      phoneNumber?.country
                    )}
                  </span>
                ) : (
                  <span>Add a Number</span>
                )}
                <Icon>arrow_forward_ios</Icon>
              </Typography>
            </MenuItem>
          )}
        </div>
        {!Boolean(currentUserInfo?.is_admin) && (
          <div className="menuitem-wrapper">
            <MenuItem
              className="menuitem"
              component={NavLink}
              to="/user-settings"
            >
              <span className="icon">
                <MyProfileIcon />
              </span>
              <span className="text">My profile</span>
            </MenuItem>
            <MenuItem
              className="menuitem"
              component={NavLink}
              to="/integrations"
            >
              <span className="icon">
                <IntegrationsIcon />
              </span>
              <span className="text">Integrations</span>
            </MenuItem>
            {!isCordova && (
              <MenuItem className="menuitem" component={NavLink} to="/billing">
                <span className="icon">
                  <BillingIcon />
                </span>
                <span className="text">Billing</span>
              </MenuItem>
            )}
            <MenuItem className="menuitem" component={NavLink} to="/logs">
              <span className="icon">
                <LogIcon />
              </span>
              <span className="text">Logs</span>
            </MenuItem>
            <MenuItem className="menuitem" component={NavLink} to="/number">
              <Icon className="icon">call</Icon>
              <span className="text">My Numbers</span>
            </MenuItem>
          </div>
        )}
        {Boolean(currentUserInfo?.is_admin) && !impersonate ? (
          <div className="menuitem-wrapper">
            <MenuItem className="menuitem" component={NavLink} to="/users">
              <span className="icon">
                <PeopleIcon />
              </span>
              <span className="text">Users</span>
            </MenuItem>
            <MenuItem className="menuitem" component={NavLink} to="/pricing">
              <span className="icon">
                <MoneyIcon />
              </span>
              <span className="text">Pricing</span>
            </MenuItem>
            <MenuItem className="menuitem" component={NavLink} to="/coupons">
              <span className="coupon icon">
                <CouponIcon />
              </span>
              <span className="text">Coupons</span>
            </MenuItem>
          </div>
        ) : (
          <div className="menuitem-wrapper">
            <MenuItem
              className="menuitem"
              component={NavLink}
              to="/schedule-templates"
            >
              <span className="icon">
                <ScheduleIcon />
              </span>
              <span className="text">Schedule templates</span>
            </MenuItem>
            <MenuItem
              className="menuitem"
              component={NavLink}
              to="/message-templates"
            >
              <span className="icon">
                <TemplateIcon />
              </span>
              <span className="text">Message templates</span>
            </MenuItem>
            <MenuItem className="menuitem" component={NavLink} to="/keywords">
              <span className="icon">
                <KeywordIcon />
              </span>
              <span className="text">Keywords</span>
            </MenuItem>
          </div>
        )}
        {impersonate && (
          <MenuItem
            className="menuitem-wrapper"
            onClick={this.stopImpersonate}
            style={{ height: 45 }}
          >
            <div className="menuitem logout">
              <span className="icon">
                <PeopleIcon />
              </span>
              <span className="text">Return to Admin</span>
            </div>
          </MenuItem>
        )}
        <MenuItem className="menuitem-wrapper" onClick={this.handleLogout}>
          <div className="menuitem logout">
            <span className="icon">
              <LogoutIcon />
            </span>
            <span className="text">Log out</span>
          </div>
        </MenuItem>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    currentUserInfo: store.userProfileReducer.user,
    church: store.userProfileReducer.church,
    globalLoading: store.global.globalLoading,
    phoneNumber: store.userProfileReducer.phone_number,
  };
};

export default connect(mapStateToProps, null)(withRouter(PagesSubMenu));
