import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  Icon,
  Fab,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import CustomSwitch from "./../../atoms/CustomSwitch";
import { authHeader } from "./../helpers/AuthHeader";
import "./../../styles/components/more/billing.scss";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

var valid = require("card-validator");

class AddNewCard extends React.Component {
  constructor(props) {
    super(props);

    var user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      open: false,
      changePayment: false,
      paymentType: "card",
      cardNumber: "", //'4242424242424242',
      expiry: "", //'12/24',
      cvc: "", //'123',
      firstName: user.success.user.first_name,
      lastName: user.success.user.last_name,
      email: user.success.user.email,
      address: "", //'Nepal',
      city: "", //'Butwal',
      state: "", //'Lumbini',
      zipcode: "", //'12345',
      country: "US",
      loading: false,
      defaultPayment: true,
      typingTimeout: 0,
      error: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (prop) => (event) => {
    var value = event.target.value;
    var name = prop;
    this.setState({
      [prop]:
        name == "expiry" && !isNaN(value) && value.length == 2
          ? value + "/"
          : value,
    });

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    var _this = this;
    this.setState({
      typingTimeout: setTimeout(function () {
        var errors = _this.state.error;
        if (
          name == "cardNumber" &&
          value.length > 0 &&
          !valid.number(value).isValid
        ) {
          errors["cardNumber"] = new String("Invalid card number");
        } else if (name == "cardNumber") {
          errors["cardNumber"] = "";
        }

        var numberValidation = valid.number(_this.state.cardNumber);
        var cvvLength =
          numberValidation.card &&
          numberValidation.card.type == "american-express"
            ? 4
            : 3;
        if (
          name == "cvc" &&
          value.length > 0 &&
          !valid.cvv(value, cvvLength).isValid
        ) {
          errors["cvc"] = new String("Invalid cvc");
        } else if (name == "cvc") {
          errors["cvc"] = "";
        }

        if (
          name == "expiry" &&
          value.length > 0 &&
          !valid.expirationDate(value).isValid
        ) {
          errors["expiry"] = new String("Invalid expiry");
        } else if (name == "expiry") {
          errors["expiry"] = "";
        }

        _this.setState({ error: errors });
      }, 1000),
    });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSwitch = () => {
    this.setState({ defaultPayment: !this.state.defaultPayment });
  };

  handleSubmit = () => {
    this.setState({ error: {} });

    var numberValidation = valid.number(this.state.cardNumber);
    var cvvValidation = valid.cvv(
      this.state.cvc,
      numberValidation.card && numberValidation.card.type == "american-express"
        ? 4
        : 3
    );
    var expValidation = valid.expirationDate(this.state.expiry);

    if (
      !numberValidation.isValid ||
      !cvvValidation.isValid ||
      !expValidation.isValid
    ) {
      var errors = [];
      if (!numberValidation.isValid) {
        errors["cardNumber"] = new String("Invalid card number");
      }

      if (!cvvValidation.isValid) {
        errors["cvc"] = new String("Invalid cvc");
      }

      if (!expValidation.isValid) {
        errors["expiry"] = new String("Invalid expiry");
      }

      this.setState({ error: errors });
      return;
    }

    this.setState({ loading: true, open: false });
    if (this.props.inline) {
      this.props.handleLoading(true, "");
    }
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.state),
    };
    return fetch(`${API_URL}create-update-card`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status != 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          this.setState({ open: true });
          if (data.error) {
            if (typeof data.error === "string") {
              this.setState({ error: { errorText: data.error } });
            } else {
              this.setState({ error: data.error });
            }
          }
          this.setState({ loading: false });
          this.props.handleLoading(false, "");
        } else {
          this.props.handler().then((data) => {
            this.setState({ loading: false });
            this.props.handleLoading(false, "Card added successfully");
          });
        }
      })
      .catch(console.log);
  };

  render() {
    const {
      paymentType,
      cardNumber,
      expiry,
      cvc,
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      zipcode,
      open,
      defaultPayment,
      loading,
      error,
    } = this.state;
    return (
      <div>
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>

        {this.props.withIcon ? (
          <Typography className="label">
            <span>Payment method</span>
            <Fab
              color="primary"
              aria-label="Add"
              className="fab payment"
              onClick={this.handleClickOpen}
            >
              <Icon>add</Icon>
            </Fab>
          </Typography>
        ) : (
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={this.handleClickOpen}
            classes={{
              root: "btn",
              label: "label",
              containedPrimary: "contained-primary",
            }}
          >
            Add a New Payment Method
          </Button>
        )}

        <Dialog
          fullScreen={window.innerWidth < 768 && true}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          classes={{
            root: "add-new-card",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <div className="header">
            <Typography component={"h2"} className="title">
              Add new card
            </Typography>
            <IconButton className="close-icon" onClick={this.handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          <div className="modal-content">
            {typeof error.errorText === "string" && (
              <div
                className={"error alt alert-danger"}
                dangerouslySetInnerHTML={{ __html: error.errorText }}
              />
            )}
            <Grid container spacing={2} className="container">
              {/*<Grid item style={{ width: '100%' }}>
            <TextField
                id="payment-type-select"
                select
                label="Select Payment Type"
                value={paymentType}
                onChange={this.handleChange('paymentType')}
                variant='filled'
                fullWidth
                classes={{
                  root: 'text-field',
                }}
                InputLabelProps={{
                  classes: {
                    root: 'label'
                  }
                }}
                InputProps={{
                  classes: {
                    root: 'input-wrapper',
                    input: 'input'
                  }
                }}
                SelectProps={{
                   MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left', }
                  }
                }}
            >
                  <MenuItem value='card'>Add new card</MenuItem>
                  <MenuItem value='paypal'>Add Paypal</MenuItem>
                  <MenuItem value='visa'>Add Visa card</MenuItem>
              </TextField>
            </Grid>*/}
              <Grid
                item
                style={{ width: window.innerWidth > 768 ? "40%" : "100%" }}
              >
                <TextField
                  id="card-number"
                  label="Credit Card Number"
                  fullWidth
                  variant="filled"
                  value={cardNumber}
                  onChange={this.handleChange("cardNumber")}
                  error={typeof error.cardNumber === "object"}
                  helperText={error.cardNumber ? error.cardNumber : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ width: window.innerWidth > 768 ? "30%" : "50%" }}
              >
                <TextField
                  id="exp-date"
                  label="Exp.date (MM/YY)"
                  fullWidth
                  variant="filled"
                  value={expiry}
                  onChange={this.handleChange("expiry")}
                  error={typeof error.expiry === "object"}
                  helperText={error.expiry ? error.expiry : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ width: window.innerWidth > 768 ? "30%" : "50%" }}
              >
                <TextField
                  id="cvc-code"
                  label="CVC CODE"
                  fullWidth
                  variant="filled"
                  value={cvc}
                  onChange={this.handleChange("cvc")}
                  error={typeof error.cvc === "object"}
                  helperText={error.cvc ? error.cvc : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ width: window.innerWidth > 768 ? "50%" : "100%" }}
              >
                <TextField
                  id="first-name"
                  label="Cardholder Name"
                  value={firstName}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("firstName")}
                  error={typeof error.firstName === "object"}
                  helperText={error.firstName ? error.firstName : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ width: window.innerWidth > 768 ? "50%" : "100%" }}
              >
                <TextField
                  id="lastName"
                  label="Cardholder Last Name"
                  value={lastName}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("lastName")}
                  error={typeof error.lastName === "object"}
                  helperText={error.lastName ? error.lastName : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography className="switch">
                  <CustomSwitch
                    onClick={this.handleSwitch}
                    checked={defaultPayment}
                  />{" "}
                  Set as default payment method
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  id="email"
                  label="Email invoices to"
                  value={email}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("email")}
                  error={typeof error.email === "object"}
                  helperText={error.email ? error.email : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="container">
              <Typography className="address-title">Billing address</Typography>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  id="address"
                  label="Address"
                  value={address}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("address")}
                  error={typeof error.address === "object"}
                  helperText={error.address ? error.address : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ width: window.innerWidth > 768 ? "45%" : "50%" }}
              >
                <TextField
                  id="city"
                  label="City"
                  value={city}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("city")}
                  error={typeof error.city === "object"}
                  helperText={error.city ? error.city : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ width: window.innerWidth > 768 ? "30%" : "50%" }}
              >
                <TextField
                  id="state"
                  label="State"
                  value={state}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("state")}
                  error={typeof error.state === "object"}
                  helperText={error.state ? error.state : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                style={{ width: window.innerWidth > 768 ? "25%" : "100%" }}
              >
                <TextField
                  id="zip-code"
                  label="Zipcode"
                  value={zipcode}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("zipcode")}
                  error={typeof error.zipcode === "object"}
                  helperText={error.zipcode ? error.zipcode : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="action-buttons">
            <Button
              color="primary"
              variant="text"
              onClick={this.handleClose}
              classes={{
                root: "btn",
                label: "label",
                textPrimary: "text-primary",
              }}
            >
              <span className="forgot-pw" style={{ color: "#7bc807" }}>
                Cancel
              </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={this.handleSubmit}
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary",
              }}
            >
              Save
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default AddNewCard;
