import React from "react";
import { Typography } from "@material-ui/core";
import Moment from "react-moment";
import "./../../styles/components/more/billing.scss";

Moment.globalFormat = "DD.MM.YYYY";
Moment.globalLocal = true;

function SingleInvoice(props) {
  return (
    <div>
      {props.invoices.map((transaction, idx) => (
        <div className="single-invoice" key={idx}>
          <div className="content">
            <Typography className="light-text">
              Date:<Moment format="MM.DD.YYYY">{transaction.created_at}</Moment>{" "}
              &nbsp; ID:{transaction.charge_id}
            </Typography>
            <Typography className="dark-text">
              {transaction.package.name} /
              <Moment format={transaction.is_monthly ? "MMMM" : "YYYY"}>
                {transaction.created_at}
              </Moment>{" "}
              -&nbsp;
              {transaction.is_monthly === 1 && (
                <Moment add={{ months: 1 }} format="MMMM">
                  {transaction.created_at}
                </Moment>
              )}
              {!transaction.is_monthly && (
                <Moment add={{ years: 1 }} format="YYYY">
                  {transaction.created_at}
                </Moment>
              )}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
}
export default SingleInvoice;
