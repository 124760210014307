import React from "react";
import ReactDataGrid from "react-data-grid";

class OrganizationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { page: 1 };
    this.rowGetter = this.rowGetter.bind(this);
    this.scrollListener = () => {
      if (
        Math.ceil(this.canvas.clientHeight + this.canvas.scrollTop) >=
        this.canvas.scrollHeight
      ) {
        const {
          allOrganizations,
          rows,
          populateData,
          isFiltersActive,
        } = this.props;
        if (allOrganizations.length > rows.length && !isFiltersActive) {
          const loadMoreData = allOrganizations.slice(
            rows.length,
            rows.length + 15
          );
          populateData(loadMoreData);
        }
      }
    };
    this.canvas = null;
  }
  componentDidMount() {
    if (this.props.rows.length) {
      this.canvas = document.querySelector(".react-grid-Canvas");
      this.canvas.addEventListener("scroll", this.scrollListener);
      this._mounted = true;
    }
  }

  componentWillUnmount() {
    if (this.canvas) {
      this.canvas.removeEventListener("scroll", this.scrollListener);
    }
  }
  getRows() {
    return this.props.rows;
  }

  getSize() {
    return this.getRows().length;
  }
  rowGetter(rowIndex) {
    let rows = this.getRows();
    let _row = rows[rowIndex];
    return _row;
  }

  RowRenderer = ({ renderBaseRow, ...props }) => {
    const pendingRowStyle = props.row.status === "Pending" ? "pending-row" : "";
    return (
      <div className={pendingRowStyle}>
        <div>{renderBaseRow(props)}</div>
      </div>
    );
  };

  render() {
    const { columns, rows, getCellActions } = this.props;
    return (
      <div className="react-grid-users-table">
        {Boolean(rows.length) && (
          <ReactDataGrid
            idProperty="id"
            columns={columns}
            rowGetter={this.rowGetter}
            rowsCount={rows?.length}
            rowHeight={50}
            onRowClick={getCellActions}
            rowRenderer={this.RowRenderer}
          />
        )}
      </div>
    );
  }
}

export default OrganizationTable;
