import React from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import ScheduleTemplates from "./components/more/ScheduleTemplates";
import Keywords from "./components/more/Keywords";
import EditSchedule from "./components/more/EditSchedule";
import EditKeyword from "./components/more/EditKeyword";
import CreateSchedule from "./components/more/CreateSchedule";
import AddConfirmationTime from "./components/more/AddConfirmationTime";
import AddConfirmationText from "./components/more/AddConfirmationText";
import MessageTemplates from "./components/more/MessageTemplates";
import NewTemplate from "./components/more/NewTemplate";
import Notifications from "./components/more/Notifications";
import Number from "./components/more/Number";
import UserSettings from "./components/more/UserSettings";
import BillingDesktop from "./components/more/BillingDesktop";
import Integrations from "./components/more/Integrations";
import Pastorsline from "./components/more/Pastorsline";
import ConfirmationSummary from "./components/more/ConfirmationSummary";
import AddFollowUp from "./components/more/AddFollowUp";
import MoreSubTab from "./components/more/MoreSubTab";
import Users from "./components/more/Users";
import Pricing from "./components/more/Pricing";
import Coupons from "./components/more/Coupons";
import EditPricing from "./components/more/EditPricing";
import EditCoupon from "./components/more/EditCoupon";
import PrivateRoute from "./PrivateRoute";

class MoreRouter extends React.Component {
  render() {
    return (
      <HashRouter baseline="more">
        <MoreSubTab />
        <Switch>
          <Route path="/" component={ScheduleTemplates} exact />
          <Route path="/" component={Keywords} exact />
          <Route path="/more/notifications" component={Notifications} exact />
          <Route path="/more/number" component={Number} exact />
          <Route path="/more/integrations" component={Integrations} exact />
          <Route path="/more/pastorsline" component={Pastorsline} exact />
          <Route path="/more/billings" component={BillingDesktop} exact />
          <Route path="/more/user-settings" component={UserSettings} exact />
          <Route
            path="/more/message-template"
            component={MessageTemplates}
            exact
          />
          <PrivateRoute path="/new-template" component={NewTemplate} exact />
          <PrivateRoute path="/new-schedule" component={CreateSchedule} exact />
          <PrivateRoute path="/edit-schedule" component={EditSchedule} exact />
          <PrivateRoute path="/edit-keyword" component={EditKeyword} exact />
          <PrivateRoute path="/pricing" component={Pricing} exact />
          <PrivateRoute path="/coupons" component={Coupons} exact />
          <PrivateRoute path="/edit-pricing" component={EditPricing} exact />
          <PrivateRoute path="/edit-coupon" component={EditCoupon} exact />
          <PrivateRoute
            path="/confirmation-summary"
            component={ConfirmationSummary}
            exact
          />
          <PrivateRoute path="/add-followup" component={AddFollowUp} exact />
          <PrivateRoute
            path="/add-confirmation-time"
            component={AddConfirmationTime}
            exact
          />
          <PrivateRoute
            path="/add-confirmation-text"
            component={AddConfirmationText}
            exact
          />
          <PrivateRoute path="/more/users" component={Users} exact />
        </Switch>
      </HashRouter>
    );
  }
}

export default MoreRouter;
