import { ACTION_TYPES } from "../actions/types";
const initialState = {
  globalLoading: null,
  showConfirmationModal: null,
  confirmationModalTitle: null,
  confirmationModalDesc: null,
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOADING:
      return Object.assign({}, state, {
        globalLoading: action.payload,
      });
    case ACTION_TYPES.TOGGLE_CONFIRMATION_MODAL:
      return Object.assign({}, state, {
        showConfirmationModal: !state.showConfirmationModal,
      });
    default:
      return state;
  }
};

export default globalReducer;
