import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Hidden,
  Icon,
  IconButton,
  Typography,
  MenuItem,
  TextField,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import "./../../styles/components/more/add-followup.scss";

function AddFollowUp() {
  const [values, setValues] = React.useState({
    text:
      "Dear [firstname], can you join us [time]? Please reply YES/NO to this message.",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <div className="page-wrapper followup">
      <HeaderMore
        backView="/add-confirmation-time"
        title="Confirmation"
        actionButton="Next"
        nextView="/message-templates"
      />
      <div className="inner-content-wrapper">
        <Hidden smDown>
          <Typography className="desktop-title">Confirm Followup</Typography>
        </Hidden>
        <div className="when">
          <Typography className="title">When</Typography>
        </div>
        <div className="add-followup">
          <Grid container>
            <Grid item className="single-item" xs={12}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                select
                label="When"
                margin="normal"
                variant="filled"
                value={values.timeDay}
                onChange={handleChange("timeDay")}
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  },
                }}
              >
                <MenuItem value="asap"> ASAP </MenuItem>
                <MenuItem value="later"> A day before </MenuItem>
                <MenuItem value="weekend">A week before </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <div className="text">
                <Typography className="title">Text</Typography>
                <div className="right-icons">
                  <IconButton className="icon-button">
                    <Icon>code</Icon>
                  </IconButton>
                  <IconButton
                    className="icon-button"
                    component={Link}
                    to="/message-templates"
                  >
                    <Icon>dashboard</Icon>
                  </IconButton>
                </div>
              </div>
              <TextField
                multiline
                fullWidth
                rowsMax="4"
                value={values.text}
                onChange={handleChange("text")}
                margin="normal"
                className="multiline-textfield"
                InputProps={{
                  classes: {
                    root: "form-control",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/message-templates"
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary next-step",
              }}
            >
              Next
            </Button>
          </Hidden>
        </div>
      </div>
    </div>
  );
}

export default AddFollowUp;
