import React from "react";
import { Button, Icon, Tooltip, Typography } from "@material-ui/core";
import { ReactComponent as HelpIcon } from "../../icons/help-icon.svg";
import { ReactComponent as OptedOutIcon } from "../../icons/optout-icon.svg";
import PeopleDetailDrawer from "./PeopleDetailDrawer";
import { formatPhoneNumber } from "../../helpers/helpers";
import "../../styles/components/services/service-list.scss";
import "../../styles/components/responsive-design.scss";

class PeopleListDesktop extends React.Component {
  render() {
    const display_phone_number = formatPhoneNumber(this.props.number);
    return (
      <PeopleDetailDrawer
        name={this.props.name}
        type={this.props.type}
        plan={this.props.plan}
        status={this.props.status}
        personId={this.props.personId}
        position={this.props.position}
        toggler={
          <Button
            className={
              this.props.status === "O"
                ? "single-service opted-out"
                : "single-service"
            }
            style={{ height: "auto" }}
          >
            <div className="label">
              <div className="left-content d-flex align-items-start justify-content-start">
                <Typography className="name text-left">
                  {this.props.name}
                </Typography>
                <Typography className="position text-left">
                  {this.props.position}
                </Typography>
                <Typography className="number">
                  {display_phone_number}
                </Typography>
              </div>
              <div className="right-content">
                {this.props.status === "C" && (
                  <Tooltip
                    title="Accepted"
                    placement={window.innerWidth > 959 ? "right" : "top"}
                  >
                    <Icon className="icon tick-icon">done</Icon>
                  </Tooltip>
                )}
                {this.props.status === "U" && (
                  <Tooltip
                    title="Unconfirmed"
                    placement={window.innerWidth > 959 ? "right" : "top"}
                  >
                    <Icon className="icon help-icon">
                      <HelpIcon />
                    </Icon>
                  </Tooltip>
                )}
                {this.props.status === "F" && (
                  <Tooltip
                    title={
                      this.props.error_message
                        ? this.props.error_message
                        : "Failed"
                    }
                    placement={window.innerWidth > 959 ? "right" : "top"}
                  >
                    <Icon className="icon cross-icon">clear</Icon>
                  </Tooltip>
                )}
                {this.props.status === "O" && (
                  <Tooltip
                    title="Opted out"
                    placement={window.innerWidth > 959 ? "right" : "top"}
                  >
                    <Icon className="icon help-icon">
                      <OptedOutIcon />
                    </Icon>
                  </Tooltip>
                )}
              </div>
            </div>
          </Button>
        }
      />
    );
  }
}

export default PeopleListDesktop;
