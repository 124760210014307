import React from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import queryString from "query-string";
import HeaderServices from "./HeaderServices";
import PeopleList from "./PeopleList";
import { authHeader } from "../helpers/AuthHeader";
import { userService } from "../../components/services/UserService";
import "../../styles/components/services/sunday-worship.scss";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
class DailyWorship extends React.Component {
  constructor(props) {
    super(props);

    //console.log(this.props.location);
    const values = queryString.parse(this.props.location.search);

    this.state = {
      plan: values.plan ? values.plan : 0,
      serviceName: values.serviceName ? values.serviceName : "",
      planName: values.planName ? values.planName : 0,
      backViewLink:
        "/weekly-worship?service=" +
        (values.service ? values.service : 0) +
        "&serviceName=" +
        values.serviceName,
      peopleList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.refreshDailyService();
  }
  refreshDailyService() {
    const { plan } = this.state;
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({ loading: true });
    return fetch(`${API_URL}scheduling/people/${plan}`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          userService.refreshToken();
          this.refreshDailyService();
          this.setState({ loading: false });
          return;
        }
        this.setState({ peopleList: data, loading: false });
      })
      .catch(console.log);
  }
  render() {
    const {
      peopleList,
      serviceName,
      planName,
      backViewLink,
      loading,
      type,
    } = this.state;
    var header = "Dashboard > Services > " + serviceName + " > " + planName;
    return (
      <div>
        <div className="one-day-service page-wrapper">
          <div className={loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={loading}
            />
          </div>
          <HeaderServices
            backView={backViewLink}
            title={header}
            bread={[
              "/dashboard",
              type ? "/services?type=" + type : "/dashboard",
              backViewLink,
            ]}
            /*hasSearch
        	  hasReload
        	  lists={[
        		{ id: 1, edit: true, title: 'Switch default template' },
        		{ id: 2, edit: true, title: 'Resend confirmation now', hasBorderBottom: true },
        		{ id: 3, switch: true, title: 'Confirmations' },
        		{ id: 4, switch: true, title: 'Reminders' },
        	  ]}
        	  bottomModalContent={
        		<div>
        		<ChangeTemplate label="Switch default template" />
        		<Button className="content-single">Resend confirmation now</Button>
        		<div className="switches">
        		  <FormControlLabel
        		  control={
        			<CustomSwitch />
        		  }
        		  label="Confirmations On"
        		  />
        		  <FormControlLabel
        		  control={
        			<CustomSwitch />
        		  }
        		  label="Followup On"
        		  />
        		</div>
        		</div>
        	  }*/
          />
          <div>
            <PeopleList persons={peopleList} />
          </div>
        </div>
      </div>
    );
  }
}
export default DailyWorship;
