// import config from "config";
import jstz from "jstz";
import { authHeader } from "./../helpers/AuthHeader";

const API_URL = process.env.REACT_APP_API_URL;

export const userService = {
  login,
  forgot,
  reset,
  logout,
  stopImpersonate,
  registerStep1,
  registerStep2,
  registerStep3,
  authorizePco,
  authorizePcoWith,
  refreshToken,
  saveNumber,
  switchNumber,
  authorizePl,
  deauthorizePco,
  deactivePl,
  deactiveNumber,
  removeNumber,
  activate,
  authorizeBitlyWith,
  deactiveBitly,
  saveCountry,
  savePreference,
  saveCoupon,
  //getAll
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${API_URL}login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a user in the response
      if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        //user.accessToken = user.token;
        localStorage.setItem("user", user);
      }

      return user;
    });
}

function forgot(email) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  };

  return fetch(`${API_URL}forgot`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function reset(password, uid) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ uid, password }),
  };

  return fetch(`${API_URL}reset`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function logout(redirect) {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  localStorage.removeItem("impersonate");
  if (redirect) {
    window.location.reload(true);
  }
  //const { from } = { from: { pathname: "/login" } };
  //this.props.history.push(from);
}

function stopImpersonate() {
  // remove user from local storage to log user out
  localStorage.setItem("user", localStorage.getItem("impersonate"));
  localStorage.removeItem("impersonate");
  //window.location.reload(true);
  window.location.href = "/users";
}

/*function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${API_URL}/users`, requestOptions).then(handleResponse);
}*/

function saveNumber(phone_number, country) {
  const requestOptions = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify({ phone_number: phone_number, country: country }),
  };

  return fetch(`${API_URL}save-number`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        var user = JSON.parse(localStorage.getItem("user"));
        user.success.phone_number = data.phone;
        //console.log(user);
        localStorage.setItem("user", JSON.stringify(user));
        //localStorage.setItem('user', JSON.stringify({ name, email, mobile_phone, church, username, phone_number }));
      }

      return user;
    });
}

function switchNumber(phone_number) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ phone_number: phone_number }),
  };

  return fetch(`${API_URL}switch-number`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        var user = JSON.parse(localStorage.getItem("user"));

        user.success.user.is_phone_pl =
          JSON.parse(data).success.user.is_phone_pl;
        if (user.success.phone_number) {
          user.success.phone_number.number = phone_number;
        } else {
          user.success.phone_number = { number: phone_number };
        }
        localStorage.setItem("user", JSON.stringify(user));
        //localStorage.setItem('user', JSON.stringify({ name, email, mobile_phone, church, username, phone_number }));
        return user;
      }
    });
}

function registerStep1(
  name,
  email,
  mobile_phone,
  captcha,
  bypass_email,
  bypass_phone,
  ipaddress
) {
  var user = JSON.parse(localStorage.getItem("user"));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      name: name,
      email: email,
      mobile_phone: mobile_phone,
      bypass_email: bypass_email,
      bypass_phone: bypass_phone,
    }),
  };
  const requestOptions2 = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      name: name,
      email: email,
      mobile_phone: mobile_phone,
      captcha: captcha,
      bypass_email: bypass_email,
      bypass_phone: bypass_phone,
      ipaddress: ipaddress,
    }),
  };

  if (bypass_email || bypass_phone) {
    return fetch(`${API_URL}register-step1`, requestOptions2)
      .then(handleResponse)
      .then((data) => {
        if (data) {
          //localStorage.setItem('user', JSON.stringify({ name, email, mobile_phone, church, username, phone_number }));
          localStorage.removeItem("user");
        }

        return user;
      });
  } else {
    return fetch(`${API_URL}check-email-phone`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        return fetch(`${API_URL}register-step1`, requestOptions2)
          .then(handleResponse)
          .then((data) => {
            if (data) {
              //localStorage.setItem('user', JSON.stringify({ name, email, mobile_phone, church, username, phone_number }));
              localStorage.removeItem("user");
            }

            return user;
          });
      });
  }
}

function registerStep2(church, username, password, coupon_code) {
  var user = JSON.parse(localStorage.getItem("user"));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username: username, id: user.success.user.id }),
  };

  return fetch(`${API_URL}check-username`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        user.success.user.username = username;
        user.success.user.password = password;
        user.success.user.church = church;
        user.success.user.coupon_code = coupon_code;
        localStorage.setItem("user", JSON.stringify(user));
        if (user.success.user.bypassed) {
          return registerStep3(null, null, user.success.user.id);
        }
      }

      return user;
    });
}

function registerStep3(phone_number, country, id, pl_sid, pl_token) {
  var user = JSON.parse(localStorage.getItem("user"));
  user = user.success.user;
  var name = "";
  var email = "";
  var mobile_phone = "";
  var church = "";
  var username = "";
  var password = "";
  var coupon_code = null;
  if (user) {
    name = user.name;
    email = user.email;
    mobile_phone = user.mobile_phone;
    church = user.church;
    username = user.username;
    password = user.password;
    coupon_code = user.coupon_code;
  }
  const timezone = jstz.determine().name();

  //console.log(JSON.stringify({ name, email, mobile_phone, church, username, password, phone_number, timezone, pl_sid, pl_token }));
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      name,
      email,
      mobile_phone,
      church,
      username,
      password,
      phone_number,
      country,
      timezone,
      pl_sid,
      pl_token,
      id,
      coupon_code,
    }),
  };

  return fetch(`${API_URL}register`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a user in the response
      var data = JSON.parse(user);
      if (data && data.success && data.success.status !== "verification") {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        //user.accessToken = user.token;
        localStorage.setItem("user", user);
      } else if (
        data &&
        data.success &&
        data.success.status === "verification"
      ) {
        localStorage.removeItem("user");
      }

      return user;
    });
}

function authorizePco() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}scheduling/login`, requestOptions)
    .then(handleResponse)
    .then((url) => {
      // login successful if there's a user in the response
      if (url) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        //user.accessToken = user.token;
        //localStorage.setItem('user', JSON.stringify(user));
      }
      url = JSON.parse(url);
      window.open(url.url, "_blank");
    });
}

function authorizePcoWith(code) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  var user = JSON.parse(localStorage.getItem("user"));
  user = user.success.user;

  return fetch(
    `${API_URL}scheduling/auth/complete?code=${code}&userId=${user.id}`,
    requestOptions
  )
    .then(handleResponse)
    .then((user) => {
      if (user) {
        localStorage.setItem("user", user);
        //console.log(user);
        //const { from } = { from: { pathname: "/dashboard" } };
        //location.href = "/dashboard";
        return user;
      }
    });
}

function deauthorizePco() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(`${API_URL}revoke-pco`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user) {
        localStorage.setItem("user", user);
        return user;
      }
    });
}

function refreshToken() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  return fetch(`${API_URL}scheduling/refresh_token`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // login successful if there's a user in the response
      if (data) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        //user.accessToken = user.token;
        //console.log(localStorage.getItem('user'));
        var user = JSON.parse(localStorage.getItem("user"));
        user.success.user = JSON.parse(data).user;
        //console.log(user);
        //console.log(data);
        localStorage.setItem("user", JSON.stringify(user));
        //console.log(localStorage.getItem('user'));
      }

      return data;
    });
}

function authorizePl(email, password, loggedIn, number) {
  var requestOptions;
  if (loggedIn) {
    requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        email: email,
        password: password,
        loggedIn: loggedIn,
        number: number,
      }),
    };
  } else {
    requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        email: email,
        password: password,
      }),
    };
  }

  return fetch(`${API_URL}pastorsline/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a user in the response
      if (user) {
        // store user details and basic auth credentials in local storage
        // to keep user logged in between page refreshes
        //user.accessToken = user.token;
        //localStorage.setItem('user', JSON.stringify(user));
        //console.log(loggedIn);
        //console.log(user);
        if (loggedIn) {
          localStorage.setItem("user", user);
        }
        return user;
      }

      //url = JSON.parse(url);

      //window.location.href = url.url;
    });
}

function deactivePl() {
  var user = JSON.parse(localStorage.getItem("user")).success;

  // eslint-disable-next-line no-undef
  if (user.user.is_phone_pl && user.phone_number)
    // eslint-disable-next-line no-undef
    return deactiveNumber(user.phone_number.id);
}

function deactiveNumber(id) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ id: id }),
  };

  return fetch(`${API_URL}deactive-number`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        var user = JSON.parse(localStorage.getItem("user"));
        user.success.user = JSON.parse(data).success.user;
        localStorage.setItem("user", JSON.stringify(user));
        return user;
      }
    });
}

function activate(code) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ code: code }),
  };

  return fetch(`${API_URL}activate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user) {
        localStorage.setItem("user", user);
        //location.href = "/dashboard?d="+Date.now();
      }

      return user;
    });
}

function removeNumber(id) {
  // eslint-disable-next-line no-restricted-globals
  if (confirm("Are you sure you want to remove it?")) {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ id: id }),
    };

    return fetch(`${API_URL}remove-number`, requestOptions)
      .then(handleResponse)
      .then((data) => {
        if (data) {
          var user = JSON.parse(localStorage.getItem("user"));
          user.success.user = JSON.parse(data).success.user;
          localStorage.setItem("user", JSON.stringify(user));
          return user;
        }
      });
  }
}

function authorizeBitlyWith(code) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({ code: code }),
  };
  return fetch(`${API_URL}bitly-token`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user) {
        localStorage.setItem("user", user);
        return user;
      }
    });
}

function deactiveBitly() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  return fetch(`${API_URL}remove-bitly-token`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user) {
        localStorage.setItem("user", user);
        return user;
      }
    });
}

function saveCountry(countryIso, countryCode) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      country_code: "+" + countryCode,
      country_iso: countryIso,
    }),
  };
  return fetch(`${API_URL}save-country`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        var user = JSON.parse(localStorage.getItem("user"));
        user.success.user.churches[0].country_code = countryCode;
        user.success.user.churches[0].country_iso = countryIso;
        localStorage.setItem("user", JSON.stringify(user));
        return data;
      }
    });
}

function savePreference(phonePreference) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      phone_preference: phonePreference,
    }),
  };
  return fetch(`${API_URL}save-country`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        var user = JSON.parse(localStorage.getItem("user"));
        user.success.user.churches[0].phone_preference = phonePreference;
        localStorage.setItem("user", JSON.stringify(user));
        return data;
      }
    });
}

function saveCoupon(
  code,
  type,
  total_uses,
  expiry_date,
  credits,
  trial_days,
  id
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      id: id,
      code: code,
      type: type,
      total_uses: total_uses,
      expiry_date: expiry_date,
      credits: credits,
      trial_days: trial_days,
    }),
  };
  return fetch(`${API_URL}save-coupon`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      if (data) {
        return data;
      }
    });
}

function handleResponse(response) {
  if (!response.ok) {
    var error = response.statusText;
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      //logout();
      //location.reload(true);
      /*const { from } = { from: { pathname: "/login" } };
            this.props.history.push(from);*/
      error = response.text();
      //const data = response.text() && JSON.parse(response.text());

      //error = (data && data.error) || response.statusText;
    }

    return Promise.reject(error);
  }

  return response.text();
}
