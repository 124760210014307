import React from "react";
import PropType from "prop-types";

/*const availableMergeFields = [
  { text: ` [First.Name] ` },
  { text: ` [service] ` },
  { text: ` [team] ` },
  { text: ` [position] ` },
  { text: ` [Plan.Date.Time] ` },
  { text: ` [Church.Name] ` },
  { text: ` [Reminder.Date] ` },
  { text: ` [ACCEPT or DECLINE] ` },
  { text: ` [Body] ` },
];*/

class MergeFields extends React.Component {
  constructor(props) {
    super(props);

    var availableMergeFields = [];

    props.keywords
      .split(",")
      .map(($field) =>
        availableMergeFields.push({ text: ` [` + $field + `] ` })
      );
    if (props.type === "confirmation") {
      availableMergeFields.push({ text: ` ACCEPT ` });
      availableMergeFields.push({ text: ` DECLINE ` });
      availableMergeFields.push({ text: ` ACCEPT ALL ` });
      availableMergeFields.push({ text: ` DECLINE ALL ` });
    }
    if (props.type === "other") {
      availableMergeFields.push({ text: ` ACCEPT ALL ` });
      availableMergeFields.push({ text: ` DECLINE ALL ` });
    }

    this.state = {
      onClick: props.onClick,
      availableMergeFields: availableMergeFields,
    };
  }

  render() {
    const { onClick, availableMergeFields } = this.state;
    return (
      <div className="d-flex flex-wrap merge-field-wrapper">
        {availableMergeFields.map((field) => (
          <div
            className="mt-2 merge-fields"
            onClick={() => onClick(field.text)}
          >
            {field.text}
          </div>
        ))}
      </div>
    );
  }
}

MergeFields.propTypes = {
  onClick: PropType.func.isRequired,
};

export default MergeFields;
