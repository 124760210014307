import React from "react";
import {
  Dialog,
  DialogContent,
  Slide,
  Icon,
  IconButton,
} from "@material-ui/core";
import "./../styles/components/bottom-modal.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function BottomModal(props) {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div style={{ display: "inline" }}>
      <IconButton className="icon-button more" onClick={handleClickOpen}>
        <Icon>more_vert</Icon>
      </IconButton>
      <Dialog
        className="bottom-modal"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogContent className="modal-content">{props.content}</DialogContent>
      </Dialog>
    </div>
  );
}

export default BottomModal;
