import React from "react";
import { Button, Grid, TextField, Icon, Typography } from "@material-ui/core";
import { css } from "@emotion/core";
import queryString from "query-string";
import ClipLoader from "react-spinners/ClipLoader";
import HeaderForAll from "./../components/HeaderForAll";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);

    const values = queryString.parse(this.props.location.search);
    this.state = {
      changeEmail: false,
      email: values.email ? values.email : "",
      oldEmail: values.email ? values.email : "",
      flashMessage: "",
      loading: false,
    };

    this.toggleChangeEmail = this.toggleChangeEmail.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFlash = this.handleFlash.bind(this);
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return this.props.history.push(`/dashboard`);
    }
  }

  handleFlash() {
    this.setState({ flashMessage: "" });
  }

  handleChange = (e) => {
    this.setState({ email: e.target.value });
  };

  toggleChangeEmail = () => {
    this.setState({ changeEmail: !this.state.changeEmail });
  };

  handleSubmit = () => {
    this.setState({ flashMessage: "", loading: true });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        old_email: this.state.oldEmail,
        email: this.state.email,
      }),
    };

    return fetch(`${API_URL}resend-activate`, requestOptions).then((data) => {
      console.log(data);
      if (data) {
        if (data.status === 401 || data.status === 400 || data.status === 404) {
          var _this = this;
          data.text().then(function (e) {
            var error = JSON.parse(e).error;
            _this.setState({ flashMessage: error });
          });
        } else {
          this.setState({ flashMessage: "Email Resent!" });
        }

        this.setState({ changeEmail: false, loading: false });
      }
    });
  };

  render() {
    const { changeEmail, email, flashMessage, loading } = this.state;

    return (
      <div className="page-wrapper create-acc">
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
        <HeaderForAll title="Verify Email" />
        <div className="auth-wrapper verify-email">
          <Typography component={"h2"} className="page-title">
            Verify your email Address
          </Typography>
          {flashMessage !== "" && (
            <div className="flash-message">
              <Typography>{flashMessage}</Typography>
              <Icon className="close-icon" onClick={this.handleFlash}>
                close
              </Icon>
            </div>
          )}
          <Typography className="verify-content">
            Please check your email for link we sent you. Then, click it to
            confirm your email address
          </Typography>
          {changeEmail ? (
            <React.Fragment>
              <Typography className="sub-title">
                Change Email Address:
              </Typography>
              <TextField
                classes={{
                  root: "text-field",
                }}
                type="email"
                value={email}
                onChange={this.handleChange}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Email"
                margin="normal"
                variant="filled"
              />
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={this.handleSubmit}
                classes={{
                  root: "btn",
                  label: "label",
                  containedPrimary: "contained-primary",
                }}
              >
                Verify Now
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography className="sub-title">
                Didn't receive the email? Try any of these:{" "}
              </Typography>
              <div className="action-buttons">
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      onClick={this.toggleChangeEmail}
                      classes={{
                        root: "btn",
                        label: "label",
                        containedPrimary: "contained-primary",
                      }}
                    >
                      Edit Email Address
                    </Button>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Button
                      color="primary"
                      variant="outlined"
                      type="submit"
                      onClick={this.handleSubmit}
                      classes={{
                        root: "btn",
                        label: "label",
                        outlinedPrimary: "outlined-btn",
                      }}
                    >
                      Resend email
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </React.Fragment>
          )}
        </div>
        <div className="auth-banner">
          <img src="images/auth-banner.png" alt="" />
        </div>
      </div>
    );
  }
}

export default VerifyEmail;
