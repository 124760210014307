const { ACTION_TYPES } = require("./types");

const getUserProfileDataSuccess = (payload) => ({
  type: ACTION_TYPES.GET_USER_PROFILE_DATA_SUCCESS,
  payload,
});

const getUserProfileDataFail = (payload) => ({
  type: ACTION_TYPES.GET_USER_PROFILE_DATA_FAIL,
  payload,
});

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getUserProfileDataSuccess,
  getUserProfileDataFail,
};
