const { ACTION_TYPES } = require("./types");

export const globalLoading = (payload) => {
  return {
    type: ACTION_TYPES.LOADING,
    payload,
  };
};

export const toggleConfirmationModal = () => {
  return {
    type: ACTION_TYPES.TOGGLE_CONFIRMATION_MODAL,
  };
};
