import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Logo from "./../components/Logo";

const background = "./images/login-bg.svg";
const HomePage = (props) => {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return props.history.push("/dashboard");
    }
  }, [props.history]);
  return (
    <div
      className="home-page"
      style={{ backgroundImage: `url(${background})` }}
    >
      <Logo href="/" className="logo" />
      <div>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          component={Link}
          to="/login"
          classes={{
            root: "btn",
            label: "label",
            containedPrimary: "contained-primary",
          }}
        >
          Login
        </Button>
        <Button
          color="primary"
          variant="text"
          component={Link}
          to="/signup"
          classes={{
            root: "btn",
            label: "label",
            textPrimary: "text-primary",
          }}
        >
          Create account
        </Button>
      </div>
    </div>
  );
};

export default HomePage;
