import React from "react";
import {
  Button,
  Grid,
  Icon,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import "./../styles/components/suggested-numbers.scss";

export default function SuggestedNumbersForModal(props) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event) => {
    setValue(event.target.value);
    props.changePhone(event.target.value);
  };
  return (
    <div className="modal-numbers-wrapper">
      <RadioGroup
        aria-label="numbers"
        name="phone_number"
        value={value}
        onChange={handleChange}
      >
        <Grid container spacing={2}>
          {props.numbers.map((item, idx) => (
            <Grid item className="single-number" key={idx}>
              <FormControlLabel
                value={item.value}
                label={item.label}
                control={
                  <Radio
                    color="primary"
                    checkedIcon=""
                    icon=""
                    classes={{
                      root: "radio",
                      checked: "checked",
                    }}
                  />
                }
                // eslint-disable-next-line react/jsx-no-duplicate-props
                label={
                  <span>
                    {item.number}
                    <span className="label">{item.label}</span>
                  </span>
                }
                labelPlacement="start"
                classes={{
                  root: "number-btn",
                  label: "number",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            color="primary"
            variant="text"
            onClick={props.reload}
            classes={{
              root: "btn",
              label: "label",
              textPrimary: "text-primary",
            }}
          >
            <Icon className="reload">cached</Icon>
            Reload
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
