import React from "react";
import {
  AppBar,
  Button,
  Dialog,
  Grid,
  Icon,
  IconButton,
  ListItem,
  Slide,
  Tab,
  Tabs,
  Typography,
  TextField,
  Tooltip,
} from "@material-ui/core";
import OrderDetails from "./OrderDetails";
import SubscriptionMobile from "./SubscriptionMobile";
import { authHeader } from "./../helpers/AuthHeader";
import { renderHTML } from "./../../helpers/helpers";
import CancelSubscriptionModal from "../Modals/CancelSubscriptionModal";
import CreditsInformationCard from "../CreditsInformationCard/CreditsInformationCard";
import "../../styles/components/more/billing.scss";

const API_URL = process.env.REACT_APP_API_URL;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SubscriptionPlans extends React.Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      open: false,
      tabValue: this.props.isMonthly === 1 ? 0 : 1,
      loading: false,
      coupon: null,
      couponCode: null,
      packages: [],
      status: user.success.status,
      showCancelSubscriptionModal: false,
    };

    this.handleTab = this.handleTab.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.refreshAllSections = this.refreshAllSections.bind(this);
  }

  handleChange = (event) => {
    this.setState({ couponCode: event.target.value });
  };
  handleClick = () => {
    this.setState({ coupon: this.state.couponCode });
  };

  componentDidMount() {
    this.getPackages();
  }

  getPackages() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({ loading: true });
    return fetch(`${API_URL}packages`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ packages: data.packages });
      })
      .catch(console.log);
  }

  handleTab = (event, tabValue) => {
    this.setState({ tabValue: tabValue });
  };

  handleClickOpen = () => {
    if (this.props.syncing) {
      alert("Unable to change because payment is not finalized yet.");
    } else {
      this.setState({ open: true });
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  refreshAllSections(loading, flashMessage) {
    this.props.refreshAllSections(loading, flashMessage);
    this.handleClose();
  }

  toggleCancelSubscriptionModal = () => {
    this.setState({
      showCancelSubscriptionModal: !this.state.showCancelSubscriptionModal,
    });
  };

  render() {
    const {
      open,
      tabValue,
      packages,
      coupon,
      status,
      showCancelSubscriptionModal,
    } = this.state;
    return (
      <div className="w-100">
        <div className="d-flex align-items-center justify-content-between">
          {!this.props.cancelled && (
            <Button
              onClick={this.toggleCancelSubscriptionModal}
              className="text-light"
            >
              Cancel
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={this.handleClickOpen}
            classes={{
              root: "btn",
              label: "label",
              containedPrimary: "contained-primary",
            }}
          >
            {this.props.currentPackage || status === "On Extended Trial"
              ? "Change"
              : "Subscribe"}
          </Button>
        </div>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          classes={{
            root: "subscription-plans-page",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <IconButton className="close-icon" onClick={this.handleClose}>
            <Icon>close</Icon>
          </IconButton>
          <Grid container className="content" spacing={2}>
            <Grid item className="left-content">
              <Typography className="page-title">Subscription</Typography>
              <AppBar className="subscription-tab">
                <Tabs
                  value={tabValue}
                  onChange={this.handleTab}
                  textColor="primary"
                  scrollButtons="auto"
                  classes={{
                    root: "tabs-wrapper",
                    indicator: "indicator",
                  }}
                >
                  <Tab
                    label="Monthly"
                    classes={{
                      root: "tab-single",
                      selected: "selected",
                    }}
                  />
                  <Tab
                    label="Yearly - Save 17%!!!"
                    classes={{
                      root: "tab-single",
                      selected: "selected",
                    }}
                  />
                </Tabs>
              </AppBar>
              {window.innerWidth > 768 ? (
                <React.Fragment>
                  <div className="tab-container">
                    <Grid container className="container">
                      {Boolean(packages.length) &&
                        packages.map((data, idx) => (
                          <Grid
                            item
                            xs={4}
                            className={
                              data.is_popular
                                ? "single-card featured"
                                : "single-card"
                            }
                            key={idx}
                          >
                            {data.is_popular === 1 && (
                              <div className="popular-tag">Most popular</div>
                            )}
                            <Typography className="plan-title">
                              {data.name}
                            </Typography>
                            <img
                              src={
                                data.image
                                  ? data.image
                                  : "./images/starting-package.png"
                              }
                              alt=""
                            />
                            <Typography className="price-tag" component={"h2"}>
                              $
                              {tabValue === 0
                                ? data.amount_per_month
                                : (data.amount_per_year / 12).toFixed(2)}{" "}
                              / mo
                            </Typography>
                            <div className="features">
                              {tabValue === 1 && (
                                <ListItem className="single features-main">
                                  Paid Annually ${data.amount_per_year} / yr
                                </ListItem>
                              )}
                              <ListItem className="single">
                                {data.credit} credits per month
                              </ListItem>
                              <ListItem className="single">
                                <div className="flexer">
                                  {data.description}
                                  <Tooltip
                                    title={
                                      data.tooltip
                                        ? renderHTML(data.tooltip)
                                        : ""
                                    }
                                    placement="top"
                                    arrow={true}
                                  >
                                    <Icon className="help-icon">
                                      help_outline
                                    </Icon>
                                  </Tooltip>
                                </div>
                              </ListItem>
                            </div>
                            <Typography className="switch"></Typography>
                            <TextField
                              placeholder="Coupon code"
                              fullWidth
                              name={"coupon-" + data.id + "-" + tabValue}
                              onChange={this.handleChange}
                              classes={{
                                root: "text-field",
                              }}
                              InputProps={{
                                classes: {
                                  root: "input-wrapper",
                                  input: "input",
                                },
                              }}
                            />
                            <OrderDetails
                              item={data}
                              period={tabValue === 0 ? "Monthly" : "Yearly"}
                              handler={this.props.handler}
                              refreshAllSections={this.refreshAllSections}
                              defaultCard={this.props.defaultCard}
                              coupon={coupon}
                              email={this.props.email}
                              currentPackage={this.props.currentPackage}
                              isMonthly={this.props.isMonthly}
                              toggler={
                                !this.props.currentPackage ||
                                !(
                                  this.props.currentPackage.id === data.id &&
                                  tabValue === !this.props.isMonthly
                                ) ? (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={this.handleClick}
                                    type="submit"
                                    classes={{
                                      root: "btn",
                                      label: "label",
                                      containedPrimary: "contained-primary",
                                    }}
                                  >
                                    Select
                                  </Button>
                                ) : (
                                  <div className="current-plan">
                                    Current Plan
                                  </div>
                                )
                              }
                            />
                          </Grid>
                        ))}
                      <div className="mt-2 mb-2 d-flex align-items-center justify-content-start w-100 ml-2">
                        <span> Edit Message templates? (All Plans)</span>
                        <Tooltip
                          title={
                            "You would be able to edit Confirmation Requests, Reminder Follow-ups, and Other (non-system) message templates."
                          }
                          placement="top"
                          arrow={true}
                        >
                          <Icon className="help-icon">help_outline</Icon>
                        </Tooltip>
                      </div>
                    </Grid>
                  </div>
                </React.Fragment>
              ) : (
                <div className="tab-container">
                  <SubscriptionMobile
                    packages={packages}
                    refreshAllSections={this.props.refreshAllSections}
                    period={tabValue === 0 ? "Monthly" : "Yearly"}
                    handler={this.props.handler}
                    defaultCard={this.props.defaultCard}
                    coupon={coupon}
                    isMonthly={this.props.isMonthly}
                    email={this.props.email}
                    togglerClick={this.handleClick}
                    handleChange={this.handleChange}
                    currentPackage={this.props.currentPackage}
                  />
                  <div className="mt-4 d-flex align-items-center justify-content-start w-100 ml-2">
                    <span> Edit Message templates? (All Plans)</span>
                    <Tooltip
                      title={
                        "You would be able to edit Confirmation Requests, Reminder Follow-ups, and Other (non-system) message templates."
                      }
                      placement="top"
                      arrow={true}
                    >
                      <Icon className="help-icon">help_outline</Icon>
                    </Tooltip>
                  </div>
                </div>
              )}
              <CreditsInformationCard />
            </Grid>
            <Grid item className="right-content">
              {/*<TweetEmbed className="tweet-embed" id="1249382299898204160" />*/}
              <img src="./images/payment-cards.png" alt="" />
            </Grid>
          </Grid>
        </Dialog>
        {showCancelSubscriptionModal && (
          <CancelSubscriptionModal
            showModal={showCancelSubscriptionModal}
            toggleModal={this.toggleCancelSubscriptionModal}
            confirmAction={this.props.cancelSubscription}
          />
        )}
      </div>
    );
  }
}

export default SubscriptionPlans;
