import React, { Fragment } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import {
  Button,
  Checkbox,
  Dialog,
  Grid,
  Icon,
  MenuItem,
  ListItemText,
  IconButton,
  Menu,
  Slide,
  TextField,
  Typography,
  Tooltip,
} from "@material-ui/core";
import LogsTable from "./LogsTable";
import LogsFilterModal from "./LogsFilterModal";
import Moment from "react-moment";
import "moment-timezone";
import moment from "moment";
import { CSVLink } from "react-csv";

import { authHeader } from "./../helpers/AuthHeader";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import { formatPhoneNumber } from "../../helpers/helpers";
import LogsTableSelectMenu from "./LogsTableSelectMenu";
import "../../styles/components/more/billing.scss";
import { withRouter } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
Moment.globalFormat = "DD.MM.YYYY";
Moment.globalLocal = true;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const headers = [
  { label: "Date", key: "date" },
  { label: "Service", key: "service" },
  { label: "From", key: "from" },
  { label: "To", key: "to" },
  { label: "Source", key: "source" },
  { label: "Credits", key: "credits" },
  { label: "Status", key: "status" },
  { label: "Message", key: "message" },
];

const types = { out: "Outgoing", in: "Incoming", clicked: "Clicked" };

class LogsListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      fromDate: new Date(""),
      toDate: new Date(""),
      selectStatus: null,
      service: "",
      from: "",
      to: "",
      status: {
        sent: true,
        undelivered: true,
        delivered: true,
        failed: true,
        queued: true,
        other: true,
      },
      logs: null,
      logArray: null,
      loading: false,
      columns: [],
      rows: [],
    };

    this.handleReset = this.handleReset.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.prepareForCSV = this.prepareForCSV.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.logs?.length !== nextProps.logs?.length) {
      this.setupLogs(nextProps.logs);
    }
  }

  componentDidMount() {
    const { logs } = this.state;
    const logsList = logs !== null ? logs : this.props.logs;
    this.setupLogs(logsList);
  }

  columnHandler = (logsTableColumn) => {
    this.setState({ columns: [] });
    const columns = logsTableColumn ? this.setupColumn(logsTableColumn) : [];
    localStorage.setItem(
      "billingLogsTableColumn",
      JSON.stringify(logsTableColumn)
    );
    setTimeout(() => {
      this.setState({ columns });
    }, 100);
  };

  setupColumn = (logsTableColumn) => {
    const columns = [];
    if (logsTableColumn.date) {
      columns.push({ key: "date", name: "Date", frozen: true });
    }
    if (logsTableColumn.service) {
      columns.push({ key: "service", name: "Service", width: 250 });
    }
    if (logsTableColumn.from) {
      columns.push({ key: "from", name: "From" });
    }
    if (logsTableColumn.to) {
      columns.push({ key: "to", name: "To" });
    }
    if (logsTableColumn.source) {
      columns.push({ key: "source", name: "Source" });
    }
    if (logsTableColumn.credits) {
      columns.push({ key: "credits", name: "Credits" });
    }
    if (logsTableColumn.status) {
      columns.push({ key: "status", name: "Status" });
    }
    if (logsTableColumn.message) {
      columns.push({ key: "message", name: "Message", width: 300 });
    }
    // columns.push({
    //   key: "more",
    //   name: <LogsTableSelectMenu columnHandler={this.columnHandler} />,
    // });
    return columns;
  };

  capitalize = (str) => {
    return str !== null ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  formatMessage = (str) => {
    return str.split("\n").map(function (item, key) {
      return (
        <span key={key}>
          {item}
          <br />
        </span>
      );
    });
  };

  setupLogs = (logs) => {
    var rows = [];
    var pushedLogs = [];
    var lastLog = null;
    var lastLogServices = [];
    var count = 0;
    var planCount = 0;
    logs.forEach((log) => {
      count++;
      var serviceName = log.scheduling_id
        ? log.service_name
        : log.parent_log
        ? log.parent_log.service_name
        : "";
      var planName = log.scheduling_id
        ? log.plan_name
        : log.parent_log
        ? log.parent_log.plan_name
        : "";
      var sidLog = {
        date: (
          <div className="date-column">
            <Typography>
              <Moment tz={this.props.timezone} format="HH:mm:ss">
                {log.created_at_utc}
              </Moment>
            </Typography>
            <Typography>
              <Moment tz={this.props.timezone} format="MM.DD.YYYY">
                {log.created_at_utc}
              </Moment>
            </Typography>
          </div>
        ),
        service: (
          <Tooltip
            classes={{
              tooltip: "logs-table-tooltip",
            }}
            title={
              <div className="content">
                <Typography>{serviceName}</Typography>
                <Typography>{planName}</Typography>
              </div>
            }
          >
            <div className="service-column">
              <Typography>{serviceName}</Typography>
              <Typography>{planName}</Typography>
            </div>
          </Tooltip>
        ),
        from: formatPhoneNumber(log?.from),
        to: formatPhoneNumber(log?.to),
        source: types[log.type],
        credits: log.credits,
        status:
          (log.status ? this.capitalize(log.status) : "") +
          (log.sent_twice ? " (2x)" : ""),
        message: (
          <Tooltip
            classes={{ tooltip: "logs-table-tooltip" }}
            title={
              <div className="content">
                <Typography>{this.formatMessage(log.message)}</Typography>
              </div>
            }
          >
            <div className="message-column">
              {this.formatMessage(log.message)}
            </div>
          </Tooltip>
        ),
        more: "",
        sid: log.sid,
        mainService: <Typography>{serviceName}</Typography>,
      };

      //if last sid didnot match
      //if this is next log, push last log
      if (
        (log.sid == null || pushedLogs.indexOf(log.sid) === -1) &&
        lastLog &&
        lastLog.sid !== log.sid
      ) {
        lastLog.service = (
          <Tooltip
            classes={{
              tooltip: "logs-table-tooltip",
            }}
            title={
              <div className="content">
                {planCount === 1 ? lastLog.mainService : ""}
                {lastLogServices}
              </div>
            }
          >
            <div className="service-column">
              {lastLog.mainService}
              {planCount === 1 ? lastLogServices[0] : "[multiple plans]"}
            </div>
          </Tooltip>
        );
        rows.push(lastLog);
      }

      if (log.sid == null) {
        rows.push(sidLog);
        lastLog = null;
        lastLogServices = [];
      } else if (pushedLogs.indexOf(log.sid) === -1) {
        pushedLogs.push(log.sid);
        lastLog = sidLog;
        lastLogServices = [];
        lastLogServices.push(<Typography>{planName}</Typography>);
        planCount = count === logs.length ? planCount : 1; //don't reset if it is last
      } else if (lastLog && lastLog.sid === log.sid) {
        lastLogServices.push(
          <Typography>
            <br />
            {planName}
          </Typography>
        );
        planCount++;
      }

      //last child of logs array
      if (lastLog && count === logs.length) {
        lastLog.service = (
          <Tooltip
            classes={{
              tooltip: "logs-table-tooltip",
            }}
            title={
              <div className="content">
                {planCount === 1 ? lastLog.mainService : ""}
                {lastLogServices}
              </div>
            }
          >
            <div className="service-column">
              {lastLog.mainService}
              {planCount === 1 ? lastLogServices[0] : "[multiple plans]"}
            </div>
          </Tooltip>
        );
        rows.push(lastLog);
      }
    });

    var logsTableColumn = JSON.parse(
      localStorage.getItem("billingLogsTableColumn")
    );
    if (!logsTableColumn) {
      logsTableColumn = {
        date: true,
        service: true,
        from: true,
        to: true,
        source: true,
        credits: true,
        status: true,
        message: true,
      };
    }
    var columns = this.setupColumn(logsTableColumn);
    this.setState({ rows, columns: columns });
  };

  handleClickStatusSelect = (event) => {
    this.setState({ selectStatus: event.currentTarget });
  };

  handleCloseStatusSelect = () => {
    this.setState({ selectStatus: null });
  };
  handleFromDateChange = (date) => {
    this.setState({ fromDate: new Date(date) });
  };
  handleToDateChange = (date) => {
    this.setState({ toDate: date });
  };
  handleChange = (event) => {
    this.setState({ [event.currentTarget.id]: event.currentTarget.value });
  };
  handleCheck = (event) => {
    var status = this.state.status;
    status[event.currentTarget.value] = event.currentTarget.checked;
    this.setState({ status: status });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleReset() {
    this.setState({
      fromDate: new Date(""),
      toDate: new Date(""),
      selectStatus: null,
      service: "",
      from: "",
      to: "",
      status: {
        sent: true,
        undelivered: true,
        delivered: true,
        failed: true,
        queued: true,
        other: true,
      },
      logs: this.props.logs,
      logArray: this.prepareForCSV(this.props.logs),
    });
  }

  handleSearch() {
    const { columns, rows, ...rest } = this.state;
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(rest),
    };
    this.setState({ loading: true });

    return fetch(`${API_URL}search-logs`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data) {
          this.setState({
            loading: false,
            logs: data.logs,
            logArray: this.prepareForCSV(data.logs),
          });
          this.setupLogs(data.logs);
        }
      })
      .catch(console.log);
  }

  capitalize(str) {
    return str !== null ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  }

  prepareForCSV(logs) {
    var logArray = [];
    logs.forEach((log) => {
      var service = log.scheduling_id
        ? log.service_name + "\n" + log.plan_name
        : log.parent_log
        ? log.parent_log.service_name + "\n" + log.parent_log.plan_name
        : "";

      logArray.push({
        date:
          log?.created_at_utc && this.props?.timezone
            ? moment(log.created_at_utc)
                .tz(this.props.timezone)
                .format("MM.DD.YYYY HH.mm.ss")
            : "",
        service: service,
        from: formatPhoneNumber(log?.from),
        to: formatPhoneNumber(log?.to),
        source: types[log.type],
        credits: log.credits,
        status:
          (log.status ? this.capitalize(log.status) : "") +
          (log.sent_twice ? " (2x)" : ""),
        message: log.message,
      });
    });

    return logArray;
  }

  renderLogsPageContent = () => {
    const {
      fromDate,
      toDate,
      selectStatus,
      service,
      from,
      to,
      status,
      logArray,
      columns,
      loading,
      rows,
    } = this.state;
    const {
      loadMoreLogs,
      totalLogs,
      currentLogsCount,
      loadMoreLoading,
      isMainLogPage,
      simpleLoading,
    } = this.props;
    return (
      <Fragment>
        {!isMainLogPage && (
          <div className="sticky-header">
            <Typography className="page-title">Logs</Typography>
            <IconButton className="close-icon" onClick={this.handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
        )}
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={80}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
        {rows && rows.length ? (
          <Fragment>
            <div
              className="top-section desktop-filter"
              style={{ padding: isMainLogPage ? "15px 10px" : 25 }}
            >
              <div className="filter" style={{ width: "100%" }}>
                <Grid container spacing={2} className="container">
                  <Grid item style={{ width: "12%" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="date-picker"
                        disableToolbar
                        variant="inline"
                        invalidDateMessage=""
                        margin="normal"
                        id="from-date-picker"
                        placeholder="From date"
                        format="MM/dd/yyyy"
                        value={fromDate}
                        onChange={this.handleFromDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item style={{ width: "12%" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="date-picker"
                        disableToolbar
                        variant="inline"
                        invalidDateMessage=""
                        margin="normal"
                        id="to-date-picker"
                        placeholder="To date"
                        format="MM/dd/yyyy"
                        value={toDate}
                        onChange={this.handleToDateChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item style={{ width: "15%" }}>
                    <TextField
                      id="service"
                      fullWidth
                      variant="filled"
                      placeholder="Service"
                      value={service}
                      onChange={this.handleChange}
                      classes={{
                        root: "text-field",
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "label",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: "input-wrapper",
                          input: "input",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item style={{ width: "14%" }}>
                    <TextField
                      id="from"
                      fullWidth
                      variant="filled"
                      placeholder="From"
                      value={from}
                      onChange={this.handleChange}
                      classes={{
                        root: "text-field",
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "label",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: "input-wrapper",
                          input: "input",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item style={{ width: "14%" }}>
                    <TextField
                      id="to"
                      fullWidth
                      variant="filled"
                      placeholder="To"
                      value={to}
                      onChange={this.handleChange}
                      classes={{
                        root: "text-field",
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "label",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: "input-wrapper",
                          input: "input",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item style={{ width: "12%" }}>
                    <Button
                      className="select-styled-button"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={this.handleClickStatusSelect}
                    >
                      <span>Status</span> <Icon>arrow_drop_down</Icon>
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={selectStatus}
                      keepMounted
                      open={Boolean(selectStatus)}
                      onClose={this.handleCloseStatusSelect}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      getContentAnchorEl={null | undefined}
                      classes={{
                        list: "status-select-menu",
                      }}
                    >
                      <MenuItem>
                        <Checkbox
                          value="delivered"
                          checked={status.delivered}
                          onChange={this.handleCheck}
                        />
                        <ListItemText className="label" primary="Delivered" />
                      </MenuItem>
                      <MenuItem>
                        <Checkbox
                          value="sent"
                          checked={status.sent}
                          onChange={this.handleCheck}
                        />
                        <ListItemText className="label" primary="Sent" />
                      </MenuItem>
                      <MenuItem>
                        <Checkbox
                          value="queued"
                          checked={status.queued}
                          onChange={this.handleCheck}
                        />
                        <ListItemText className="label" primary="Queued" />
                      </MenuItem>
                      <MenuItem>
                        <Checkbox
                          value="failed"
                          checked={status.failed}
                          onChange={this.handleCheck}
                        />
                        <ListItemText className="label" primary="Failed" />
                      </MenuItem>
                      <MenuItem>
                        <Checkbox
                          value="undelivered"
                          checked={status.undelivered}
                          onChange={this.handleCheck}
                        />
                        <ListItemText className="label" primary="Undelivered" />
                      </MenuItem>
                      <MenuItem>
                        <Checkbox
                          value="other"
                          checked={status.other}
                          onChange={this.handleCheck}
                        />
                        <ListItemText className="label" primary="Other" />
                      </MenuItem>
                    </Menu>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      //type="submit"
                      onClick={this.handleSearch}
                      classes={{
                        root: "btn",
                        label: "label",
                        containedPrimary: "contained-primary search-button",
                      }}
                    >
                      <Icon>search</Icon>
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="outlined"
                      onClick={this.handleReset}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <div className="download flexer" style={{ width: "10%" }}>
                <LogsTableSelectMenu columnHandler={this.columnHandler} />
                <CSVLink
                  filename={"logs.csv"}
                  data={
                    logArray !== null
                      ? logArray
                      : this.prepareForCSV(this.props.logs)
                  }
                  headers={headers}
                >
                  <IconButton className="icon-button">
                    <Icon className="icon">get_app</Icon>
                  </IconButton>
                </CSVLink>
              </div>
            </div>
            <div className="top-section mobile-filter">
              <div className="filter" style={{ width: "90%" }}>
                <LogsFilterModal
                  state={this.state}
                  fromDateChange={this.handleFromDateChange}
                  toDateChange={this.handleToDateChange}
                  handleChange={this.handleChange}
                  handleCheck={this.handleCheck}
                  handleReset={this.handleReset}
                  handleSearch={this.handleSearch}
                />
              </div>
              <div className="download flexer" style={{ width: "50%" }}>
                <LogsTableSelectMenu columnHandler={this.columnHandler} />
                <CSVLink
                  filename={"logs.csv"}
                  data={
                    logArray !== null
                      ? logArray
                      : this.prepareForCSV(this.props.logs)
                  }
                  headers={headers}
                >
                  <IconButton className="icon-button">
                    <Icon className="icon">get_app</Icon>
                  </IconButton>
                </CSVLink>
              </div>
            </div>
            <div className="ml-3 mr-3">
              <LogsTable
                columns={columns}
                rows={rows}
                loadMoreLogs={loadMoreLogs}
                totalLogs={totalLogs}
                currentLogsCount={currentLogsCount}
                loadMoreLoading={loadMoreLoading}
              />
            </div>
          </Fragment>
        ) : (
          !simpleLoading && (
            <div className="mt-5 flexer">
              <h3>No logs found</h3>
            </div>
          )
        )}
      </Fragment>
    );
  };

  render() {
    const { open } = this.state;
    const { isMainLogPage } = this.props;
    return (
      <div>
        {!isMainLogPage && (
          <Button
            color="primary"
            variant="text"
            onClick={this.handleClickOpen}
            classes={{
              root: "btn",
              label: "label",
            }}
          >
            <span className="forgot-pw">View all</span>
          </Button>
        )}
        {isMainLogPage ? (
          <div className="logs-list-page">{this.renderLogsPageContent()}</div>
        ) : (
          <Dialog
            fullScreen
            open={open}
            onClose={this.handleClose}
            TransitionComponent={Transition}
            classes={{
              root: "logs-list-page",
              paper: "paper",
            }}
            BackdropProps={{
              classes: {
                root: "backdrop",
              },
            }}
          >
            {this.renderLogsPageContent()}
          </Dialog>
        )}
      </div>
    );
  }
}

export default withRouter(LogsListPage);
