import React from "react";
import { Button } from "@material-ui/core";
import HeaderPeople from "./HeaderPeople";
import PeopleList from "./PeopleList";

const PeopleService = () => (
  <div className="page-wrapper">
    <HeaderPeople
      backView="/dashboard"
      title="Barack Obama / Services"
      lists={[
        { id: 1, title: "Turn on confirmations for all" },
        { id: 2, title: "Turn off confirmations for all" },
      ]}
      bottomModalContent={
        <div>
          <Button className="content-single">
            Turn on confirmations for all
          </Button>
          <Button className="content-single">
            Turn off confirmations for all
          </Button>
        </div>
      }
    />
    <PeopleList
      persons={[
        {
          id: 1,
          avatar: "a",
          target: "/weekly-service",
          name: "Bible Study",
          on: true,
        },
        {
          id: 2,
          avatar: "a",
          target: "/weekly-service",
          name: "Sunday Service",
          on: true,
          disabled: true,
        },
        {
          id: 3,
          avatar: "a",
          target: "/weekly-service",
          name: "Summer in the city",
          on: true,
        },
        { id: 4, avatar: "a", target: "/weekly-service", name: "Summer Camp" },
      ]}
    />
  </div>
);

export default PeopleService;
