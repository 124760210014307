import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { authHeader } from "./../helpers/AuthHeader";
import { content } from "./content";

const API_URL = process.env.REACT_APP_API_URL;
class CreditsInformationCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCredits: []
    };
  }

  refresh() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    return fetch(`${API_URL}country-credits`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ countryCredits: data.country_credits });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.refresh();
  }

  render() {
    const countryCredits = this.state.countryCredits;
    console.log(content);
  return (
    <Card className="credit-information-card-container">
      <CardContent>
        <Typography variant="h4" className="card-heading">
          How many credits do I need for ONE, 160-character text?
        </Typography>
        <div className="content-wrapper">
          {countryCredits.map((item, index) => (
            <div className="content-section" key={index}>
              <img className="flag-icon" src={content[item.country_iso].flag} alt="flags" />
              <span>{content[item.country_iso].text.replace('[credits]', item.credits)}</span>
            </div>
          ))}
        </div>
        <div className="mt-2">
          * Incoming messages are free <br />
          ** Credits refresh at the end of each billing cycle (no rollover
          credits).
          <br />
          ***All prices are in USD.
        </div>
      </CardContent>
    </Card>
  );
  }
};

export default CreditsInformationCard;
