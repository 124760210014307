import React from "react";
import Avatar from "react-avatar";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { MenuItem, Popover, Tooltip, Icon } from "@material-ui/core";
import { ReactComponent as PeopleIcon } from "../icons/people-icon.svg";
import { ReactComponent as MyProfileIcon } from "../icons/profile-icon.svg";
import { ReactComponent as IntegrationsIcon } from "../icons/integration-icon.svg";
import { ReactComponent as BillingIcon } from "./../icons/billing-icon.svg";
import { ReactComponent as LogoutIcon } from "../icons/logout-icon.svg";
import { ReactComponent as ScheduleIcon } from "../icons/schedule-icon.svg";
import { ReactComponent as TemplateIcon } from "../icons/template-icon.svg";
import { ReactComponent as KeywordIcon } from "../icons/keyword-icon.svg";
import { ReactComponent as SettingIcon } from "./../icons/setting-icon.svg";
import { ReactComponent as LogIcon } from "../icons/logs.svg";
import { userService } from "./services/UserService";
import { formatPhoneNumber, isCordovaApp, logout } from "../helpers/helpers";
import { checkProfile } from "../services/api";
import "../styles/components/profile-setting-dropright.scss";

class MainTabSettingDropright extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      first_name: "",
      name: "",
      email: "",
      churchName: "",
      admin: false,
      anchorEl: null,
      errorMessage: "",
      showMenu: false,
    };
  }

  componentDidMount() {
    this.checkProfile();
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  handleLogout = () => {
    logout();
    this.props.history.push("/login");
  };

  stopImpersonate = (e) => {
    e.preventDefault();
    userService.stopImpersonate();
    return;
  };

  checkProfile = async () => {
    try {
      const data = await checkProfile();
      let user = JSON.parse(localStorage.getItem("user"));
      user.success.user.pco_syncing = data.church.pco_syncing;
      user.success.status = data.church.status;
      if (Boolean(data.church.pco_syncing)) {
        this.props.showSync();
      }
      if (!data.phone_number) {
        this.props.switchNoNumber();
      }
      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      this.setState({ errorMessage: error?.message });
    }
  };

  render() {
    const { showMenu } = this.state;
    const { currentUserInfo, phoneNumber, globalLoading } = this.props;
    const isCordova = isCordovaApp();
    const impersonate = Boolean(localStorage.getItem("impersonate"));
    return (
      <div>
        <Tooltip
          title="Settings"
          placement={window.innerWidth > 959 ? "right" : "top"}
        >
          <MenuItem
            onClick={this.toggleMenu}
            classes={{
              root: "single",
            }}
          >
            <SettingIcon />
          </MenuItem>
        </Tooltip>

        <Popover
          id="simple-popover"
          open={showMenu}
          onClose={this.toggleMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          classes={{
            root: "profile-info-dropright",
            paper: "paper",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {globalLoading ? (
            <div className="w-100 flexer">
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <MenuItem className="menuitem-wrapper name-title">
              <Avatar
                name={currentUserInfo?.name}
                email={currentUserInfo?.email}
                className="avatar"
              />
              <div>
                {currentUserInfo?.first_name && (
                  <h3 className="name">
                    {currentUserInfo?.first_name}{" "}
                    <span
                      style={{ fontSize: 12 }}
                      className="font-weight-normal"
                    >
                      (Owner)
                    </span>
                  </h3>
                )}
                <h5 className="title">{currentUserInfo?.churches[0]?.name}</h5>
              </div>
            </MenuItem>
          )}
          <MenuItem
            className={
              globalLoading || phoneNumber
                ? "menuitem-wrapper number"
                : "menuitem-wrapper number addNumberLink"
            }
            onClick={this.toggleMenu}
            component={Link}
            to="/number"
          >
            {globalLoading || phoneNumber ? (
              <span>
                {formatPhoneNumber(phoneNumber?.number, phoneNumber?.country)}
              </span>
            ) : (
              <span>Add a Number</span>
            )}
          </MenuItem>
          <div className="menuitem-wrapper">
            <MenuItem
              className="menuitem"
              onClick={this.toggleMenu}
              component={Link}
              to="/user-settings"
            >
              <span className="icon">
                <MyProfileIcon />
              </span>
              <span className="text">My profile</span>
            </MenuItem>
            <MenuItem
              className="menuitem"
              onClick={this.toggleMenu}
              component={Link}
              to="/integrations"
            >
              <span className="icon">
                <IntegrationsIcon />
              </span>
              <span className="text">Integrations</span>
            </MenuItem>
            {!isCordova && (
              <MenuItem
                className="menuitem"
                onClick={this.toggleMenu}
                component={Link}
                to="/billing"
              >
                <span className="icon">
                  <BillingIcon />
                </span>
                <span className="text">Billing</span>
              </MenuItem>
            )}
            <MenuItem
              className="menuitem"
              onClick={this.toggleMenu}
              component={Link}
              to="/logs"
            >
              <span className="icon">
                <LogIcon />
              </span>
              <span className="text">Logs</span>
            </MenuItem>
            <MenuItem
              className="menuitem"
              onClick={this.toggleMenu}
              component={Link}
              to="/number"
            >
              <Icon className="icon">call</Icon>
              <span className="text">My Numbers</span>
            </MenuItem>
          </div>
          <div className="menuitem-wrapper">
            {Boolean(currentUserInfo?.is_admin) && !impersonate && (
              <MenuItem
                className="menuitem"
                onClick={this.toggleMenu}
                component={Link}
                to="/users"
              >
                <span className="icon">
                  <PeopleIcon />
                </span>
                <span className="text">Users</span>
              </MenuItem>
            )}
            <MenuItem
              className="menuitem"
              onClick={this.toggleMenu}
              component={Link}
              to="/schedule-templates"
            >
              <span className="icon">
                <ScheduleIcon />
              </span>
              <span className="text">Schedule templates</span>
            </MenuItem>
            <MenuItem
              className="menuitem"
              onClick={this.toggleMenu}
              component={Link}
              to="/message-templates"
            >
              <span className="icon">
                <TemplateIcon />
              </span>
              <span className="text">Message templates</span>
            </MenuItem>
            <MenuItem
              className="menuitem"
              onClick={this.toggleMenu}
              component={Link}
              to="/keywords"
            >
              <span className="icon">
                <KeywordIcon />
              </span>
              <span className="text">Keywords</span>
            </MenuItem>
          </div>
          {impersonate && (
            <MenuItem
              className="menuitem-wrapper"
              onClick={this.stopImpersonate}
            >
              <div className="menuitem logout">
                <span className="icon">
                  <PeopleIcon />
                </span>
                <span className="text">Return to Admin</span>
              </div>
            </MenuItem>
          )}
          <MenuItem className="menuitem-wrapper" onClick={this.handleLogout}>
            <div className="menuitem logout">
              <span className="icon">
                <LogoutIcon />
              </span>
              <span className="text">Log out</span>
            </div>
          </MenuItem>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    currentUserInfo: store.userProfileReducer.user,
    globalLoading: store.global.globalLoading,
    phoneNumber: store.userProfileReducer.phone_number,
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(MainTabSettingDropright));
