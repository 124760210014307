import React from "react";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import StatusIconService from "./StatusIconService";
import "../../styles/components/services/service-list.scss";
import "../../styles/components/responsive-design.scss";

export default function ServiceList(props) {
  return (
    <div>
      {props.services.map((service, idx) => (
        <Link
          to={service.target}
          className={
            service.active ? "single-service active" : "single-service"
          }
          key={idx}
        >
          <div className="left-content">
            <Typography>{service.title}</Typography>
          </div>
          <div className="right-content">
            {service.template && (
              <Typography className="template-type">
                {service.template}
              </Typography>
            )}
            <div className="status">
              <span>
                {service.confirmed}/{service.total}
              </span>
              {service.status && <StatusIconService status={service.status} />}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}
