const ACTION_TYPES = {
  LOADING: "LOADING",
  TOGGLE_CONFIRMATION_MODAL: "TOGGLE_CONFIRMATION_MODAL",

  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAIL: "USER_LOGIN_FAIL",

  GET_USER_PROFILE_DATA_SUCCESS: "GET_USER_PROFILE_DATA_SUCCESS",
  GET_USER_PROFILE_DATA_FAIL: "GET_USER_PROFILE_DATA_FAIL",

  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",

  SHOW_PLAN_FILTER_ICON: "SHOW_PLAN_FILTER_ICON",
  SHOW_STATUS_FILTER_ICON: "SHOW_STATUS_FILTER_ICON",

  GET_SCHEDULING_SERVICES: "GET_SCHEDULING_SERVICES",
  GET_SCHEDULING_SERVICES_SUCCESS: "GET_SCHEDULING_SERVICES_SUCCESS",
  GET_SCHEDULING_SERVICES_ERROR: "GET_SCHEDULING_SERVICES_ERROR",
  SET_SCHEDULING_SERVICES_DATA: "SET_SCHEDULING_SERVICES_DATA",

  ASSIGN_TEMPLATE: "ASSIGN_TEMPLATE",
  ASSIGN_TEMPLATE_SUCCESS: "ASSIGN_TEMPLATE_SUCCESS",
  ASSIGN_TEMPLATE_ERROR: "ASSIGN_TEMPLATE_ERROR",
  ASSIGN_TEMPLATE_CLEAR: "ASSIGN_TEMPLATE_CLEAR",
};

export { ACTION_TYPES };
