import React from "react";
import { Icon, Typography } from "@material-ui/core";
import CustomSwitch from "./../atoms/CustomSwitch";
import "./../styles/components/service-single.scss";

const ServiceSingle = (props) => (
  <div className="service-single">
    <div className="left-content">
      <Icon>folder_open</Icon>
      <Typography>{props.service}</Typography>
    </div>
    <div>
      <CustomSwitch />
    </div>
  </div>
);

export default ServiceSingle;
