import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Icon,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { geolocated } from "react-geolocated";
import SuggestedNumbersForModal from "./../components/SuggestedNumbersForModal";
import { twilioService } from "../components/services/TwilioService";
import "../styles/components/choose-number-modal.scss";

const usaCities = [
  { name: "Alabama", value: "AL" },
  { name: "Alaska", value: "AK" },
  { name: "American Samoa", value: "AS" },
  { name: "Arizona", value: "AZ" },
  { name: "Arkansas", value: "AR" },
  { name: "California", value: "CA" },
  { name: "Colorado", value: "CO" },
  { name: "Connecticut", value: "CT" },
  { name: "Delaware", value: "DE" },
  { name: "District Of Columbia", value: "DC" },
  { name: "Federated States Of Micronesia", value: "FM" },
  { name: "Florida", value: "FL" },
  { name: "Georgia", value: "GA" },
  { name: "Guam", value: "GU" },
  { name: "Hawaii", value: "HI" },
  { name: "Idaho", value: "ID" },
  { name: "Illinois", value: "IL" },
  { name: "Indiana", value: "IN" },
  { name: "Iowa", value: "IA" },
  { name: "Kansas", value: "KS" },
  { name: "Kentucky", value: "KY" },
  { name: "Louisiana", value: "LA" },
  { name: "Maine", value: "ME" },
  { name: "Marshall Islands", value: "MH" },
  { name: "Maryland", value: "MD" },
  { name: "Massachusetts", value: "MA" },
  { name: "Michigan", value: "MI" },
  { name: "Minnesota", value: "MN" },
  { name: "Mississippi", value: "MS" },
  { name: "Missouri", value: "MO" },
  { name: "Montana", value: "MT" },
  { name: "Nebraska", value: "NE" },
  { name: "Nevada", value: "NV" },
  { name: "New Hampshire", value: "NH" },
  { name: "New Jersey", value: "NJ" },
  { name: "New Mexico", value: "NM" },
  { name: "New York", value: "NY" },
  { name: "North Carolina", value: "NC" },
  { name: "North Dakota", value: "ND" },
  { name: "Northern Mariana Islands", value: "MP" },
  { name: "Ohio", value: "OH" },
  { name: "Oklahoma", value: "OK" },
  { name: "Oregon", value: "OR" },
  { name: "Palau", value: "PW" },
  { name: "Pennsylvania", value: "PA" },
  { name: "Puerto Rico", value: "PR" },
  { name: "Rhode Island", value: "RI" },
  { name: "South Carolina", value: "SC" },
  { name: "South Dakota", value: "SD" },
  { name: "Tennessee", value: "TN" },
  { name: "Texas", value: "TX" },
  { name: "Utah", value: "UT" },
  { name: "Vermont", value: "VT" },
  { name: "Virgin Islands", value: "VI" },
  { name: "Virginia", value: "VA" },
  { name: "Washington", value: "WA" },
  { name: "West Virginia", value: "WV" },
  { name: "Wisconsin", value: "WI" },
  { name: "Wyoming", value: "WY" },
];

const canadaCities = [
  { value: "AB", name: "Alberta" },
  { value: "BC", name: "British Columbia" },
  { value: "MB", name: "Manitoba" },
  { value: "NB", name: "New Brunswick" },
  { value: "NL", name: "Newfoundland and Labrador" },
  { value: "NS", name: "Nova Scotia" },
  { value: "NT", name: "Northwest Territories" },
  { value: "NU", name: "Nunavut" },
  { value: "ON", name: "Ontario" },
  { value: "PE", name: "Prince Edward Island" },
  { value: "QC", name: "Québec" },
  { value: "SK", name: "Saskatchewan" },
  { value: "YT", name: "Yukon" },
];

class ChooseNumberModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      select: {
        country: "US",
        selectBy: "location",
        partOfNum: "first",
        numberType: "local",
        search: "",
        city: "",
      },
      submitted: false,
      loading: false,
      numbers: [],
      showNumberForm: false,
      phone_number: this.props.phone_number,
      display_phone_number: this.props.number,
      countries: [
        { value: "US", name: "USA" },
        { value: "CA", name: "Canada" },
        { value: "AU", name: "Australia" },
        { value: "ZA", name: "South Africa" },
        { value: "GB", name: "United Kingdom" },
        { value: "NZ", name: "New Zealand" },
      ],
      phone_numbers: [],
      error: {},
      emptySearch: false,
      anchorEl: null,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.refreshNumbers = this.refreshNumbers.bind(this);
    this.changePhone = this.changePhone.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  changePhone = (phone) => {
    this.setState({ phone_number: phone });
  };

  handleSelect = (event) => {
    const { select } = this.state;
    select[event.target.name] = event.target.value;
    if (event.target.name === "country") {
      select["city"] = "";
    }
    var phone_number = "";
    var display_phone_number = "";
    if (select.country === "NZ") {
      phone_number = "NZ";
      display_phone_number = "New Zealand (one-way)";
    } else if (select.country !== "CA" && select.country !== "US") {
      select["selectBy"] = "number";
      select["numberType"] = "mobile";
    } else {
      select["numberType"] = "local";
    }
    this.setState({ select, phone_number, display_phone_number });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  refreshNumbers() {
    var { select } = this.state;

    // stop here if form is invalid
    var search = select.selectBy === "number" ? select.search : select.city;
    if (
      !(
        select.country &&
        select.selectBy &&
        select.partOfNum &&
        select.numberType
      )
    ) {
      return;
    }

    this.setState({ loading: true });

    twilioService
      .numbers(
        select.country,
        select.selectBy,
        search,
        select.partOfNum,
        select.numberType
      )
      .then(
        (data) => {
          //console.log(data);
          if (data) {
            this.setState({ numbers: data });
            this.setState({ loading: false });
            if (data.length === 0) {
              this.setState({ emptySearch: true });
            } else {
              this.setState({ emptySearch: false });
            }
          }
        },
        (error) => {
          console.log(error);
          this.setState({ loading: false });
        }
      );
  }

  handleSubmit(e) {
    this.props.handleSubmit(
      e,
      this.state.phone_number,
      this.state.select.country
    );
    this.setState({ open: false });
  }

  render() {
    const { open, select, numbers, countries, emptySearch } = this.state;
    return (
      <div>
        <Button
          onClick={this.handleClickOpen}
          color="primary"
          variant="text"
          classes={{
            root: "btn",
            label: "label",
            textPrimary: "text-primary",
          }}
        >
          Pick different number
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          classes={{
            root: "choose-number-modal",
            paper: `${select.selectBy === "number" ? "paper-two" : "paper"}`,
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
          Paper={{
            classes: {
              root: "paper",
            },
          }}
        >
          <DialogTitle className="title">Choose number</DialogTitle>
          <DialogContent className="content modal-numbers-wrapper">
            <Grid
              className="d-flex align-items-center justify-content-start"
              spacing={2}
              style={{ marginBottom: 22 }}
            >
              <Grid item className="item mr-2" style={{ width: "25%" }}>
                <TextField
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                  id="filled-uncontrolled"
                  select
                  label="Country"
                  margin="normal"
                  variant="filled"
                  name="country"
                  value={select.country}
                  onChange={this.handleSelect}
                  SelectProps={{
                    MenuProps: {
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    },
                  }}
                >
                  {countries.map((country, idx) => (
                    <MenuItem key={idx} value={country.value}>
                      {" "}
                      {country.name}{" "}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              {select.country !== "NZ" ? (
                <React.Fragment>
                  <Grid item className="item mr-2" style={{ width: "25%" }}>
                    <TextField
                      classes={{
                        root: "text-field",
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "label",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: "input-wrapper",
                          input: "input",
                        },
                      }}
                      id="filled-uncontrolled"
                      select
                      label="By"
                      margin="normal"
                      variant="filled"
                      name="selectBy"
                      value={select.selectBy}
                      onChange={this.handleSelect}
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        },
                      }}
                    >
                      {(select.country === "CA" || select.country === "US") && (
                        <MenuItem value="location"> Location </MenuItem>
                      )}
                      <MenuItem value="number"> Number </MenuItem>
                    </TextField>
                  </Grid>
                  {select.selectBy === "location" ? (
                    <Grid
                      item
                      className="item double-field mr-1"
                      style={{ width: "50%" }}
                    >
                      <TextField
                        classes={{
                          root: "text-field",
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "label",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: "input-wrapper",
                            input: "input",
                          },
                        }}
                        style={{ width: "100%" }}
                        id="filled-uncontrolled"
                        select
                        label="Please choose"
                        margin="normal"
                        variant="filled"
                        name="city"
                        value={select.city}
                        onChange={this.handleSelect}
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          },
                        }}
                      >
                        {select.country === "US" &&
                          usaCities.map((city, idx) => (
                            <MenuItem key={idx} value={city.value}>
                              {city.name}
                            </MenuItem>
                          ))}

                        {select.country === "CA" &&
                          canadaCities.map((city, idx) => (
                            <MenuItem key={idx} value={city.value}>
                              {city.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      <Button
                        color="primary"
                        variant="text"
                        onClick={this.refreshNumbers}
                        classes={{
                          root: "btn",
                          label: "label",
                          textPrimary: "text-primary",
                        }}
                        className="mt-2"
                      >
                        <Icon className="search">search</Icon>
                        Search
                      </Button>
                    </Grid>
                  ) : (
                    <Grid item className="item double-field mr-1">
                      <TextField
                        label="Search by digits, phrases"
                        classes={{
                          root: "text-field mr-2",
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "label",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: "input-wrapper",
                            input: "input",
                          },
                        }}
                        onChange={this.handleSelect}
                        id="filled-uncontrolled"
                        placeholder="Search by digits, phrases or leave blank"
                        margin="normal"
                        variant="filled"
                        name="search"
                        value={select.search}
                        FormHelperTextProps={{
                          classes: {
                            root: "helper-text",
                          },
                        }}
                      />
                      <TextField
                        classes={{
                          root: "text-field",
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "label",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: "input-wrapper",
                            input: "input",
                          },
                        }}
                        id="filled-uncontrolled"
                        select
                        label="Match to"
                        margin="normal"
                        variant="filled"
                        name="partOfNum"
                        value={select.partOfNum}
                        onChange={this.handleSelect}
                        SelectProps={{
                          MenuProps: {
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                          },
                        }}
                      >
                        <MenuItem selected value="first">
                          {" "}
                          First Part of Number{" "}
                        </MenuItem>
                        <MenuItem value="anywhere">
                          {" "}
                          Anywhere in Number{" "}
                        </MenuItem>
                        <MenuItem value="last"> Last Part of Number </MenuItem>
                      </TextField>
                      <Button
                        color="primary"
                        variant="text"
                        onClick={this.refreshNumbers}
                        classes={{
                          root: "btn",
                          label: "label",
                          textPrimary: "text-primary",
                        }}
                        className="mt-2"
                      >
                        <Icon className="search">search</Icon>
                        Search
                      </Button>
                    </Grid>
                  )}
                </React.Fragment>
              ) : (
                <p>
                  We would assign you a US global number. The traffic is
                  converted to an NZ-shared shortcode. The current cost is 15
                  credits for 1 text message (160 characters). However, this
                  cost may decrease as more NZ church partners join, and we can
                  get better rates.
                </p>
              )}
              {emptySearch && (
                <p>
                  NOT AVAILABLE: AS OUR VENDOR EXPAND THEIR COVERAGE THROUGH
                  PARTNERSHIPS WITH NEW SERVICE PROVIDERS, THEY GO THROUGH A
                  RIGOROUS PERFORMANCE AND RELIABILITY TESTING PROCESS OF NEW
                  NUMBERS. PLEASE SELECT A DIFFERENT SEARCH CRITERIA
                </p>
              )}
            </Grid>
            {select.country !== "NZ" && (
              <SuggestedNumbersForModal
                numbers={numbers}
                reload={this.refreshNumbers}
                changePhone={this.changePhone}
              />
            )}
          </DialogContent>
          <DialogActions className="action-button">
            <Button
              color="primary"
              variant="contained"
              fullWidth
              disabled={this.state.phone_number ? false : true}
              onClick={this.handleSubmit}
              type="submit"
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary",
              }}
            >
              Choose
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(ChooseNumberModal);
