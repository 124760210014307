import React from "react";
import { NavLink } from "react-router-dom";
import { MenuItem, Tooltip } from "@material-ui/core";
import { ReactComponent as DashboardIcon } from "../icons/dashboard-icon.svg";
import { ReactComponent as ServiceIcon } from "../icons/service-icon.svg";
import { ReactComponent as MoreIcon } from "../icons/more-icon.svg";
import { ReactComponent as HelpIcon } from "../icons/help-icon.svg";
import { ReactComponent as PeopleIcon } from "../icons/people-icon.svg";
import MainTabSettingDropright from "./MainTabSettingDropright";
import "../styles/components/main-menu.scss";
import "../styles/components/mobile-menu.scss";

function MainMenu(props) {
  function handleGetHelp() {
    var help2 = document.getElementById("intercom-container");
    if (help2) {
      if (help2.style.display === "block") {
        help2.style.display = "none";
      } else {
        help2.style.display = "block";
      }
    }
    var help = document.getElementsByClassName("intercom-lightweight-app");
    var i;
    for (i = 0; i < help.length; i++) {
      if (help[i].style.display === "block") {
        help[i].style.display = "none";
      } else {
        help[i].style.display = "block";
      }
    }
    // Intercom('show');
    /*Tawk_API.showWidget();
    Tawk_API.maximize();*/
  }

  const user = JSON.parse(localStorage.getItem("user"));
  //Todo multpile church
  const integrated =
    user &&
    user.success &&
    user.success.user &&
    user.success.user.churches[0].pivot.pco_access_token &&
    user.success.status !== "Not Active"
      ? true
      : false;
  const admin =
    user &&
    user.success &&
    user.success.user &&
    user.success.user.is_admin === 1
      ? true
      : false;
  let impersonate = localStorage.getItem("impersonate");
  return (
    <div>
      <div className={impersonate ? "impersonate main-menu" : "main-menu"}>
        <Tooltip
          title="Dashboard"
          placement={window.innerWidth > 959 ? "right" : "top"}
        >
          <MenuItem
            component={NavLink}
            to="/dashboard"
            disableRipple
            classes={{
              root: "single dashboard",
            }}
          >
            <DashboardIcon />
          </MenuItem>
        </Tooltip>
        {integrated && (
          <Tooltip
            title="Services"
            placement={window.innerWidth > 959 ? "right" : "top"}
          >
            <MenuItem
              disableRipple
              component={NavLink}
              to="/services-all"
              classes={{
                root: "single",
              }}
            >
              <ServiceIcon />
            </MenuItem>
          </Tooltip>
        )}
        <Tooltip
          title="People"
          placement={window.innerWidth > 959 ? "right" : "top"}
        >
          <MenuItem
            disableRipple
            component={NavLink}
            to="/people"
            classes={{
              root: "single",
            }}
          >
            <PeopleIcon />
          </MenuItem>
        </Tooltip>
        {admin && !impersonate && (
          <MenuItem
            disableRipple
            component={NavLink}
            to="/users"
            classes={{
              root: "single",
            }}
          >
            <span className="main-label admin">A</span>
          </MenuItem>
        )}

        <div className="bottom-icons">
          <MainTabSettingDropright
            showSync={props.showSync}
            switchNoNumber={props.switchNoNumber}
          />
        </div>
      </div>

      <div className="mobile-menu">
        <MenuItem
          component={NavLink}
          to="/dashboard"
          disableRipple
          classes={{
            root: "single",
          }}
        >
          <span className="main-label">Dashboard</span>
          <DashboardIcon />
        </MenuItem>

        {integrated && (
          <MenuItem
            disableRipple
            component={NavLink}
            to="/services-all"
            classes={{
              root: "single",
            }}
          >
            <span className="main-label">Services</span>
            <ServiceIcon />
          </MenuItem>
        )}

        <MenuItem
          disableRipple
          component={NavLink}
          to="/more"
          classes={{
            root: "single",
          }}
        >
          <span className="main-label">More</span>
          <MoreIcon />
        </MenuItem>

        <MenuItem
          disableRipple
          component={NavLink}
          to="#"
          onClick={handleGetHelp}
          classes={{
            root: "single help-tab",
          }}
        >
          <span className="main-label">Get Help</span>
          <HelpIcon />
        </MenuItem>
      </div>
    </div>
  );
}

export default MainMenu;
