import React from "react";
import { Link } from "react-router-dom";
import { Typography, Avatar } from "@material-ui/core";
import ToggleButton from "./../../atoms/ToggleButton";
import "./../../styles/components/people/people-list.scss";
import { usePeopleStore } from "../../hooks/redux/usePeople";

function PeopleList(props) {
  const { persons } = props;
  const { people } = usePeopleStore();

  return (
    <div className="services-wrapper">
      {persons &&
        Array.isArray(persons) &&
        persons.map((person, idx) => (
          <Link
            to={person?.target}
            className={
              person?.disabled ? "single-person disabled" : "single-person"
            }
            key={idx}
          >
            <div className="left-content d-flex flex-row align-items-center p-3 p-md-4 gap-1">
              {person?.withAvatar && (
                <Avatar className="avatar mr-2" src={person?.avatar}>
                  {person?.name?.charAt(0)}
                </Avatar>
              )}
              <Typography>{person?.name}</Typography>
            </div>
            <div className="right-content">
              <ToggleButton
                on={!person?.skipped}
                person={person}
                onToggle={
                  person?.skipped ? props?.unSkipPeople : props?.skipPeople
                }
                loading={
                  (people?.unSkip || people?.skip) && people?.id === person?.id
                }
                allLoading={people.unSkipAll || people?.skipAll}
              />
            </div>
          </Link>
        ))}
    </div>
  );
}
export default PeopleList;
