import React from "react";
import { userService } from "./../components/services/UserService";
import queryString from "query-string";
import { css } from "@emotion/core";
import {
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Icon,
} from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";
import Logo from "./../components/Logo";
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class Activate extends React.Component {
  constructor(props) {
    super(props);

    const values = queryString.parse(this.props.location.search);
    userService.activate(values.code).then(
      (user) => {
        //console.log(user);
        if (user) {
          user = JSON.parse(user);
          localStorage.setItem("user", JSON.stringify(user));
          window.location.href = "/signup-details?id=" + user.success.user.id;
        }
      },
      (error) => {
        window.location.href = "/login?message=invalid";
      }
    );
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return this.props.history.push(`/dashboard`);
    }
  }

  render() {
    return (
      <div className="page-wrapper login">
        <div className="sweet-loading">
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={true}
          />
        </div>
        <div className="auth-wrapper">
          <div className="login-page">
            <div className="logo-wrapper">
              <Logo href="/" className="logo" />
              <h3>Login</h3>
            </div>
            <form name="form">
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Email Or Username"
                name="email"
                //defaultValue="yourname@email.com"
                margin="normal"
                variant="filled"
                FormHelperTextProps={{
                  classes: {
                    root: "helper-text",
                  },
                }}
              />
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility">
                        <Icon>{"visibility_off"}</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                type="Password"
                id="filled-uncontrolled"
                label="Password"
                name="password"
                //defaultValue="password"
                margin="normal"
                variant="filled"
                FormHelperTextProps={{
                  classes: {
                    root: "helper-text",
                  },
                }}
              />
              <div>
                <Button
                  color="primary"
                  variant="text"
                  to="/forgot"
                  classes={{
                    root: "btn",
                    label: "label",
                    textPrimary: "text-primary",
                  }}
                >
                  <span className="forgot-pw">Forgot your password?</span>
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  /*component={Link}
            to='/dashboard'*/
                  type="submit"
                  classes={{
                    root: "btn",
                    label: "label",
                    containedPrimary: "contained-primary",
                  }}
                >
                  Login
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  to="/signup"
                  classes={{
                    root: "btn",
                    label: "label",
                    textPrimary: "text-primary",
                  }}
                >
                  <span className="light">Don't have an account?</span> &nbsp;
                  Create account
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="auth-banner">
          <img src="images/auth-banner.png" alt="" />
        </div>
      </div>
    );
  }
}

export default Activate;
