import React from "react";
import queryString from "query-string";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import HeaderServices from "./HeaderServices";
import LogList from "./LogList";
import { authHeader } from "../helpers/AuthHeader";
import { userService } from "../../components/services/UserService";
import "../../styles/components/services/weekly-worship.scss";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class Log extends React.Component {
  constructor(props) {
    super(props);

    const values = queryString.parse(this.props.location.search);

    this.state = {
      type: values.type ? values.type : "",
      service: values.service ? values.service : 0,
      serviceName: values.serviceName ? values.serviceName : "",
      plan: values.plan ? values.plan : 0,
      planName: values.planName ? values.planName : "",
      personName: values.personName ? values.personName : "",
      personId: values.personId ? values.personId : "",
      backViewLink:
        "/logs-people?" +
        (values.type ? "type=" + values.type + "&" : "") +
        "plan=" +
        values.plan +
        "&planName=" +
        values.planName +
        "&service=" +
        values.service +
        "&serviceName=" +
        values.serviceName,
      weeklyLink:
        "/weekly-worship?service=" +
        (values.service ? values.service : 0) +
        "&serviceName=" +
        values.serviceName +
        (values.type ? "&type=" + values.type : ""),
      logList: [],
      loading: false,
    };
  }

  refreshLogs(reload) {
    const { plan, personId, type } = this.state;
    var _this = this;
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    _this.setState({ loading: true });
    return fetch(`${API_URL}logs/${plan}/${personId}/${type}`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          userService.refreshToken();
          _this.refreshLogs(true);
          _this.setState({ loading: false });
          return;
        }
        _this.setState({ logList: data, loading: false });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.refreshLogs();
  }

  render() {
    const {
      logList,
      personName,
      loading,
      type,
      weeklyLink,
      backViewLink,
    } = this.state;
    //console.log(logList);
    var header = "Services";
    switch (type) {
      case "C":
        header = "Accepted " + header;
        break;
      case "F":
        header = "Failed " + header;
        break;
      case "A":
        header = "Sent " + header;
        break;
      case "U":
        header = "Unconfirmed " + header;
        break;
      default:
        header = "Services";
    }
    header = personName;
    return (
      <div>
        <div className="page-wrapper">
          <div className={loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={loading}
            />
          </div>
          <HeaderServices
            backView={backViewLink}
            title={header}
            bread={[
              "/dashboard",
              type ? "/services?type=" + type : "/dashboard?value=1",
              weeklyLink,
              backViewLink,
            ]}
          />
          <div>
            <LogList logs={logList} />
          </div>
        </div>
      </div>
    );
  }
}

export default Log;
