import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Hidden,
  Icon,
  IconButton,
  Typography,
  TextField,
} from "@material-ui/core";
import HeaderServices from "./HeaderServices";
import "./../../styles/components/more/add-confirmation.scss";

function SendReminder() {
  const [values, setValues] = React.useState({
    text:
      "Dear [firstname], can you join us [time]? Please reply YES/NO to this message.",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <div className="add-confirmation page-wrapper">
      <HeaderServices
        backView="/add-confirmation-time"
        title="Reminder 225 people"
        hasSearch
        actionButton="Send"
        nextView="/confirmation-summary"
      />
      <div className="add-confirmation-text">
        <Hidden smDown>
          <Typography className="desktop-title">Send Reminder</Typography>
        </Hidden>
        <div className="text">
          <Typography className="title">Text</Typography>
          <div className="right-icons">
            <IconButton className="icon-button">
              <Icon>code</Icon>
            </IconButton>
            <IconButton
              className="icon-button"
              component={Link}
              to="/message-templates"
            >
              <Icon>dashboard</Icon>
            </IconButton>
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="textfield-wrapper">
              <TextField
                multiline
                fullWidth
                rowsMax="4"
                value={values.text}
                onChange={handleChange("text")}
                margin="normal"
                className="multiline-textfield"
                InputProps={{
                  classes: {
                    root: "form-control",
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/confirmation-summary"
            classes={{
              root: "btn",
              label: "label",
              containedPrimary: "contained-primary next-step",
            }}
          >
            Send
          </Button>
        </Hidden>
      </div>
    </div>
  );
}

export default SendReminder;
