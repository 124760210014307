import React from "react";
import { Button } from "@material-ui/core";
import HeaderForAll from "./../components/HeaderForAll";
import PCRadio from "./../components/PCRadio";

const AfterAuthStepOne = () => (
  <div className="after-auth-one page-wrapper">
    <HeaderForAll
      backView="/dashboard"
      title="Planning center"
      nextView="/after-auth-two"
      nextLabel="Next"
    />
    <div className="steps-wrapper">
      <Button
        color="primary"
        classes={{
          root: "btn",
          label: "label",
          textPrimary: "btn-active",
        }}
      >
        Step 1
      </Button>
      <Button
        disabled
        classes={{
          root: "btn",
          label: "label",
          disabled: "btn-disabled",
        }}
      >
        Step 2
      </Button>
    </div>
    <div className="content">
      <PCRadio />
    </div>
  </div>
);

export default AfterAuthStepOne;
