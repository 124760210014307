import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  TextField,
  Typography,
  Grid,
  Switch,
  Icon,
  InputAdornment,
  Tooltip,
} from "@material-ui/core";
import Avatar from "react-avatar";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import ChangePasswordModal from "./ChangePasswordModal";
import { connect } from "react-redux";
import lodash from "lodash";
import TimezoneSelect from "react-timezone-select";
import { updateUserInfo } from "../../services/api";
import { updateUserProfile } from "../../store/actions/userActions";
import { toggleConfirmationModal } from "../../store/actions/globalAction";
import ConfirmationModal from "../ConfirmationModal";
import "../../styles/components/more/user-settings.scss";

class UserSettings extends React.Component {
  constructor(props) {
    super(props);
    this.focusName = React.createRef();
    this.focusEmail = React.createRef();
    this.focusUsername = React.createRef();
    this.focusOrganizationName = React.createRef();
    this.state = {
      fields: {
        name: {
          value: "",
          key: "name",
          active: false,
        },
        email: {
          value: "",
          key: "email",
          active: false,
        },
        mobile_phone: {
          value: "",
          key: "mobile_phone",
          active: false,
        },
        username: {
          value: "",
          key: "username",
          active: false,
        },
        password: {
          value: "******",
          key: "password",
          active: false,
        },
        church: {
          value: "",
          active: false,
          key: "church",
        },
        one_way_only: {
          value: false,
          active: false,
          key: "one_way_only",
        },
        timezone: {
          value: "",
          active: false,
          key: "timezone",
        },
      },
      error: {},
      currentChangeField: "",
      confirmationModalTitle: "",
      confirmationModalDesc: "",
      confirmationAction: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { fields } = this.state;
    if (!this.props.currentUserInfo && nextProps.currentUserInfo) {
      fields.name.value = nextProps?.currentUserInfo?.name;
      fields.email.value = nextProps?.currentUserInfo?.email;
      fields.username.value = nextProps?.currentUserInfo?.username;
      fields.mobile_phone.value = nextProps?.currentUserInfo?.mobile_phone;
      fields.church.value = nextProps?.currentUserInfo?.churches[0]?.name;
      fields.timezone.value = nextProps?.currentUserInfo?.timezone;
      fields.one_way_only.value =
        nextProps?.currentUserInfo?.churches[0]?.one_way_only === 1;
      fields.one_way_only.active = true;
      this.setState({
        fields,
      });
    }
  }

  componentDidMount() {
    this.editProfileInformation();
  }

  setConfirmationModalData = (value) => {
    const { toggleConfirmationModal } = this.props;
    const title = `Enable one-way texting`;
    const desc = `This replaces the usual ACCEPT/DECLINE with a URL we host which links to your person’s opened service requests. Ideal for countries with only one-way texting, e.g. NZ.\n\nAre you sure you want to ${
      value ? "enable" : "disable"
    } one-way texting?`;
    this.setState(
      {
        confirmationModalTitle: title,
        confirmationModalDesc: desc,
        confirmationAction: this.toggleEnableOneWay,
      },
      () => {
        toggleConfirmationModal();
      }
    );
  };

  toggleEnableOneWay = () => {
    const { fields } = this.state;
    fields.one_way_only.value = !fields.one_way_only.value;
    this.setState({ fields });
    var _this = this;
    setTimeout(function () {
      _this.handleSubmit();
    }, 100);
  };

  editProfileInformation = () => {
    const { currentUserInfo } = this.props;
    const { fields } = this.state;
    fields.name.value = currentUserInfo?.name;
    fields.email.value = currentUserInfo?.email;
    fields.username.value = currentUserInfo?.username;
    fields.mobile_phone.value = currentUserInfo?.mobile_phone;
    fields.church.value = currentUserInfo?.churches[0].name;
    fields.timezone.value = currentUserInfo?.timezone;
    fields.one_way_only.value =
      currentUserInfo?.churches[0]?.one_way_only === 1;
    fields.one_way_only.active = currentUserInfo?.churches ? true : false;
    this.setState({
      fields,
    });
  };

  handleActiveField = (fieldToActive) => {
    const allRefs = {
      name: {
        ref: this.focusName,
      },
      email: {
        ref: this.focusEmail,
      },
      username: {
        ref: this.focusUsername,
      },
      mobile_phone: {
        ref: null,
      },
      church: {
        ref: this.focusOrganizationName,
      },
      one_way_only: {
        ref: null,
      },
      timezone: {
        ref: null,
      },
    };
    const { fields } = this.state;

    allRefs[fieldToActive].ref?.current?.focus();
    lodash.mapValues(fields, (values) => {
      if (values.key !== fieldToActive) {
        values.active = false;
      }
    });
    fields[fieldToActive].active = !fields[fieldToActive].active;
    this.setState({ fields });
    if (!fields[fieldToActive].active) {
      this.handleSubmit();
    }
  };

  handleFieldChange = (event) => {
    const { fields } = this.state;
    const fieldName = event.target.name;
    var fieldValue = event.target.value;
    if (fieldName !== "one_way_only") {
      fields[fieldName].value = fieldValue;
    }
    this.setState({ fields, currentChangeField: fieldName });
    if (fieldName === "one_way_only") {
      this.setConfirmationModalData(event.target.checked);
    }
  };

  handlePhoneNumberChange = (value) => {
    const { fields } = this.state;
    fields.mobile_phone.value = value;
    this.setState({ fields, currentChangeField: "mobile_phone" });
  };

  handleTimezone = (timezone) => {
    const { fields } = this.state;
    fields["timezone"].value = timezone.value;
    this.setState({ fields, currentChangeField: "timezone" });
  };

  handleSubmit = async () => {
    const { fields, currentChangeField } = this.state;
    const { currentUserInfo, updateUserProfileData } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      if (!currentChangeField.length) return null;
      if (
        currentChangeField !== "one_way_only" &&
        !fields[currentChangeField].value
      ) {
        fields[currentChangeField].value = currentUserInfo[currentChangeField];
        this.setState({
          error: {
            [currentChangeField]: `${currentChangeField} is required`,
            fields,
          },
        });
        return;
      }
      const body = JSON.stringify({
        [currentChangeField]: fields[currentChangeField].value,
      });
      const responseBack = await updateUserInfo(body);
      if (responseBack?.success) {
        user.success.user = responseBack?.success?.user;
        localStorage.setItem("user", JSON.stringify(user));
        updateUserProfileData(responseBack?.success?.user);
        this.setState({ currentChangeField: "", error: {} });
      }
    } catch (error) {
      if (error?.response?.data) {
        let errors = {};
        fields[currentChangeField].value = currentUserInfo[currentChangeField];
        errors = {
          [currentChangeField]:
            error?.response?.data?.error[currentChangeField][0],
        };
        this.setState({ error: errors, fields });
      }
    }
  };

  render() {
    const {
      error,
      fields,
      confirmationModalDesc,
      confirmationModalTitle,
      confirmationAction,
    } = this.state;
    const { currentUserInfo, showConfirmationModal } = this.props;

    return (
      <div className="page-wrapper my-profile">
        <PagesSubMenu />
        {/*<div className={loading ? "sweet-loading" : ""}>
        <ClipLoader
          css={override}
          sizeUnit={"px"}
          size={150}
          color={'#7BC807'}
          loading={loading}
        />
        </div>*/}
        <HeaderMore backView="/dashboard" title="User Settings" />
        <div className="desktop-design">
          <Typography className="desktop-title" component={"h2"}>
            User Settings
          </Typography>
          <Grid container className="user-detail">
            <Typography className="title" component={"h2"}>
              You
            </Typography>
            <Grid item md={6} xs={12} className="item">
              <div className="avatar">
                <Avatar
                  name={currentUserInfo?.name}
                  email={currentUserInfo?.email}
                  className="avatar"
                />
              </div>
              <form className="name">
                <TextField
                  style={{ width: fields.name.value?.length * 9 + 70 }}
                  classes={{ root: "text-field" }}
                  variant="filled"
                  name="name"
                  value={fields.name.value}
                  onChange={this.handleFieldChange}
                  inputRef={this.focusName}
                  error={error?.name}
                  helperText={error?.name}
                  InputProps={{
                    readOnly: !fields.name.active,
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          className="edit"
                          onClick={() => this.handleActiveField("name")}
                        >
                          {fields.name.active ? "Save" : "Edit"}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Grid>
            <Grid item md={6} xs={12} className="item">
              <form className="contact">
                <TextField
                  style={{ width: fields.email.value?.length * 9 + 108 }}
                  classes={{ root: "text-field" }}
                  id="filled-read-only-input"
                  variant="filled"
                  name="email"
                  value={fields.email.value}
                  onChange={this.handleFieldChange}
                  inputRef={this.focusEmail}
                  error={error?.email}
                  helperText={error?.email}
                  InputProps={{
                    readOnly: !fields.email.active,
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="icon">
                          <Icon>email</Icon>
                        </span>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          className="edit"
                          onClick={() => this.handleActiveField("email")}
                        >
                          {fields.email.active ? "Save" : "Edit"}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  className={`input-wrapper ${
                    error.mobile_phone ? "phone error" : "phone"
                  }`}
                  style={{ width: fields.mobile_phone.value?.length * 9 + 170 }}
                >
                  <span className="icon">
                    <Icon>phone</Icon>
                  </span>
                  <PhoneInput
                    name="mobile_phone"
                    country={"us"}
                    value={fields.mobile_phone.value}
                    onChange={(mobile_phone) =>
                      this.handlePhoneNumberChange(mobile_phone)
                    }
                    disabled={!fields.mobile_phone.active}
                  />
                  <span
                    className="edit"
                    onClick={() => this.handleActiveField("mobile_phone")}
                  >
                    {fields.mobile_phone.active ? "Save" : "Edit"}
                  </span>
                </div>
              </form>
            </Grid>
          </Grid>
          <Grid
            container
            className="user-detail login"
            style={{ marginTop: 40 }}
          >
            <Typography className="title" component={"h2"}>
              Your Login Credentials
            </Typography>
            <Grid item xs={12}>
              <form className="contact">
                <TextField
                  style={{ width: fields.username.value?.length * 9 + 150 }}
                  classes={{ root: "text-field" }}
                  id="filled-read-only-input"
                  name="username"
                  variant="filled"
                  value={fields.username.value}
                  onChange={this.handleFieldChange}
                  inputRef={this.focusUsername}
                  error={error?.username}
                  helperText={error?.username}
                  InputProps={{
                    readOnly: !fields.username.active,
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="label mt-0">Username:</span>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          className="edit"
                          onClick={() => this.handleActiveField("username")}
                        >
                          {fields.username.active ? "Save" : "Edit"}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  style={{ width: fields.password.value?.length * 9 + 150 }}
                  classes={{ root: "text-field" }}
                  id="filled-read-only-input"
                  variant="filled"
                  value={fields.password.value}
                  onChange={this.handleFieldChange}
                  InputProps={{
                    readOnly: !fields.password.active,
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="label mt-0">Password:</span>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ChangePasswordModal />
                      </InputAdornment>
                    ),
                  }}
                />
              </form>
            </Grid>
          </Grid>

          <Grid
            container
            className="user-detail login"
            style={{ marginTop: 40 }}
          >
            <Typography className="title" component={"h2"}>
              Your Organization
            </Typography>
            <Grid item xs={12} md={8}>
              <form className="contact">
                <TextField
                  style={{
                    width: `${fields.church.value?.length * 9 + 200}px`,
                    maxWidth: "100%",
                  }}
                  id="filled-read-only-input"
                  name="church"
                  variant="filled"
                  value={fields.church.value}
                  onChange={this.handleFieldChange}
                  inputRef={this.focusOrganizationName}
                  error={error?.church}
                  helperText={error?.church}
                  InputProps={{
                    readOnly: !fields.church.active,
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <span className="label mt-0">Organization name:</span>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <span
                          className="edit"
                          onClick={() => this.handleActiveField("church")}
                        >
                          {fields.church.active ? "Save" : "Edit"}
                        </span>
                      </InputAdornment>
                    ),
                  }}
                />

                <Tooltip title="This replaces the usual ACCEPT/DECLINE with a URL we host which links to your person’s opened service requests. Ideal for countries with only one-way texting, e.g. NZ.">
                  <label className="input-wrapper one-way">
                    <span className="label mt-0 MuiFilledInput-adornedStart">
                      Enable one-way texting:
                    </span>

                    <Switch
                      name="one_way_only"
                      checked={fields.one_way_only.value}
                      onChange={this.handleFieldChange}
                      value={fields.one_way_only.value}
                      disabled={!fields.one_way_only.active}
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      disableRipple
                      classes={{
                        root: "custom-switch",
                        thumb: "thumb",
                        track: "track",
                        switchBase: "switch-base",
                        checked: "checked",
                      }}
                    />
                  </label>
                </Tooltip>
              </form>
            </Grid>
          </Grid>

          <Grid
            container
            className="user-detail login"
            style={{ marginTop: 40 }}
          >
            <Typography className="title" component={"h2"}>
              Localization
            </Typography>
            <Grid item xs={12} md={8}>
              <form className="contact">
                {fields.timezone.active ? (
                  <div class="input-wrapper">
                    <span className="label mt-0">Timezone:</span>
                    <TimezoneSelect
                      id="timezoneSelect"
                      name="timezone"
                      labelStyle="altName"
                      value={fields.timezone}
                      onChange={this.handleTimezone}
                    />
                    <span
                      className="edit"
                      onClick={() => this.handleActiveField("timezone")}
                    >
                      {fields.timezone.active ? "Save" : "Edit"}
                    </span>
                  </div>
                ) : (
                  <TextField
                    style={{
                      width: `${fields.timezone.value?.length * 9 + 200}px`,
                      maxWidth: "100%",
                    }}
                    id="filled-read-only-input"
                    name="timezoned"
                    variant="filled"
                    value={fields.timezone.value}
                    InputProps={{
                      readOnly: true,
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <span className="label mt-0">Timezone:</span>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <span
                            className="edit"
                            onClick={() => this.handleActiveField("timezone")}
                          >
                            {fields.timezone.active ? "Save" : "Edit"}
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </form>
            </Grid>
          </Grid>
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            title={confirmationModalTitle}
            description={confirmationModalDesc}
            confirmAction={confirmationAction}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    currentUserInfo: store.userProfileReducer.user,
    church: store.userProfileReducer.church,
    phoneNumber: store.userProfileReducer.phone_number,
    showConfirmationModal: store.global.showConfirmationModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateUserProfileData: (updatedData) =>
    dispatch(updateUserProfile(updatedData)),
  toggleConfirmationModal: () => dispatch(toggleConfirmationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
