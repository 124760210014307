import React, { Component } from "react";
import Slider from "react-slick";
import {
  Button,
  ListItem,
  Typography,
  TextField,
  Tooltip,
  Icon,
} from "@material-ui/core";
import OrderDetails from "./OrderDetails";
import { renderHTML } from "../../helpers/helpers";

export default class SubscriptionMobile extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 1,
    };
    return (
      <Slider {...settings}>
        {this.props.packages.map((data, idx) => (
          <div
            className={data.is_popular ? "single-card featured" : "single-card"}
            key={idx}
          >
            {data.is_popular === 1 && (
              <div className="popular-tag">Most popular</div>
            )}
            <Typography className="plan-title">{data.name}</Typography>
            <img src="./images/starting-package.png" alt="" />
            <Typography className="price-tag" component={"h2"}>
              $
              {this.props.period === "Monthly"
                ? data.amount_per_month
                : (data.amount_per_year / 12).toFixed(2)}{" "}
              / mo
            </Typography>
            <img
              src={data.image ? data.image : "./images/starting-package.png"}
              alt=""
            />
            <div className="features">
              {this.props.period === "Yearly" && (
                <ListItem className="single features-main">
                  Paid Annually ${data.amount_per_year} / yr
                </ListItem>
              )}
              <ListItem className="single">
                {data.credit} credits per month
              </ListItem>
              <ListItem className="single">
                <div className="flexer">
                  {data.description}
                  <Tooltip
                    title={data.tooltip ? renderHTML(data.tooltip) : ""}
                    placement="top"
                    arrow={true}
                  >
                    <Icon className="help-icon">help_outline</Icon>
                  </Tooltip>
                </div>
              </ListItem>
            </div>
            <Typography className="switch"></Typography>
            <TextField
              placeholder="Coupon code"
              name={
                "coupon-" +
                data.id +
                "-" +
                (this.props.period === "Monthly" ? 0 : 1)
              }
              onChange={this.props.handleChange}
              fullWidth
              classes={{
                root: "text-field",
              }}
              InputProps={{
                classes: {
                  root: "input-wrapper",
                  input: "input",
                },
              }}
            />
            <OrderDetails
              item={data}
              period={this.props.period}
              handler={this.props.handler}
              refreshAllSections={this.props.refreshAllSections}
              defaultCard={this.props.defaultCard}
              coupon={this.props.coupon}
              email={this.props.email}
              currentPackage={this.props.currentPackage}
              isMonthly={this.props.isMonthly}
              toggler={
                !this.props.currentPackage ||
                !(
                  this.props.currentPackage.id === data.id &&
                  (this.props.period === "Monthly") === this.props.isMonthly
                ) ? (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={this.props.togglerClick}
                    type="submit"
                    classes={{
                      root: "btn",
                      label: "label",
                      containedPrimary: "contained-primary",
                    }}
                  >
                    Select
                  </Button>
                ) : (
                  <div className="label btn current-plan">Current Plan</div>
                )
              }
            />
          </div>
        ))}
      </Slider>
    );
  }
}
