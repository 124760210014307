import React from "react";
import queryString from "query-string";
import { Button, Hidden, Typography, TextField } from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import { updatePricing } from "../../services/api";
import "../../styles/components/more/schedule-templates.scss";

class EditPricing extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    this.state = {
      pricing: values.id ? values.id : 0,
      amount: values.amount ? values.amount : 1,
      country: values.country ? values.country : "US",
      disabled: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async () => {
    const { pricing, amount } = this.state;
    this.setState({ disabled: true });
    await updatePricing(pricing, amount);
    window.location.href = "/pricing";
  };

  handleChange(e) {
    this.setState({ amount: e.currentTarget.value });
  }

  render() {
    const { amount, country, disabled } = this.state;

    let header = "Pricing";
    if (country) {
      header = `${header} > ${country}`;
    }
    return (
      <div className="edit-schedule page-wrapper my-profile">
        <PagesSubMenu />
        <Hidden mdUp>
          <HeaderMore
            backView="/pricing"
            title={`...> ${country}`}
            nextView="/users"
          />
        </Hidden>
        <div
          className={
            window.innerWidth > 959 ? "desktop-design" : "inner-content-wrapper"
          }
        >
          <Hidden smDown>
            <Typography className="desktop-title" component={"h2"}>
              {header}
            </Typography>
          </Hidden>
          <div className="content">
            <Typography
              component="h6"
              className="mt-2 ml-4"
              style={{ fontSize: 14 }}
            >
              <TextField
                id="amount"
                label="Amount"
                fullWidth
                variant="filled"
                value={amount}
                onChange={this.handleChange}
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                type="number"
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
              />
            </Typography>
            <Typography
              component="h6"
              className="mt-2 ml-4 primary"
              style={{ fontSize: 14 }}
            >
              <Button
                disabled={disabled}
                color="primary"
                variant="contained"
                type="submit"
                onClick={this.handleSubmit}
                classes={{
                  root: "btn",
                  label: "label",
                  containedPrimary: "contained-primary",
                }}
              >
                Update
              </Button>
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

export default EditPricing;
