import React from "react";
import {
  Icon,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import CustomSwitch from "./../../atoms/CustomSwitch";
import "./../../styles/components/more/notifications.scss";

const services = [
  "Summer in the city",
  "Sunday Service",
  "Bible Study",
  "Summer in the city",
];

function Notifications() {
  const [values, setValues] = React.useState({
    type: "reminder",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <div className="notifications page-wrapper">
      <HeaderMore backView="/dashboard" title="Notifications" hasSearch />
      <div className="inner-content-wrapper">
        <div className="inner-content-wrapper">
          <Typography className="section-title">
            Notify me when users
          </Typography>
          <FormControlLabel
            className="single-switch"
            control={<CustomSwitch />}
            label="Confirm"
          />
          <FormControlLabel
            className="single-switch"
            control={<CustomSwitch />}
            label="Decline"
          />
          <Typography className="section-title" style={{ marginTop: 50 }}>
            Notify me
          </Typography>
          <RadioGroup
            aria-label="Gender"
            name="gender1"
            onChange={handleChange}
          >
            <FormControlLabel
              className="single-switch"
              value="asap"
              control={<Radio />}
              label="As it happens"
            />
            <FormControlLabel
              className="single-switch"
              value="daily"
              control={<Radio />}
              label="Daily summary"
            />
            <FormControlLabel
              className="single-switch"
              value="weekly"
              control={<Radio />}
              label="Weekly summary"
            />
          </RadioGroup>
          <Typography className="section-title" style={{ marginTop: 50 }}>
            Notify by
          </Typography>
          <FormControlLabel
            className="single-switch"
            control={<CustomSwitch />}
            label="SMS"
          />
          <FormControlLabel
            className="single-switch"
            control={<CustomSwitch />}
            label="Email"
          />
          <FormControlLabel
            className="single-switch"
            control={<CustomSwitch />}
            label="Mobile"
          />
          <Typography className="section-title" style={{ marginTop: 50 }}>
            Notify me about services
          </Typography>
          {services.map((service, idx) => (
            <FormControlLabel
              classes={{
                root: "single-switch",
                label: "label",
              }}
              control={<CustomSwitch />}
              label={
                <p className="label-with-icon">
                  <span>{service}</span>
                  <Icon>keyboard_arrow_right</Icon>
                </p>
              }
              key={idx}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Notifications;
