import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Hidden,
  Typography,
  MenuItem,
  TextField,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import "./../../styles/components/more/add-confirmation.scss";

function AddConfirmationTime() {
  const [values, setValues] = React.useState({
    timeDay: "asap",
    timeWhen: "between",
    startingTime: "am",
    endingTime: "pm",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  return (
    <div className="page-wrapper add-confirmation">
      <HeaderMore
        backView="/new-schedule"
        title="Confirmation"
        actionButton="Next"
        nextView="/add-confirmation-text"
      />
      <div className="inner-content-wrapper">
        <Hidden smDown>
          <Typography className="desktop-title">Confirm Time</Typography>
        </Hidden>
        <div className="when">
          <Typography className="title">When</Typography>
        </div>
        <div className="add-confirmation-time">
          <Grid container>
            <Grid item className="single-item" xs={12}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                select
                label="When"
                margin="normal"
                variant="filled"
                value={values.timeDay}
                onChange={handleChange("timeDay")}
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  },
                }}
              >
                <MenuItem value="asap"> ASAP </MenuItem>
                <MenuItem value="later"> A day before </MenuItem>
                <MenuItem value="weekend">A week before </MenuItem>
              </TextField>
            </Grid>
            <Grid item className="single-item" xs={12}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                select
                label="When"
                margin="normal"
                variant="filled"
                value={values.timeWhen}
                onChange={handleChange("timeWhen")}
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  },
                }}
              >
                <MenuItem value="between"> Between </MenuItem>
                <MenuItem value="at"> At </MenuItem>
              </TextField>
            </Grid>
            {values.timeWhen === "between" && (
              <Grid container>
                <Grid item className="single-item" xs={4}>
                  <TextField
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    id="filled-uncontrolled"
                    label="Hour"
                    defaultValue="08"
                    margin="normal"
                    variant="filled"
                  />
                </Grid>
                <Grid item className="single-item" xs={4}>
                  <TextField
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    id="filled-uncontrolled"
                    label="Minutes"
                    defaultValue="00"
                    margin="normal"
                    variant="filled"
                  />
                </Grid>
                <Grid item className="single-item" xs={4}>
                  <TextField
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    id="filled-uncontrolled"
                    select
                    label="am/pm"
                    margin="normal"
                    variant="filled"
                    value={values.startingTime}
                    onChange={handleChange("startingTime")}
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    <MenuItem value="am"> AM </MenuItem>
                    <MenuItem value="pm"> PM </MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            )}
            <Grid item className="single-item" xs={4}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Hour"
                defaultValue="08"
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item className="single-item" xs={4}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Minutes"
                defaultValue="00"
                margin="normal"
                variant="filled"
              />
            </Grid>
            <Grid item className="single-item" xs={4}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                select
                label="am/pm"
                margin="normal"
                variant="filled"
                value={values.endingTime}
                onChange={handleChange("endingTime")}
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  },
                }}
              >
                <MenuItem value="am"> AM </MenuItem>
                <MenuItem value="pm"> PM </MenuItem>
              </TextField>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/add-confirmation-text"
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary next-step",
              }}
            >
              Next
            </Button>
          </Hidden>
        </div>
      </div>
    </div>
  );
}

export default AddConfirmationTime;
