import React, { useEffect, useMemo, useState } from "react";

import debounce from "lodash.debounce";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";

import { usePeopleStore } from "../../hooks/redux/usePeople";
import HeaderPeople from "./HeaderPeople";
import PeopleList from "./PeopleList";

const People = () => {
  const { people, fetchPeople, unSkipPeople, skipPeople, unSkipAllPeople } =
    usePeopleStore();
  const [search, setSearch] = useState("");
  const {
    fetch,
    error,
    people: peopleList,
    skipSuccess,
    unSkipSuccess,
    unSkipAllSuccess,
  } = people;

  useEffect(() => {
    const printValue = debounce((value) => fetchPeople(value), 1000);
    printValue(search);
    return () => printValue.cancel();
  }, [search]);

  const setConfirmation = (type) => {
    if (type === "OFF" && peopleList?.length) {
      const ids = [];
      peopleList.map((people) => {
        ids.push(people.id);
      });
      skipPeople(ids);
    }
    if (type === "ON" && peopleList?.length) {
      unSkipAllPeople();
    }
  };

  useEffect(() => {
    if (unSkipSuccess || skipSuccess || unSkipAllSuccess) fetchPeople();
  }, [skipSuccess, unSkipSuccess, unSkipAllSuccess]);

  const content = useMemo(() => {
    if (error) {
      return (
        <p className="text-center text-danger mx-auto mt-5">
          {error?.message ??
            "Unexpected error occurred while fetching data,Please try again"}
        </p>
      );
    } else if (fetch && !peopleList?.length) {
      return (
        <div className="mx-auto text-center mt-5">
          <CircularProgress />
        </div>
      );
    } else if (peopleList?.length) {
      return (
        <PeopleList
          persons={peopleList}
          unSkipPeople={unSkipPeople}
          skipPeople={skipPeople}
        />
      );
    } else {
      return <p className="text-primary">No People Found!</p>;
    }
  }, [error, fetch, peopleList, unSkipPeople, skipPeople]);

  return (
    <div style={{ flex: 1 }}>
      <HeaderPeople
        setSearch={setSearch}
        title="People"
        lists={[
          {
            id: 1,
            title: "Turn on confirmations for all",
            target: "#",
            onClick: () => setConfirmation("ON"),
          },
          {
            id: 2,
            title: "Turn off confirmations for all",
            target: "#",
            onClick: () => setConfirmation("OFF"),
          },
        ]}
        bottomModalContent={
          <div>
            <Button className="content-single">
              Turn on confirmations for all
            </Button>
            <Button className="content-single">
              Turn off confirmations for all
            </Button>
          </div>
        }
      />
      <div className="mx-auto h-full">{content}</div>
    </div>
  );
};

export default People;
