import React from "react";
import {
  Button,
  MenuItem,
  Select,
  TextField,
  Icon,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { userService } from "./../services/UserService";
import "../../styles/components/choose-number-modal.scss";
import "../../styles/components/more/pastorsline.scss";
import SuggestedNumbersForModal from "../../components/SuggestedNumbersForModal";
import { formatPhoneNumber } from "../../helpers/helpers";

class Pastorsline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      numberImported: false,
      loggedIn: true,
      submitted: false,
      loading: false,
      number: "",
      open: false,
      pastorslineLoggedIn: false,
      companies: [],
      numbers: null,
      companyId: 0,
      coupon_code: props.coupon_code ? props.coupon_code : null,
      error: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitPhone = this.submitPhone.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick(e) {
    this.setState({ numberImported: false });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleClickOpen() {
    this.setState({ open: true });
  }

  handleClose() {
    this.setState({ open: false });
  }

  handleCompanyChange = (e) => {
    this.setState({ companyId: e.target.value });
    this.state.companies.map((c, idx) => {
      if (e.target.value == c.company.id) {
        var numbers = [];
        c.numbers.map((n) => {
          numbers.push({
            value: n.phone_number,
            number: formatPhoneNumber(n.phone_number, n.country_code),
            label: "SMS",
            country: n.country_code,
          });
        });
        this.setState({ numbers: numbers });
      }
    });
  };

  handlePhoneChange = (phone) => {
    this.setState({ number: phone });
  };

  refreshNumbers() {}

  submitPhone(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password, loggedIn, number, coupon_code } = this.state;

    const error = {};
    // stop here if form is invalid
    if (!(email && password && number)) {
      if (!email) {
        error["email"] = "this field is required";
      }
      if (!password) {
        error["password"] = "this field is required";
      }
      if (!number) {
        error["number"] = "this field is required";
      }

      this.setState({ error });
      return;
    }

    this.setState({ loading: true });
    userService.authorizePl(email, password, loggedIn, number).then(
      (user) => {
        if (loggedIn) {
          // eslint-disable-next-line no-redeclare
          var user = JSON.parse(localStorage.getItem("user"));
          this.setState({
            numberImported: true,
            loading: false,
            number: user.success.user.primary_number,
          });
          //console.log(user);
          window.location.href = "/number";
        } else {
          this.setState({ loading: true });
          user = JSON.parse(user);
          const realUser = JSON.parse(localStorage.getItem("user"));
          userService
            .registerStep3(
              user.number,
              null,
              realUser.success.user.id,
              user.sid,
              user.pl_token,
              coupon_code
            )
            .then(
              (user) => {
                this.setState({ error: {}, loading: false });
                window.location.href = "/dashboard?d=" + Date.now();
              },
              (error) => {
                if (typeof error === "string") {
                  this.setState({ error: { phone: error }, loading: false });
                } else {
                  var _this = this;
                  error.then(function (e) {
                    var errors = JSON.parse(e).error;
                    var errorJson = {};
                    errorJson["errorText"] = "";
                    for (const key in errors) {
                      console.log(key);
                      errorJson[key] = errors[key][0];
                      errorJson["errorText"] +=
                        "<li>" + key + ": " + errors[key][0] + "</li>";
                    }
                    _this.setState({ error: errorJson, loading: false });
                  });
                }
              }
            );
        }
      },
      (error) => {
        var _this = this;
        error.then(function (e) {
          var errors = JSON.parse(e).error;
          _this.setState({
            error: { email: errors, password: "" },
            loading: false,
          });
        });
      }
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password, loggedIn } = this.state;

    const error = {};
    // stop here if form is invalid
    if (!(email && password)) {
      if (!email) {
        error["email"] = "this field is required";
      }
      if (!password) {
        error["password"] = "this field is required";
      }

      this.setState({ error });
      return;
    }

    this.setState({ loading: true });
    userService.authorizePl(email, password).then(
      (user) => {
        this.setState({
          pastorslineLoggedIn: true,
          companies: JSON.parse(user).user_numbers,
        });
      },
      (error) => {
        var _this = this;
        error.then(function (e) {
          var errors = JSON.parse(e).error;
          _this.setState({
            error: { email: errors, password: "" },
            loading: false,
          });
        });
      }
    );
  }

  componentDidMount() {
    var user = JSON.parse(localStorage.getItem("user"));

    //console.log(user);
    if (user.success.token) {
      this.setState({ loggedIn: user.success.user.id });
    }
    if (user.success.token && user.success.user.is_phone_pl) {
      this.setState({
        numberImported: true,
        number: user.success.user.primary_number,
      });
    } else if (!user.success.token) {
      this.setState({ loggedIn: false });
    }
  }

  render() {
    const {
      email,
      password,
      error,
      open,
      pastorslineLoggedIn,
      companies,
      companyId,
      numbers,
    } = this.state;
    //console.log(loggedIn);
    return (
      <div>
        {this.props.state !== "open" && (
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleClickOpen}
            classes={{
              root: "pastorsline-btn",
              label: "label",
              containedPrimary: "contained-primary",
            }}
          >
            Connect
            <img src="./images/pastorsline.svg" alt="" />
          </Button>
        )}

        {this.props.state === "open" && (
          <Button
            color="primary"
            variant="contained"
            type="submit"
            onClick={this.handleClickOpen}
            classes={{
              root: "btn",
              label: "label",
              containedPrimary: "contained-primary",
            }}
          >
            Connect
          </Button>
        )}
        <Dialog
          fullScreen={window.innerWidth < 600 && true}
          open={open}
          onClose={this.handleClose}
          classes={{
            root: "add-new-number-modal pastorsline",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
          Paper={{
            classes: {
              root: "paper",
            },
          }}
        >
          <Typography className="title">
            Import Number From PastorsLine
            <Icon onClick={this.handleClose} className="close-icon">
              close
            </Icon>
          </Typography>
          <DialogContent className="content modal-numbers-wrapper">
            {!pastorslineLoggedIn ? (
              <form name="form" onSubmit={this.handleSubmit}>
                <TextField
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                  id="filled-uncontrolled"
                  label="PastorsLine Email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  //defaultValue="yourname@email.com"
                  margin="normal"
                  variant="filled"
                  error={typeof error.email === "string"}
                  helperText={error.email ? error.email : ""}
                  FormHelperTextProps={{
                    classes: {
                      root: "helper-text",
                    },
                  }}
                />
                <br />
                <TextField
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                  type="Password"
                  id="filled-uncontrolled"
                  label="PastorsLine Password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  //defaultValue="password"
                  error={typeof error.password === "string"}
                  helperText={error.password ? error.password : ""}
                  margin="normal"
                  variant="filled"
                  FormHelperTextProps={{
                    classes: {
                      root: "helper-text",
                    },
                  }}
                />
                <div>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    /*component={Link}
                to='/dashboard'*/
                    type="submit"
                    classes={{
                      root: "btn",
                      label: "label",
                      containedPrimary: "contained-primary",
                    }}
                  >
                    Import Number
                  </Button>
                </div>
              </form>
            ) : (
              <React.Fragment>
                <form name="form" onSubmit={this.submitPhone}>
                  <div className="pl-number">
                    <Select
                      className="credits-select"
                      labelId="label"
                      id="select"
                      value={companyId}
                      onChange={this.handleCompanyChange}
                      classes={{
                        root: "select",
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="0">Select Company</MenuItem>
                      {companies.map((c, idx) => (
                        <MenuItem key={idx} value={c.company.id}>
                          {c.company.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </form>
                {numbers && (
                  <React.Fragment>
                    <SuggestedNumbersForModal
                      numbers={numbers}
                      reload={this.refreshNumbers}
                      changePhone={this.handlePhoneChange}
                    />
                    <div className="btn-wrapper">
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={this.submitPhone}
                        classes={{
                          root: "btn",
                          label: "label",
                          containedPrimary: "contained-primary",
                        }}
                      >
                        Choose
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}
export default Pastorsline;
