import React from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Box,
  Button,
  Grid,
  TextField,
  MenuItem,
  Icon,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import queryString from "query-string";
import { geolocated } from "react-geolocated";
import HeaderForAll from "../components/HeaderForAll";
import SuggestedNumbers from "../components/SuggestedNumbers";
import Pastorsline from "../components/more/Pastorsline";
import ChooseNumberModal from "../components/ChooseNumberModal";
import { userService } from "../components/services/UserService";
import { twilioService } from "../components/services/TwilioService";
import { formatPhoneNumber } from "../helpers/helpers";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class CreateAccountThree extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_number: "",
      display_phone_number: "",
      country: "US",
      selectBy: "location",
      partOfNum: "first",
      numberType: "local",
      search: "",
      submitted: false,
      loading: false,
      numbers: [],
      countries: [
        { iso: "US", name: "United States" },
        { iso: "CA", name: "Canada" },
      ],
      error: {},
    };

    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      window.location.href = "/signup";
      return;
    }

    const values = queryString.parse(this.props.location.search);
    if (!values.id || parseInt(values.id) !== user.success.user.id) {
      const { from } = { from: { pathname: "/signup" } };
      this.props.history.push(from);
      return;
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.chooseNumber = this.chooseNumber.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return this.props.history.push(`/dashboard`);
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });

    if (name !== "phone_number" && name !== "search") {
      //setTimeout(function(){_this.refreshNumbers()}, 100);
    } else if (name === "phone_number") {
      this.setState({
        display_phone_number: formatPhoneNumber(value, this.state.country),
      });
    }
  }

  handleClick(e) {
    this.refreshNumbers();
  }

  refreshNumbers() {
    var { country, selectBy, search, partOfNum, numberType } = this.state;

    // stop here if form is invalid
    if (!(country && selectBy && partOfNum && numberType)) {
      return;
    }

    this.setState({ loading: true });
    if (search.length === 0) {
      selectBy = "latlong";
      search =
        this.props.isGeolocationAvailable &&
        this.props.isGeolocationEnabled &&
        this.props.coords
          ? this.props.coords.latitude + "," + this.props.coords.longitude
          : "";
      //search = "37.840699,-122.461853"
    }

    twilioService
      .numbers(country, selectBy, search, partOfNum, numberType)
      .then(
        (data) => {
          //console.log(data);
          if (data) {
            this.setState({ numbers: data });
            this.setState({ loading: false });
          }
        },
        (error) => {
          console.log(error);
          this.setState({ loading: false });
        }
      );
  }

  chooseNumber(e, phone_number, country) {
    this.setState({
      phone_number: phone_number,
      country: country,
      display_phone_number: formatPhoneNumber(phone_number, country),
    });
  }

  handleSubmit(e, phone_number) {
    e.preventDefault();

    this.setState({ submitted: true });
    phone_number =
      phone_number && phone_number.length > 0
        ? phone_number
        : this.state.phone_number;

    // stop here if form is invalid
    if (!phone_number) {
      this.setState({ error: { phone: "phone number is required" } });
      return;
    }
    var country = this.state.country;

    this.setState({ loading: true });
    const user = JSON.parse(localStorage.getItem("user"));
    userService.registerStep3(phone_number, country, user.success.user.id).then(
      (user) => {
        /*const { from } = this.props.location.state || { from: { pathname: "/dashboard" } };
					this.props.history.push(from);*/
        window.location.href = "/dashboard?d=" + Date.now();
        /*this.setState({ error: {}, loading: false })
                    location.href = "/login"*/
      },
      (error) => {
        if (typeof error == "string") {
          this.setState({ error: { phone: error }, loading: false });
        } else {
          var _this = this;
          error.then(function (e) {
            var errors = JSON.parse(e).error;
            var errorJson = {};
            errorJson["errorText"] = "";
            for (const key in errors) {
              //console.log(key);
              errorJson[key] =
                typeof errors[key] == "string" ? errors[key] : errors[key][0];
              errorJson["errorText"] +=
                typeof errors[key] == "string"
                  ? errors[key] + "<br />"
                  : key + ": " + errors[key][0] + "<br />";
            }
            _this.setState({ error: errorJson, loading: false });
          });
        }
      }
    );
  }

  searchByLatLong(country, partOfNum, numberType) {
    const selectBy1 = "latlong";
    var search1 =
      this.props.isGeolocationAvailable &&
      this.props.isGeolocationEnabled &&
      this.props.coords
        ? this.props.coords.latitude + "," + this.props.coords.longitude
        : "";
    //var search1 = "37.840699,-122.461853";
    console.log(search1);
    if (search1.length > 0) {
      twilioService
        .numbers(country, selectBy1, search1, partOfNum, numberType)
        .then(
          (data) => {
            //console.log(data);
            if (data.length > 0) {
              this.setState({ phone_number: data[0]["value"] });
              this.setState({ display_phone_number: data[0]["number"] });
            } else {
              //this.setState({ phone_number: "" });
              //this.setState({ display_phone_number: "no near number around your area." });
            }
          },
          (error) => {
            console.log(error);
            this.setState({ loading: false });
          }
        );
    }
  }

  render() {
    const {
      country,
      selectBy,
      partOfNum,
      search,
      phone_number,
      display_phone_number,
      numberType,
      numbers,
      countries,
      error,
    } = this.state;

    if (search.length === 0 && phone_number.length === 0) {
      this.searchByLatLong(country, partOfNum, numberType);
    }

    return (
      <div className="page-wrapper with-scroll schedules create-acc">
        <div className={this.state.loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={this.state.loading}
          />
        </div>
        <HeaderForAll
          backView="/signup-details"
          title="Choose number"
          onClick={this.handleSubmit}
          // eslint-disable-next-line no-script-url
          nextView="javascript:void;"
          nextLabel="Let's start"
          loading={this.state.loading}
        />
        <div className="auth-wrapper with-scroll" style={{ paddingRight: 50 }}>
          <div className="choose-number-wrapper steps">
            <div className="logo-wrapper mb-1">
              <h3 style={{ marginLeft: 0 }}>Choose your number</h3>
            </div>
            <form name="form" className="mt-1" onSubmit={this.handleSubmit}>
              {error.hasOwnProperty("errorText") && error.errorText !== "" && (
                <div
                  className={"alert alert-danger flash-message"}
                  dangerouslySetInnerHTML={{ __html: error.errorText }}
                />
              )}
              <div className="selected-number">{display_phone_number}</div>

              <Grid container spacing={2} className="for-small-device">
                <Typography className="change-number-notice" component={"h4"}>
                  Would you like to change your number?
                </Typography>
                <Grid item xs={6} style={{ display: "inline-block" }}>
                  <TextField
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    id="filled-uncontrolled"
                    select
                    label="Country"
                    margin="normal"
                    variant="filled"
                    name="country"
                    value={country}
                    onChange={this.handleChange}
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    {countries.map((country, idx) => (
                      <MenuItem key={idx} value={country.iso}>
                        {" "}
                        {country.name}{" "}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6} style={{ display: "inline-block" }}>
                  <TextField
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    id="filled-uncontrolled"
                    select
                    label="By"
                    margin="normal"
                    variant="filled"
                    name="selectBy"
                    value={selectBy}
                    onChange={this.handleChange}
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    <MenuItem value="location"> Location </MenuItem>
                    <MenuItem value="number"> Number </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    id="filled-uncontrolled"
                    placeholder="Search by digits, phrases or leave blank"
                    margin="normal"
                    variant="filled"
                    name="search"
                    value={search}
                    onChange={this.handleChange}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.refreshNumbers();
                      }
                    }}
                    error={typeof error.phone === "string"}
                    helperText={error.phone ? error.phone : ""}
                    FormHelperTextProps={{
                      classes: {
                        root: "helper-text",
                      },
                    }}
                  />
                </Grid>
                {selectBy === "number" && (
                  <Grid item xs={12}>
                    <TextField
                      classes={{
                        root: "text-field",
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "label",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: "input-wrapper",
                          input: "input",
                        },
                      }}
                      id="filled-uncontrolled"
                      select
                      label="Match to"
                      margin="normal"
                      variant="filled"
                      name="partOfNum"
                      value={partOfNum}
                      onChange={this.handleChange}
                      SelectProps={{
                        MenuProps: {
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                        },
                      }}
                    >
                      <MenuItem value="first"> First Part of Number </MenuItem>
                      <MenuItem value="anywhere"> Anywhere in Number </MenuItem>
                      <MenuItem value="last"> Last Part of Number </MenuItem>
                    </TextField>
                  </Grid>
                )}
                <RadioGroup
                  className="radio-group"
                  aria-label="radios"
                  name="numberType"
                  value={numberType}
                  onChange={this.handleChange}
                >
                  <Grid item xs={12} className="radio-buttons">
                    <FormControlLabel
                      value="local"
                      control={<Radio color="primary" />}
                      label="Local"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value="tollFree"
                      control={<Radio color="primary" />}
                      label="Toll free"
                      labelPlacement="end"
                    />
                  </Grid>
                </RadioGroup>
                <Grid item xs={12}>
                  <SuggestedNumbers
                    numbers={numbers}
                    handleChange={this.handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  {numbers.length > 0 ? (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={this.handleClick}
                      classes={{
                        root: "btn",
                        label: "label",
                        textPrimary: "text-primary",
                      }}
                    >
                      <Icon className="reload">cached</Icon>
                      Reload
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={this.handleClick}
                      classes={{
                        root: "btn",
                        label: "label",
                        textPrimary: "text-primary",
                      }}
                    >
                      <Icon className="search">search</Icon>
                      Search
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Box className="for-large-device" align="right">
                <ChooseNumberModal handleSubmit={this.chooseNumber} />
                <br />
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  onClick={this.handleSubmit}
                  disabled={this.state.phone_number ? false : true}
                  type="submit"
                  classes={{
                    root: "btn",
                    label: "label",
                    containedPrimary: "contained-primary nextbtn",
                  }}
                  loading={this.state.loading}
                >
                  Confirm
                </Button>
              </Box>
              <div className="pastorsline-number-select">
                <Typography className="title" component="h4">
                  Or use your pastorsline number
                </Typography>
                {window.innerWidth > 959 && <Pastorsline />}
                {window.innerWidth <= 959 && (
                  <Button
                    color="primary"
                    variant="contained"
                    component={Link}
                    to="/pastorsline"
                    classes={{
                      root: "pastorsline-btn",
                      label: "label",
                      containedPrimary: "contained-primary",
                    }}
                  >
                    Connect
                    <img src="./images/pastorsline.svg" alt="" />
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="auth-banner">
          <img src="images/auth-banner.png" alt="" />
        </div>
      </div>
    );
  }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(CreateAccountThree);
