import React from "react";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { AppBar, Tabs, Tab, Icon } from "@material-ui/core";
import TabContainer from "./../TabContainer";
import ServiceList from "./ServiceList";
import { authHeader } from "../helpers/AuthHeader";
import { userService } from "./UserService";
import "../../styles/components/services/service-tabs.scss";

// var config = require("config");

const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
class ServiceTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceList: this.props.serviceList,
      tabValue: 0,
      folder: 1,
      folderList: [{ id: 1, status: "close", title: "Upcoming Services" }],
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e, v) {
    this.setState({ tabValue: v });
    var folder = this.state.folder;
    // eslint-disable-next-line array-callback-return
    this.props.folderList.map((f, i) => {
      if (i === v) {
        this.setState({ folder: f.id });
        folder = f.id;
      }
    });
    this.refreshServices(false, folder);
  }

  refreshServices(reload, folderId) {
    folderId = folderId ? folderId : this.state.folderId;
    var _this = this;
    _this.setState({ loading: true });
    fetch(
      API_URL +
        "scheduling/services" +
        (folderId && folderId !== 1 ? "/" + folderId : ""),
      {
        headers: authHeader(),
      }
    )
      .then((res) => res.json())
      .then(
        (data) => {
          if (data.error) {
            userService.refreshToken();
            _this.refreshServices(true);
            return;
          }
          _this.setState({ serviceList: data, loading: false });
        },
        (error) => {
          _this.setState({ loading: false });
        }
      )
      .catch(console.log);
  }

  render() {
    const { tabValue, loading } = this.state;
    var serviceList =
      this.state.serviceList.length > 0
        ? this.state.serviceList
        : this.props.serviceList;
    return (
      <div>
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
        <AppBar className="service-tabs">
          <Tabs
            value={tabValue}
            onChange={this.handleChange}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            classes={{
              root: "tabs-wrapper",
              indicator: "indicator",
            }}
          >
            {this.props.folderList.map((data, idx) => (
              <Tab
                icon={
                  <React.Fragment>
                    <Icon className="icon folder">folder_open</Icon>
                    <Icon className="icon on-select">folder</Icon>
                  </React.Fragment>
                }
                label={data.title}
                classes={{
                  root: "tab-single",
                  wrapper: "wrapper",
                  selected: "selected",
                }}
                key={idx}
              />
            ))}
          </Tabs>
        </AppBar>
        <div className="tab-container" style={{ height: "88vh" }}>
          <TabContainer>
            <ServiceList services={serviceList} />
          </TabContainer>
        </div>
        {/*<div>
          <ServiceList services={this.props.serviceList} />
          </div>*/}
      </div>
    );
  }
}

export default ServiceTabs;
