import React from "react";
import {
  CircularProgress,
  IconButton,
  Tooltip,
  Snackbar,
} from "@material-ui/core";
import { ReactComponent as MoreIcon } from "../icons/notification-icon.svg";

class ToggleButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      on: this.props.on,
      showSnackBar: false,
      snackbarMessage: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.on !== this.props.on) {
      this.setState({
        on: this.props.on,
      });
    }

    if (prevProps.allLoading !== this.props.allLoading) {
      this.setState({
        showSnackBar: !this.props.allLoading,
        snackbarMessage: `You ${
          prevProps.on ? "Disabled" : "Enabled"
        } Text Notifications for All`,
      });
    }
  }

  handleToggle = (e) => {
    e.preventDefault();
    const { onToggle, person } = this.props;

    if (onToggle && person.id) {
      onToggle(person.id).then(() => {
        this.setState((prevState) => ({
          on: !prevState.on,
          showSnackBar: true,
          snackbarMessage: `You ${
            prevState.on ? "Disabled" : "Enabled"
          } Text Notifications from ${person.name}`,
        }));
      });
    } else {
      this.setState((prevState) => ({
        on: !prevState.on,
      }));
    }
  };

  handleCloseSnackbar = () => {
    this.setState({ showSnackBar: false });
  };

  render() {
    const { loading, allLoading } = this.props;
    const { on, showSnackBar, snackbarMessage } = this.state;

    return (
      <>
        {loading || allLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Tooltip
              title={
                on ? "Disable Text Notifications" : "Enable Text Notifications"
              }
              aria-label="add"
            >
              <IconButton
                onClick={this.handleToggle}
                className={on ? "toggle-btn on" : "toggle-btn off"}
              >
                <MoreIcon style={{ fill: on ? "#7bc807" : "#000" }} />
              </IconButton>
            </Tooltip>

            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={showSnackBar}
              autoHideDuration={4000}
              message={snackbarMessage}
              onClose={this.handleCloseSnackbar}
            />
          </>
        )}
      </>
    );
  }
}

export default ToggleButton;
