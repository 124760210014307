import React, { useCallback } from "react";
import {
  Icon,
  Dialog,
  DialogContent,
  Typography,
  Button,
} from "@material-ui/core";
import CountriesSelect from "./CountriesSelect";

function DefaultCountryModal(props) {
  const {
    open,
    closeModal,
    labels,
    onChange,
    value,
    onSubmit,
    className,
  } = props;

  const submitData = useCallback(() => {
    closeModal();
    onSubmit();
  }, [closeModal, onSubmit]);

  return (
    <Dialog
      fullScreen={window.innerWidth < 600}
      open={open}
      onClose={closeModal}
      classes={{
        root: "add-new-number-modal pastorsline",
        paper: "paper number-modal",
      }}
    >
      <Typography className="title">
        Select default country code
        <Icon className="close-icon" onClick={closeModal}>
          close
        </Icon>
      </Typography>
      <DialogContent className="content modal-numbers-wrapper flexer-col">
        <CountriesSelect
          labels={labels}
          value={value}
          onChange={onChange}
          className={`w-75 mb-4 mt-3 ${className}`}
        />
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={submitData}
          classes={{
            root: "btn mb-2 w-100",
            label: "label",
            containedPrimary: "contained-primary",
          }}
        >
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default DefaultCountryModal;
