import React from "react";
import { Typography } from "@material-ui/core";
import PagesSubMenu from "./PagesSubMenu";
import HeaderMore from "./HeaderMore";
import MessageTabs from "./MessageTabs";
import { getMessageTemplates } from "../../services/api";
import "../../styles/components/more/message-templates.scss";

class MessageTemplates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messageTemplates: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.getMessageTemplates();
  }

  getMessageTemplates = async () => {
    this.setState({ loading: true });
    const data = await getMessageTemplates();
    const templates = [];
    for (let i = 0; i < data.length; i++) {
      const template = data[i];
      templates.push({
        id: template.id,
        type: template.category,
        message_type: template.message_type,
        title: template.name,
        detail: template.body,
        multiple_body: template.multiple_body_html,
        additional_part: template.additional_part,
        multiple_body_part: template.multiple_body_part,
        keywords: template.keywords,
      });
    }
    this.setState({ messageTemplates: templates, loading: false });
  };

  render() {
    const { messageTemplates } = this.state;
    return (
      <div className="page-wrapper message-templates  with-scroll my-profile">
        <PagesSubMenu />
        <HeaderMore
          backView="/dashboard"
          title="Message templates"
          //hasSearch
        />
        <div
          className={
            window.innerWidth > 959 ? "desktop-design" : "inner-content-wrapper"
          }
        >
          {window.innerWidth > 959 && (
            <Typography className="desktop-title" component={"h2"}>
              Message templates
            </Typography>
          )}
          <MessageTabs
            templates={messageTemplates}
            reload={getMessageTemplates}
          />
        </div>
      </div>
    );
  }
}
export default MessageTemplates;
