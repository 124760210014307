import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Menu,
  MenuItem,
  Icon,
  IconButton,
  Paper,
  Select,
  Switch,
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
} from "@material-ui/core";
import lodash from "lodash";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import HeaderMore from "./HeaderMore";
import PaymentMethods from "./PaymentMethods";
import EnableAutoRecharge from "./EnableAutoRecharge";
import LogsListPage from "./LogsListPage";
import EditCard from "./EditCard";
import AddNewCard from "./AddNewCard";
import TransactionHistory from "./TransactionHistory";
import SubscriptionPlans from "./SubscriptionPlans";
import { ReactComponent as DownloadIcon } from "../../icons/download-icon.svg";
import PagesSubMenu from "./PagesSubMenu";
import DeletePaymentMethod from "./DeletePaymentMethod";
import SetPrimaryMethod from "./SetPrimaryMethod";
import CustomSwitch from "./../../atoms/CustomSwitch";
import Moment from "react-moment";
import "moment-timezone";
import moment from "moment";

import { authHeader } from "./../helpers/AuthHeader";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import {
  getAllLogs,
  getDefaultCardApi,
  getCurrentPackage,
  loadCards,
  loadPayments,
  loadCreditsApi,
  cancelSubscription,
  addCreditsApi,
} from "../../services/api";
import "../../styles/components/more/billing.scss";
import { toggleConfirmationModal } from "../../store/actions/globalAction";
import ConfirmationModal from "../ConfirmationModal";
import { isCordovaApp, renderHTML } from "./../../helpers/helpers";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
Moment.globalFormat = "DD.MM.YYYY";
Moment.globalLocal = true;
class Billing extends React.Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem("user"));

    this.state = {
      autorecharge: false,
      anchorEl: null,
      recharge: { limit: 2, cardId: 0, creditId: 0 },
      switchOn: true,
      dateFocus: false,
      amountFocus: false,
      checked: false,
      loading: false,
      isMonthly: null,
      currentPackage: null,
      cancelledPackage: null,
      startingPackage: null,
      defaultCard: null,
      cards: [],
      transactions: [],
      slicedCards: [],
      slicedTransactions: [],
      credits: [],
      autoCredits: [],
      creditsUsed: 0,
      creditsGiven: 0,
      cycleStart: "",
      cycleEnd: "",
      confirmedRequests: 0,
      failedCount: 0,
      followupReminders: 0,
      other: 0,
      logs: [],
      dropdownMenu: null,
      creditId: 0,
      billSyncing: false,
      creditsEnabled: user.success.user.username === "demo",
      email: null,
      trialEnd: null,
      nextBilling: null,
      flashMessage: "",
      errorMessage: "",
      confirmationModalTitle: "",
      confirmationModalDesc: "",
      confirmationAction: null,
      countryCredits: "",
      cancelled: true,
      totalLogs: null,
      loadMoreLoading: null,
    };

    this.handleShow = this.handleShow.bind(this);
    this.handleSwitchOff = this.handleSwitchOff.bind(this);
    this.handleSwitchOn = this.handleSwitchOn.bind(this);
    this.handleToggleDateFocus = this.handleToggleDateFocus.bind(this);
    this.handleToggleAmountFocus = this.handleToggleAmountFocus.bind(this);
    this.handleClickPaymentMenu = this.handleClickPaymentMenu.bind(this);
    this.handleClosePaymentMenu = this.handleClosePaymentMenu.bind(this);
    this.changeCards = this.changeCards.bind(this);
  }

  componentDidMount() {
    this.getDefaultCard();
    this.getPackage();
    this.loadCards();
    this.loadPayments();
    this.loadCredits();
    this.loadLogs();
  }

  refreshAllSections = (loading, flashMessage) => {
    this.getDefaultCard();
    this.getPackage();
    this.loadCards();
    this.loadPayments();
    this.loadCredits();
    this.handleLoading(loading, flashMessage);
  };

  getDefaultCard = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      const data = await getDefaultCardApi();
      if (data.card) {
        this.setState({
          defaultCard: data.card,
          email: data.email,
          creditsEnabled: user.success.status !== "Not Active",
        });
      }
    } catch (error) {
      this.setState({
        errorMessage: error?.message,
        creditsEnabled: user.success.status !== "Not Active",
      });
    }
  };

  getPackage = async () => {
    try {
      const data = await getCurrentPackage();
      this.setState({
        isMonthly: data.isMonthly,
        currentPackage: data.current,
        cancelledPackage: data.cancelledPackage,
        startingPackage: data.starting,
        billSyncing: Boolean(data.billSyncing),
        trialEnd: data.trialEnd,
        nextBilling: data.nextBillingDate,
        countryCredits: data.countryCredits,
        cancelled: data.cancelled,
      });
      if (data.rechargeCredit) {
        this.setState({
          autorecharge: true,
          recharge: {
            limit: data.rechargeLimit,
            creditId: data.rechargeCredit.id,
            creditAmount: data.rechargeCredit.amount,
            creditQuantity: data.rechargeCredit.quantity,
            cardId: data.rechargeCardId,
          },
        });
      }
      let user = JSON.parse(localStorage.getItem("user"));
      user.success.status = data.church.status;
      user.success.user.churches[0].plan_id = data.church.plan_id;
      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      this.setState({ errorMessage: error?.message });
    }
  };

  loadCards = async () => {
    try {
      const data = await loadCards();
      if (data.cards) {
        this.setState({
          cards: data.cards.data.length ? data.cards.data : [],
          slicedCards: data.cards.data.length
            ? data.cards.data.slice(0, 3)
            : [],
        });
      }
    } catch (error) {
      this.setState({ errorMessage: error?.message });
    }
  };

  loadPayments = async () => {
    try {
      const data = await loadPayments();
      if (data.payments) {
        this.setState({
          transactions: data.payments.length ? data.payments : [],
          slicedTransactions: data.payments.length
            ? data.payments.slice(0, 6)
            : [],
        });
      }
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  loadLogs = async () => {
    const params = `limit=20&page=1`;
    const logsData = await getAllLogs(params);
    if (!lodash.isEmpty(logsData)) {
      this.setState({
        confirmedRequests: logsData.confirmedRequests,
        failedCount: logsData.failed,
        followupReminders: logsData.followupReminders,
        logs: logsData.logs,
        other: logsData.other,
        totalLogs: logsData.logTotal,
      });
    }
  };

  loadMoreLogs = async (params) => {
    const { logs } = this.state;
    this.setState({ loadMoreLoading: true });
    const logsData = await getAllLogs(params);
    if (!lodash.isEmpty(logsData)) {
      this.setState({
        confirmedRequests: logsData.confirmedRequests,
        failedCount: logsData.failed,
        followupReminders: logsData.followupReminders,
        logs: [...logs, ...logsData.logs],
        other: logsData.other,
        totalLogs: logsData.logTotal,
        loadMoreLoading: false,
      });
    }
  };

  setCancelSubscriptionModalData = () => {
    const { toggleConfirmationModal } = this.props;
    const title = `Cancel Subscription`;
    const desc = `Are you sure you want to cancel current subscription?`;
    this.setState(
      {
        confirmationModalTitle: title,
        confirmationModalDesc: desc,
        confirmationAction: this.cancelSubscription,
      },
      () => {
        toggleConfirmationModal();
      }
    );
  };

  setAddCreditConfirmationModal = () => {
    const { creditId } = this.state;
    const { toggleConfirmationModal } = this.props;
    if (creditId > 0) {
      const title = `Add Credits`;
      const desc = `Are you sure you want to add credits you selected?`;
      this.setState(
        {
          confirmationModalTitle: title,
          confirmationModalDesc: desc,
          confirmationAction: this.addCredits,
        },
        () => {
          toggleConfirmationModal();
        }
      );
    }
  };

  changeCards() {
    return this.loadCards().then((data) => {
      this.getDefaultCard();
    });
  }

  loadCredits = async () => {
    const { currentUserInfo } = this.props;
    try {
      const data = await loadCreditsApi();
      if (data.credits) {
        this.setState({
          credits: data.credits,
          autoCredits: data.auto_credits,
          creditsUsed: data.credits_used,
          creditsGiven: data.credits_given,
          cycleStart: data.start_date
            ? moment(data.start_date)
                .tz(currentUserInfo?.timezone)
                .format("MM.DD.YYYY")
            : "",
          cycleEnd: data.end_date
            ? moment(data.end_date)
                .tz(currentUserInfo?.timezone)
                .format("MM.DD.YYYY")
            : "",
        });
      }
    } catch (error) {
      this.setState({ errorMessage: error?.message });
    }
  };

  cancelSubscription = async (comment) => {
    this.setState({ loading: true });
    try {
      const data = await cancelSubscription(comment);
      let user = JSON.parse(localStorage.getItem("user"));
      user.success.status = data.church.status;
      user.success.user.churches[0].intercom_tag = data.church.intercom_tag;
      user.success.user.churches[0].is_cancelling = data.church.is_cancelling;
      user.success.user.churches[0].cancel_date = data.church.cancel_date;
      user.success.user.churches[0].cancel_end_date =
        data.church.cancel_end_date;
      localStorage.setItem("user", JSON.stringify(user));
      window.location.href = "/billing";
      this.setState({
        loading: false,
        currentPackage: null,
        cancelledPackage: this.state.currentPackage,
        confirmationModalDesc: "",
        confirmationModalTitle: "",
        confirmationAction: null,
      });
    } catch (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  addCredits = async () => {
    this.setState({ loading: true, flashMessage: "", creditId: 0 });
    try {
      await addCreditsApi(JSON.stringify({ creditId: this.state.creditId }));
      await this.loadPayments();
      await this.loadCredits();
      this.setState({
        loading: false,
        creditId: 0,
        confirmationAction: null,
        confirmationModalDesc: "",
        confirmationModalTitle: "",
        flashMessage: "You have successfully added credits to your account.",
      });
    } catch (error) {
      this.setState({ errorMessage: error?.message });
    }
    // const requestOptions = {
    //   method: "POST",
    //   headers: authHeader(),
    //   body: JSON.stringify({ creditId: this.state.creditId }),
    // };

    // this.setState({ loading: true, flashMessage: "", creditId: 0 });
    // return fetch(`${API_URL}charge`, requestOptions)
    //   .then((res) => {
    //     if (!res.ok) {
    //       //console.log(res);
    //       if (res.status !== 401) return;
    //     }
    //     return res.json();
    //   })
    //   .then((data) => {
    //     var _this = this;
    //     this.loadPayments().then((data) => {
    //       _this.loadCredits().then((data) => {
    //         _this.setState({
    //           loading: false,
    //           flashMessage:
    //             "You have successfully added credits to your account.",creditId
    //         });
    //       });
    //     });
    //   })
    //   .catch(console.log);
  };

  handleCreditChange = (event) => {
    this.setState({ creditId: event.target.value });
  };

  handleShow = (values) => {
    //set autorecharge values here
    this.setState({
      autorecharge: true,
      recharge: {
        limit: values.limit,
        creditId: values.creditId,
        cardId: values.cardId,
      },
    });
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({
        recharge_credit_id: values.creditId,
        recharge_limit: values.limit,
        recharge_card_id: values.cardId,
      }),
    };

    this.setState({ loading: true });
    return fetch(`${API_URL}autorecharge`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.credit) {
          this.setState({
            loading: false,
            autorecharge: true,
            recharge: {
              limit: values.limit,
              creditId: values.creditId,
              cardId: values.cardId,
              creditAmount: data.credit.amount,
              creditQuantity: data.credit.quantity,
            },
          });
        } else {
          this.setState({ loading: false, autorecharge: false });
        }
      })
      .catch(console.log);
  };
  handleSwitchOff = () => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
    };

    this.setState({ loading: true, switchOn: false });
    return fetch(`${API_URL}disable-autorecharge`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState({
          loading: false,
          autorecharge: false,
          switchOn: true,
          recharge: {
            limit: 2,
            creditId: 0,
            cardId: 0,
            creditAmount: 0,
            creditQuantity: 0,
          },
        });
      })
      .catch(console.log);
  };
  handleSwitchOn = () => {
    this.setState({ switchOn: true });
  };
  handleToggleDateFocus = () => {
    this.setState({ dateFocus: !this.state.dateFocus });
  };
  handleToggleAmountFocus = () => {
    this.setState({ amountFocus: !this.state.amountFocus });
  };
  handleClickPaymentMenu = (card) => {
    const dropdownMenu = (
      <Menu
        id="payment-menu"
        // eslint-disable-next-line no-restricted-globals
        anchorEl={event.target}
        keepMounted
        // eslint-disable-next-line no-restricted-globals
        open={Boolean(event.target)}
        onClose={this.handleClosePaymentMenu}
        classes={{
          paper: "dropdown-more",
        }}
      >
        <span>
          <SetPrimaryMethod
            card={card}
            handleClose={this.handleClosePaymentMenu}
            handleLoading={this.handleLoading}
            changeCards={this.changeCards}
            defaultCard={this.state.defaultCard}
          />
        </span>
        <EditCard
          card={card}
          email={this.state.email}
          inline={true}
          handleClose={this.handleClosePaymentMenu}
          defaultCard={this.state.defaultCard}
          handleLoading={this.handleLoading}
          handler={this.changeCards}
          toggler={<MenuItem>Edit</MenuItem>}
        />
        <DeletePaymentMethod
          card={card}
          inline={true}
          handleClose={this.handleClosePaymentMenu}
          handleLoading={this.handleLoading}
          changeCards={this.changeCards}
          defaultCard={this.state.defaultCard}
          toggler={<MenuItem>Delete</MenuItem>}
        />
      </Menu>
    );

    this.setState({ dropdownMenu: dropdownMenu });
  };
  handleClosePaymentMenu = () => {
    this.setState({ dropdownMenu: null });
  };
  handleLoading = (loading, flashMessage) => {
    this.setState({ loading: loading, flashMessage: flashMessage });
  };

  render() {
    const {
      checked,
      isMonthly,
      currentPackage,
      cancelledPackage,
      startingPackage,
      loading,
      dropdownMenu,
      dateFocus,
      amountFocus,
      autorecharge,
      recharge,
      switchOn,
      cards,
      transactions,
      slicedTransactions,
      slicedCards,
      defaultCard,
      credits,
      autoCredits,
      creditsGiven,
      creditsUsed,
      confirmedRequests,
      failedCount,
      followupReminders,
      other,
      logs,
      creditId,
      billSyncing,
      creditsEnabled,
      email,
      trialEnd,
      nextBilling,
      flashMessage,
      confirmationModalDesc,
      confirmationModalTitle,
      confirmationAction,
      countryCredits,
      cancelled,
      totalLogs,
      loadMoreLoading,
      cycleStart,
      cycleEnd,
    } = this.state;
    const { currentUserInfo, showConfirmationModal } = this.props;
    const isCordova = isCordovaApp();
    return (
      <div className="page-wrapper billing-page">
        <PagesSubMenu />
        <HeaderMore
          backView="/dashboard"
          title="Billing"
          subTitle={`(${currentUserInfo?.churches[0]?.name} church)`}
        />

        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>

        <div className="desktop-design">
          <Typography className="desktop-title" component={"h2"}>
            Billing{" "}
            <span style={{ fontSize: 16 }}>
              ({currentUserInfo?.churches[0]?.name} church)
            </span>
          </Typography>
          {flashMessage !== "" && (
            <div className="flash-message mb">
              <Typography>{flashMessage}</Typography>
              <Icon
                className="close-icon"
                onClick={() => this.setState({ flashMessage: "" })}
              >
                close
              </Icon>
            </div>
          )}
          <Grid container spacing={2} className="cards">
            <Grid item md={3}>
              {startingPackage && (
                <div className="starting-package">
                  {cancelledPackage ? (
                    <React.Fragment>
                      <img
                        src={
                          cancelledPackage.image
                            ? cancelledPackage.image
                            : "./images/starting-package.png"
                        }
                        alt=""
                      />
                      <Typography className="large-text" component={"h3"}>
                        {cancelledPackage.name}
                      </Typography>
                      <Typography className="small-text" component={"p"}>
                        {cancelledPackage.credit} credits a month
                      </Typography>
                      <Typography className="large-text" component={"h3"}>
                        $
                        {isMonthly
                          ? cancelledPackage.amount_per_month
                          : cancelledPackage.amount_per_year}
                        {isMonthly ? " / mo" : " / yr"}
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img
                        src={
                          currentPackage && currentPackage.image
                            ? currentPackage.image
                            : "./images/starting-package.png"
                        }
                        alt=""
                      />
                      <Typography className="large-text" component={"h3"}>
                        {currentPackage ? currentPackage.name : "Trial"}
                      </Typography>
                      <Typography className="small-text" component={"p"}>
                        {currentPackage
                          ? currentPackage.credit
                          : startingPackage}
                        {currentPackage
                          ? " credits a month"
                          : " credits per trial"}
                      </Typography>
                      <Tooltip title={renderHTML(countryCredits)}>
                        <Typography
                          className="smaller-text underline-text"
                          component={"p"}
                        >
                          How much is one credit?
                        </Typography>
                      </Tooltip>
                      <Typography className="large-text" component={"h3"}>
                        $
                        {currentPackage
                          ? isMonthly
                            ? currentPackage.amount_per_month
                            : currentPackage.amount_per_year
                          : 0}
                        {currentPackage ? (isMonthly ? " / mo" : " / yr") : ""}
                      </Typography>
                    </React.Fragment>
                  )}
                  <div className="actions">
                    {/*!cancelled && (
                      <Button
                        color="primary"
                        variant="text"
                        onClick={this.setCancelSubscriptionModalData}
                        classes={{
                          root: "btn",
                          label: "label",
                          textPrimary: "text-primary",
                        }}
                      >
                        <span className="forgot-pw" style={{ color: "white" }}>
                          Cancel
                        </span>
                      </Button>
                    )*/}
                    <SubscriptionPlans
                      currentPackage={currentPackage}
                      email={email}
                      syncing={billSyncing}
                      defaultCard={defaultCard}
                      handler={this.changeCards}
                      isMonthly={isMonthly}
                      refreshAllSections={this.refreshAllSections}
                      cancelSubscription={this.cancelSubscription}
                      cancelled={cancelled}
                    />
                  </div>
                  {trialEnd && (
                    <div className="small-text">
                      <br />
                      Trial will end on{" "}
                      <Moment format="MM.DD.YYYY">{trialEnd}</Moment>
                    </div>
                  )}
                  {nextBilling && (
                    <div className="small-text">
                      <br />
                      Next Billing date on{" "}
                      <Moment format="MM.DD.YYYY">{nextBilling}</Moment>
                    </div>
                  )}
                </div>
              )}
              {creditsEnabled && (
                <React.Fragment>
                  <div className="add-credits">
                    <Typography className="large-text" component={"h3"}>
                      I Just Need Few Credits
                    </Typography>
                    <Select
                      className="credits-select"
                      labelId="label"
                      id="select"
                      value={creditId}
                      onChange={this.handleCreditChange}
                      classes={{
                        root: "select",
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="0">Select Credit</MenuItem>
                      {credits.map((credit, idx) => (
                        <MenuItem key={idx} value={credit.id}>
                          {credit.quantity} credits - ${credit.amount}
                        </MenuItem>
                      ))}
                    </Select>
                    <div className="btn-wrapper">
                      <Tooltip
                        title={
                          currentPackage
                            ? ""
                            : "Please subscribe to one of our plans to add credits."
                        }
                      >
                        <Button
                          color={currentPackage ? "primary" : "surface-light"}
                          variant="contained"
                          type="submit"
                          onClick={
                            currentPackage
                              ? this.setAddCreditConfirmationModal
                              : null
                          }
                          classes={{
                            root: "btn",
                            label: "label",
                            containedPrimary: "contained-primary",
                          }}
                        >
                          Add credits
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="auto-recharge">
                    {autorecharge ? (
                      <React.Fragment>
                        {switchOn === true ? (
                          <React.Fragment>
                            <Typography className="large-text" component={"h3"}>
                              <CustomSwitch
                                checked={true}
                                onClick={this.handleSwitchOff}
                              />
                              Autorecharge
                            </Typography>
                            <Typography className="details">
                              When credits are less than <b>{recharge.limit}</b>{" "}
                              <br /> then recharge to{" "}
                              <b>
                                {recharge.creditQuantity} ($
                                {recharge.creditAmount})
                              </b>{" "}
                            </Typography>
                            <div className="btn-wrapper">
                              <EnableAutoRecharge
                                autoCredits={autoCredits}
                                cards={cards}
                                recharge={recharge}
                                toggler={
                                  <Button
                                    color="primary"
                                    variant="text"
                                    classes={{
                                      root: "btn",
                                      label: "label",
                                      textPrimary: "text-primary",
                                    }}
                                  >
                                    <span
                                      className="forgot-pw"
                                      style={{ color: "#7bc807" }}
                                    >
                                      Edit
                                    </span>
                                  </Button>
                                }
                                onEnable={this.handleShow}
                              />
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="autorecharge-off">
                            <Typography className="large-text" component={"h3"}>
                              <Switch
                                checked={checked}
                                onClick={this.handleSwitchOn}
                                value="checked"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple
                                classes={{
                                  root: "custom-switch",
                                  thumb: "thumb",
                                  track: "track",
                                  switchBase: "switch-base",
                                  checked: "checked",
                                }}
                              />
                              Autorecharge
                            </Typography>
                            <Typography className="details">
                              {" "}
                              Enable autorecharge{" "}
                            </Typography>
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Typography className="large-text" component={"h3"}>
                          Autorecharge
                        </Typography>
                        <EnableAutoRecharge
                          autoCredits={autoCredits}
                          cards={cards}
                          recharge={recharge}
                          toggler={
                            <Button
                              color="primary"
                              variant="contained"
                              type="submit"
                              fullWidth
                              classes={{
                                root: "btn",
                                label: "label",
                                containedPrimary: "contained-primary",
                              }}
                            >
                              Enable Autorecharge
                            </Button>
                          }
                          onEnable={this.handleShow}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs={9}>
              <Card className="billing-card">
                <CardContent className="card-content">
                  <div className="section-title">
                    <Typography className="label">
                      Logs [
                      <span>
                        {creditsUsed}{" "}
                        {creditsGiven > 0 && (
                          <span className="text">out of {creditsGiven}</span>
                        )}
                      </span>{" "}
                      used this billing cycle{" "}
                      {cycleStart != "" ? (
                        <span>
                          ({cycleStart} to {cycleEnd})
                        </span>
                      ) : (
                        ""
                      )}
                      ]
                    </Typography>
                    <LogsListPage
                      logs={logs}
                      loadMoreLogs={this.loadMoreLogs}
                      totalLogs={totalLogs}
                      currentLogsCount={logs?.length}
                      loadMoreLoading={loadMoreLoading}
                      timezone={currentUserInfo?.timezone}
                    />
                  </div>
                  <Grid container spacing={2} className="cards">
                    {/*<Grid item className="single">
                      <Progress
                        type="circle"
                        width={100}
                        percent={
                          creditsGiven > 0
                            ? (creditsUsed * 100) / creditsGiven + ""
                            : "0"
                        }
                        strokeWidth={10}
                        status="declined"
                        theme={{
                          declined: {
                            color: "#343434",
                            trailColor: "#B5B5B5",
                            symbol: (
                              <Tooltip
                                title={
                                  <span>
                                    {creditsUsed}{" "}
                                    {creditsGiven > 0 && (
                                      <span className="text">
                                        out of {creditsGiven}
                                      </span>
                                    )}
                                  </span>
                                }
                                placement="top"
                              >
                                <span>
                                  {creditsUsed >= 1000
                                    ? (creditsUsed / 1000).toFixed(0) + "k"
                                    : creditsUsed}
                                </span>
                              </Tooltip>
                            ),
                          },
                        }}
                      />
                      <Typography className="label">
                        Credits used this billing cycle
                      </Typography>
                    </Grid>*/}
                    <Grid item className="single">
                      <Progress
                        type="circle"
                        width={100}
                        percent={
                          creditsUsed > 0
                            ? (confirmedRequests * 100) / creditsUsed + ""
                            : "0"
                        }
                        strokeWidth={10}
                        status="declined"
                        theme={{
                          declined: {
                            color: "#FBC02D",
                            trailColor: "#E8E8E8",
                            symbol: confirmedRequests + "",
                          },
                        }}
                      />
                      <Typography className="label">
                        Confirmed Requests
                      </Typography>
                    </Grid>
                    <Grid item className="single">
                      <Progress
                        type="circle"
                        width={100}
                        percent={
                          creditsUsed > 0
                            ? (followupReminders * 100) / creditsUsed + ""
                            : "0"
                        }
                        strokeWidth={10}
                        status="accepted"
                        theme={{
                          accepted: {
                            color: "#7BC807",
                            trailColor: "#E8E8E8",
                            symbol: followupReminders + "",
                          },
                        }}
                      />
                      <Typography className="label">
                        Followup Reminders
                      </Typography>
                    </Grid>
                    <Grid item className="single">
                      <Progress
                        type="circle"
                        width={100}
                        percent={
                          creditsUsed > 0
                            ? (failedCount * 100) / creditsUsed + ""
                            : "0"
                        }
                        strokeWidth={10}
                        status="declined"
                        theme={{
                          declined: {
                            color: "#FF5454",
                            trailColor: "#E8E8E8",
                            symbol: failedCount + "",
                          },
                        }}
                      />
                      <Typography className="label">Failed</Typography>
                    </Grid>
                    {other > 0 && (
                      <Grid item className="single">
                        <Progress
                          type="circle"
                          width={100}
                          percent={
                            creditsUsed > 0
                              ? (other * 100) / creditsUsed + ""
                              : "0"
                          }
                          strokeWidth={10}
                          status="declined"
                          theme={{
                            declined: {
                              color: "#707070",
                              trailColor: "#E8E8E8",
                              symbol: other + "",
                            },
                          }}
                        />
                        <Typography className="label">Other</Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
              <div className="payment-method">
                <div className="section-title">
                  <AddNewCard
                    withIcon={true}
                    handler={this.changeCards}
                    handleLoading={this.handleLoading}
                  />
                  <PaymentMethods
                    cards={cards}
                    changeCards={this.changeCards}
                    defaultCard={defaultCard}
                    email={email}
                    handleLoading={this.handleLoading}
                  />
                </div>
                <TableContainer component={Paper} className="table-container">
                  <Table aria-label="simple table" className="cards-table">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Cards</TableCell>
                        <TableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody className="payment-method-table-body">
                      {slicedCards.map((card, idx) => (
                        <TableRow key={idx}>
                          <TableCell scope="row">
                            {card.brand} ending in {card.last4}
                          </TableCell>
                          <TableCell align="right">
                            <div>
                              <IconButton
                                className="icon-button"
                                aria-controls="payment-menu"
                                aria-haspopup="true"
                                card={card}
                                onClick={(e) =>
                                  this.handleClickPaymentMenu(card, e)
                                }
                              >
                                <Icon className="more-icon">more_vert</Icon>
                              </IconButton>
                              {dropdownMenu}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="transaction-history">
                <div className="section-title">
                  <Typography className="label">Transaction History</Typography>
                  <TransactionHistory
                    transactions={transactions}
                    timezone={currentUserInfo?.timezone}
                  />
                </div>
                <TableContainer component={Paper} className="table-container">
                  <Table aria-label="simple table" className="cards-table">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Number</TableCell>
                        <TableCell style={{ display: "flex" }}>
                          Date{" "}
                          <Button
                            onClick={this.handleToggleDateFocus}
                            className={dateFocus === true && "on-focus"}
                          >
                            <Icon className="drop-arrow">arrow_drop_down</Icon>
                          </Button>
                        </TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell style={{ display: "flex" }}>
                          Amount{" "}
                          <Button
                            onClick={this.handleToggleAmountFocus}
                            className={amountFocus === true && "on-focus"}
                          >
                            <Icon className="drop-arrow">arrow_drop_down</Icon>
                          </Button>
                        </TableCell>
                        <TableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {slicedTransactions.map((transaction, idx) => (
                        <TableRow key={idx}>
                          <TableCell scope="row">
                            {transaction.receipt_number}
                          </TableCell>
                          <TableCell>
                            <Moment
                              tz={currentUserInfo?.timezone}
                              format="MM.DD.YYYY"
                            >
                              {transaction.created_at_utc}
                            </Moment>
                          </TableCell>
                          <TableCell>{transaction.description}</TableCell>
                          <TableCell>${transaction.total}</TableCell>
                          <TableCell align="right">
                            {transaction.receipt_url && (
                              <IconButton
                                className="icon-button"
                                component={Link}
                                to={{ pathname: transaction.receipt_url }}
                                target="_blank"
                              >
                                <DownloadIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="mobile-design">
          <Typography className="desktop-title" component={"h2"}>
            Billing
          </Typography>
          {flashMessage !== "" && (
            <div className="flash-message">
              <Typography>{flashMessage}</Typography>
              <Icon
                className="close-icon"
                onClick={() => this.setState({ flashMessage: "" })}
              >
                close
              </Icon>
            </div>
          )}
          <Grid container>
            <Grid item xs={12}>
              <Card className="billing-card">
                <CardContent className="card-content">
                  <div className="section-title" style={{ flexWrap: "wrap" }}>
                    <Typography className="label">
                      Logs [
                      <span>
                        {creditsUsed}{" "}
                        {creditsGiven > 0 && (
                          <span className="text">out of {creditsGiven}</span>
                        )}
                      </span>{" "}
                      used this billing cycle{" "}
                      {cycleStart != "" ? (
                        <span>
                          ({cycleStart} to {cycleEnd})
                        </span>
                      ) : (
                        ""
                      )}
                      ]
                    </Typography>
                    <LogsListPage
                      logs={logs}
                      timezone={currentUserInfo?.timezone}
                    />
                  </div>
                  <Grid container spacing={2} className="cards logs-section">
                    {/*<Grid item className="single" xs={6}>
                      <Progress
                        type="circle"
                        width={100}
                        percent={
                          creditsGiven > 0
                            ? (creditsUsed * 100) / creditsGiven + ""
                            : "0"
                        }
                        strokeWidth={10}
                        status="declined"
                        theme={{
                          declined: {
                            color: "#343434",
                            trailColor: "#B5B5B5",
                            symbol: (
                              <Tooltip
                                title={
                                  <span>
                                    {creditsUsed}{" "}
                                    {creditsGiven > 0 && (
                                      <span className="text">
                                        out of {creditsGiven}
                                      </span>
                                    )}
                                  </span>
                                }
                                placement="top"
                              >
                                <span>
                                  {creditsUsed >= 1000
                                    ? (creditsUsed / 1000).toFixed(0) + "k"
                                    : creditsUsed}
                                </span>
                              </Tooltip>
                            ),
                          },
                        }}
                      />
                      <Typography className="label">
                        Credits used <br /> this billing cycle
                      </Typography>
                    </Grid>*/}
                    <Grid item className="single" xs={6}>
                      <Progress
                        type="circle"
                        width={100}
                        percent={
                          creditsUsed > 0
                            ? (confirmedRequests * 100) / creditsUsed + ""
                            : "0"
                        }
                        strokeWidth={10}
                        status="declined"
                        theme={{
                          declined: {
                            color: "#FBC02D",
                            trailColor: "#E8E8E8",
                            symbol: confirmedRequests + "",
                          },
                        }}
                      />
                      <Typography className="label">
                        Confirmed Requests
                      </Typography>
                    </Grid>
                    <Grid item className="single" xs={6}>
                      <Progress
                        type="circle"
                        width={100}
                        percent={
                          creditsUsed > 0
                            ? (followupReminders * 100) / creditsUsed + ""
                            : "0"
                        }
                        strokeWidth={10}
                        status="accepted"
                        theme={{
                          accepted: {
                            color: "#7BC807",
                            trailColor: "#E8E8E8",
                            symbol: followupReminders + "",
                          },
                        }}
                      />
                      <Typography className="label">
                        Followup Reminders
                      </Typography>
                    </Grid>
                    <Grid item className="single" xs={6}>
                      <Progress
                        type="circle"
                        width={100}
                        percent={
                          creditsUsed > 0
                            ? (failedCount * 100) / creditsUsed + ""
                            : "0"
                        }
                        strokeWidth={10}
                        status="declined"
                        theme={{
                          declined: {
                            color: "#FF5454",
                            trailColor: "#E8E8E8",
                            symbol: failedCount + "",
                          },
                        }}
                      />
                      <Typography className="label">Failed</Typography>
                    </Grid>
                    {other > 0 && (
                      <Grid item className="single" xs={6}>
                        <Progress
                          type="circle"
                          width={100}
                          percent={
                            creditsUsed > 0
                              ? (other * 100) / creditsUsed + ""
                              : "0"
                          }
                          strokeWidth={10}
                          status="declined"
                          theme={{
                            declined: {
                              color: "#707070",
                              trailColor: "#E8E8E8",
                              symbol: other + "",
                            },
                          }}
                        />
                        <Typography className="label">Other</Typography>
                      </Grid>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="cards">
            <Grid item md={3} xs={12}>
              {startingPackage && !isCordova && (
                <div className="starting-package">
                  {cancelledPackage ? (
                    <React.Fragment>
                      <img
                        src={
                          cancelledPackage.image
                            ? cancelledPackage.image
                            : "./images/starting-package.png"
                        }
                        alt=""
                      />
                      <Typography className="large-text" component={"h3"}>
                        {cancelledPackage.name}
                      </Typography>
                      <Typography className="small-text" component={"p"}>
                        {cancelledPackage.credit} credits a month
                      </Typography>
                      <Typography className="large-text" component={"h3"}>
                        $
                        {isMonthly
                          ? cancelledPackage.amount_per_month
                          : cancelledPackage.amount_per_year}
                        {isMonthly ? " / mo" : " / yr"}
                      </Typography>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <img
                        src={
                          currentPackage && currentPackage.image
                            ? currentPackage.image
                            : "./images/starting-package.png"
                        }
                        alt=""
                      />
                      <Typography className="large-text" component={"h3"}>
                        {currentPackage ? currentPackage.name : "Trial"}
                      </Typography>
                      <Typography className="small-text" component={"p"}>
                        {currentPackage
                          ? currentPackage.credit
                          : startingPackage}
                        {currentPackage
                          ? " credits a month"
                          : " credits per trial"}
                      </Typography>
                      <Typography className="large-text" component={"h3"}>
                        $
                        {currentPackage
                          ? isMonthly
                            ? currentPackage.amount_per_month
                            : currentPackage.amount_per_year
                          : 0}
                        {currentPackage ? (isMonthly ? " / mo" : " / yr") : ""}
                      </Typography>
                    </React.Fragment>
                  )}
                  <div className="actions">
                    {/*!cancelled && (
                      <Button
                        color="primary"
                        variant="text"
                        onClick={this.setCancelSubscriptionModalData}
                        classes={{
                          root: "btn",
                          label: "label",
                          textPrimary: "text-primary",
                        }}
                      >
                        <span className="forgot-pw" style={{ color: "white" }}>
                          Cancel
                        </span>
                      </Button>
                    )*/}
                    <SubscriptionPlans
                      currentPackage={currentPackage}
                      email={email}
                      syncing={billSyncing}
                      defaultCard={defaultCard}
                      handler={this.changeCards}
                      isMonthly={isMonthly}
                      refreshAllSections={this.refreshAllSections}
                      cancelSubscription={this.cancelSubscription}
                      cancelled={cancelled}
                    />
                  </div>
                  {trialEnd && (
                    <div className="small-text">
                      <br />
                      Trial will end on{" "}
                      <Moment format="MM.DD.YYYY">{trialEnd}</Moment>
                    </div>
                  )}
                  {nextBilling && (
                    <div className="small-text">
                      <br />
                      Next Billing date on{" "}
                      <Moment format="MM.DD.YYYY">{nextBilling}</Moment>
                    </div>
                  )}
                </div>
              )}
              {creditsEnabled && (
                <React.Fragment>
                  <div className="add-credits">
                    <Typography className="large-text" component={"h3"}>
                      I Just Need Few Credits
                    </Typography>
                    <Select
                      className="credits-select"
                      labelId="label"
                      id="select"
                      value={creditId}
                      onChange={this.handleCreditChange}
                      classes={{
                        root: "select",
                      }}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      }}
                    >
                      <MenuItem value="0">Select Credit</MenuItem>
                      {credits.map((credit) => (
                        <MenuItem key={credit.id} value={credit.id}>
                          {credit.quantity} credits - ${credit.amount}
                        </MenuItem>
                      ))}
                    </Select>
                    <div className="btn-wrapper">
                      <Tooltip
                        title={
                          currentPackage
                            ? ""
                            : "Please subscribe to one of our plans to add credits."
                        }
                      >
                        <Button
                          color={currentPackage ? "primary" : "surface-light"}
                          variant="contained"
                          type="submit"
                          onClick={
                            currentPackage
                              ? this.setAddCreditConfirmationModal
                              : null
                          }
                          classes={{
                            root: "btn",
                            label: "label",
                            containedPrimary: "contained-primary",
                          }}
                        >
                          Add credits
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  <div className="auto-recharge">
                    {autorecharge === true ? (
                      <React.Fragment>
                        {switchOn === true ? (
                          <React.Fragment>
                            <Typography className="large-text" component={"h3"}>
                              <CustomSwitch
                                checked={true}
                                onClick={this.handleSwitchOff}
                              />
                              Autorecharge
                            </Typography>
                            <Typography className="details">
                              When credits are less than <b>{recharge.limit}</b>{" "}
                              <br /> then recharge to{" "}
                              <b>
                                {recharge.creditQuantity} ($
                                {recharge.creditAmount})
                              </b>{" "}
                            </Typography>
                            <div className="btn-wrapper">
                              <EnableAutoRecharge
                                autoCredits={autoCredits}
                                cards={cards}
                                recharge={recharge}
                                toggler={
                                  <Button
                                    color="primary"
                                    variant="text"
                                    classes={{
                                      root: "btn",
                                      label: "label",
                                      textPrimary: "text-primary",
                                    }}
                                  >
                                    <span
                                      className="forgot-pw"
                                      style={{ color: "#7bc807" }}
                                    >
                                      Edit
                                    </span>
                                  </Button>
                                }
                                onEnable={this.handleShow}
                              />
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="autorecharge-off">
                            <Typography className="large-text" component={"h3"}>
                              <Switch
                                checked={checked}
                                onClick={this.handleSwitchOn}
                                value="checked"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                disableRipple
                                classes={{
                                  root: "custom-switch",
                                  thumb: "thumb",
                                  track: "track",
                                  switchBase: "switch-base",
                                  checked: "checked",
                                }}
                              />
                              Autorecharge
                            </Typography>
                            <Typography className="details">
                              {" "}
                              Enable autorecharge{" "}
                            </Typography>
                          </div>
                        )}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Typography className="large-text" component={"h3"}>
                          Autorecharge
                        </Typography>
                        <EnableAutoRecharge
                          autoCredits={autoCredits}
                          cards={cards}
                          recharge={recharge}
                          toggler={
                            <Button
                              color="primary"
                              variant="contained"
                              type="submit"
                              fullWidth
                              classes={{
                                root: "btn",
                                label: "label",
                                containedPrimary: "contained-primary",
                              }}
                            >
                              Enable Autorecharge
                            </Button>
                          }
                          onEnable={this.handleShow}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )}
            </Grid>
            <Grid item xs={12}>
              <div className="payment-method">
                <div className="section-title">
                  <AddNewCard
                    withIcon={true}
                    handler={this.changeCards}
                    handleLoading={this.handleLoading}
                  />
                  <PaymentMethods
                    cards={cards}
                    changeCards={this.changeCards}
                    defaultCard={defaultCard}
                    handleLoading={this.handleLoading}
                  />
                </div>
                <TableContainer component={Paper} className="table-container">
                  <Table aria-label="simple table" className="cards-table">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Cards</TableCell>
                        <TableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody className="payment-method-table-body">
                      {slicedCards.map((card) => (
                        <TableRow key={card.id}>
                          <TableCell scope="row">
                            {card.brand} ending in {card.last4}
                          </TableCell>
                          <TableCell align="right">
                            <div>
                              <IconButton
                                className="icon-button"
                                aria-controls="payment-menu"
                                aria-haspopup="true"
                                card={card}
                                onClick={(e) =>
                                  this.handleClickPaymentMenu(card, e)
                                }
                              >
                                <Icon className="more-icon">more_vert</Icon>
                              </IconButton>
                              {dropdownMenu}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="transaction-history">
                <div className="section-title">
                  <Typography className="label">Transaction History</Typography>
                  <TransactionHistory
                    transactions={transactions}
                    timezone={currentUserInfo?.timezone}
                  />
                </div>
                <TableContainer component={Paper} className="table-container">
                  <Table aria-label="simple table" className="cards-table">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>Number</TableCell>
                        <TableCell>
                          <span style={{ display: "flex" }}>
                            Date{" "}
                            <Button
                              onClick={this.handleToggleDateFocus}
                              className={dateFocus === true && "on-focus"}
                            >
                              <Icon className="drop-arrow">
                                arrow_drop_down
                              </Icon>
                            </Button>
                          </span>
                        </TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>
                          <span style={{ display: "flex" }}>
                            Amount{" "}
                            <Button
                              onClick={this.handleToggleAmountFocus}
                              className={amountFocus === true && "on-focus"}
                            >
                              <Icon className="drop-arrow">
                                arrow_drop_down
                              </Icon>
                            </Button>
                          </span>
                        </TableCell>
                        <TableCell align="right" />
                      </TableRow>
                    </TableHead>
                    <TableBody className="table-body">
                      {slicedTransactions.map((transaction) => (
                        <TableRow key={transaction.id}>
                          <TableCell scope="row">
                            {transaction.receipt_number}
                          </TableCell>
                          <TableCell>
                            <Moment
                              tz={currentUserInfo?.timezone}
                              format="MM.DD.YYYY"
                            >
                              {transaction.created_at_utc}
                            </Moment>
                          </TableCell>
                          <TableCell>{transaction.description}</TableCell>
                          <TableCell>${transaction.total}</TableCell>
                          <TableCell align="right">
                            {transaction.receipt_url && (
                              <IconButton
                                className="icon-button"
                                component={Link}
                                to={{ pathname: transaction.receipt_url }}
                                target="_blank"
                              >
                                <DownloadIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </div>
        {showConfirmationModal && (
          <ConfirmationModal
            title={confirmationModalTitle}
            description={confirmationModalDesc}
            confirmAction={confirmationAction}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    currentUserInfo:
      store.userProfileReducer.user ||
      JSON.parse(localStorage.getItem("user"))?.success?.user,
    showConfirmationModal: store.global.showConfirmationModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleConfirmationModal: () => dispatch(toggleConfirmationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);
