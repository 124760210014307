import React from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Icon,
  TextField,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import "./../../styles/components/more/user-settings.scss";
import { authHeader } from "./../helpers/AuthHeader";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

export default function ChangePasswordModal() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    var errors = {};
    var showError = false;
    if (document.getElementById("password").value.length === 0) {
      errors["password"] = "password is required";
      showError = true;
    }
    if (document.getElementById("old_password").value.length === 0) {
      errors["old_password"] = "old password is required";
      showError = true;
    }
    if (document.getElementById("c_password").value.length === 0) {
      errors["c_password"] = "confirmation password is required";
      showError = true;
    }
    if (
      document.getElementById("c_password").value !==
      document.getElementById("password").value
    ) {
      errors["c_password"] = "confirmation password should match";
      showError = true;
    }
    if (showError) {
      setError(errors);
      console.log(errors);
      console.log(error);
      return;
    }
    const requestOptions = {
      method: "PUT",
      headers: authHeader(),
      body: JSON.stringify({
        [document.getElementById("old_password").name]: document.getElementById(
          "old_password"
        ).value,
        [document.getElementById("c_password").name]: document.getElementById(
          "c_password"
        ).value,
        [document.getElementById("password").name]: document.getElementById(
          "password"
        ).value,
      }),
    };
    //console.log(requestOptions);return;
    fetch(`${API_URL}profile`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then(
        (data) => {
          console.log(data);
          if (!data) {
            setError({ password: "invalid password" });
            return;
          }
          if (data.error) {
            setError(data.error);
            return;
          }
          setOpen(false);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch(console.log);
  };
  const [values, setValues] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [state, setState] = React.useState({
    showOldPassword: false,
    showNewPassword: false,
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleShowOldPassword = () => {
    setState({ ...state, showOldPassword: !state.showOldPassword });
  };

  const handleShowNewPassword = () => {
    setState({ ...state, showNewPassword: !state.showNewPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div style={{ marginTop: 15 }}>
      <span className="edit" onClick={handleClickOpen}>
        Change
      </span>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="change-pw-dialog-title"
        aria-describedby="change-pw-dialog-description"
        classes={{
          root: "user-dialog password",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogTitle className="dialog-title" id="change-pw-dialog-title">
          New Password{" "}
          <Icon onClick={handleClose} className="close-icon">
            close
          </Icon>
        </DialogTitle>
        <form className="change-password">
          <TextField
            classes={{
              root: "text-field",
            }}
            InputLabelProps={{
              classes: {
                root: "label",
              },
            }}
            InputProps={{
              type: state.showOldPassword ? "text" : "password",
              value: values.password,
              onChange: handleChange("oldPassword"),
              classes: {
                root: "input-wrapper",
                input: "input",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowOldPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <Icon>
                      {state.showOldPassword ? "visibility" : "visibility_off"}
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="old_password"
            name="old_password"
            error={typeof error.old_password === "string"}
            helperText={error.old_password ? error.old_password : ""}
            margin="normal"
            type="password"
            placeholder="Old password"
          />
          <TextField
            classes={{
              root: "text-field",
            }}
            InputLabelProps={{
              classes: {
                root: "label",
              },
            }}
            InputProps={{
              type: state.showNewPassword ? "text" : "password",
              value: values.password,
              onChange: handleChange("newPassword"),
              classes: {
                root: "input-wrapper",
                input: "input",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <Icon>
                      {state.showNewPassword ? "visibility" : "visibility_off"}
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="password"
            name="password"
            error={typeof error.password === "string"}
            helperText={error.password ? error.password : ""}
            margin="normal"
            type="password"
            placeholder="New Password"
          />
          <TextField
            classes={{
              root: "text-field",
            }}
            InputLabelProps={{
              classes: {
                root: "label",
              },
            }}
            InputProps={{
              type: state.showNewPassword ? "text" : "password",
              value: values.password,
              onChange: handleChange("confirmPassword"),
              classes: {
                root: "input-wrapper",
                input: "input",
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <Icon>
                      {state.showNewPassword ? "visibility" : "visibility_off"}
                    </Icon>
                  </IconButton>
                </InputAdornment>
              ),
            }}
            id="c_password"
            name="c_password"
            error={typeof error.c_password === "string"}
            helperText={error.c_password ? error.c_password : ""}
            type="password"
            margin="normal"
            placeholder="Repeat New Password"
          />
        </form>
        <DialogActions>
          <Button
            className="action-btn"
            onClick={handleSubmit}
            color="primary"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
