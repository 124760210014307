const { ACTION_TYPES } = require("./types");

export const togglePlanFilterIcon = (payload) => {
  return {
    type: ACTION_TYPES.SHOW_PLAN_FILTER_ICON,
    payload,
  };
};

export const toggleStatusFilterIcon = (payload) => {
  return {
    type: ACTION_TYPES.SHOW_STATUS_FILTER_ICON,
    payload,
  };
};
