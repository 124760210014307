import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Hidden,
  TextField,
  Typography,
  Switch,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import "./../../styles/components/more/create-schedule.scss";

function CreateSchedule() {
  const [state, setState] = React.useState({
    followUp: false,
    name: "",
  });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleInputChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  return (
    <div className="create-schedule page-wrapper">
      <HeaderMore
        backView="/schedule-templates"
        title={state.name || "New Schedule"}
        actionButton={state.name ? "Ok" : "Cancel"}
        nextView="/schedule-templates"
      />
      <div className="inner-content-wrapper">
        <Hidden smDown>
          <Typography className="desktop-title">New Schedule</Typography>
        </Hidden>
        <div className="textfield-wrapper">
          <TextField
            classes={{
              root: "text-field",
            }}
            InputLabelProps={{
              classes: {
                root: "label",
              },
            }}
            InputProps={{
              classes: {
                root: "input-wrapper",
                input: "input",
              },
            }}
            id="filled-uncontrolled"
            label="Schedule Name"
            margin="normal"
            variant="filled"
            value={state.name}
            onChange={handleInputChange("name")}
          />
        </div>
        <div className="confirmation">
          <Typography className="title">Confirmation</Typography>
          <div className="add">
            <Button
              variant="outlined"
              className="btn add-followup"
              component={Link}
              to="/add-confirmation-time"
            >
              Add confirmation
            </Button>
          </div>
        </div>
        <div className="switch-control">
          <Typography component="h3" className="sub-title">
            Follow up
          </Typography>
          <Switch
            checked={state.followUp}
            onChange={handleChange("followUp")}
            value="followUp"
            inputProps={{ "aria-label": "secondary checkbox" }}
            disableRipple
            classes={{
              root: "custom-switch",
              thumb: "thumb",
              track: "track",
              switchBase: "switch-base",
              checked: "checked",
            }}
          />
        </div>
        <div>
          {state.followUp ? (
            <div className="switch-on">
              <Button
                variant="outlined"
                className="btn add-followup"
                component={Link}
                to="/add-followup"
              >
                Add follow up
              </Button>
            </div>
          ) : (
            <div className="switch-off">
              <Typography>
                You can setup reminders (up to 2) for all people who confirmed
                attendance.
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateSchedule;
