import React from "react";
import { Icon } from "@material-ui/core";
import { ReactComponent as HelpIcon } from "../../icons/help-icon.svg";

class StatusIconService extends React.Component {
  render() {
    return (
      <>
        {this.props.status === "U" && (
          <Icon className="icon help-icon">
            <HelpIcon />{" "}
          </Icon>
        )}
        {this.props.status === "F" && (
          <Icon className="icon cross-icon">clear</Icon>
        )}
        {this.props.status === "C" && (
          <Icon className="icon tick-icon">done</Icon>
        )}
        {!this.props.status && <Icon className="icon send-icon">near_me</Icon>}
      </>
    );
  }
}

export default StatusIconService;
