import React from "react";
import { FormControlLabel, Radio, Typography } from "@material-ui/core";
import "../../styles/components/services/service-list.scss";
import "../../styles/components/responsive-design.scss";

export default function ServiceListDesktop(props) {
  const statusColor = () => {
    if (props.total === 0) {
      return "";
    }
    if (props.confirmed === props.total) {
      return "#7BC807";
    }
    return "#FBC02D";
  };
  return (
    <FormControlLabel
      value={props.title + "::" + props.id}
      control={
        <Radio
          disableRipple
          icon=""
          checkedIcon=""
          classes={{
            root: "radio",
            checked: "active",
          }}
        />
      }
      label={
        <React.Fragment>
          <div className="left-content">
            <Typography>{props.title}</Typography>
          </div>
          <div className="right-content">
            {props.template && (
              <Typography className="template-type">
                {props.template}
              </Typography>
            )}
            <div className="status" style={{ color: statusColor() }}>
              <span>
                {props.confirmed}/{props.total}
              </span>
            </div>
          </div>
        </React.Fragment>
      }
      classes={{
        root: "single-service",
        label: "label",
      }}
    />
  );
}
