import React from "react";
import { Drawer, Icon, Typography } from "@material-ui/core";
import LogList from "./LogList.js";

import { authHeader } from "./../helpers/AuthHeader";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { userService } from "./UserService";
import "../../styles/components/responsive-design.scss";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class PeopleDetailDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      type: props.type ? props.type : "",
      plan: props.plan ? props.plan : 0,
      personName: props.name ? props.name : "",
      personId: props.personId ? props.personId : "",
      position: props.position ? props.position : "",
      logList: [],
      open: false,
      loading: false,
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  toggleDrawer = () => {
    const { open } = this.state;
    this.refreshLogs(true);
    this.setState({ open: !open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  refreshLogs(reload) {
    const plan = this.props.plan;
    const personId = this.props.personId;
    const type = this.props.type;

    if (plan === 0 || personId === "") {
      return;
    }
    var _this = this;
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    _this.setState({ loading: true });
    return fetch(`${API_URL}logs/${plan}/${personId}/${type}`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          userService.refreshToken();
          _this.refreshLogs(true);
          _this.setState({ loading: false });
          return;
        }
        _this.setState({ logList: data, loading: false });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.refreshLogs();
  }

  render() {
    const personName = this.props.name;
    const position = this.props.position;
    const { logList, loading, open } = this.state;

    return (
      <div className="logs">
        <div onClick={this.toggleDrawer}>{this.props.toggler}</div>
        <Drawer
          anchor="right"
          open={open}
          onClose={this.toggleDrawer}
          classes={{
            root: "logs-drawer",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <div className={open && loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={loading}
            />
          </div>
          <span className="title">
            <Typography component={"h3"}>
              {personName}, {position}
            </Typography>
            <Icon onClick={this.handleClose}>close</Icon>
          </span>
          <div className="content">
            <LogList logs={logList} />
          </div>
        </Drawer>
      </div>
    );
  }
}

export default PeopleDetailDrawer;
