import { ACTION_TYPES } from "../actions/types";
const initialState = {
  church: null,
  package: null,
  phone_number: null,
  phone_numbers: null,
  user: null,
  users: null,
  errorMessage: null,
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_USER_PROFILE_DATA_SUCCESS:
      return Object.assign({}, state, {
        ...action?.payload,
      });

    case ACTION_TYPES.GET_USER_PROFILE_DATA_FAIL:
      return Object.assign({}, state, {
        errorMessage: action?.payload?.message,
      });

    case ACTION_TYPES.UPDATE_USER_PROFILE:
      return Object.assign({}, state, {
        user: action?.payload,
      });

    default:
      return state;
  }
};

export default userProfileReducer;
