import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  Icon,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import HeaderForAll from "../components/HeaderForAll";
import Logo from "../components/Logo";
import { userService } from "../components/services/UserService";
import "react-phone-input-2/lib/style.css";

const publicIp = require("public-ip");
const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

//const recaptchaRef = React.createRef();

class CreateAccountOne extends React.Component {
  captcha = {};

  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      name: user ? user.name : "",
      email: user ? user.email : "",
      mobile_phone: user ? user.mobile_phone : "",
      bypass_phone: false,
      bypass_email: false,
      submitted: false,
      loading: false,
      error: {},
      flashMessage: "",
      success: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFlash = this.handleFlash.bind(this);
    this.bypassEmail = this.bypassEmail.bind(this);
    this.bypassPhone = this.bypassPhone.bind(this);
  }
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return this.props.history.push(`/dashboard?d=${Date.now()}`);
    }
  }

  handleFlash() {
    this.setState({ flashMessage: "" });
  }

  bypassEmail(e) {
    var error = this.state.error;
    error.email = null;
    this.setState({ bypass_email: e.target.checked, error: error });
  }

  bypassPhone(e) {
    var error = this.state.error;
    error.mobile_phone = null;
    this.setState({ bypass_phone: e.target.checked, error: error });
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({
      ...this.state.textmask,
      [name]: value,
    });
  }

  handleClick = async (e) => {
    e.preventDefault();

    this.setState({ submitted: true, flashMessage: "" });
    const { name, email, bypass_email, bypass_phone } = this.state;

    const error = {};
    // stop here if form is invalid
    /*var emailPattern = /^[\+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if(email && !emailPattern.test(email)) {
            error["email"] = "email is invalid";
        }*/

    var phonePattern = /^\d{11}$/;
    var phonePattern2 = /^\+\d{1} \(\d{3}\) \d{3}-\d{4}$/;
    var mobile_phone = this.state.mobile_phone.trim();
    console.log(mobile_phone);
    console.log(phonePattern.test(mobile_phone));
    if (
      mobile_phone &&
      !(phonePattern.test(mobile_phone) || phonePattern2.test(mobile_phone))
    ) {
      error["mobile_phone"] = "Mobile phone is invalid.";
    }

    if (
      name &&
      (name.indexOf(" ") === -1 || name.indexOf(" ") === name.length - 1)
    ) {
      error["name"] = "Please enter your full name";
    }

    const recaptchaValue = this.captcha.getValue(); //recaptchaRef.current.getValue();

    if (!(name && email && mobile_phone && recaptchaValue.length > 0)) {
      if (!name) {
        error["name"] = "this field is required";
      }
      if (!mobile_phone) {
        error["mobile_phone"] = "this field is required";
      }
      if (!email) {
        error["email"] = "this field is required";
      }
      if (recaptchaValue.length === 0) {
        error["captcha"] = "ReCaptcha should be filled";
      }
      this.captcha.reset();
      this.setState({ error });
      return;
    }

    if (error && (error.email || error.mobile_phone || error.name)) {
      this.captcha.reset();
      this.setState({ error });
      return;
    }

    this.setState({ loading: true });
    var ipaddress = await publicIp.v4();
    userService
      .registerStep1(
        name,
        email,
        mobile_phone,
        recaptchaValue,
        bypass_email,
        bypass_phone,
        ipaddress
      )
      .then(
        (data) => {
          //const { from } = { from: { pathname: "/verify-email?email=" + email } };
          //this.props.history.push(from);
          window.location.href =
            "/verify-email?email=" + encodeURIComponent(email);
          //this.setState({ error: {}, loading: false, success: true });
        },
        (error) => {
          console.log(typeof error);
          console.log(error);
          if (typeof error === "string") {
            this.setState({ flashMessage: error, loading: false });
          } else {
            var _this = this;
            error.then(function (e) {
              var errors = JSON.parse(e).error;
              var errorJson = {};
              if (typeof errors === "string") {
                _this.setState({ flashMessage: errors, loading: false });
              } else {
                errorJson["errorText"] = "";
                for (const key in errors) {
                  errorJson[key] = errors[key][0];
                  errorJson["errorText"] +=
                    "<li>" + key + ": " + errors[key][0] + "</li>";
                }
              }
              _this.captcha.reset();
              _this.setState({ error: errorJson, loading: false });
            });
          }
        }
      );
  };

  render() {
    const {
      name,
      email,
      mobile_phone,
      loading,
      error,
      success,
      flashMessage,
      bypass_email,
      bypass_phone,
    } = this.state;
    return (
      <div className="page-wrapper create-acc">
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
        <HeaderForAll
          title="Create account"
          // eslint-disable-next-line no-script-url
          nextView="javascript:void;"
          onClick={this.handleClick}
          nextLabel="Next"
          loading={loading}
        />
        <div className="auth-wrapper">
          <div className="create-account-wrapper steps">
            {flashMessage !== "" && (
              <div className="flash-message">
                <Typography>{flashMessage}</Typography>
                <Icon className="close-icon" onClick={this.handleFlash}>
                  close
                </Icon>
              </div>
            )}
            <div className="logo-wrapper">
              {success && (
                <div className="success center mb">
                  Please check your email for link we sent you. Then, click it
                  to confirm your email address
                </div>
              )}
              <Logo href="/" className="logo" />
              <h3>Create account</h3>
            </div>
            <form name="form" onSubmit={this.handleSubmit}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Full Name"
                name="name"
                value={name}
                onChange={this.handleChange}
                //defaultValue="Agata"
                margin="normal"
                variant="filled"
                error={typeof error.name === "string"}
                helperText={error.name ? error.name : ""}
              />

              <TextField
                classes={{
                  root: "text-field",
                }}
                type="email"
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Email"
                name="email"
                value={email}
                onChange={this.handleChange}
                //defaultValue="agata@nairidesign.co.uk"
                margin="normal"
                variant="filled"
                error={typeof error.email === "string"}
                helperText={error.email ? error.email : ""}
              />
              {((typeof error.email === "string" &&
                error.email.includes("valid")) ||
                bypass_email) && (
                <FormControlLabel
                  className="label"
                  control={<Checkbox onChange={this.bypassEmail} />}
                  label="Yes, this email is valid."
                />
              )}
              <div
                className={`input-wrapper ${
                  typeof error.mobile_phone === "string"
                    ? "phone error"
                    : "phone"
                }`}
              >
                <PhoneInput
                  placeholder="Mobile phone"
                  country={"us"}
                  value={mobile_phone}
                  onChange={(mobile_phone) => this.setState({ mobile_phone })}
                />
                {typeof error.mobile_phone === "string" && (
                  <Box ml={2}>
                    <FormHelperText error>
                      {error.mobile_phone ? error.mobile_phone : ""}
                    </FormHelperText>
                  </Box>
                )}
              </div>
              {((typeof error.mobile_phone === "string" &&
                error.mobile_phone.includes("real")) ||
                bypass_phone) && (
                <FormControlLabel
                  className={
                    typeof error.mobile_phone === "string"
                      ? "label mt-4"
                      : "label mt-1"
                  }
                  control={<Checkbox onChange={this.bypassPhone} />}
                  label="Yes, this is a real mobile phone."
                />
              )}
              {/*<TextField
            classes={{
                root: 'text-field',
              }}
            InputLabelProps={{
              classes: {
                root: 'label'
              }
            }}
            InputProps={{
              inputComponent: TextMaskCustom,
              classes: {
                root: 'input-wrapper',
                input: 'input'
              }
            }}
            id="filled-uncontrolled"
            label="Mobile phone"
            name="mobile_phone"
            value={mobile_phone}
            onChange={this.handleChange}
            //defaultValue="+48 508 115 695"
            margin="normal"
            variant="filled"
            error={typeof error.mobile_phone === "string"}
            helperText={error.mobile_phone ? error.mobile_phone : ""}
          />*/}
              <ReCAPTCHA
                id="recaptcha"
                //ref={recaptchaRef}
                ref={(el) => {
                  this.captcha = el;
                }}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={this.handleChange}
              />
              {typeof error.captcha === "string" && (
                <div class="div-error">{error.captcha}</div>
              )}
              <Button
                color="primary"
                variant="contained"
                fullWidth
                component={Link}
                to="/create-account-two"
                onClick={this.handleClick}
                type="submit"
                loading={loading}
                classes={{
                  root: "btn",
                  label: "label",
                  containedPrimary: "contained-primary nextbtn",
                }}
              >
                Next
              </Button>
              <Button
                color="primary"
                variant="text"
                component={Link}
                to="/login"
                classes={{
                  root: "btn",
                  label: "label",
                  textPrimary: "text-primary",
                }}
              >
                <span className="light">Already have an account?</span> &nbsp;
                Login here.
              </Button>
            </form>
          </div>
        </div>
        <div className="auth-banner">
          <img src="images/auth-banner.png" alt="" />
        </div>
      </div>
    );
  }
}
export default CreateAccountOne;
