import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Hidden,
  Icon,
  Typography,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import "./../../styles/components/more/new-template.scss";

function NewTemplate() {
  const [values, setValues] = React.useState({
    text:
      "Dear [firstname], can you join us [time]? Please reply YES/NO to this message.",
    type: "reminder",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <div className="page-wrapper new-template-page">
      <PagesSubMenu />
      <HeaderMore
        backView="/message-templates"
        title="Message templates"
        actionButton="Ok"
        nextView="/dashboard"
      />
      <div className="inner-content-wrapper">
        <Hidden smDown>
          <Typography className="desktop-title">
            New Message Template
          </Typography>
        </Hidden>
        <div className="new-template">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                select
                label="Type"
                margin="normal"
                variant="filled"
                value={values.type}
                onChange={handleChange("type")}
                SelectProps={{
                  MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  },
                }}
              >
                <MenuItem value="reminder"> Reminder </MenuItem>
                <MenuItem value="confirmation"> Confirmation </MenuItem>
              </TextField>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Name"
                defaultValue="Aaisha"
                margin="normal"
                variant="filled"
              />
              <div className="multiline-textfield-wrapper">
                <div className="text">
                  <Typography className="title">Text</Typography>
                  <div className="right-icons">
                    <IconButton className="icon-button">
                      <Icon>code</Icon>
                    </IconButton>
                  </div>
                </div>
                <TextField
                  multiline
                  fullWidth
                  rowsMax="4"
                  value={values.text}
                  onChange={handleChange("text")}
                  margin="normal"
                  className="multiline-textfield"
                  InputProps={{
                    classes: {
                      root: "form-control",
                    },
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <Hidden smDown>
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/dashboard"
            classes={{
              root: "btn",
              label: "label",
              containedPrimary: "contained-primary next-step",
            }}
          >
            Save
          </Button>
        </Hidden>
      </div>
    </div>
  );
}

export default NewTemplate;
