import React from "react";
import PropType from "prop-types";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const cancellingReasons = [
  "I don't understand how to use your product.",
  "It's too expensive.",
  "I found another option or product that I like better.",
  "I don't use it enough.",
  "Some features I need are missing.",
  "Bugs or Support Problems.",
  "Other reasons",
];

class CancelSubscriptionModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelingReason: "",
      cancelingComment: "",
      finalComment: "",
      toggleModal: props.toggleModal,
      showModal: props.showModal,
    };

    this.handleChange = this.handleChange.bind(this);
    this.cancelSubscription = this.cancelSubscription.bind(this);
  }

  cancelSubscription = () => {
    this.props.confirmAction(this.state.finalComment);
    //this.state.toggleModal();
  };

  handleChange = (event) => {
    const { cancelingReason, cancelingComment } = this.state;
    if (event.target.name === "reason") {
      this.setState({
        cancelingReason: event.target.value,
        finalComment: event.target.value + " " + cancelingComment,
      });
    } else {
      this.setState({
        cancelingComment: event.target.value,
        finalComment: cancelingReason + " " + event.target.value,
      });
    }
  };

  render() {
    const {
      cancelingReason,
      cancelingComment,
      toggleModal,
      showModal,
    } = this.state;
    return (
      <div>
        <Button variant="outlined" color="primary" onClick={toggleModal}>
          Open form dialog
        </Button>
        <Dialog
          open={showModal}
          onClose={toggleModal}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" className="pb-0">
            <strong>Cancel subscription</strong>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              You have not canceled your account yet. Please select an option
              below.
              <br /> What's the single biggest reason for you canceling?
            </DialogContentText>
            <FormControl className="w-100">
              <InputLabel id="demo-controlled-open-select-label">
                Plese select canceling reason
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                name="reason"
                value={cancelingReason}
                onChange={this.handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {cancellingReasons.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogContentText className="mt-4" style={{ fontWeight: 600 }}>
              We are sorry to see you go. You're welcome back at any time. Any
              final comments for us?
            </DialogContentText>
            <TextField
              id="outlined-multiline-static"
              label="Comments"
              name="comment"
              multiline
              rows={6}
              value={cancelingComment}
              onChange={this.handleChange}
              variant="outlined"
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleModal} color="primary">
              Nevermind
            </Button>
            <Button onClick={this.cancelSubscription} className="orange-btn">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

CancelSubscriptionModal.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
};

export default CancelSubscriptionModal;
