import React from "react";
import queryString from "query-string";
import { Typography, Grid, Button } from "@material-ui/core";
import "./../../styles/components/dashboard/planning-center.scss";
import { userService } from "./../../components/services/UserService";

class PlanningCenter extends React.Component {
  constructor(props) {
    super(props);

    //console.log(this.props.location);
    const values = queryString.parse(this.props.location.search);

    this.state = {
      error: values.error ? true : false,
    };

    if (values.code) {
      userService.authorizePcoWith(values.code).then(
        (user) => {
          window.location.href = "/dashboard?d=" + Date.now();
          /*const { from } = { from: { pathname: "/dashboard" } };
						this.props.history.push(from);*/
        },
        (error) => console.log(error)
      );
    } else if (values.error) {
      console.log("show error here" + values.error);
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    userService.authorizePco().then(
      (user) => {},
      (error) => console.log(error)
    );

    return;
  }

  render() {
    const error = this.state.error;
    return (
      <div className="planning-center authorise">
        <div className="pco">
          <span className="logo-wrapper">
            <img src="./images/pco-logo-blue.png" alt="" />
          </span>
          <Typography>
            {error
              ? "There was an error integrating, please try again"
              : "It is syncing now"}
            . Once integrated, your services and people will be synced with
            Confirmed.Church. As soon as you send the email service request to
            your unconfirmed people in Planning Center Services, we will send
            the confirmation text message within 10 minutes (skipped if they
            haven't already confirmed via email). The text follow-up service
            reminders would be sent 1 month, 1 week, 1 day and 1 minute before
            your people is scheduled to serve.
          </Typography>
          {error && (
            <Button
              color="primary"
              variant="contained"
              // component={Link}
              onClick={this.handleClick}
              to="#"
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary",
              }}
            >
              Authorize
            </Button>
          )}
        </div>
        <div className="comming-soon">
          <Typography component="h3">Comming soon</Typography>
          <Grid container spacing={2} className="logos">
            {/*<Grid item xs={4}>
				<img src="./images/elvanto.svg" alt="" />
			  </Grid>*/}
            <Grid item xs={8}>
              <img src="./images/ccb.svg" alt="" />
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
export default PlanningCenter;
