import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userReducer";
import userProfileReducer from "./userProfileReducer";
import globalReducer from "./globalReducer";
import organizationReducer from "./organizationReducer";
import peopleReducer from "./peopleReducer";
import servicesReducer from "./servicesReducer";

const config = {
  key: "root",
  storage,
  whitelist: ["client"],
};

const rootReducer = combineReducers({
  userReducer,
  userProfileReducer,
  global: globalReducer,
  organization: organizationReducer,
  people: peopleReducer,
  services: servicesReducer,
});

export default persistReducer(config, rootReducer);
