import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import * as peopleActions from "../../store/actions/peopleActions";

export function usePeopleStore() {
  const dispatch = useDispatch();
  const _people = useSelector((store) => store.people);

  const fetchPeople = useCallback(
    async (search) => await dispatch(peopleActions.fetchPeople(search)),
    [dispatch]
  );

  const skipPeople = useCallback(
    async (id) => await dispatch(peopleActions.skipPeople(id)),
    [dispatch]
  );

  const unSkipPeople = useCallback(
    async (id) => await dispatch(peopleActions.unSkipPeople(id)),
    [dispatch]
  );

  const unSkipAllPeople = useCallback(
    async () => await dispatch(peopleActions.unSkipAllPeople()),
    [dispatch]
  );

  return {
    people: _people,
    fetchPeople,
    skipPeople,
    unSkipPeople,
    unSkipAllPeople,
  };
}
