import React from "react";
import { Link } from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import { css } from "@emotion/core";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  Icon,
  Typography,
  Button,
  Hidden,
  Grid,
} from "@material-ui/core";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import VideoView from "./VideoView";
import Footer from "../Footer";
import { authHeader } from "../../components/helpers/AuthHeader";
import { userService } from "../../components/services/UserService";
import { userProfileMiddeware } from "../../store/middlewares/userProfileMiddleware";
import ConnectPcoModal from "../ConnectPcoModal";
import en from "react-phone-number-input/locale/en.json";
import "../../styles/components/dashboard/dashboard-main.scss";
import { getCountryCallingCode } from "react-phone-number-input/input";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

const phoneOptions = [
  { key: "mobile", value: "Use mobile" },
  { key: "primary", value: "Use Main/Primary" },
  {
    key: "mobile-primary",
    value: "Try mobile-first and if invalid, send via main/primary",
  },
];

class DashboardMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      unconfirmed: "-1",
      accepted: "-1",
      declined: "-1",
      failed: "-1",
      total: "-1",
      sent: "-1",
      integrated: true,
      active: true,
      firstName: "",
      showConnectPcoModal: false,
      country: this.props.country,
      phonePreference: this.props.phonePreference,
      showSnackBar: false,
      snackbarMessage: "",
      phonePreference: this.props.phonePreference,
    };
  }

  toggleConnectPcoModal = () => {
    this.setState({ showConnectPcoModal: !this.state.showConnectPcoModal });
  };

  componentDidMount() {
    const { getUserProfileData } = this.props;
    this.refreshDashboard();
    getUserProfileData();
  }

  handlePhonePreferenceChange = async () => {
    try {
      const { phonePreference } = this.state;
      if (phonePreference) {
        const data = await userService.savePreference(phonePreference);
        if (data) {
          this.setState({
            showSnackBar: true,
            snackbarMessage: "Number preference updated successfully",
          });
        }
      }
    } catch (error) {
      this.setState({ showSnackBar: true, snackbarMessage: error.message });
    }
  };

  handleDefaultCountryChange = async () => {
    try {
      const { country } = this.state;
      if (country) {
        const data = await userService.saveCountry(
          country,
          getCountryCallingCode(country)
        );
        if (data) {
          this.setState({
            showSnackBar: true,
            snackbarMessage: "Country code updated successfully",
          });
        }
      }
    } catch (error) {
      this.setState({ showSnackBar: true, snackbarMessage: error.message });
    }
  };

  handleCountryChangeForPcoModal = (countryIso) => {
    this.setState({ country: countryIso }, () => {
      this.handleDefaultCountryChange();
    });
  };

  handleDefaultNumChangeForPcoModal = (event) => {
    const phonePreference = event.target.value;
    this.setState({ phonePreference }, () => {
      this.handlePhonePreferenceChange();
    });
  };

  refreshDashboard(reload) {
    var _this = this;
    var user = JSON.parse(localStorage.getItem("user"));
    var integrated = user.success.user.churches[0].pivot.pco_access_token
      ? true
      : false;
    var active = user.success.status !== "Not Active";
    this.setState({
      integrated: integrated,
      firstName: user.success.user.first_name,
      active: active,
      country: user.success.user.churches[0].phone_country,
      phonePreference: user.success.user.churches[0].phone_preference,
    });

    if (!integrated) {
      //this.toggleConnectPcoModal();
    }

    if (integrated) {
      fetch(API_URL + "scheduling/dashboard", {
        headers: authHeader(),
      })
        .then((res) => {
          if (!res.ok) {
            if (res.status !== 401) return;
          }
          return res.json();
        })
        .then((data) => {
          if (!data) {
            _this.setState({
              unconfirmed: "0",
              accepted: "0",
              declined: "0",
              failed: "0",
              total: "0",
              sent: "0",
            });
          }
          if (data.error) {
            userService.refreshToken().then(
              (data) => {
                _this.refreshDashboard(true);
                return;
              },
              (error) => {
                _this.setState({
                  unconfirmed: "0",
                  accepted: "0",
                  declined: "0",
                  failed: "0",
                  total: "0",
                  sent: "0",
                });
              }
            );
            return;
          }
          if (data) {
            _this.setState({
              unconfirmed: data.unconfirmed,
              accepted: data.accepted,
              declined: data.declined,
              failed: data.failed,
              total: data.unconfirmed + data.declined + data.accepted,
              sent: data.sent,
            });
            const searchParams = new URLSearchParams(window.location.search);
            const id = searchParams.get("d");
            if (id) {
              this.toggleConnectPcoModal();
            }
          }
        })
        .catch(console.log);
    } else {
      this.setState({
        unconfirmed: "0",
        accepted: "0",
        declined: "0",
        failed: "0",
        total: "0",
        sent: "0",
      });
    }
  }

  render() {
    const {
      unconfirmed,
      accepted,
      // declined,
      failed,
      total,
      sent,
      integrated,
      active,
      firstName,
      showConnectPcoModal,
    } = this.state;
    return (
      <React.Fragment>
        <div className="dashboard-main">
          <div className="cards-wrapper">
            {integrated && active && (
              <Grid container spacing={2} className="container welcome">
                <Card className="dashboard-card welcome-card">
                  <CardContent className="card-content">
                    <Grid item md={6} xs={12} className="content">
                      <h3>Welcome back {firstName}!</h3>
                      {sent > 0 && (
                        <span>
                          You sent <strong>{sent}</strong> confirmations this
                          week
                        </span>
                      )}
                    </Grid>
                    <Grid item md={6} xs={12} className="banner">
                      <img src="images/welcome-banner.png" alt="" />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {!integrated && active && (
              <Grid container spacing={2} className="container welcome">
                <Card className="dashboard-card welcome-card">
                  <CardContent className="card-content">
                    <Grid item md={6} xs={12} className="content">
                      <h3>Welcome {firstName}!</h3>
                      <div className="for-small-device">
                        <span>Set up your integration</span>
                        <Button
                          color="primary"
                          variant="contained"
                          component={Link}
                          to="/integrations"
                          type="submit"
                          classes={{
                            root: "btn",
                            label: "label",
                            containedPrimary: "contained-primary",
                          }}
                        >
                          Set up
                        </Button>
                      </div>
                    </Grid>
                    <Grid item md={6} xs={12} className="banner">
                      <img src="images/welcome-banner.png" alt="" />
                    </Grid>
                  </CardContent>
                </Card>
                <Grid item xs={12} className="video">
                  <div className="video-wrapper">
                    <VideoView
                      toggler={<Icon className="play-icon">play_arrow</Icon>}
                    />
                    {/*<iframe src="https://www.youtube.com/embed/j61j9X4xCnA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />*/}
                  </div>
                </Grid>
              </Grid>
            )}
            {!active && (
              <Grid container spacing={2} className="container welcome">
                <Card className="dashboard-card welcome-card">
                  <CardContent className="card-content">
                    <Grid item md={6} xs={12} className="content">
                      <h3>Welcome {firstName}!</h3>
                      <span>
                        Your account is suspended. In order to activate it,
                        Please subscribe in the billing page.
                      </span>
                      <Button
                        color="primary"
                        variant="contained"
                        component={Link}
                        to="/billing"
                        type="submit"
                        classes={{
                          root: "btn",
                          label: "label",
                          containedPrimary: "contained-primary",
                        }}
                      >
                        Subscribe
                      </Button>
                    </Grid>
                    <Grid item md={6} xs={12} className="banner">
                      <img src="images/welcome-banner.png" alt="" />
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            )}
            {integrated && active && (
              <Grid container spacing={2} className="container">
                <Grid
                  item
                  xs={6}
                  md={3}
                  component={Link}
                  to={unconfirmed > 0 ? "/services?type=U" : "#"}
                >
                  <Card className="dashboard-card flexer">
                    {unconfirmed > -1 && (
                      <CardContent className="card-content flexer-col">
                        <Icon className="icon help-icon">help</Icon>
                        <Progress
                          type="circle"
                          width={80}
                          percent={
                            total === 0 ? "0" : (unconfirmed * 100) / total + ""
                          }
                          strokeWidth={14}
                          status="fullwidth"
                          symbolClassName="fullwidth-symbol"
                          theme={{
                            fullwidth: {
                              color: "#FBC02D",
                              trailColor: "#F1F1F1",
                              symbol: unconfirmed + "",
                            },
                          }}
                        />
                        <Typography className="label">Unconfirmed</Typography>
                      </CardContent>
                    )}
                    {unconfirmed === -1 && (
                      <div>
                        <MoonLoader
                          css={override}
                          sizeUnit={"px"}
                          size={70}
                          color={"#7BC807"}
                          loading
                        />
                        <Typography className="label">Unconfirmed</Typography>
                      </div>
                    )}
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  component={Link}
                  to={accepted > 0 ? "/services?type=C" : "#"}
                >
                  <Card className="dashboard-card flexer">
                    {accepted > -1 && (
                      <CardContent className="card-content flexer-col">
                        <Icon className="icon tick-icon">done</Icon>
                        <Progress
                          type="circle"
                          width={80}
                          percent={
                            total === 0 ? "0" : (accepted * 100) / total + ""
                          }
                          strokeWidth={14}
                          status="unconfirmed"
                          symbolClassName="unconfirmed-symbol"
                          theme={{
                            unconfirmed: {
                              color: "#7BC807",
                              trailColor: "#F1F1F1",
                              symbol: accepted + "",
                            },
                          }}
                        />
                        <Typography className="label">Accepted</Typography>
                      </CardContent>
                    )}
                    {accepted === -1 && (
                      <div>
                        <MoonLoader
                          css={override}
                          sizeUnit={"px"}
                          size={70}
                          color={"#7BC807"}
                          loading
                        />
                        <Typography className="label">Accepted</Typography>
                      </div>
                    )}
                  </Card>
                </Grid>

                <Grid
                  item
                  xs={6}
                  md={3}
                  component={Link}
                  to={failed > 0 ? "/services?type=F" : "#"}
                >
                  <Card className="dashboard-card flexer">
                    {failed > -1 && (
                      <CardContent className="card-content flexer-col">
                        <Icon className="icon cross-icon">clear</Icon>
                        <div className="number red">{failed}</div>
                        <Typography className="label">Failed</Typography>
                      </CardContent>
                    )}
                    {failed === -1 && (
                      <div>
                        <MoonLoader
                          css={override}
                          sizeUnit={"px"}
                          size={70}
                          color={"#7BC807"}
                          loading
                        />
                        <Typography className="label">Failed</Typography>
                      </div>
                    )}
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={3}
                  component={Link}
                  to={sent > 0 ? "/services?type=A" : "#"}
                >
                  <Card className="dashboard-card flexer">
                    {sent > -1 && (
                      <CardContent className="card-content flexer-col">
                        <Icon className="icon send-icon">near_me</Icon>
                        <div className="number">{sent}</div>
                        <Typography className="label">
                          Sent confirmations
                          <br />
                          for upcoming <br /> services
                        </Typography>
                      </CardContent>
                    )}
                    {sent === -1 && (
                      <div>
                        <MoonLoader
                          css={override}
                          sizeUnit={"px"}
                          size={70}
                          color={"#7BC807"}
                          loading
                        />
                        <Typography className="label">
                          Sent confirmations
                          <br />
                          for upcoming <br /> services
                        </Typography>
                      </div>
                    )}
                  </Card>
                </Grid>

                <Hidden mdDown>
                  <div className="help-button">
                    <VideoView
                      toggler={
                        <Button
                          color="primary"
                          variant="contained"
                          classes={{
                            root: "btn",
                            label: "label",
                            containedPrimary: "contained-primary",
                          }}
                        >
                          Need some help?
                          <Icon className="play-icon">play_arrow</Icon>
                        </Button>
                      }
                    />
                  </div>
                </Hidden>
              </Grid>
            )}
          </div>
          <Footer />
        </div>
        {showConnectPcoModal && (
          <ConnectPcoModal
            open={showConnectPcoModal}
            closeModal={this.toggleConnectPcoModal}
            labels={en}
            onCountryChange={this.handleCountryChangeForPcoModal}
            countryValue={this.state.country}
            className={this.props.pco_account ? "country-white-select" : ""}
            phoneNumberValue={this.state.phonePreference}
            name={"phone_preference"}
            onPhoneNumChange={this.handleDefaultNumChangeForPcoModal}
            phoneOptions={phoneOptions}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserProfileData: () => dispatch(userProfileMiddeware()),
});

export default connect(null, mapDispatchToProps)(DashboardMain);
