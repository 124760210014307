export const content = {
  'US': {
    flag:
      "https://pl-wp-static.s3.us-west-2.amazonaws.com/confirmed-church/wp-content/uploads/2021/04/19030827/united-states.svg",
    text: "(US) requires [credits] credit for 1 text message (160 characters)",
  },
  'CA': {
    flag:
      "https://pl-wp-static.s3.us-west-2.amazonaws.com/confirmed-church/wp-content/uploads/2021/04/19031148/canada.svg",
    text: "(CA) requires [credits] credit for 1 text message (160 characters)",
  },
  'GB': {
    flag:
      "https://pl-wp-static.s3.us-west-2.amazonaws.com/confirmed-church/wp-content/uploads/2021/04/19031457/united-kingdom.svg",
    text: "(GB) requires [credits] credits for 1 text message (160 characters)",
  },
  'AU': {
    flag:
      "https://pl-wp-static.s3.us-west-2.amazonaws.com/confirmed-church/wp-content/uploads/2021/04/19031458/australia.svg",
    text: "(AU) requires [credits] credits for 1 text message (160 characters)",
  },
  'ZA': {
    flag:
      "https://pl-wp-static.s3.us-west-2.amazonaws.com/confirmed-church/wp-content/uploads/2021/04/19031459/south-africa.svg",
    text: "(ZA) requires [credits] credits for 1 text message (160 characters)",
  },
  'NZ': {
    flag:
      "https://pl-wp-static.s3.us-west-2.amazonaws.com/confirmed-church/wp-content/uploads/2021/10/12090911/new-zealand1.png",
    text: "(NZ) requires [credits] credits for 1 text message (160 characters)",
  },
};
