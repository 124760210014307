import React from "react";
import { css } from "@emotion/core";
import {
  Button,
  IconButton,
  Icon,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import ClipLoader from "react-spinners/ClipLoader";
import HeaderForAll from "../components/HeaderForAll";
import Logo from "../components/Logo";
import { userService } from "../components/services/UserService";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class CreateAccountTwo extends React.Component {
  constructor(props) {
    super(props);
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user?.success) {
      window.location.href = "/login";
    }

    this.state = {
      church: "",
      username: "",
      password: "",
      confirm_password: "",
      coupon_code: null,
      showPassword: "",
      submitted: false,
      loading: false,
      error: {},
      flashMessage: user?.success?.user?.status > 1,
    };

    if (user) {
      this.state.church = user.church ? user.church : "";
      this.state.username = user.username ? user.username : user.email;
    }
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleFlash = this.handleFlash.bind(this);
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return this.props.history.push(`/dashboard`);
    }
  }

  handleFlash() {
    this.setState({ flashMessage: false });
  }
  handleChangePassword = (prop) => (event) => {
    this.setState({ ...this.state.password, [prop]: event.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleClick(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { church, username, password, confirm_password, coupon_code } =
      this.state;

    const error = {};
    // stop here if form is invalid
    if (!(church && username && password)) {
      if (!church) {
        error["church"] = "this field is required";
      }
      if (!username) {
        error["username"] = "this field is required";
      }
      if (!password) {
        error["password"] = "this field is required";
      }
      this.setState({ error });
      return;
    }

    if (password !== confirm_password) {
      error["confirm_password"] = "password doesnot match";
      this.setState({ error });
      return;
    }

    this.setState({ loading: true });
    userService.registerStep2(church, username, password, coupon_code).then(
      (data) => {
        var user = JSON.parse(localStorage.getItem("user"));
        if (user && !user.success.user.bypassed) {
          window.location.href = "/signup-number?id=" + user.success.user.id;
        } else {
          window.location.href = "/verification"; //"id=" + user.success.user.id;
        }
        /*const { from } = { from: { pathname: "/signup-number?id="+user.success.user.id } };
            this.props.history.push(from);*/
        //this.setState({ error: {}, loading: false });
      },
      (error) => {
        var _this = this;
        error.then(function (e) {
          var errors = JSON.parse(e).error;
          var errorJson = {};
          errorJson["errorText"] = "";
          for (const key in errors) {
            //console.log(key);
            errorJson[key] = errors[key][0];
            errorJson["errorText"] +=
              "<li>" + key + ": " + errors[key][0] + "</li>";
          }
          _this.setState({ error: errorJson, loading: false });
        });
      }
    );
  }

  render() {
    const {
      church,
      username,
      password,
      confirm_password,
      coupon_code,
      loading,
      error,
      showPassword,
      flashMessage,
    } = this.state;
    return (
      <div className="page-wrapper create-acc">
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
        <HeaderForAll
          backView="/signup"
          title="Create account"
          // eslint-disable-next-line no-script-url
          nextView="javascript:void;"
          onClick={this.handleClick}
          nextLabel="Next"
          loading={loading}
        />
        <div className="auth-wrapper">
          <div className="create-account-wrapper steps">
            <div className="logo-wrapper">
              <Logo href="/" className="logo" />
              <h3>Your details</h3>
            </div>
            {flashMessage && (
              <div className="flash-message">
                <Typography>Your email has already been verified!</Typography>
                <Icon className="close-icon" onClick={this.handleFlash}>
                  close
                </Icon>
              </div>
            )}
            <form name="form" onSubmit={this.handleSubmit}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Company"
                name="church"
                value={church}
                onChange={this.handleChange}
                //defaultValue="Nairi Limited"
                margin="normal"
                variant="filled"
                error={typeof error.church === "string"}
                helperText={error.church ? error.church : ""}
              />

              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Username"
                name="username"
                value={username}
                onChange={this.handleChange}
                //defaultValue="agata@nairidesign.co.uk"
                margin="normal"
                variant="filled"
                error={typeof error.username === "string"}
                helperText={error.username ? error.username : ""}
              />

              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  type: showPassword ? "text" : "password",
                  value: password,
                  onChange: this.handleChangePassword("password"),
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        <Icon>
                          {showPassword ? "visibility" : "visibility_off"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="filled-uncontrolled"
                label="Password"
                name="password"
                //defaultValue="password"
                margin="normal"
                variant="filled"
                error={typeof error.password === "string"}
                helperText={error.password ? error.password : ""}
              />

              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  type: showPassword ? "text" : "password",
                  value: confirm_password,
                  onChange: this.handleChangePassword("confirm_password"),
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        <Icon>
                          {showPassword ? "visibility" : "visibility_off"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="filled-uncontrolled"
                label="Repeat password"
                name="confirm_password"
                //defaultValue="password"
                margin="normal"
                variant="filled"
                error={typeof error.confirm_password === "string"}
                helperText={
                  error.confirm_password ? error.confirm_password : ""
                }
              />

              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Coupon Code"
                name="coupon_code"
                value={coupon_code}
                onChange={this.handleChange}
                margin="normal"
                variant="filled"
                error={typeof error.coupon_code === "string"}
                helperText={error.coupon_code ? error.coupon_code : ""}
              />
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={this.handleClick}
                type="submit"
                classes={{
                  root: "btn",
                  label: "label",
                  containedPrimary: "contained-primary nextbtn",
                }}
                loading={loading}
              >
                Next
              </Button>
            </form>
          </div>
        </div>
        <div className="auth-banner">
          <img src="images/auth-banner.png" alt="" />
        </div>
      </div>
    );
  }
}

export default CreateAccountTwo;
