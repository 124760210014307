import React from "react";
import {
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { ReactComponent as TableSortIcon } from "../../icons/table-sort-icon.svg";
import "./../../styles/components/more/billing.scss";

class LogsTableSelectMenu extends React.Component {
  constructor(props) {
    super(props);

    var logsTableColumn = JSON.parse(
      localStorage.getItem("billingLogsTableColumn")
    );
    if (!logsTableColumn) {
      logsTableColumn = {
        date: true,
        service: true,
        from: true,
        to: true,
        source: true,
        credits: true,
        status: true,
        message: true,
      };
    }
    this.state = {
      tableContent: null,
      logsTableColumn: logsTableColumn,
    };
  }

  handleClick = (event) => {
    this.setState({ tableContent: event.currentTarget });
  };

  handleCheck = (event) => {
    var logsTableColumn = this.state.logsTableColumn;
    logsTableColumn[event.currentTarget.value] = event.currentTarget.checked;
    this.setState({ logsTableColumn }, () => {
      this.props.columnHandler(logsTableColumn);
    });
  };

  handleCloseMenu = () => {
    this.setState({ tableContent: null });
  };

  render() {
    const { tableContent, logsTableColumn } = this.state;
    return (
      <div>
        <IconButton
          aria-controls="table-more-menu"
          aria-haspopup="true"
          onClick={this.handleClick}
        >
          <TableSortIcon className="mb-1 mr-2" />
        </IconButton>
        <Menu
          id="table-more-menu"
          anchorEl={tableContent}
          keepMounted
          open={Boolean(tableContent)}
          onClose={this.handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          getContentAnchorEl={null | undefined}
          classes={{
            list: "status-select-menu",
          }}
        >
          <MenuItem>
            <Checkbox
              value="date"
              checked={logsTableColumn.date}
              onChange={this.handleCheck}
            />
            <ListItemText className="label" primary="Date" />
          </MenuItem>
          <MenuItem>
            <Checkbox
              value="service"
              checked={logsTableColumn.service}
              onChange={this.handleCheck}
            />
            <ListItemText className="label" primary="Service" />
          </MenuItem>
          <MenuItem>
            <Checkbox
              value="from"
              checked={logsTableColumn.from}
              onChange={this.handleCheck}
            />
            <ListItemText className="label" primary="From" />
          </MenuItem>
          <MenuItem>
            <Checkbox
              value="to"
              checked={logsTableColumn.to}
              onChange={this.handleCheck}
            />
            <ListItemText className="label" primary="To" />
          </MenuItem>
          <MenuItem>
            <Checkbox
              value="source"
              checked={logsTableColumn.source}
              onChange={this.handleCheck}
            />
            <ListItemText className="label" primary="Source" />
          </MenuItem>
          <MenuItem>
            <Checkbox
              value="credits"
              checked={logsTableColumn.credits}
              onChange={this.handleCheck}
            />
            <ListItemText className="label" primary="Credits" />
          </MenuItem>
          <MenuItem>
            <Checkbox
              value="status"
              checked={logsTableColumn.status}
              onChange={this.handleCheck}
            />
            <ListItemText className="label" primary="Status" />
          </MenuItem>
          <MenuItem>
            <Checkbox
              value="message"
              checked={logsTableColumn.message}
              onChange={this.handleCheck}
            />
            <ListItemText className="label" primary="Message" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default LogsTableSelectMenu;
