import React from "react";
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  MenuItem,
  Typography,
} from "@material-ui/core";
import "../../styles/components/more/billing.scss";

import { authHeader } from "./../helpers/AuthHeader";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

class SetPrimaryMethod extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      card: props.card,
      defaultCard: props.defaultCard,
    };

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.handleClose();
  };

  handleConfirm = () => {
    this.setState({ open: false });
    this.props.handleClose();
    this.props.handleLoading(true);
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ card_id: this.state.card.id }),
    };

    return fetch(`${API_URL}switch-default-card`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.props.changeCards().then((data) => {
          this.props.handleLoading(false);
        });
      })
      .catch(console.log);
  };

  render() {
    const { open, card, defaultCard } = this.state;
    return (
      <div>
        {defaultCard && defaultCard.id !== card.id && (
          <MenuItem onClick={this.handleClickOpen}>Make default</MenuItem>
        )}
        {card && (
          <Dialog
            fullScreen={window.innerWidth < 768 && true}
            key={card.id}
            open={open}
            onClose={this.handleClose}
            aria-labelledby="form-dialog-title"
            classes={{
              root: "add-new-card set-primary",
              paper: "paper",
            }}
            BackdropProps={{
              classes: {
                root: "backdrop",
              },
            }}
          >
            <div className="header">
              <Typography component={"h2"} className="title">
                Set primary payment method
              </Typography>
              <IconButton className="close-icon" onClick={this.handleClose}>
                <Icon>close</Icon>
              </IconButton>
            </div>
            <div className="modal-content">
              <Typography className="details">
                You are selecting:{" "}
                <span className="bold-text">
                  {card.brand} ending with **** {card.last4}
                </span>{" "}
                as your primary payment method
              </Typography>
            </div>
            <div className="action-buttons">
              <Button
                color="primary"
                variant="text"
                onClick={this.handleClose}
                classes={{
                  root: "btn",
                  label: "label",
                  textPrimary: "text-primary",
                }}
              >
                <span className="forgot-pw">Cancel</span>
              </Button>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={this.handleConfirm}
                classes={{
                  root: "btn",
                  label: "label",
                  containedPrimary: "contained-primary",
                }}
              >
                Confirm
              </Button>
            </div>
          </Dialog>
        )}
      </div>
    );
  }
}

export default SetPrimaryMethod;
