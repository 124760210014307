import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  Icon,
  Fab,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import CustomSwitch from "./../../atoms/CustomSwitch";
import { authHeader } from "./../helpers/AuthHeader";
import "./../../styles/components/more/billing.scss";
import { css } from "@emotion/core";
import { addScheduleTemplate } from "../../services/api";
import ClipLoader from "react-spinners/ClipLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

var valid = require("card-validator");

class NewTemplateModal extends React.Component {
  constructor(props) {
    super(props);

    var user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      open: false,
      name: "",
      loading: false,
      error: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (prop) => (event) => {
    var value = event.target.value;
    var name = prop;
    this.setState({
      [prop]: value,
    });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSubmit = async () => {
    var errors = [];

    if (!this.state.name) {
      errors["name"] = new String("Name is required");
      this.setState({ error: errors });
      return;
    }

    this.setState({ open: false });

    await addScheduleTemplate(this.state.name).then(
      (data) => {
        console.log(data);
        if (data.success) {
          //this.props.reload();
          window.location.reload(false);
        }
      },
      (error) => {
        console.log(error);
        if (error.response) {
          this.setState({ errors: error.response.data.error });
        }
        //this.setState({ loading: false, open: false });
      }
    );
  };

  render() {
    const { name, open, loading, error } = this.state;
    return (
      <div>
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>

        <Button
          color="primary"
          variant="contained"
          type="submit"
          onClick={this.handleClickOpen}
          classes={{
            root: "btn",
            label: "label",
            containedPrimary: "contained-primary",
          }}
        >
          Create a New Schedule Template
        </Button>

        <Dialog
          fullScreen={window.innerWidth < 768 && true}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          classes={{
            root: "add-new-card",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <div className="header">
            <Typography component={"h2"} className="title">
              Add new Schedule Template
            </Typography>
            <IconButton className="close-icon" onClick={this.handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          <div className="modal-content">
            {typeof error.errorText === "string" && (
              <div
                className={"error alt alert-danger"}
                dangerouslySetInnerHTML={{ __html: error.errorText }}
              />
            )}
            <Grid container spacing={2} className="container">
              <Grid item style={{ width: "100%" }}>
                <TextField
                  id="name"
                  label="Name"
                  fullWidth
                  variant="filled"
                  value={name}
                  onChange={this.handleChange("name")}
                  error={typeof error.name === "object"}
                  helperText={error.name ? error.name : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="action-buttons">
            <Button
              color="primary"
              variant="text"
              onClick={this.handleClose}
              classes={{
                root: "btn",
                label: "label",
                textPrimary: "text-primary",
              }}
            >
              <span className="forgot-pw" style={{ color: "#7bc807" }}>
                Cancel
              </span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={this.handleSubmit}
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary",
              }}
            >
              Save
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default NewTemplateModal;
