const initialState = {
  fetch: null,
  error: null,
  people: [],
};

const peopleReducer = (state = initialState, action) => {
  switch (action.type) {
    // Fetch people
    case "PEOPLE_FETCH":
      return {
        ...state,
        fetch: true,
        error: false,
      };
    case "PEOPLE_FETCH_SUCCESS":
      return {
        ...state,
        fetch: false,
        error: false,
        people: action.payload,
      };
    case "PEOPLE_FETCH_REJECT":
      return {
        ...state,
        fetch: false,
        error: action.error,
      };

    // Skip people
    case "PEOPLE_SKIP":
      return {
        ...state,
        skip: !Array.isArray(action.id),
        skipSuccess: false,
        id: action.id,
        error: false,
        skipAll: Array.isArray(action.id),
        skipSuccess: false,
      };
    case "PEOPLE_SKIP_SUCCESS":
      return {
        ...state,
        skip: false,
        error: false,
        skipSuccess: true,
        skipAll: false,
        skipSuccess: true,
      };
    case "PEOPLE_SKIP_REJECT":
      return {
        ...state,
        skip: false,
        error: action.error,
        skipSuccess: false,
        skipAll: false,
        skipSuccess: false,
      };

    // UnSkip People
    case "PEOPLE_UNSKIP":
      return {
        ...state,
        unSkip: true,
        error: false,
        id: action.id,
        unSkipSuccess: false,
      };
    case "PEOPLE_UNSKIP_SUCCESS":
      return {
        ...state,
        unSkip: false,
        unSkipSuccess: true,
        error: false,
      };
    case "PEOPLE_UNSKIP_REJECT":
      return {
        ...state,
        unSkip: false,
        error: action.error,
        unSkipSuccess: false,
      };

    // UnSkip All People
    case "PEOPLE_UNSKIP_ALL":
      return {
        ...state,
        unSkipAll: true,
        error: false,
        unSkipAllSuccess: false,
      };
    case "PEOPLE_UNSKIP_ALL_SUCCESS":
      return {
        ...state,
        unSkipAll: false,
        unSkipAllSuccess: true,
        error: false,
      };
    case "PEOPLE_UNSKIP_ALL_REJECT":
      return {
        ...state,
        unSkipAll: false,
        error: action.error,
        unSkipAllSuccess: false,
      };

    default:
      return state;
  }
};

export default peopleReducer;
