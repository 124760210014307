import React from "react";

const samples = {
  "First.Name": "John",
  "Full.Name": "John Smith",
  Service: "Sunday Service",
  Team: "Band",
  Position: "Pianist",
  "Reminder.Date": "1 week",
  "Reminder.Date.O": " (in 1 hour)",
  "Human.Date": "Tomorrow",
  "Human.Date.S": "tomorrow",
  "Plan.Date.Time": "October 10, 2021 11:00 AM",
  "Plan.Time": "10-10-2021 11:00 AM",
  "Church.Name": "River of Life",
  Url: "https://bitly/dfsdfsf",
};

class MessageTemplatePreviewBox extends React.Component {
  constructor(props) {
    super(props);
    const { body, keywords, parts, additional, multiple } = props;

    var template = this.mergeFields(
      body,
      keywords,
      parts,
      additional,
      multiple
    );
    this.state = {
      template: template,
    };
  }

  componentWillReceiveProps(props) {
    const { body, keywords, parts, additional, multiple } = props;
    let template = this.mergeFields(
      body,
      keywords,
      parts,
      additional,
      multiple
    );
    this.setState({ template });
  }

  mergeFields(body, keywords, parts, additional, multiple) {
    let template = body;
    let prevField = "";
    console.log("PARTS", parts);
    keywords.split(",").forEach((item) => {
      if (samples[item]) {
        template = template
          .split("[" + item + "]")
          .join(
            (prevField === "Team" && item === "Position" ? "/" : "") +
              samples[item]
          );
        parts = parts
          .split("[" + item + "]")
          .join(
            (prevField === "Team" && item === "Position" ? "/" : "") +
              samples[item]
          );
      }
      prevField = item;
    });
    let replacement = "";
    if (parts || additional) {
      replacement = parts.replace("<p>", "<p>1. ");
      replacement += parts.replace("<p>", "<p>2. ");
      additional =
        additional && additional !== "<p>null</p>"
          ? additional.replace("[Bottom.Body]", replacement)
          : "";
    }

    if (multiple) {
      replacement += additional;
    } else if (additional) {
      replacement = additional;
    }

    template = template.replace("[Body]", replacement);

    return template;
  }

  render() {
    const { template } = this.state;
    return (
      <div className="preview-box-container">
        <h6>Preview:</h6>
        <div
          className="preview-box-wrapper"
          dangerouslySetInnerHTML={{ __html: template }}
        ></div>
      </div>
    );
  }
}

export default MessageTemplatePreviewBox;
