import axios from "axios";
import { authHeader } from "../../components/helpers/AuthHeader";

const API_URL = process.env.REACT_APP_API_URL;

export const fetchPeople = (search) => {
  return (dispatch) => {
    dispatch({ type: "PEOPLE_FETCH" });

    const url = API_URL + "people" + `?keyword=${search ? search : ""}`;

    return axios
      .get(url, {
        headers: authHeader(),
      })
      .then((response) => {
        dispatch({
          type: "PEOPLE_FETCH_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PEOPLE_FETCH_REJECT",
          error,
        });
      });
  };
};

export const skipPeople = (id) => {
  return (dispatch) => {
    dispatch({ type: "PEOPLE_SKIP", id });

    const url = API_URL + `people/skip/${id}`;

    return axios
      .post(
        url,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: "PEOPLE_SKIP_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PEOPLE_SKIP_REJECT",
          error,
        });
      });
  };
};

export const unSkipPeople = (id) => {
  return (dispatch) => {
    dispatch({ type: "PEOPLE_UNSKIP", id });

    const url = API_URL + `people/unskip/${id}`;

    return axios
      .post(
        url,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: "PEOPLE_UNSKIP_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PEOPLE_UNSKIP_REJECT",
          error,
        });
      });
  };
};

export const unSkipAllPeople = () => {
  return (dispatch) => {
    dispatch({ type: "PEOPLE_UNSKIP_ALL" });

    const url = API_URL + "people/unskip/all";

    return axios
      .post(
        url,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        dispatch({
          type: "PEOPLE_UNSKIP_ALL_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "PEOPLE_UNSKIP_ALL_REJECT",
          error,
        });
      });
  };
};
