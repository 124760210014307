import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Icon,
} from "@material-ui/core";
import "./../styles/components/header-for-all.scss";

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
const HeaderForAll = (props) => (
  <AppBar position="static" className="header-for-all">
    <Toolbar className="toolbar">
      {props.backView && (
        <IconButton
          component={Link}
          to={props.backView}
          edge="start"
          color="inherit"
          aria-label="Menu"
          className="icon-button"
        >
          <Icon>keyboard_arrow_left</Icon>
        </IconButton>
      )}
      <Typography className="title" component="h2">
        {props.title}
      </Typography>
      <Button
        component={Link}
        to={props.nextView}
        onClick={props.onClick}
        className="next-btn"
        color="inherit"
      >
        {props.nextLabel}
      </Button>
    </Toolbar>
    <div className={props.loading ? "sweet-loading" : ""}>
      <ClipLoader
        css={override}
        sizeUnit={"px"}
        size={150}
        color={"#7BC807"}
        loading={props.loading}
      />
    </div>
  </AppBar>
);

export default HeaderForAll;
