import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

const ButtonWithSpinner = (props) => {
  const { onClick, disabled, loading, classes, children } = props;
  return (
    <Button
      color="primary"
      variant="contained"
      type="submit"
      classes={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {loading && (
        <span
          className="spinner-border spinner-border-sm mr-1"
          role="status"
          aria-hidden="true"
        ></span>
      )}
      {children}
    </Button>
  );
};

ButtonWithSpinner.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  classes: PropTypes.object,
  children: PropTypes.node,
};

ButtonWithSpinner.defaultProps = {
  onClick: () => {},
  disabled: false,
  loading: false,
  classes: {},
  children: null,
};

export default ButtonWithSpinner;
