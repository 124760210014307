import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CouponForm from "./CouponForm";

class AddCoupon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={this.handleClickOpen}
          classes={{
            root: "btn",
            label: "label",
            containedPrimary: "contained-primary nextbtn",
          }}
        >
          Add New Coupon Code
        </Button>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="add-new-number-one-title"
          aria-describedby="add-new-number-one-description"
          classes={{
            root: "add-new-number-modal",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <DialogContent>
            <CouponForm
              refresh={this.props.refresh}
              handleClose={this.handleClose}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default AddCoupon;
