import React from "react";
import { Checkbox, Typography } from "@material-ui/core";
import RemindPeopleTab from "./RemindPeopleTab";
import HeaderServices from "./HeaderServices";
import "./../../styles/components/more/message-tabs.scss";

function ChoosePeopleToRemind() {
  const [state, setState] = React.useState({
    selectAll: false,
  });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };
  return (
    <div className="page-wrapper remind-choose-people">
      <HeaderServices
        backView="/services-templates"
        title="Send reminder"
        hasSearch
        nextView="/send-reminder"
        actionButton="ok"
      />
      <RemindPeopleTab
        selectAll={
          <div className="select-all">
            <Checkbox
              className="checkbox"
              checked={state.selectAll}
              onChange={handleChange("selectAll")}
              value="selectAll"
              inputProps={{
                "aria-label": "primary checkbox",
              }}
            />
            <Typography>Select all</Typography>
          </div>
        }
        persons={[
          {
            id: 1,
            name: "Prem Regmi",
            service: "Sunday Service",
            day: "25 June",
            when: "thisWeek",
            selected: state.selectAll,
          },
          {
            id: 2,
            name: "Paru Bhattarai",
            service: "Bible study",
            day: "6 July",
            when: "nextWeek",
            selected: state.selectAll,
          },
          {
            id: 3,
            name: "John Doe",
            service: "Bible study",
            day: "28 June",
            when: "thisWeek",
            selected: state.selectAll,
          },
          {
            id: 4,
            name: "Ed Sheeran",
            service: "Sunday worship",
            day: "2 July",
            when: "nextWeek",
            selected: state.selectAll,
          },
        ]}
      />
    </div>
  );
}
export default ChoosePeopleToRemind;
