import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Icon,
  Hidden,
  FormControl,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import BottomModal from "./../BottomModal";
import CustomDropdown from "./../CustomDropdown";
import "./../../styles/components/people/header-people.scss";

class HeaderPeople extends React.Component {
  state = {
    searching: false,
  };

  showSearch = () => {
    this.setState({
      searching: true,
    });
  };

  hideSearch = () => {
    this.setState({
      searching: false,
    });
  };

  render() {
    var bread =
      this.props.bread && this.props.bread.length > 0
        ? this.props.bread
        : false;
    return (
      <AppBar position="static" className="header-people">
        {this.state.searching ? (
          <Toolbar className="search-bar">
            <FormControl className="form-control">
              <TextField
                className="search"
                id="input-with-icon-textfield"
                placeholder="Search"
                variant="outlined"
                onChange={(e) => this.props.setSearch(e.target.value)}
                InputProps={{
                  classes: {
                    input: "input",
                    notchedOutline: "fieldset",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton className="icon-button">
                        <Icon>search</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Button className="cancel-btn" onClick={this.hideSearch}>
              Cancel
            </Button>
          </Toolbar>
        ) : (
          <Toolbar className="toolbar">
            {this.props.backView && (
              <IconButton
                component={Link}
                to={this.props.backView}
                edge="start"
                color="inherit"
                aria-label="Menu"
                className="icon-button"
              >
                <Icon>keyboard_arrow_left</Icon>
              </IconButton>
            )}
            <Typography className="title" component="h2">
              {bread &&
                this.props.title.split(" > ").map(function (t, i) {
                  if (i === bread.length || bread[i].length === 0) {
                    return t;
                  } else {
                    return (
                      <Button component={Link} to={bread[i]}>
                        {t} {">"}{" "}
                      </Button>
                    );
                  }
                })}
              {!bread && this.props.title}
            </Typography>
            {this.props.lists && (
              <div className="right-action">
                <IconButton className="icon-button" onClick={this.showSearch}>
                  <Icon>search</Icon>
                </IconButton>
                {this.props.hasReload && (
                  <IconButton
                    className="icon-button"
                    onClick={this.props.reload}
                  >
                    <Icon>autorenew</Icon>
                  </IconButton>
                )}
                <Hidden mdUp>
                  {" "}
                  <BottomModal content={this.props.bottomModalContent} />{" "}
                </Hidden>
                <Hidden smDown>
                  <CustomDropdown lists={this.props.lists} />
                </Hidden>
              </div>
            )}
          </Toolbar>
        )}
      </AppBar>
    );
  }
}

export default HeaderPeople;
