import React from "react";
import Link from "@material-ui/core/Link";

const Logo = (props) => (
  <Link href={props.href ? props.href : "/"} className={props.className}>
    <img
      src="images/logo.svg"
      className={window.innerWidth > 959 ? "" : "small-logo"}
      alt=""
    />
  </Link>
);

export default Logo;
