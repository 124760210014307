import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import {
  formatPhoneNumberIntl,
  getCountryCallingCode,
} from "react-phone-number-input";

export const formatPhoneNumber = (phoneNumberString = "", country) => {
  if (!phoneNumberString) return null;
  if (!country) {
    var user = JSON.parse(localStorage.getItem("user"));
    country = user.success.user.churches[0].country_iso;
  } else if (country === "NZ") {
    return "New Zealand (one-way)";
  }
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var cleanedFormat =
    "+" +
    (phoneNumberString.startsWith("+") ? "" : getCountryCallingCode(country)) +
    cleaned;
  return formatPhoneNumberIntl(cleanedFormat);
};

export const logout = () => {
  if (window.parent) {
    window.parent.postMessage(
      {
        event: "user-logout",
      },
      "*"
    );
  }
  localStorage.removeItem("user");
  localStorage.removeItem("impersonate");
};

export const renderHTML = (rawHTML) => (
  <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
);

export const truncateString = (str, n) => {
  return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
};

export const copyTextToClipboard = async (text) => {
  if (!text) return null;
  await navigator.clipboard.writeText(text);
};

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: 11,
    border: "1px solid #acacac",
    padding: "2px 8px",
    borderRadius: "0.25rem",
    maxWidth: 200,
  },
  arrow: {
    color: theme.palette.common.white,
    "&:before": {
      border: "1px solid #acacac",
    },
  },
}))(Tooltip);

export const isCordovaApp = () => {
  return (
    typeof cordova == "object" ||
    navigator.userAgent.toLowerCase().indexOf("cordova") > -1
  );
};

export const replaceWordsIntoHTML = (str, targetWords) => {
  let results = "";
  results = targetWords
    .map((word) => word.replace(/[/\\^$*+?.()|[\]{}]/g, "\\$&"))
    .join("|");
  const regex = new RegExp(results, "g");
  return str.replace(regex, (word) => ` <strong>${word}</strong> `);
};

export const isEmptyObject = (object = {}) => {
  if (!object) return true;
  return Object.keys(object).length === 0;
};

export const isNotAnEmptyArray = (arr) => {
  return arr && Array.isArray(arr) && Boolean(arr.length);
};

export const getEditMessageTextByPlan = (plan) => {
  const prefix = "Edit Message templates? ";
  if (plan === "Just Starting")
    return {
      text: `${prefix} (No)`,
      tooltip:
        "You would not be able to edit any Confirmation Requests or Reminder Follow-up message templates.",
    };
  if (plan === "I Mean Business")
    return {
      text: `${prefix} (Some)`,
      tooltip:
        "You would be able to edit Confirmation Requests message templates only. Can’t access the Reminder Follow-up message templates.",
    };
  if (plan === "Going Pro!")
    return {
      text: `${prefix} (All)`,
      tooltip:
        "You would be able to edit Confirmation Requests, Reminder Follow-ups, and Other (non-system) message templates.",
    };
};
