import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { Button, Icon, IconButton, Typography } from "@material-ui/core";
import Avatar from "react-avatar";
import "./../../styles/components/more/more-main.scss";

import { authHeader } from "./../helpers/AuthHeader";
import { userService } from "./../services/UserService";
import { logout } from "../../helpers/helpers";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

class MoreSubTab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneNumber: "",
      name: "",
      email: "",
      admin: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    logout();
    this.props.history.push("/login");
  }

  stopImpersonate(e) {
    e.preventDefault();
    userService.stopImpersonate();
    return;
  }
  checkProfile() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    return fetch(`${API_URL}profile`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState({
          phoneNumber: data.phone_number.number,
          name: data.user.name,
          email: data.user.email,
          admin: data.user.is_admin,
        });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.checkProfile();
  }

  render() {
    const { phoneNumber, name, email } = this.state;
    var admin = this.state.admin ? true : false;
    var navlist = [
      {
        id: 1,
        target: "/",
        icon: "calendar_today",
        label: "Schedule Templates",
      },
      {
        id: 2,
        target: "/more/message-template",
        icon: "dashboards",
        label: "Message Templates",
      },
      {
        id: 3,
        target: "/more/user-settings",
        icon: "settings",
        label: "User Settings",
      },
      //{ id: 4, target: '/more/notifications', icon: 'notifications', label: 'Notifications' },
      //{ id: 4, target: '/more/users', icon: 'people', label: 'Users' },
      { id: 5, target: "/more/number", icon: "call", label: "My Numbers" },
      {
        id: 6,
        target: "/more/billings",
        icon: "attach_money",
        label: "Billings",
      },
      {
        id: 7,
        target: "/more/integrations",
        icon: "settings",
        label: "Integrations",
      },
    ];
    if (!admin || localStorage.getItem("impersonate")) {
      navlist.splice(3, 1);
    }

    return (
      <div className="more-main desktop">
        <div className="user-data">
          <div className="personal-info">
            <Avatar name={name} email={email} className="avatar" />
            <div>
              <div className="number">
                <Typography component="h4" className="title">
                  Your number
                </Typography>
                <span className="info">{phoneNumber}</span>
              </div>
              <div className="credits">
                <Typography component="h4" className="title">
                  You have
                </Typography>
                <span className="info">0 credits</span>
              </div>
              {localStorage.getItem("impersonate") && (
                <div className="single">
                  <Button
                    className="content-single"
                    onClick={this.stopImpersonate}
                  >
                    Return to Admin
                  </Button>
                </div>
              )}
              <div className="single">
                <Button className="info" onClick={this.handleClick}>
                  Logout
                </Button>
              </div>
            </div>
          </div>
          <div className="navs">
            {navlist.map((item, idx) => (
              <NavLink key={idx} to={item.target} className="single-nav" exact>
                <IconButton className="nav-icon">
                  <Icon>{item.icon}</Icon>
                </IconButton>
                <Typography className="nav-label">{item.label}</Typography>
              </NavLink>
            ))}
          </div>
        </div>
        <Button
          className="pastorsline-logo"
          component={Link}
          to="/more/pastorsline"
        >
          <img src="images/pastorsline.svg" alt="" />
        </Button>
      </div>
    );
  }
}
export default withRouter(MoreSubTab);
