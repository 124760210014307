import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { css } from "@emotion/core";
import Moment from "react-moment";
import ClipLoader from "react-spinners/ClipLoader";
import ReactDataGrid from "react-data-grid";
import { Hidden, Link, Button } from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import { copyTextToClipboard, formatPhoneNumber } from "../../helpers/helpers";
import {
  getImpersonate,
  getUsers,
  confirmVerification,
} from "../../services/api";
import { ReactComponent as AdminIcon } from "../../icons/people-icon.svg";
import { ReactComponent as CopyClipboard } from "../../icons/copy-clipboard.svg";
import TooltipWhenOverflow from "../HelperComponents/TooltipWhenOverflow";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AddCreditsModal from "../Modals/AddCreditsModal";
import "../../styles/components/more/users.scss";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
Moment.globalFormat = "MMMM DD, YYYY"; // hh:mm A";
Moment.globalLocal = true;

const columns = [
  { key: "name", name: "Name", frozen: true },
  { key: "username", name: "Username" },
  { key: "email", name: "Email", width: 250 },
  { key: "phone", name: "Phone Number" },
  { key: "timezone", name: "Timezone" },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      rows: [],
      credits: 0,
      loading: false,
      error: {},
      snackbar: null,
      showAddCreditModal: false,
    };
  }

  componentDidMount() {
    this.getAllUsers();
  }

  handleLogin = async (id) => {
    this.setState({ loading: true });
    const user = await getImpersonate(id);
    const impersonate = JSON.parse(localStorage.getItem("user"));
    localStorage.setItem("impersonate", JSON.stringify(impersonate));
    localStorage.removeItem("user");
    localStorage.setItem("user", JSON.stringify(user));
    this.setState({ loading: false });
    //this.props.history.push("/dashboard");
    window.location.href = "/dashboard";
  };

  handleApprove = async (id) => {
    const { getChurches } = this.props;
    this.setState({ loading: true });
    const responseData = await confirmVerification(id, 1);
    if (responseData?.success) {
      getChurches();
    }
    this.getAllUsers();
  };

  handleDecline = async (id) => {
    const { getChurches } = this.props;
    this.setState({ loading: true });
    const responseBack = await confirmVerification(id, 0);
    if (responseBack?.success) {
      getChurches();
    }
    this.getAllUsers();
  };

  getAllUsers = async () => {
    this.setState({ loading: true });
    const data = await getUsers(
      this.props.selectedOrg.id,
      this.props.selectedOrg.status?.includes("Confirmed")
    );
    const currentUser = JSON.parse(localStorage.getItem("user")).success.user;
    const rows = [];
    data.users.map((row) =>
      rows.push({
        title: "",
        name: (
          <Fragment>
            <span>
              {row.name} {Boolean(row.is_admin) && <AdminIcon height={16} />}
            </span>
            <div id={"row-" + row.id + "-action"}>
              {row.id !== currentUser.id &&
              (row.status >= 3 || row.status < 1) ? (
                <Fragment>
                  <Button
                    className="align-items-start justify-content-start p-0"
                    component={Link}
                    onClick={() => this.handleLogin(row.id)}
                    style={{ color: "#a0d900", padding: 0 }}
                  >
                    Login
                  </Button>
                </Fragment>
              ) : (
                ""
              )}
              {row.id !== currentUser.id && row.status === 3 && row.bypassed ? (
                <Fragment>
                  <Button
                    className="align-items-start justify-content-start p-0"
                    component={Link}
                    onClick={() => this.handleApprove(row.id)}
                    style={{ color: "#a0d900", padding: 0 }}
                  >
                    Approve&nbsp;&nbsp;&nbsp;&nbsp;
                  </Button>
                  <Button
                    className="align-items-start justify-content-start p-0 red-btn"
                    component={Link}
                    onClick={() => this.handleDecline(row.id)}
                    style={{ color: "#a0d900", padding: 0 }}
                  >
                    Decline
                  </Button>
                </Fragment>
              ) : (
                ""
              )}
            </div>
          </Fragment>
        ),
        username: (
          <div className="d-flex justify-content-start align-items-start">
            <TooltipWhenOverflow>{row?.username} </TooltipWhenOverflow>
            <CopyClipboard
              onClick={() => this.handleCopyText(row.username)}
              height={14}
            />
          </div>
        ),
        email: (
          <div className="d-flex justify-content-start align-items-start">
            <TooltipWhenOverflow>{row?.email} </TooltipWhenOverflow>
            <CopyClipboard
              onClick={() => this.handleCopyText(row.email)}
              height={14}
            />
          </div>
        ),
        phone: (
          <div className="d-flex justify-content-start align-items-start">
            <TooltipWhenOverflow>
              {formatPhoneNumber(row?.primary_number)}{" "}
            </TooltipWhenOverflow>
            {Boolean(row.primary_number) && (
              <CopyClipboard
                onClick={() =>
                  this.handleCopyText(formatPhoneNumber(row?.primary_number))
                }
                height={14}
              />
            )}
          </div>
        ),
        timezone: row.timezone,
      })
    );
    this.setState({
      users: data.users,
      rows,
      credits: data.credits,
      loading: false,
    });
  };

  handleCopyText = async (text) => {
    await copyTextToClipboard(text);
    this.setState({ snackbar: true });
  };

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ snackbar: false });
  };

  toggleAddCreditModal = () => {
    this.setState({ showAddCreditModal: !this.state.showAddCreditModal });
  };

  render() {
    const { loading, rows, credits, snackbar, showAddCreditModal } = this.state;
    const { selectedOrg } = this.props;
    return (
      <div className="users-page">
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
        <Hidden mdUp>
          <HeaderMore
            hasReload="true"
            reload={this.getAllUsers}
            backView="/dashboard"
            title="Users"
            nextView="#"
          />
        </Hidden>
        <div className="mt-5">
          <div className="d-flex align-items-end justify-content-end flex-column total-credit">
            <h6>
              <b>Total Credits: </b>
              {credits ? credits : 0}
            </h6>
            {credits != null && (
              <h6
                onClick={this.toggleAddCreditModal}
                className="p-0 add-credit-link"
              >
                <b>+/- Credits</b>
              </h6>
            )}
          </div>
          <div className="d-flex justify-content-around align-align-items-center mb-3 w-100">
            <h6>
              <b>Org Name: </b>
              {selectedOrg.orgName}
            </h6>
            <h6>
              <b>Status: </b>
              {selectedOrg.status}
            </h6>
            <h6>
              <b>Integrations: </b>
              {selectedOrg.integrations}
            </h6>
            <h6>
              <b>Plan </b>({selectedOrg.plan})
            </h6>
            <h6>
              <b>Signup Date: </b>
              {selectedOrg.signupDate}
            </h6>
          </div>
          <div className="react-grid-users-table">
            <div className="absolute-wrapper">
              {rows.length > 0 && (
                <ReactDataGrid
                  columns={columns}
                  rowGetter={(i) => rows[i]}
                  rowsCount={rows.length}
                  enableCellSelect={false}
                  rowHeight={50}
                  minColumnWidth={140}
                />
              )}
            </div>
          </div>
        </div>
        <Snackbar
          open={snackbar}
          autoHideDuration={2000}
          onClose={this.closeSnackbar}
        >
          <Alert onClose={this.closeSnackbar} severity="success">
            Copied to the clipboard!
          </Alert>
        </Snackbar>
        {showAddCreditModal && (
          <AddCreditsModal
            showModal={showAddCreditModal}
            toggleModal={this.toggleAddCreditModal}
            totalCredits={credits}
            refreshCredits={this.getAllUsers}
            church={this.props.selectedOrg.id}
          />
        )}
      </div>
    );
  }
}

export default withRouter(Users);
