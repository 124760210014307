import React, { useCallback, useState } from "react";
import PropType from "prop-types";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import IncreaseDecreaseButton from "../IncreaseDecreaseButton/IncreaseDecreaseButton";
import { addBonusCredits } from "../../services/api";
import "../../styles/components/more/users.scss";

const AddCreditsModal = (props) => {
  const [creditCount, setCreditCount] = useState(0);
  const [comment, setComment] = useState("");
  const { toggleModal, showModal, totalCredits } = props;

  const increaseCount = useCallback(() => {
    setCreditCount(Number(creditCount) + 1);
  }, [creditCount]);

  const decreaseCount = useCallback(() => {
    setCreditCount(Number(creditCount) - 1);
  }, [creditCount]);

  const handleChange = useCallback((event) => {
    setCreditCount(event.target.value);
  }, []);

  const handleChangeComment = useCallback((event) => {
    setComment(event.target.value);
  }, []);

  const handleSubmit = async (e) => {
    await addBonusCredits(props.church, creditCount, comment);
    props.refreshCredits();
    toggleModal();
  };

  return (
    <Dialog
      open={showModal}
      maxWidth="lg"
      onClose={toggleModal}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle className="pb-0" id="form-dialog-title">
        <strong>Total Credits:</strong> {totalCredits}
      </DialogTitle>
      <DialogContent>
        <div className="mt-2">
          <IncreaseDecreaseButton
            handleChange={handleChange}
            creditCount={creditCount}
            increaseCount={increaseCount}
            decreaseCount={decreaseCount}
          />
        </div>
        <TextField
          id="standard-multiline-static"
          label="Comment"
          value={comment}
          onChange={handleChangeComment}
          className="p-0 mt-2"
          multiline
          //rows={4}
          fullWidth
        />
      </DialogContent>
      <DialogActions className="mb-2 mr-3">
        <Button onClick={toggleModal} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} className="save-btn" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCreditsModal;

AddCreditsModal.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
  totalCredits: PropType.string,
};
