import React from "react";
import { Typography, Tooltip } from "@material-ui/core";
import { ReactComponent as ConfirmationIcon } from "../icons/confirmation-icon.svg";
import { ReactComponent as ReminderIcon } from "../icons/reminder-icon.svg";
import { ReactComponent as EditIcon } from "../icons/edit-grey.svg";
import "../styles/atoms/template.scss";

const Template = (props) => {
  const { handleEditTemplate, template } = props;
  const currentUser = JSON.parse(localStorage.getItem("user")).success.user;
  const currentStatus = JSON.parse(localStorage.getItem("user")).success.status;
  const editEnabled = true;
  /*currentStatus !== "Not Active" &&
    (((currentUser.churches[0].plan_id.includes("pro") ||
      currentUser.churches[0].plan_id.includes("free")) &&
      !(
        props.type === "other" &&
        props.template.message_type !== "accepted_reply" &&
        props.template.message_type !== "declined_reply"
      )) ||
      (currentUser.churches[0].plan_id.includes("business") &&
        props.type !== "other" &&
        props.type !== "reminder"));*/
  const systemLocked =
    props.type === "other" &&
    props.template.message_type !== "accepted_reply" &&
    props.template.message_type !== "declined_reply";

  return (
    <div className="template cursor-pointer">
      <div className="title-bar">
        <Typography className="title">{props.title}</Typography>
        {editEnabled && !systemLocked ? (
          <Tooltip title="Edit template" aria-label="add">
            <EditIcon
              className="mr-2"
              onClick={() => handleEditTemplate(template)}
            />
          </Tooltip>
        ) : systemLocked ? (
          <Tooltip
            title="No access allowed. Blocked by the system"
            aria-label="add"
          >
            <img src="./images/system_lock.svg" alt="" className="lock" />
          </Tooltip>
        ) : (
          <Tooltip
            title="Your current plan doesn't allow access to this feature"
            aria-label="add"
          >
            <img src="./images/premium_lock.svg" alt="" className="lock" />
          </Tooltip>
        )}
        {props.type === "reminder" ? <ReminderIcon /> : <ConfirmationIcon />}
      </div>
      <Typography className="detail">{props.templateDetail}</Typography>
    </div>
  );
};

export default Template;
