import React from "react";
import PropTypes from "prop-types";
import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import { Select, MenuItem } from "@material-ui/core";

const CountriesSelect = ({ value, onChange, labels, ...rest }) => {
  return (
    <Select
      className="country-select"
      labelId="label"
      id="select"
      {...rest}
      value={value}
      onChange={(event) => onChange(event.target.value || undefined)}
      classes={{
        root: "select",
      }}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      }}
    >
      <MenuItem value="0">Select Country Code</MenuItem>
      {getCountries().map((country, idx) => (
        <MenuItem key={idx} value={country}>
          {labels[country]} +{getCountryCallingCode(country)}
        </MenuItem>
      ))}
    </Select>
  );
};

CountriesSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default CountriesSelect;
