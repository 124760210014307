import React, { Component } from "react";
import lodash from "lodash";
import HeaderMore from "../components/more/HeaderMore";
import PagesSubMenu from "../components/more/PagesSubMenu";
import LogsListPage from "../components/more/LogsListPage";
import { getAllLogs } from "../services/api";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import { connect } from "react-redux";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class Logs extends Component {
  state = {
    logs: [],
    totalLogs: [],
    loadMoreLoading: false,
    loading: false,
  };

  componentDidMount() {
    this.loadLogs();
  }

  loadLogs = async () => {
    const params = `limit=20&page=1`;
    this.setState({ loading: true });
    const logsData = await getAllLogs(params);
    if (!lodash.isEmpty(logsData)) {
      this.setState({
        logs: logsData.logs,
        totalLogs: logsData.logTotal,
        loading: false,
      });
    }
  };

  loadMoreLogs = async (params) => {
    const { logs } = this.state;
    this.setState({ loadMoreLoading: true });
    const logsData = await getAllLogs(params);
    if (!lodash.isEmpty(logsData)) {
      this.setState({
        logs: [...logs, ...logsData.logs],
        totalLogs: logsData.logTotal,
        loadMoreLoading: false,
      });
    }
  };

  toggleLoading = (val) => {
    this.setState({ loading: val });
  };

  render() {
    const { logs, totalLogs, loadMoreLoading, loading } = this.state;
    const { currentUserInfo } = this.props;
    return (
      <div className="page-wrapper billing-page">
        <PagesSubMenu />
        <HeaderMore backView="/dashboard" title="Logs" />
        <div className="inner-content-wrapper w-100 logs-container">
          <h3 className="p-0 logs-heading">Logs</h3>
          <LogsListPage
            logs={logs}
            loadMoreLogs={this.loadMoreLogs}
            totalLogs={totalLogs}
            currentLogsCount={logs?.length}
            loadMoreLoading={loadMoreLoading}
            isMainLogPage={true}
            simpleLoading={loading}
            timezone={currentUserInfo?.timezone}
          />
        </div>
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    currentUserInfo: store.userProfileReducer.user,
  };
};

export default connect(mapStateToProps)(Logs);
