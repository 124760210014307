import React from "react";
import queryString from "query-string";
import { Button, Hidden, Typography, TextField } from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import { updatePricing } from "../../services/api";
import "../../styles/components/more/schedule-templates.scss";
import CouponForm from "./CouponForm";
import { authHeader } from "./../helpers/AuthHeader";

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

const API_URL = process.env.REACT_APP_API_URL;

class EditCoupon extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    this.state = {
      id: values.id ? values.id : null,
      code: null,
      type: "uses",
      total_uses: 0,
      expiry_date: null,
      credits: 0,
      trial_days: 0,
      loading: false,
    };

    this.getCoupon = this.getCoupon.bind(this);
  }

  componentDidMount() {
    this.getCoupon();
  }

  getCoupon() {
    this.setState({ loading: true });

    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ id: this.state.id }),
    };
    return fetch(`${API_URL}get-coupon`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState({
          code: data.coupon ? data.coupon.code : "",
          type: data.coupon ? data.coupon.type : "uses",
          total_uses: data.coupon ? data.coupon.total_uses : 0,
          expiry_date: data.coupon ? data.coupon.expiry_date : null,
          credits: data.coupon ? data.coupon.credits : 0,
          trial_days: data.coupon ? data.coupon.trial_days : 0,
          loading: false,
        });
      })
      .catch(console.log);
  }

  render() {
    const {
      id,
      code,
      type,
      total_uses,
      expiry_date,
      credits,
      trial_days,
      loading,
    } = this.state;

    return (
      <div className="edit-schedule page-wrapper my-profile">
        <PagesSubMenu />
        <Hidden mdUp>
          <HeaderMore
            backView="/coupons"
            title="Edit Coupon"
            nextView="/users"
          />
        </Hidden>
        <div
          className={
            window.innerWidth > 959 ? "desktop-design" : "inner-content-wrapper"
          }
        >
          <div className={loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={loading}
            />
          </div>
          <Hidden smDown>
            <Typography className="desktop-title" component={"h2"}>
              Edit Coupon
            </Typography>
          </Hidden>
          <CouponForm
            id={id}
            code={code}
            type={type}
            total_uses={total_uses}
            expiry_date={expiry_date}
            credits={credits}
            trial_days={trial_days}
          />
        </div>
      </div>
    );
  }
}

export default EditCoupon;
