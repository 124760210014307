import React from "react";
import { Icon, Typography } from "@material-ui/core";
import "./../../styles/components/responsive-design.scss";

export default function AccountSyncMessage(props) {
  return (
    <React.Fragment>
      {props.isSyncing && (
        <div
          className={
            props.auto
              ? "account-syncing-message"
              : "account-syncing-message info"
          }
        >
          {props.auto ? (
            <Typography className="message">
              Please wait , Your account is syncing now!
            </Typography>
          ) : (
            <Typography className="message">
              Due to Planning Center API rate limiting, any new changes would
              automatically sync every hour and can't be manually updated at
              this time.
            </Typography>
          )}
          <Icon className="close-icon" onClick={props.hideSync}>
            close
          </Icon>
        </div>
      )}
    </React.Fragment>
  );
}
