import React from "react";
import { Grid, Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import "./../styles/components/suggested-numbers.scss";

class SuggestedNumbers extends React.Component {
  render() {
    return (
      <RadioGroup
        aria-label="numbers"
        name="phone_number"
        className="numbers-wrapper"
        value={this.props.phone_number}
        onChange={this.props.handleChange}
      >
        <Grid container spacing={2}>
          {this.props.numbers.map((item, idx) => (
            <Grid item xs={6} key={idx}>
              <FormControlLabel
                value={item.value}
                label={item.label}
                control={
                  <Radio
                    color="primary"
                    checkedIcon=""
                    icon=""
                    classes={{
                      root: "radio",
                      checked: "checked",
                    }}
                  />
                }
                // eslint-disable-next-line react/jsx-no-duplicate-props
                label={
                  <span>
                    {item.number}
                    <span className="label">{item.label}</span>
                  </span>
                }
                labelPlacement="start"
                classes={{
                  root: "number-btn",
                  label: "number",
                }}
              />
            </Grid>
          ))}
        </Grid>
      </RadioGroup>
    );
  }
}

export default SuggestedNumbers;
