import React from "react";
import { Hidden, Icon, Typography } from "@material-ui/core";
import "../../styles/components/services/log-list.scss";

import Moment from "react-moment";
import { formatPhoneNumber } from "../../helpers/helpers";
Moment.globalFormat = "MMMM DD, YYYY hh:mm A";
Moment.globalLocal = true;

class LogList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logs: props.logs,
    };
  }
  render() {
    return (
      <div className="logs-wrapper">
        {this.props.logs.map((log, idx) => (
          <div
            className={
              log.type === "clicked"
                ? "single-service clicked"
                : "single-service"
            }
            key={idx}
          >
            <div className="left-content">
              {log.type === "clicked" && (
                <div className="icon-div">
                  <Icon>mouse</Icon>
                  {log.created_at ? (
                    "Clicked"
                  ) : (
                    <img
                      class="material-icons MuiIcon-root"
                      src="images/pco.png"
                      alt="favicon"
                    />
                  )}
                  <Typography className="detail">From: PCO</Typography>
                </div>
              )}
              {log.type === "out" && (
                <div className="icon-div">
                  <Icon>chat</Icon>
                  <Icon>call_made</Icon>
                  {log.to && log.from && (
                    <Typography className="detail">
                      To: {formatPhoneNumber(log?.to)},
                      <Hidden smUp>
                        <br />
                      </Hidden>{" "}
                      From: {formatPhoneNumber(log?.from)}
                    </Typography>
                  )}
                </div>
              )}
              {log.type === "in" && (
                <div className="icon-div">
                  <Icon>chat</Icon>
                  <Icon>call_received</Icon>
                  {log.to && log.from && (
                    <Typography className="detail">
                      From: {formatPhoneNumber(log?.from)},
                      <Hidden smUp>
                        <br />
                      </Hidden>{" "}
                      To: {formatPhoneNumber(log?.to)}
                    </Typography>
                  )}
                </div>
              )}
              <Typography>
                <pre>{log.message}</pre>
              </Typography>
              {log.error_message && (
                <Typography className="text-danger">
                  {log.error_message}
                </Typography>
              )}
            </div>
            <div className="right-content">
              {log.created_at && (
                <Typography>
                  <Moment>{log.created_at}</Moment>
                </Typography>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
export default LogList;
