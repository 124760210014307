import React from "react";
import { Link } from "react-router-dom";
import Swipeout from "rc-swipeout";
import { Icon, Typography } from "@material-ui/core";
import "rc-swipeout/assets/index.css";
import { ReactComponent as HelpIcon } from "../../icons/help-icon.svg";
import "./../../styles/components/services/service-list.scss";

const PeopleList = (props) => (
  <div className="services-wrapper">
    {props.persons.map((person, idx) => (
      <Swipeout
        right={[
          {
            text: (
              <div>
                {person.status === "active" && ""}
                {person.status === "absence" && "Remind now"}
                {person.status === "inactive" && (
                  <span>
                    Max number <br /> of reminders <br /> sent
                  </span>
                )}
              </div>
            ),
            onPress: () => console.log("delete"),
            className: person.status,
          },
        ]}
        onOpen={() => console.log("open")}
        onClose={() => console.log("close")}
        key={idx}
      >
        <Link to={person.target} className="single-service">
          <div className="left-content">
            <Typography>{person.name}</Typography>
          </div>
          <div className="right-content">
            {person.status === "active" && (
              <Icon className="icon tick-icon">done</Icon>
            )}
            {person.status === "absence" && (
              <Icon className="icon help-icon">
                <HelpIcon />
              </Icon>
            )}
            {person.status === "inactive" && (
              <Icon className="icon cross-icon">clear</Icon>
            )}
          </div>
        </Link>
      </Swipeout>
    ))}
  </div>
);

export default PeopleList;
