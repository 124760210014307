import React from "react";
import { css } from "@emotion/core";
import queryString from "query-string";
import ClipLoader from "react-spinners/ClipLoader";
import HeaderPeople from "./HeaderPeople";
import LogList from "./LogList";

import { authHeader } from "./../helpers/AuthHeader";
import "../../styles/components/people/people-list.scss";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class Log extends React.Component {
  constructor(props) {
    super(props);

    const values = queryString.parse(this.props.location.search);

    this.state = {
      type: values.type ? values.type : "",
      plan: values.plan ? values.plan : "",
      planName: values.planName ? values.planName : "",
      serviceId: values.service ? values.service : "",
      serviceName: values.serviceName ? values.serviceName : "",
      backViewLink:
        "/weekly-worship?service=" +
        (values.service ? values.service : 0) +
        "&serviceName=" +
        values.serviceName +
        (values.type ? "&type=" + values.type : ""),
      logList: [],
      loading: false,
    };
  }

  refreshLog() {
    const { serviceId, serviceName, plan, type } = this.state;
    var _this = this;
    // cache.getItem('logPeople' + serviceId + plan + type, function (err, data) {
    // 	if (data) {
    // 		_this.setState({ logList: data });
    // 	} else {
    _this.setState({ loading: true });
    fetch(
      API_URL +
        "logs-people/" +
        plan +
        "/" +
        type +
        "?serviceId=" +
        serviceId +
        "&serviceName=" +
        serviceName,
      {
        headers: authHeader(),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        _this.setState({ logList: data, loading: false });
        // cache.setItem(
        // 	'logPeople' + serviceId + plan + type,
        // 	data,
        // 	function (err) {}
        // );
      })
      .catch(console.log);
    // 	}
    // });
  }

  componentDidMount() {
    this.refreshLog();
  }

  render() {
    var header = "Services";
    switch (this.state.type) {
      case "C":
        header = "Accepted " + header;
        break;
      case "F":
        header = "Failed " + header;
        break;
      case "A":
        header = "Sent " + header;
        break;
      case "U":
        header = "Unconfirmed " + header;
        break;
      default:
        header = "Services";
    }
    header = this.state.planName;
    return (
      <div>
        <div className="page-wrapper">
          <div className={this.state.loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={this.state.loading}
            />
          </div>
          <HeaderPeople
            title={header}
            backView={this.state.backViewLink}
            bread={[
              "/dashboard",
              this.state.type
                ? "/services?type=" + this.state.type
                : "/dashboard?value=1",
              this.state.backViewLink,
            ]}
          />
          <LogList logs={this.state.logList} />
        </div>
      </div>
    );
  }
}

export default Log;
