import React from "react";
import { Link } from "react-router-dom";
import {
  Typography,
  Button,
  Icon,
  List,
  ListItem,
  ListItemText,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableContainer,
  TableBody,
  Paper,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import BottomModal from "./../BottomModal";
import { authHeader } from "./../helpers/AuthHeader";
import "../../styles/components/more/schedule-templates.scss";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;
class Pricing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countryCredits: [],
      loading: false,
      dropdownMenu: null,
    };
  }

  refresh() {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({ loading: true });
    return fetch(`${API_URL}country-credits`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.setState({ countryCredits: data.country_credits, loading: false });
      })
      .catch(console.log);
  }

  componentDidMount() {
    this.refresh();
  }
  handleClickMenu = (event) => {
    var idArray = event.currentTarget.id.split("_");
    var id = idArray[0];
    var credits = idArray[1];
    var country = idArray[2];
    var dropdownMenu = (
      <Menu
        id="simple-menu"
        anchorEl={event.currentTarget}
        keepMounted
        open={Boolean(event.currentTarget)}
        onClose={this.handleClose}
        classes={{
          paper: "dropdown-more",
        }}
      >
        <MenuItem
          component={Link}
          to={
            "/edit-pricing?id=" +
            id +
            "&amount=" +
            credits +
            "&country=" +
            country
          }
        >
          Edit
        </MenuItem>
      </Menu>
    );
    this.setState({ dropdownMenu: dropdownMenu });
  };
  handleClose = () => {
    this.setState({ dropdownMenu: null });
  };
  render() {
    const { countryCredits, dropdownMenu } = this.state;
    return (
      <div className="page-wrapper schedule-templates" style={{ flex: 1 }}>
        <PagesSubMenu />
        {/*<div className={loading ? "sweet-loading" : ""}>
  		<ClipLoader
  		  css={override}
  		  sizeUnit={"px"}
  		  size={150}
  		  color={'#7BC807'}
  		  loading={loading}
  		/>
  	  <div>*/}
        <HeaderMore backView="/dashboard" title="Pricing" />
        <div className="inner-content-wrapper mobile-view">
          <List
            component="nav"
            aria-label="Secondary mailbox folders"
            className="schedule-list"
          >
            {countryCredits.map((countryCredit, index) => {
              return (
                <ListItem button className="single-listitem" key={index}>
                  <ListItemText
                    className="label"
                    primary={countryCredit.country_iso}
                  />
                  <div className="right-content">
                    <span className="number active">
                      {countryCredit.credits}
                    </span>
                    <BottomModal
                      content={
                        <div>
                          <Button
                            className="content-single"
                            component={Link}
                            to={"/edit-pricing?id=" + countryCredit.id}
                          >
                            Edit
                          </Button>
                        </div>
                      }
                    />
                  </div>
                </ListItem>
              );
            })}
          </List>
        </div>

        <div className="desktop-view">
          <div className="title-section">
            <Typography className="desktop-title" component={"h2"}>
              Pricing
            </Typography>
            {/*<Button
              color='primary'
              variant='contained'
              component={Link}
              to='/new-template'
              classes={{
              root: 'btn',
              label: 'label',
              containedPrimary: 'contained-primary'
              }}
            >Add new template</Button>*/}
          </div>
          <TableContainer
            component={Paper}
            className="schedule-templates-table"
          >
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow className="table-head">
                  <TableCell>Country</TableCell>
                  <TableCell>Credits/texts</TableCell>
                  {/*<TableCell>Costs/Phone</TableCell>*/}
                  <TableCell align="right" />
                </TableRow>
              </TableHead>
              <TableBody className="table-body">
                {countryCredits.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell scope="row">{row.country_iso}</TableCell>
                    <TableCell>
                      <span className="number active">{row.credits}/160</span>
                    </TableCell>
                    {/*<TableCell>
                      <span className="default"></span>
                    </TableCell>*/}
                    <TableCell align="right">
                      <div>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          id={
                            row.id + "_" + row.credits + "_" + row.country_iso
                          }
                          onClick={this.handleClickMenu}
                        >
                          <Icon>more_vert</Icon>
                        </IconButton>
                        {dropdownMenu}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {/*<Fab color="primary" aria-label="Add" className="fab disabled" component={Link} to="/new-pricing"><Icon>add</Icon></Fab>*/}
      </div>
    );
  }
}
export default Pricing;
