import userProfileAction from "../actions/userProfileAction";
import { checkProfile } from "../../services/api";
import { globalLoading } from "../actions/globalAction";

export const userProfileMiddeware = () => async (dispatch) => {
  try {
    dispatch(globalLoading(true));
    const data = await checkProfile();
    if (data?.user) {
      dispatch(userProfileAction.getUserProfileDataSuccess(data));
      dispatch(globalLoading(false));
    }
  } catch (err) {
    dispatch(globalLoading(false));
    dispatch(
      userProfileAction.getUserProfileDataFail({
        message: "Server Error please try again",
        err: err.message,
      })
    );
  }
};
