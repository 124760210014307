import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { userService } from "./../services/UserService";

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;

class CouponForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      code: this.props.code ? this.props.code : null,
      type: this.props.type ? this.props.type : "uses",
      total_uses: this.props.total_uses ? this.props.total_uses : 0,
      expiry_date: this.props.expiry_date ? this.props.expiry_date : null,
      credits: this.props.credits ? this.props.credits : 0,
      trial_days: this.props.trial_days ? this.props.trial_days : 0,
      error: {},
      loading: false,
      id: this.props.id ? this.props.id : null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      code: props.code ? props.code : null,
      type: props.type ? props.type : "uses",
      total_uses: props.total_uses ? props.total_uses : 0,
      expiry_date: props.expiry_date ? props.expiry_date : null,
      credits: props.credits ? props.credits : 0,
      trial_days: props.trial_days ? props.trial_days : 0,
      id: props.id ? props.id : null,
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange(e) {
    if (e.target) {
      if (e.target.name == "uses" || e.target.name == "no_expiry") {
        this.setState({ expiry_date: null });
      } else if (e.target.name == "expiry") {
        this.setState({ total_uses: 0 });
      }

      this.setState({ [`${e.target.name}`]: e.target.value });
    } else {
      this.setState({ expiry_date: e });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const { id, code, type, total_uses, expiry_date, credits, trial_days } =
      this.state;

    // stop here if form is invalid
    if (!code) {
      this.setState({ error: { code: "code is required" } });
      return;
    }

    var typeReal = type;
    if (type == "uses" && total_uses <= 0) {
      this.setState({
        error: { total_uses: "Total Number of Uses field is required" },
      });
      return;
    } else if (type == "expiry" && expiry_date == null) {
      this.setState({
        error: { expiry_date: "Expiry Date field is required" },
      });
      return;
    } else if (type == "no_expiry") {
      typeReal = "expiry";
    }

    if (trial_days == 0 && credits == 0) {
      this.setState({
        error: { trial_days: "Trial Days or Credits field is required" },
      });
      return;
    }

    this.setState({ loading: true });

    userService
      .saveCoupon(
        code,
        typeReal,
        total_uses,
        expiry_date,
        credits,
        trial_days,
        this.props.id
      )
      .then(
        (data) => {
          this.setState({ loading: false });
          if (!this.props.id) {
            this.props.handleClose();
            this.props.refresh();
          } else {
            //location.href = "/coupons";
            this.props.history.push({
              pathname: `/coupons`,
            });
          }
        },
        (error) => {
          var errorJson = {};
          error.then(function (e) {
            var errors = JSON.parse(e).error;
            console.log(errors);
            for (const key in errors) {
              errorJson[key] = errors[key][0];
            }
          });
          this.setState({ loading: false, error: errorJson });
          this.props.refresh();
          return;
        }
      );
  }

  render() {
    const {
      error,
      loading,
      code,
      type,
      total_uses,
      expiry_date,
      credits,
      trial_days,
    } = this.state;
    return (
      <div className="content">
        <div className={this.props.id ? "" : "choose-number-wrapper"}>
          <div className={loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={loading}
            />
          </div>
          <Typography
            className="main-title"
            component={"h3"}
            style={{ marginLeft: 0, width: "100%", textAlign: "center" }}
          >
            {!this.props.id ? "New Coupon Code" : ""}
          </Typography>
          <form name="form">
            {error.hasOwnProperty("errorText") && error.errorText !== "" && (
              <div
                className={"alert alert-danger"}
                dangerouslySetInnerHTML={{ __html: error.errorText }}
              />
            )}

            <Box align="right" className="right">
              <div className="content">
                <Typography
                  component="h6"
                  className="mt-2 ml-4"
                  style={{ fontSize: 14 }}
                >
                  <TextField
                    id="code"
                    name="code"
                    label="Code"
                    fullWidth
                    variant="filled"
                    disabled={this.props.id}
                    value={code}
                    onChange={this.handleChange}
                    error={typeof error.code === "string"}
                    helperText={error.code ? error.code : ""}
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                      style: {
                        transform: "translate(12px, 10px) scale(0.75)",
                      },
                    }}
                    type="text"
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                  />

                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="type"
                    name="type"
                    value={type}
                    onChange={this.handleChange}
                    error={typeof error.type === "string"}
                    helperText={error.type ? error.type : ""}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      paddingTop: "15px",
                    }}
                  >
                    <MenuItem key={0} value="uses">
                      Expire By Total Number
                    </MenuItem>
                    <MenuItem key={1} value="expiry">
                      Expire By Date
                    </MenuItem>
                    <MenuItem key={2} value="no_expiry">
                      Never Expire
                    </MenuItem>
                  </Select>

                  {type == "uses" && (
                    <TextField
                      id="total_uses"
                      name="total_uses"
                      label="Total Number of Uses"
                      fullWidth
                      variant="filled"
                      value={total_uses}
                      onChange={this.handleChange}
                      error={typeof error.total_uses === "string"}
                      helperText={error.total_uses ? error.total_uses : ""}
                      classes={{
                        root: "text-field",
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "label",
                        },
                      }}
                      type="number"
                      InputProps={{
                        classes: {
                          root: "input-wrapper",
                          input: "input",
                        },
                      }}
                    />
                  )}
                  {type == "expiry" && (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="date-picker"
                        disableToolbar
                        variant="inline"
                        invalidDateMessage=""
                        margin="normal"
                        id="expiry_date"
                        name="expiry_date"
                        label="Expiry Date"
                        format="MM/dd/yyyy"
                        value={expiry_date}
                        onChange={this.handleChange}
                        error={typeof error.expiry_date === "string"}
                        helperText={error.expiry_date ? error.expiry_date : ""}
                        style={{ width: "100%", textAlign: "left" }}
                      />
                    </MuiPickersUtilsProvider>
                  )}

                  <TextField
                    id="credits"
                    name="credits"
                    label="Credits"
                    fullWidth
                    variant="filled"
                    value={credits}
                    onChange={this.handleChange}
                    error={typeof error.credits === "string"}
                    helperText={error.credits ? error.credits : ""}
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    type="number"
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                  />

                  <TextField
                    id="trial_days"
                    name="trial_days"
                    label="Trial Days"
                    fullWidth
                    variant="filled"
                    value={trial_days}
                    onChange={this.handleChange}
                    error={typeof error.trial_days === "string"}
                    helperText={error.trial_days ? error.trial_days : ""}
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    type="number"
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                  />
                </Typography>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={this.handleSubmit}
                  classes={{
                    root: "btn",
                    label: "label",
                    containedPrimary: "contained-primary nextbtn",
                  }}
                >
                  Submit
                </Button>
              </div>
            </Box>
          </form>
        </div>
      </div>
    );
  }
}

export default CouponForm;
