import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Icon,
  FormControl,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import "./../../styles/components/more/header-more.scss";

class HeaderMore extends React.Component {
  state = {
    searching: false,
  };

  showSearch = () => {
    this.setState({
      searching: true,
    });
  };

  hideSearch = () => {
    this.setState({
      searching: false,
    });
  };

  render() {
    var bread =
      this.props.bread && this.props.bread.length > 0
        ? this.props.bread
        : false;
    return (
      <AppBar position="static" className="header-more">
        {this.state.searching ? (
          <Toolbar className="search-bar">
            <FormControl className="form-control">
              <TextField
                className="search"
                id="input-with-icon-textfield"
                placeholder="Search"
                variant="outlined"
                InputProps={{
                  classes: {
                    input: "input",
                    notchedOutline: "fieldset",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton className="icon-button">
                        <Icon>search</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Button className="cancel-btn" onClick={this.hideSearch}>
              Cancel
            </Button>
          </Toolbar>
        ) : (
          <Toolbar className="toolbar">
            {this.props.backView && (
              <IconButton
                component={Link}
                to={this.props.backView}
                edge="start"
                color="inherit"
                aria-label="Menu"
                className="icon-button"
              >
                <Icon>keyboard_arrow_left</Icon>
              </IconButton>
            )}
            <Typography className="title" component="h2">
              {bread &&
                this.props.title.split(" > ").map(function (t, i) {
                  if (i === bread.length) {
                    return t;
                  } else {
                    return (
                      <Button component={Link} to={bread[i]}>
                        {t} >{" "}
                      </Button>
                    );
                  }
                })}
              {!bread && this.props.title}
              <span style={{ fontSize: 12 }}> {this.props.subTitle}</span>
            </Typography>
            <div className="right-action">
              {this.props.hasSearch && (
                <IconButton className="icon-button" onClick={this.showSearch}>
                  <Icon>search</Icon>
                </IconButton>
              )}
              {this.props.actionButton && (
                <Button
                  className="action-button"
                  onClick={this.props.handleAction}
                  component={Link}
                  to={this.props.nextView}
                >
                  {this.props.actionButton}
                </Button>
              )}
              {this.props.hasReload && (
                <IconButton className="icon-button" onClick={this.props.reload}>
                  <Icon>autorenew</Icon>
                </IconButton>
              )}
            </div>
          </Toolbar>
        )}
      </AppBar>
    );
  }
}

export default HeaderMore;
