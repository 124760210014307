import React from "react";
import PropType from "prop-types";
import "../../styles/components/increaseDecreaseBtn.scss";

const IncreaseDecreaseButton = ({
  creditCount,
  increaseCount,
  decreaseCount,
  handleChange,
}) => {
  return (
    <div className="flexer">
      <div
        onClick={decreaseCount}
        className="value-button flexer"
        id="decrease"
      >
        -
      </div>
      <input
        className="credit-input"
        type="number"
        id="number"
        value={creditCount}
        onChange={handleChange}
      />
      <div
        onClick={increaseCount}
        className="value-button flexer"
        id="increase"
      >
        +
      </div>
    </div>
  );
};

export default IncreaseDecreaseButton;

IncreaseDecreaseButton.propTypes = {
  creditCount: PropType.any,
  increaseCount: PropType.func,
  decreaseCount: PropType.func,
  handleChange: PropType.func,
};
