import { userService } from "../../components/services/UserService";
import { assignTemplate, getSchedulingServices } from "../../services/api";
import { ACTION_TYPES } from "../actions/types";

export const getSchedulingServicesMiddleware = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ACTION_TYPES.GET_SCHEDULING_SERVICES,
      folderId: id,
    });
    const data = await getSchedulingServices(id);
    let appendData = [];
    if (data && data?.length > 0) {
      appendData = data.map((folder) => {
        folder.checked = true;
        return folder;
      });
    }
    dispatch({
      type: ACTION_TYPES.GET_SCHEDULING_SERVICES_SUCCESS,
      folderId: id,
      data: appendData,
    });
  } catch (err) {
    if (err?.response?.status === 401) {
      userService.refreshToken().then(() => {
        dispatch(getSchedulingServicesMiddleware(id));
      });
    } else {
      dispatch({
        type: ACTION_TYPES.GET_SCHEDULING_SERVICES_ERROR,
        errorMessage: err?.message,
        folderId: id,
      });
    }
  }
};

export const assignScheduleTemplateToFoldersOrServices =
  (id, serviceIds, folderIds) => async (dispatch) => {
    try {
      dispatch({
        type: ACTION_TYPES.ASSIGN_TEMPLATE,
        id,
      });
      const data = await assignTemplate(id, serviceIds, folderIds);
      dispatch({
        type: ACTION_TYPES.ASSIGN_TEMPLATE_SUCCESS,
        id,
        data,
      });
    } catch (err) {
      dispatch({
        type: ACTION_TYPES.ASSIGN_TEMPLATE_ERROR,
        errorMessage: err?.message,
        id,
      });
    }
  };

export const setSchedulingServiceData = (payload) => {
  return {
    type: ACTION_TYPES.SET_SCHEDULING_SERVICES_DATA,
    payload,
  };
};
