import React, { Fragment } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import LoginPage from "./views/LoginPage";
import ForgotPage from "./views/ForgotPage";
import ResetPage from "./views/ResetPage";
import HomePage from "./views/HomePage";
import CreateAccountOne from "./views/CreateAccountOne";
import VerifyEmail from "./views/VerifyEmail";
import Verification from "./views/Verification";
import CreateAccountTwo from "./views/CreateAccountTwo";
import CreateAccountThree from "./views/CreateAccountThree";
import Activate from "./views/Activate";
import Layout from "./views/Layout";
import DashboardMain from "./components/dashboard/DashboardMain";
import AfterAuthStepOne from "./views/AfterAuthStepOne";
import AfterAuthStepTwo from "./views/AfterAuthStepTwo";
import WeeklyWorship from "./components/services/WeeklyWorship";
import DailyWorship from "./components/services/DailyWorship";
import Services from "./components/services/Services";
import ServicesTemplates from "./components/services/ServicesTemplates";
import ChoosePeopleToRemind from "./components/services/ChoosePeopleToRemind";
import SendReminder from "./components/services/SendReminder";
import Schedules from "./components/services/Schedules";
import PeopleService from "./components/people/PeopleService";
import ServiceStatus from "./components/services/ServiceStatus";
import WeeklyService from "./components/people/WeeklyService";
import LogPeople from "./components/people/Log";
import Log from "./components/services/Log";
import ScheduleTemplates from "./components/more/ScheduleTemplates";
import Keywords from "./components/more/Keywords";
import EditSchedule from "./components/more/EditSchedule";
import EditKeyword from "./components/more/EditKeyword";
import CreateSchedule from "./components/more/CreateSchedule";
import AddConfirmationTime from "./components/more/AddConfirmationTime";
import AddConfirmationText from "./components/more/AddConfirmationText";
import MessageTemplates from "./components/more/MessageTemplates";
import NewTemplate from "./components/more/NewTemplate";
import Notifications from "./components/more/Notifications";
import Number from "./components/more/Number";
import UserSettings from "./components/more/UserSettings";
import Billing from "./components/more/Billing";
import Integrations from "./components/more/Integrations";
import Pastorsline from "./components/more/Pastorsline";
import ConfirmationSummary from "./components/more/ConfirmationSummary";
import AddFollowUp from "./components/more/AddFollowUp";
import PlanningCenter from "./components/dashboard/PlanningCenter";
import Bitly from "./components/more/Bitly";
import More from "./components/more/More";
import PrivateRoute from "./PrivateRoute";
import Organizations from "./views/Organizations";
import { connect } from "react-redux";
import { userProfileMiddeware } from "./store/middlewares/userProfileMiddleware";
import NotFoundPage from "./views/NotFoundPage";
import Pricing from "./components/more/Pricing";
import Coupons from "./components/more/Coupons";
import EditPricing from "./components/more/EditPricing";
import EditCoupon from "./components/more/EditCoupon";
import { isCordovaApp } from "./helpers/helpers";
import Logs from "./views/Logs";
import RenderIntercom from "./components/RenderIntercom";
import People from "./components/people/People";

// const NotFound = () => (
//   <div className="not-found-error">
//     <h3>Page not found</h3>
//   </div>
// );

const ResponsiveDashboard = () => (
  <React.Fragment>
    <Hidden mdUp>
      <Layout />
    </Hidden>
    <Hidden smDown>
      <DashboardMain />
    </Hidden>
  </React.Fragment>
);

class Routes extends React.Component {
  componentDidMount() {
    const { getUserProfileData } = this.props;
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      getUserProfileData();
    }
    if (isCordovaApp()) {
      window.addEventListener("message", this.handleCardovaAuthentication);
    }
  }

  componentWillUnmount() {
    if (isCordovaApp()) {
      window.removeEventListener("message", this.handleCardovaAuthentication);
    }
  }

  handleCardovaAuthentication = (event) => {
    const userData = JSON.parse(localStorage.getItem("user"));
    if (event.data.event === "auth" && !userData) {
      localStorage.setItem("user", JSON.stringify(event.data.data));
      window.location.href = "/dashboard";
    }
  };

  render() {
    return (
      <Fragment>
        <RenderIntercom />
        <BrowserRouter basename="/">
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/login" component={LoginPage} exact />
            <Route path="/forgot" component={ForgotPage} exact />
            <Route path="/reset" component={ResetPage} exact />
            <Route path="/signup" component={CreateAccountOne} exact />
            <Route path="/verify-email" component={VerifyEmail} exact />
            <Route path="/verification" component={Verification} exact />
            <Route path="/signup-details" component={CreateAccountTwo} exact />
            <Route path="/signup-number" component={CreateAccountThree} exact />
            <PrivateRoute
              path="/authorization"
              component={PlanningCenter}
              exact
            />
            <PrivateRoute
              path="/after-auth-one"
              component={AfterAuthStepOne}
              exact
            />
            <PrivateRoute
              path="/after-auth-two"
              component={AfterAuthStepTwo}
              exact
            />
            <PrivateRoute
              path="/dashboard"
              component={ResponsiveDashboard}
              exact
            />
            <PrivateRoute path="/services-all" component={Services} exact />
            <PrivateRoute
              path="/weekly-worship"
              component={WeeklyWorship}
              exact
            />
            <PrivateRoute
              path="/daily-worship"
              component={DailyWorship}
              exact
            />
            <PrivateRoute
              path="/personal-service"
              component={PeopleService}
              exact
            />
            <PrivateRoute
              path="/weekly-service"
              component={WeeklyService}
              exact
            />
            <PrivateRoute
              path="/services-templates"
              component={ServicesTemplates}
              exact
            />
            <PrivateRoute
              path="/reminder-choose-people"
              component={ChoosePeopleToRemind}
              exact
            />
            <PrivateRoute
              path="/send-reminder"
              component={SendReminder}
              exact
            />
            <PrivateRoute
              path="/schedule-templates"
              component={ScheduleTemplates}
              deniedPage={true}
              exact
            />
            <PrivateRoute
              path="/keywords"
              component={Keywords}
              deniedPage={true}
              exact
            />
            <PrivateRoute path="/users" exact component={Organizations} />
            <PrivateRoute path="/pricing" exact component={Pricing} />
            <PrivateRoute path="/coupons" exact component={Coupons} />
            <PrivateRoute path="/edit-coupon" component={EditCoupon} exact />
            <PrivateRoute path="/edit-pricing" component={EditPricing} exact />
            <PrivateRoute
              path="/edit-schedule"
              component={EditSchedule}
              exact
            />
            <PrivateRoute path="/edit-keyword" component={EditKeyword} exact />
            <PrivateRoute
              path="/new-schedule"
              component={CreateSchedule}
              exact
            />
            <PrivateRoute
              path="/add-confirmation-time"
              component={AddConfirmationTime}
              exact
            />
            <PrivateRoute
              path="/add-confirmation-text"
              component={AddConfirmationText}
              exact
            />
            <PrivateRoute
              path="/message-templates"
              component={MessageTemplates}
              deniedPage={true}
              exact
            />
            <PrivateRoute path="/new-template" component={NewTemplate} exact />
            <PrivateRoute
              path="/notifications"
              component={Notifications}
              exact
            />
            <PrivateRoute
              path="/number"
              component={Number}
              deniedPage={true}
              exact
            />
            <PrivateRoute
              path="/user-settings"
              component={UserSettings}
              deniedPage={true}
              exact
            />
            <PrivateRoute path="/billing" component={Billing} exact />
            <PrivateRoute path="/logs" exact component={Logs} />
            <PrivateRoute
              path="/integrations"
              component={Integrations}
              deniedPage={true}
              exact
            />
            <PrivateRoute path="/bitly-callback" component={Bitly} exact />
            <Route path="/pastorsline" component={Pastorsline} exact />
            <PrivateRoute
              path="/confirmation-summary"
              component={ConfirmationSummary}
              exact
            />
            <PrivateRoute path="/add-followup" component={AddFollowUp} exact />
            <PrivateRoute path="/services" component={ServiceStatus} exact />
            <PrivateRoute path="/logs-people" component={LogPeople} exact />
            <PrivateRoute path="/logs" component={Log} exact />
            <PrivateRoute path="/more" component={More} exact />
            <Route path="/schedules" component={Schedules} exact />
            <PrivateRoute path="/people" component={People} exact />
            <Route path="/activate" component={Activate} exact />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getUserProfileData: () => dispatch(userProfileMiddeware()),
});

export default connect(null, mapDispatchToProps)(Routes);
