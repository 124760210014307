import React from "react";
import {
  Button,
  Card,
  Dialog,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Slide,
  TableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import AddNewCard from "./AddNewCard";
import EditCard from "./EditCard";
import DeletePaymentMethod from "./DeletePaymentMethod";
import { authHeader } from "../helpers/AuthHeader";
import "../../styles/components/more/billing.scss";

const API_URL = process.env.REACT_APP_API_URL;

const cardImages = {
  "american express": "./images/american-express.png",
  mastercard: "./images/master-card.png",
  visa: "./images/visa.png",
  discover: "./images/discover.png",
  jcb: "./images/jcb.png",
  "union pay": "./images/union-pay.jpg",
  "diners club": "./images/diners-club.png",
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PaymentMethods(props) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setPaymentmenu] = React.useState(null);
  const [flashMessage, setFlashMessage] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickPaymentMenu = (event) => {
    console.log(event.currentTarget);
    setPaymentmenu(event.currentTarget);
  };

  const handleClosePaymentMenu = () => {
    setPaymentmenu(null);
  };

  const handleLoading = (loading, flashMessage) => {
    setFlashMessage(flashMessage);
    props.handleLoading(loading, flashMessage);
  };
  const handleFlash = () => {
    setFlashMessage("");
  };

  const switchDefaultCard = (e) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ card_id: e.currentTarget.value }),
    };

    return fetch(`${API_URL}switch-default-card`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        props.changeCards();
      })
      .catch(console.log);
  };

  return (
    <div>
      <Button
        color="primary"
        variant="text"
        onClick={handleClickOpen}
        classes={{
          root: "btn",
          label: "label",
        }}
      >
        <span className="forgot-pw">View all</span>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{
          root: "payment-methods-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        {flashMessage !== "" && (
          <div className="flash-message">
            <Typography>{flashMessage}</Typography>
            <Icon className="close-icon relative" onClick={handleFlash}>
              close
            </Icon>
          </div>
        )}
        <div className="sticky-header">
          <Typography className="page-title">Payment method</Typography>
          <IconButton className="close-icon" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <div className="content desktop-design mt-4">
          <Table
            stickyHeader
            aria-label="simple table"
            className="payment-table"
          >
            <TableHead className="table-head">
              <TableRow>
                <TableCell>Card</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {props.cards.map((card, idx) => (
                <TableRow key={idx}>
                  <TableCell style={{ width: "5%", paddingRight: 0 }}>
                    <FormControlLabel
                      className="label"
                      control={
                        <Checkbox
                          className="fav-icon"
                          value={card.id}
                          onChange={switchDefaultCard}
                          checked={
                            props.defaultCard
                              ? props.defaultCard.id === card.id
                              : false
                          }
                          icon={
                            <IconButton>
                              <Icon className="empty">star_outline</Icon>
                            </IconButton>
                          }
                          checkedIcon={
                            <IconButton>
                              <Icon className="filled">star</Icon>
                            </IconButton>
                          }
                        />
                      }
                    />
                  </TableCell>
                  <TableCell className="cards-logo" style={{ width: "10%" }}>
                    <img
                      width={57}
                      src={cardImages[card.brand.toLowerCase()]}
                      alt=""
                    />
                  </TableCell>
                  <TableCell className="card-info" style={{ width: "25%" }}>
                    <div>
                      <Typography className="card-title">
                        {card.brand}
                      </Typography>
                      <Typography className="info">
                        **** {card.last4}
                      </Typography>
                    </div>
                  </TableCell>
                  <TableCell style={{ width: "45%" }}>
                    Expires {card.exp_month}/{card.exp_year}
                  </TableCell>
                  <TableCell>
                    <div className="action">
                      <EditCard
                        card={card}
                        email={props.email}
                        handler={props.changeCards}
                        defaultCard={props.defaultCard}
                        handleLoading={handleLoading}
                      />
                      <DeletePaymentMethod
                        card={card}
                        changeCards={props.changeCards}
                        defaultCard={props.defaultCard}
                        handleLoading={handleLoading}
                        toggler={
                          <Button
                            color="primary"
                            variant="text"
                            onClick={handleClickOpen}
                            classes={{
                              root: "btn",
                              label: "label",
                              textPrimary: "text-primary",
                            }}
                          >
                            <span
                              className="forgot-pw"
                              style={{ color: "#7BC807" }}
                            >
                              Delete
                            </span>
                          </Button>
                        }
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="new-payment">
            <AddNewCard
              handler={props.changeCards}
              handleLoading={handleLoading}
            />
          </div>
        </div>
        <div className="mobile-design mobile-content">
          {props.cards.map((card, idx) => (
            <Card className="card-single" key={idx}>
              <div className="card-header">
                <div>
                  <FormControlLabel
                    className="label"
                    control={
                      <Checkbox
                        className="fav-icon"
                        value={card.id}
                        onChange={switchDefaultCard}
                        checked={
                          props.defaultCard
                            ? props.defaultCard.id === card.id
                            : false
                        }
                        icon={
                          <IconButton>
                            <Icon className="empty">star_outline</Icon>
                          </IconButton>
                        }
                        checkedIcon={
                          <IconButton>
                            <Icon className="filled">star</Icon>
                          </IconButton>
                        }
                      />
                    }
                  />
                  <img
                    width={57}
                    src={cardImages[card.brand.toLowerCase()]}
                    alt=""
                  />
                </div>
                <div>
                  <IconButton
                    className="icon-button"
                    aria-controls="payment-menu"
                    aria-haspopup="true"
                    id={card.id}
                    brand={card.brand}
                    last4={card.last4}
                    exp_month={card.exp_month}
                    exp_year={card.exp_year}
                    name={card.name}
                    address_line1={card.address_line1}
                    address_city={card.address_city}
                    address_state={card.address_state}
                    address_zip={card.address_zip}
                    address_country={card.address_country}
                    onClick={handleClickPaymentMenu}
                  >
                    <Icon className="more-icon">more_vert</Icon>
                  </IconButton>
                  <Menu
                    id="payment-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClosePaymentMenu}
                    classes={{
                      paper: "dropdown-more",
                    }}
                  >
                    <EditCard
                      selected={anchorEl}
                      card={card}
                      email={props.email}
                      inline={true}
                      defaultCard={props.defaultCard}
                      handleClose={handleClosePaymentMenu}
                      handleLoading={handleLoading}
                      handler={props.changeCards}
                      toggler={<MenuItem>Edit</MenuItem>}
                    />
                    <DeletePaymentMethod
                      selected={anchorEl}
                      card={card}
                      inline={true}
                      handleClose={handleClosePaymentMenu}
                      handleLoading={handleLoading}
                      changeCards={props.changeCards}
                      defaultCard={props.defaultCard}
                      toggler={<MenuItem>Delete</MenuItem>}
                    />
                    {/**<MenuItem onClick={handleClosePaymentMenu}>Edit</MenuItem>
					<DeletePaymentMethod toggler={<MenuItem onClick={handleClosePaymentMenu}>Delete</MenuItem>} />*/}
                  </Menu>
                </div>
              </div>
              <div className="card-detail">
                <div>
                  <Typography className="card-title">{card.brand}</Typography>
                  <Typography component={"p"} className="info">
                    **** {card.last4}
                  </Typography>
                </div>
                <Typography>
                  Expires {card.exp_month}/{card.exp_year}
                </Typography>
              </div>
            </Card>
          ))}
          <div className="new-payment">
            <AddNewCard
              handler={props.changeCards}
              handleLoading={handleLoading}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}
