import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import PropType from "prop-types";
import {
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Icon,
} from "@material-ui/core";
import { ReactComponent as FilterIcon } from "../../icons/filter-green.svg";
import "../../styles/components/more/billing.scss";

const FilterChecklist = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checkList, setCheckList] = React.useState([]);

  const { filteration, showStatusFilterIcon, showPlanFilterIcon } = props;

  useEffect(() => {
    setCheckList(props.checkList);
  }, [props.checkList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = useCallback(
    (event, idx) => {
      const cloneData = [...checkList];
      cloneData[idx].active = !cloneData[idx].active;
      setCheckList(cloneData);
      filteration(cloneData);
    },
    [checkList, filteration, setCheckList]
  );

  return (
    <div>
      <IconButton
        aria-controls="table-more-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Icon>more_vert</Icon>
      </IconButton>
      {showPlanFilterIcon && checkList.length === 7 && <FilterIcon />}
      {showStatusFilterIcon && checkList.length > 7 && <FilterIcon />}
      <Menu
        id="table-more-menu"
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          list: "filter-select-menu",
        }}
      >
        {Boolean(checkList.length) &&
          checkList.map((item, idx) => (
            <MenuItem className="w-100" key={idx}>
              <Checkbox
                value={item.key}
                checked={item.active}
                name={item.key}
                onChange={(event) => handleCheck(event, idx)}
              />
              <ListItemText className="label" primary={item.key} />
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
};

FilterChecklist.propTypes = {
  filteration: PropType.func,
  checkList: PropType.array,
};

const mapStateToProps = (store) => {
  return {
    showPlanFilterIcon: store.organization.showPlanFilterIcon,
    showStatusFilterIcon: store.organization.showStatusFilterIcon,
  };
};

export default connect(mapStateToProps, null)(FilterChecklist);
