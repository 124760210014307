import React from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Icon,
  Hidden,
  FormControl,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import BottomModal from "../BottomModal";
import CustomDropdown from "../CustomDropdown";
import "../../styles/components/services/header-services.scss";

class HeaderServices extends React.Component {
  state = {
    searching: false,
  };
  showSearch = () => {
    this.setState({
      searching: true,
    });
  };

  hideSearch = () => {
    this.setState({
      searching: false,
    });
  };

  dashboardClick = () => {
    var x = document.getElementsByClassName("dashboard-tab");
    x[0].click();
  };

  reload() {
    //this.props.refresh(this);
    //console.log('refreshed');
    window.location.reload(false);
  }

  render() {
    var bread =
      this.props.bread &&
      this.props.bread.length > 0 &&
      window.innerWidth <= 959
        ? this.props.bread
        : false;
    var _this = this;
    return (
      <AppBar
        position="static"
        className={
          this.props.className
            ? this.props.className + " header-services"
            : "header-services"
        }
      >
        {this.state.searching ? (
          <Toolbar className="search-bar">
            <FormControl className="form-control">
              <TextField
                className="search"
                id="input-with-icon-textfield"
                placeholder="Search"
                variant="outlined"
                InputProps={{
                  classes: {
                    input: "input",
                    notchedOutline: "fieldset",
                  },
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton className="icon-button">
                        <Icon>search</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Button className="cancel-btn" onClick={this.hideSearch}>
              Cancel
            </Button>
          </Toolbar>
        ) : (
          <Toolbar className="toolbar">
            {this.props.backView && (
              <IconButton
                component={Link}
                to={this.props.backView}
                edge="start"
                color="inherit"
                aria-label="Menu"
                className="icon-button"
              >
                <Icon>keyboard_arrow_left</Icon>
              </IconButton>
            )}
            <Typography className="title" component="h2">
              {bread &&
                this.props.title.split(" > ").map(function (t, i) {
                  if (i === bread.length || bread[i].length === 0) {
                    return t;
                  } else if (bread[i] === "dashboardClick") {
                    return (
                      <Button component={Link} onClick={_this.dashboardClick}>
                        {t} {">"}{" "}
                      </Button>
                    );
                  } else {
                    return (
                      <Button component={Link} to={bread[i]}>
                        {t} {">"}{" "}
                      </Button>
                    );
                  }
                })}
              {!bread && this.props.title}
            </Typography>
            <div className="right-action">
              {this.props.hasSearch && (
                <IconButton className="icon-button" onClick={this.showSearch}>
                  <Icon>search</Icon>
                </IconButton>
              )}
              {this.props.hasReload && (
                <IconButton className="icon-button" onClick={this.props.reload}>
                  <Icon>autorenew</Icon>
                </IconButton>
              )}
              {this.props.actionButton && (
                <Button
                  disableRipple
                  className="header-button"
                  component={Link}
                  to={this.props.nextView || "/"}
                >
                  {this.props.actionButton}
                </Button>
              )}
              {this.props.actionButton && (
                <Hidden mdUp>
                  {" "}
                  <BottomModal content={this.props.bottomModalContent} />{" "}
                </Hidden>
              )}
              {this.props.lists && (
                <Hidden smDown>
                  <CustomDropdown lists={this.props.lists} />
                </Hidden>
              )}
            </div>
          </Toolbar>
        )}
      </AppBar>
    );
  }
}

export default HeaderServices;
