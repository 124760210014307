import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  AppBar,
  Button,
  Hidden,
  Tabs,
  Tab,
  Typography,
} from "@material-ui/core";
import PersonSingle from "./../../atoms/PersonSingle";
import "./../../styles/components/more/message-tabs.scss";

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function RemindPeopleTab(props) {
  const [value, setValue] = React.useState(0);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  let thisWeek = props.persons.filter(function (person) {
    return person.when === "thisWeek";
  });

  let nextWeek = props.persons.filter(function (person) {
    return person.when === "nextWeek";
  });

  return (
    <div className="outer-wrapper people-tabs">
      <Hidden smDown>
        <Typography className="desktop-title">Send Reminder</Typography>
      </Hidden>
      <AppBar position="static" className="message-tabs">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          variant="fullWidth"
          classes={{
            root: "tabs-wrapper",
            indicator: "indicator",
          }}
        >
          <Tab
            label="All"
            classes={{
              root: "tab-single",
              selected: "selected",
            }}
          />
          <Tab
            label="This week"
            classes={{
              root: "tab-single",
              selected: "selected",
            }}
          />
          <Tab
            label="Next week"
            classes={{
              root: "tab-single",
              selected: "selected",
            }}
          />
        </Tabs>
      </AppBar>
      {value === 0 && (
        <TabContainer>
          <div>
            {props.selectAll}
            {props.persons.map((person, idx) => (
              <PersonSingle
                selected={person.selected}
                name={person.name}
                service={person.service}
                day={person.day}
                key={idx}
              />
            ))}
          </div>
        </TabContainer>
      )}
      {value === 1 && (
        <TabContainer>
          {thisWeek.map((person, idx) => (
            <PersonSingle
              selected={person.selected}
              name={person.name}
              service={person.service}
              day={person.day}
              key={idx}
            />
          ))}
        </TabContainer>
      )}
      {value === 2 && (
        <TabContainer>
          {nextWeek.map((person, idx) => (
            <PersonSingle
              selected={person.selected}
              name={person.name}
              service={person.service}
              day={person.day}
              key={idx}
            />
          ))}
        </TabContainer>
      )}
      <Hidden smDown>
        <Button
          color="primary"
          variant="contained"
          component={Link}
          to="/send-reminder"
          classes={{
            root: "btn",
            label: "label",
            containedPrimary: "contained-primary next-step",
          }}
        >
          Next
        </Button>
      </Hidden>
    </div>
  );
}
export default RemindPeopleTab;
