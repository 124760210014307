import { ACTION_TYPES } from "../actions/types";
const initialState = {
  // SCHEDULING SERVICES LOADING
  schedulingServicesData: {},

  // ASSIGN TEMPLATE
  assignTemplateStatus: "",
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_SCHEDULING_SERVICES: {
      const updatedSchedulingServicesStatus = {
        ...state.schedulingServicesData,
        [action.folderId]: {
          isLoading: true,
          error: null,
        },
      };
      return Object.assign({}, state, {
        schedulingServicesData: updatedSchedulingServicesStatus,
      });
    }

    case ACTION_TYPES.GET_SCHEDULING_SERVICES_SUCCESS: {
      const updatedSchedulingServicesStatus = {
        ...state.schedulingServicesData,
        [action.folderId]: {
          isLoading: false,
          error: null,
          data: action.data,
        },
      };
      return Object.assign({}, state, {
        schedulingServicesData: updatedSchedulingServicesStatus,
      });
    }

    case ACTION_TYPES.GET_SCHEDULING_SERVICES_ERROR: {
      const updatedSchedulingServicesStatus = {
        ...state.schedulingServicesData,
        [action.folderId]: {
          isLoading: false,
          error: action.errorMessage,
        },
      };
      return Object.assign({}, state, {
        schedulingServicesData: updatedSchedulingServicesStatus,
      });
    }

    case ACTION_TYPES.SET_SCHEDULING_SERVICES_DATA: {
      return Object.assign({}, state, {
        schedulingServicesData: action.payload,
      });
    }

    case ACTION_TYPES.ASSIGN_TEMPLATE: {
      return Object.assign({}, state, {
        assignTemplateStatus: "loading",
      });
    }

    case ACTION_TYPES.ASSIGN_TEMPLATE_SUCCESS: {
      return Object.assign({}, state, {
        assignTemplateStatus: "success",
      });
    }

    case ACTION_TYPES.ASSIGN_TEMPLATE_ERROR: {
      return Object.assign({}, state, {
        assignTemplateStatus: "error",
      });
    }

    case ACTION_TYPES.ASSIGN_TEMPLATE_CLEAR: {
      return Object.assign({}, state, {
        assignTemplateStatus: "",
      });
    }

    default:
      return state;
  }
};

export default servicesReducer;
