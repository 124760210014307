import React from "react";
import { Link } from "react-router-dom";
import {
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { ReactComponent as EditIcon } from "../icons/edit-icon.svg";
import CustomSwitch from "./../atoms/CustomSwitch";
import "./../styles/components/custom-dropdown.scss";

export default function CustomDropdown(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div>
      <IconButton
        className="icon-button"
        aria-haspopup="true"
        aria-controls="simple-menu"
        onClick={handleClick}
      >
        <Icon>more_vert</Icon>
      </IconButton>
      <Menu
        className="custom-dropdown"
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.lists.map((item, idx) => (
          <MenuItem
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              item.onClick ? item.onClick() : "";
              handleClose();
            }}
            className={
              item.hasBorderBottom
                ? "dropdown-item border-bottom"
                : "dropdown-item"
            }
            component={Link}
            to={item.target}
            key={idx}
          >
            {item.edit && <EditIcon />}
            {item.switch && <CustomSwitch />}
            <Typography className="title">{item.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
