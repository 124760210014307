import React from "react";
import {
  Button,
  Dialog,
  Grid,
  Icon,
  IconButton,
  MenuItem,
  Slide,
  Typography,
  TextField,
} from "@material-ui/core";
import "../../styles/components/more/billing.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class EnableAutoRecharge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      values: {
        cardId: props.recharge.cardId,
        creditId: props.recharge.creditId,
        limit: props.recharge.limit,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleEnable = this.handleEnable.bind(this);
  }

  handleChange = (prop) => (event) => {
    const values = this.state.values;
    values[prop] = event.target.value;
    this.setState({ values: values });
  };

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleEnable = () => {
    const values = this.state.values;
    if (values.cardId !== 0 && values.creditId !== 0) {
      this.setState({ open: false });
      this.props.onEnable(values);
    }
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  componentWillReceiveProps(props) {
    this.setState({
      values: {
        cardId: props.recharge.cardId,
        creditId: props.recharge.creditId,
        limit: props.recharge.limit,
      },
    });
  }

  render() {
    const { values, open } = this.state;
    return (
      <React.Fragment>
        <div onClick={this.handleClickOpen}>{this.props.toggler}</div>
        <Dialog
          fullScreen
          open={open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          classes={{
            root: "subscription-plans-page enable-autorecharge",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <IconButton className="close-icon" onClick={this.handleClose}>
            <Icon>close</Icon>
          </IconButton>
          <Grid container className="content" spacing={2}>
            <Grid item className="left-content">
              <Typography className="page-title">
                Enable Auto-recharge
              </Typography>
              <Typography className="page-detail">
                Auto-recharge automatically tops up your Confirmed.church Credit
                balance when it falls below a certain amount, funded from your
                credit card, Paypal or Skrill account.
              </Typography>
              <Grid container spacing={2} className="container">
                <Grid
                  item
                  style={{ width: window.innerWidth > 768 ? "30%" : "100%" }}
                >
                  <Typography className="input-title">
                    Recharge my account with
                  </Typography>
                  <TextField
                    id="credit-amount"
                    select
                    label="Credit amount"
                    value={values.creditId}
                    onChange={this.handleChange("creditId")}
                    variant="filled"
                    fullWidth
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    <MenuItem value="0">Select Credit</MenuItem>
                    {this.props.autoCredits.map((credit) => (
                      <MenuItem key={credit.id} value={credit.id}>
                        {credit.quantity} (${credit.amount}){" "}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid
                  item
                  style={{ width: window.innerWidth > 768 ? "30%" : "100%" }}
                >
                  <Typography className="input-title">
                    When my Credits fall below
                  </Typography>
                  <TextField
                    id="actual-amount"
                    select
                    label="Actual amount"
                    value={values.limit}
                    onChange={this.handleChange("limit")}
                    variant="filled"
                    fullWidth
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    <MenuItem value="2">2 credits</MenuItem>
                    <MenuItem value="10">10 credits</MenuItem>
                    <MenuItem value="25">25 credits</MenuItem>
                    <MenuItem value="50">50 credits</MenuItem>
                    <MenuItem value="100">100 credits</MenuItem>
                    <MenuItem value="500">500 credits</MenuItem>
                  </TextField>
                </Grid>
                <Grid
                  item
                  style={{ width: window.innerWidth > 768 ? "40%" : "100%" }}
                >
                  <Typography className="input-title">Pay with</Typography>
                  <TextField
                    id="payment-type-select"
                    select
                    label="Select Card"
                    value={values.cardId}
                    onChange={this.handleChange("cardId")}
                    variant="filled"
                    fullWidth
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    <MenuItem value="0">Select Payment Method</MenuItem>
                    {this.props.cards.map((card) => (
                      <MenuItem key={card.id} value={card.id}>
                        {card.brand} ****{card.last4}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <div className="action-buttons">
                <Button
                  color="primary"
                  variant="text"
                  onClick={this.handleClose}
                  classes={{
                    root: "btn",
                    label: "label",
                    textPrimary: "text-primary",
                  }}
                >
                  <span className="forgot-pw" style={{ color: "#7BC807" }}>
                    Cancel
                  </span>
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  onClick={this.handleEnable}
                  classes={{
                    root: "btn",
                    label: "label",
                    containedPrimary: "contained-primary",
                  }}
                >
                  Enable
                </Button>
              </div>
            </Grid>
            <Grid item className="right-content">
              {/*<TweetEmbed className="tweet-embed" id="1249382299898204160" />*/}
              <img src="./images/payment-cards.png" alt="" />
            </Grid>
          </Grid>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default EnableAutoRecharge;
