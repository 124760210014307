import React from "react";
import { Typography } from "@material-ui/core";
import HeaderServices from "./HeaderServices";
import "./../../styles/components/services/services-templates.scss";

function SingleReminderTemplate(props) {
  const [active, setActive] = React.useState(false);
  function handleToggle() {
    setActive(!active);
  }

  return (
    <div
      className="single-template-content"
      onClick={handleToggle}
      style={{ backgroundColor: active ? "#f0f0f0" : "" }}
    >
      <Typography className="item-title">{props.itemTitle}</Typography>
      <Typography className="item-detail">{props.itemDetail}</Typography>
    </div>
  );
}
function ServicesTemplates() {
  return (
    <div className="page-wrapper">
      <HeaderServices
        backView="/daily-worship"
        title="2 by 2"
        nextView="/reminder-choose-people"
        actionButton="select"
      />
      <div className="services-templates">
        <div className="single-section">
          <Typography className="title">Confirmation</Typography>
          <SingleReminderTemplate
            itemTitle="ASAP between 8 AM and 10 AM"
            itemDetail="Dear [firstname], can you join us [time]? Please reply YES/NO to this message."
          />
        </div>
        <div className="single-section">
          <Typography className="title">Confirmation reminder</Typography>
          <SingleReminderTemplate
            itemTitle="Day after"
            itemDetail="Dear [firstname], can you join us [time]? Please reply YES/NO to this message."
          />
        </div>
        <div className="single-section">
          <Typography className="title">Follow up</Typography>
          <SingleReminderTemplate
            itemTitle="Day before"
            itemDetail="Dear [firstname], can you join us [time]? Please reply YES/NO to this message."
          />
          <SingleReminderTemplate
            itemTitle="Week before"
            itemDetail="Dear [firstname], can you join us [time]? Please reply YES/NO to this message."
          />
        </div>
      </div>
    </div>
  );
}
export default ServicesTemplates;
