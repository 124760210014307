import React from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import queryString from "query-string";
import {
  Button,
  TextField,
  InputAdornment,
  Icon,
  IconButton,
} from "@material-ui/core";
import { userService } from "../components/services/UserService";
import ClipLoader from "react-spinners/ClipLoader";
import Logo from "../components/Logo";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
class ResetPage extends React.Component {
  constructor(props) {
    super(props);

    const values = queryString.parse(this.props.location.search);
    this.state = {
      uid: values.code ? values.code : "",
      confirm_password: "",
      password: "",
      submitted: false,
      loading: false,
      showPassword: "",
      error: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handleMouseDownPassword = this.handleMouseDownPassword.bind(this);
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return this.props.history.push(`/dashboard`);
    }
  }

  handleChangePassword = (prop) => (event) => {
    this.setState({ ...this.state.password, [prop]: event.target.value });
  };
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { confirm_password, password, uid } = this.state;

    const error = {};
    // stop here if form is invalid
    if (!uid || !password || password !== confirm_password) {
      if (!password) {
        error["password"] = "this field is required";
      } else if (password !== confirm_password) {
        error["confirm_password"] = "password does not match";
      } else if (!uid) {
        error["password"] = "Invalid Link";
      }

      this.setState({ error });
      return;
    }

    this.setState({ loading: true });
    userService.reset(password, uid).then(
      (user) => {
        const { from } = this.props.location.state || {
          from: { pathname: "/login" },
        };
        this.props.history.push(from);
      },
      (error) => {
        if (typeof error === "string") {
          this.setState({ error: { password: "Invalid Link" } });
        } else {
          var _this = this;
          error.then(function (e) {
            var es = JSON.parse(e);
            var errors = es.error;
            var errorJson = { password: "Invalid Link" };
            if (typeof errors === "string") {
              if (es.email) {
                var url = "/verify-email?email=" + encodeURIComponent(es.email);
                errorJson = {
                  password: errors + ' <a href="' + url + '">Verify Email</a>',
                };
              } else {
                errorJson = { password: errors };
              }
            }
            _this.setState({ error: errorJson });
          });
        }
        this.setState({ loading: false });
      }
    );
  }

  render() {
    const {
      confirm_password,
      password,
      loading,
      error,
      showPassword,
    } = this.state;
    return (
      <div className="page-wrapper">
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
        <div className="auth-wrapper">
          <div className="login-page">
            <Logo href="/" className="logo" />
            <h3>Reset password</h3>
            <form name="form" onSubmit={this.handleSubmit}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  type: showPassword ? "text" : "password",
                  value: password,
                  onChange: this.handleChangePassword("password"),
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        <Icon>
                          {showPassword ? "visibility" : "visibility_off"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="filled-uncontrolled"
                label="Password"
                name="password"
                //defaultValue="password"
                error={typeof error.password === "string"}
                helperText={error.password ? error.password : ""}
                margin="normal"
                variant="filled"
                FormHelperTextProps={{
                  classes: {
                    root: "helper-text",
                  },
                }}
              />
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  type: showPassword ? "text" : "password",
                  value: confirm_password,
                  onChange: this.handleChangePassword("confirm_password"),
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                      >
                        <Icon>
                          {showPassword ? "visibility" : "visibility_off"}
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="filled-uncontrolled"
                label="Confirm Password"
                name="confirm_password"
                margin="normal"
                variant="filled"
                error={typeof error.confirm_password === "string"}
                helperText={
                  error.confirm_password ? error.confirm_password : ""
                }
                FormHelperTextProps={{
                  classes: {
                    root: "helper-text",
                  },
                }}
              />
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  /*component={Link}
            to='/dashboard'*/
                  type="submit"
                  classes={{
                    root: "btn",
                    label: "label",
                    containedPrimary: "contained-primary",
                  }}
                >
                  Reset
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  component={Link}
                  to="/login"
                  classes={{
                    root: "btn",
                    label: "label",
                    textPrimary: "text-primary",
                  }}
                >
                  Login
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="auth-banner">
          <img src="images/auth-banner.png" alt="" />
        </div>
      </div>
    );
  }
}

export default ResetPage;
