import React from "react";
import { Link } from "react-router-dom";
import { Button, Box, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ChooseNumberModal from "./../ChooseNumberModal";
import Pastorsline from "./Pastorsline";
import { userService } from "./../services/UserService";
import { formatPhoneNumber } from "../../helpers/helpers";
import "../../styles/components/choose-number-modal.scss";

class AddNewNumberStepOne extends React.Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      open: this.props.donotShowButton ? this.props.donotShowButton : false,
      phone_number: this.props.phone_number,
      country: this.props.country,
      display_phone_number: this.props.number,
      numberImported: this.props.donotShowButton
        ? false
        : user.success && user.success.user.is_phone_pl
        ? true
        : false,
      error: {},
    };
    this.chooseNumber = this.chooseNumber.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(props) {
    this.setState({
      phone_number: props.phone_number,
      country: props.country,
      display_phone_number: props.number,
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  chooseNumber(e, phone_number, country) {
    this.setState({
      phone_number: phone_number,
      country: country,
      display_phone_number: formatPhoneNumber(phone_number, country),
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { phone_number, country } = this.state;

    // stop here if form is invalid
    if (!phone_number) {
      this.setState({ error: { phone: "phone number is required" } });
      return;
    }

    this.setState({ loading: true });

    userService.saveNumber(phone_number, country).then(
      (data) => {
        this.setState({ loading: false, open: false });
        this.props.reset();
      },
      (error) => {
        console.log(error);
        this.setState({ loading: false, open: false });
        this.props.reset();
        return;
      }
    );
    this.setState({ submitted: true });
  }

  render() {
    const { open, numberImported, phone_number, display_phone_number, error } =
      this.state;
    const { hasPlNumber, hasActiveNormalNum } = this.props;
    return (
      <div>
        {!this.props.donotShowButton && (
          <Button
            color="primary"
            variant="contained"
            onClick={this.handleClickOpen}
            classes={{
              root: "btn",
              label: "label",
              containedPrimary: "contained-primary nextbtn",
            }}
          >
            Add New Number
          </Button>
        )}
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="add-new-number-one-title"
          aria-describedby="add-new-number-one-description"
          classes={{
            root: "add-new-number-modal",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <DialogContent>
            <div className="content">
              <div className="choose-number-wrapper">
                <Typography
                  className="main-title"
                  component={"h3"}
                  style={{ marginLeft: 0, width: "100%", textAlign: "center" }}
                >
                  {!hasActiveNormalNum
                    ? "Choose your number"
                    : "USE YOUR PASTORSLINE NUMBER"}
                </Typography>
                <form name="form">
                  {error.hasOwnProperty("errorText") &&
                    error.errorText !== "" && (
                      <div
                        className={"alert alert-danger"}
                        dangerouslySetInnerHTML={{ __html: error.errorText }}
                      />
                    )}
                  {!hasActiveNormalNum && (
                    <>
                      <div className="selected-number">
                        {display_phone_number}
                      </div>
                      <Box align="right" className="right">
                        <ChooseNumberModal
                          handleSubmit={this.chooseNumber}
                          number={phone_number}
                          phone_number={display_phone_number}
                        />
                        <br />
                        <Button
                          color="primary"
                          variant="contained"
                          type="submit"
                          onClick={this.handleSubmit}
                          disabled={phone_number ? false : true}
                          classes={{
                            root: "btn",
                            label: "label",
                            containedPrimary: "contained-primary nextbtn",
                          }}
                        >
                          Choose
                        </Button>
                      </Box>
                    </>
                  )}
                  {!hasPlNumber && (
                    <div
                      className={`pastorsline-number-select ${
                        !hasPlNumber ? "mt-0" : ""
                      }`}
                    >
                      {!hasPlNumber && !hasActiveNormalNum && (
                        <Typography className="title" component="h4">
                          Or use your pastorsline number
                        </Typography>
                      )}
                      {!numberImported && <Pastorsline />}
                      {numberImported && (
                        <Button
                          color="primary"
                          variant="contained"
                          component={Link}
                          to="/integrations"
                          classes={{
                            root: "pastorsline-btn disabled",
                            label: "label",
                            containedPrimary: "contained-primary",
                          }}
                        >
                          Already Connected
                          <img src="./images/pastorsline.svg" alt="" />
                        </Button>
                      )}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default AddNewNumberStepOne;
