import React from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import { Button, TextField, Typography, Icon } from "@material-ui/core";
import { userService } from "../components/services/UserService";
import Logo from "../components/Logo";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
class ForgotPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      submitted: false,
      loading: false,
      error: {},
      flashMessage: "",
      verifyUrl: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return this.props.history.push("/dashboard");
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleFlash() {
    this.setState({ flashMessage: "" });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email } = this.state;

    const error = {};
    // stop here if form is invalid
    if (!email) {
      error["email"] = "this field is required";
      this.setState({ error });
      return;
    }

    this.setState({ loading: true, verifyUrl: null, flashMessage: "" });
    userService.forgot(email).then(
      (data) => {
        this.setState({
          error: {},
          success: "The reset email is sent successfully.",
          loading: false,
        });
      },
      (error) => {
        if (typeof error === "string") {
          this.setState({ error: { email: error } });
        } else {
          var _this = this;
          error.then(function (e) {
            var es = JSON.parse(e);
            var errors = es.error;
            var errorJson = {};
            if (typeof errors === "string") {
              if (es.email) {
                var url = "/verify-email?email=" + encodeURIComponent(es.email);
                errorJson = {};
                _this.setState({ verifyUrl: url, flashMessage: errors });
              } else {
                errorJson = { email: errors };
              }
            }
            _this.setState({ error: errorJson });
          });
        }
        this.setState({ success: "", loading: false });
      }
    );
  }

  render() {
    const {
      email,
      loading,
      error,
      success,
      flashMessage,
      verifyUrl,
    } = this.state;
    return (
      <div className="page-wrapper">
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>
        <div className="auth-wrapper">
          <div className="login-page">
            <div className="logo-wrapper">
              <Logo href="/" className="logo" />
              <h3>Reset password</h3>
            </div>
            {flashMessage !== "" && (
              <div className="flash-message">
                <Typography>
                  {flashMessage}{" "}
                  {verifyUrl ? (
                    <Link to={verifyUrl} className="text-primary">
                      <span className="label">Verify Email</span>
                    </Link>
                  ) : (
                    ""
                  )}
                </Typography>
                <Icon className="close-icon" onClick={this.handleFlash}>
                  close
                </Icon>
              </div>
            )}
            <div className={success ? "success" : ""} style={{ marginTop: 30 }}>
              {success}
            </div>
            <form name="form" onSubmit={this.handleSubmit}>
              <TextField
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                label="Email"
                name="email"
                value={email}
                onChange={this.handleChange}
                //defaultValue="yourname@email.com"
                margin="normal"
                variant="filled"
                error={typeof error.email === "string"}
                helperText={error.email ? error.email : ""}
                FormHelperTextProps={{
                  classes: {
                    root: "helper-text",
                  },
                }}
              />
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  /*component={Link}
            to='/dashboard'*/
                  type="submit"
                  classes={{
                    root: "btn",
                    label: "label",
                    containedPrimary: "contained-primary",
                  }}
                >
                  Send
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  component={Link}
                  to="/signup"
                  classes={{
                    root: "btn",
                    label: "label",
                    textPrimary: "text-primary",
                  }}
                >
                  <span className="light">Don't have an account?</span> &nbsp;
                  Create account
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="auth-banner">
          <img src="images/auth-banner.png" alt="" />
        </div>
      </div>
    );
  }
}

export default ForgotPage;
