import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Hidden, Grid } from "@material-ui/core";

const useStyles = makeStyles({
  commingSoon: {
    display: "flex",
    position: "fixed",
    bottom: 0,
    left: 285,
    right: 0,
    justifyContent: "flex-end",
    borderTop: "1px solid #E4E4E4",
    background: "#fff",
  },
  logos: {
    alignItems: "center",
    display: "flex",
    paddingLeft: 26,
  },
});
export default function Footer() {
  const classes = useStyles();
  return (
    <Hidden mdDown>
      <Grid container>
        <Grid item xs={12}>
          <div className={`${classes.commingSoon} footer-cooming-soon`}>
            <div className={classes.logos}>
              <Typography component="h3">Coming soon</Typography>
              {/*<img src="./images/elvanto.svg" alt="" />*/}
              <img src="./images/ccb.svg" alt="" />
            </div>
          </div>
        </Grid>
      </Grid>
    </Hidden>
  );
}
