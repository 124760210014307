import React from "react";
import { Button, Typography } from "@material-ui/core";
import HeaderForAll from "./../components/HeaderForAll";
import ServiceSingle from "./../components/ServiceSingle";

const AfterAuthStepTwo = () => (
  <div className="after-auth-two page-wrapper">
    <HeaderForAll
      backView="/after-auth-one"
      title="Choose services"
      nextView="/dashboard"
      nextLabel="Finish"
    />
    <div className="steps-wrapper">
      <Button
        disabled
        classes={{
          root: "btn",
          label: "label",
          disabled: "btn-disabled",
        }}
      >
        Step 1
      </Button>
      <Button
        color="primary"
        classes={{
          root: "btn",
          label: "label",
          textPrimary: "btn-active",
        }}
      >
        Step 2
      </Button>
      <Typography className="service-title" component="h3">
        Folders
      </Typography>
      <ServiceSingle service="Middle school" />
      <ServiceSingle service="Church" />
      <ServiceSingle service="Campus" />
      <ServiceSingle service="Primary school" />
    </div>
    <div className="upcoming-services">
      <Typography className="service-title" component="h3">
        Upcoming services
      </Typography>
      <ServiceSingle service="Sunday service" />
      <ServiceSingle service="Bible Study" />
      <ServiceSingle service="Summer in the city" />
    </div>
  </div>
);

export default AfterAuthStepTwo;
