import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { AppBar, Tabs, Tab, Typography } from "@material-ui/core";
import Template from "./../../atoms/Template";
import EditTemplateModal from "../Modals/EditTemplateModal";
import "../../styles/components/more/message-tabs.scss";

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

function MessageTabs(props) {
  const [value, setValue] = useState(0);
  const [templateToEdit, setTemplateToEdit] = useState({});
  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  const setEditTemplateModal = useCallback((currentTemplate) => {
    setTemplateToEdit(currentTemplate);
    setShowEditTemplateModal(true);
  }, []);

  const toggleModal = useCallback(() => {
    setShowEditTemplateModal(!showEditTemplateModal);
  }, [showEditTemplateModal]);

  let reminders = props.templates.filter(function (template) {
    return template.type === "reminder";
  });

  let confirmations = props.templates.filter(function (template) {
    return template.type === "confirmation";
  });

  let other = props.templates.filter(function (template) {
    return template.type === "other";
  });

  return (
    <div>
      <AppBar
        className={window.innerWidth > 600 ? "message-tabs" : "service-tabs"}
      >
        <Tabs
          value={value}
          style={{ background: "#343434" }}
          onChange={handleChange}
          textColor="primary"
          variant={window.innerWidth > 600 ? "fullWidth" : "scrollable"}
          scrollButtons="auto"
          classes={{
            root: "tabs-wrapper",
            indicator: "indicator",
          }}
        >
          <Tab
            label="All"
            classes={{
              root: "tab-single",
              wrapper: "wrapper",
              selected: "selected",
            }}
          />
          <Tab
            label="Confirmations"
            classes={{
              root: "tab-single",
              wrapper: "wrapper",
              selected: "selected",
            }}
          />
          <Tab
            label="Reminders"
            classes={{
              root: "tab-single",
              wrapper: "wrapper",
              selected: "selected",
            }}
          />
          <Tab
            label="Other"
            classes={{
              root: "tab-single",
              wrapper: "wrapper",
              selected: "selected",
            }}
          />
        </Tabs>
      </AppBar>
      <div className="tab-container" style={{ paddingTop: 20 }}>
        {value === 0 && (
          <TabContainer>
            {props.templates.map((template, idx) => (
              <Template
                title={template.title}
                type={template.type}
                templateDetail={template.detail}
                template={template}
                handleEditTemplate={setEditTemplateModal}
                key={idx}
              />
            ))}
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            {confirmations.map((template, idx) => (
              <Template
                title={template.title}
                type={template.type}
                templateDetail={template.detail}
                template={template}
                handleEditTemplate={setEditTemplateModal}
                key={idx}
              />
            ))}
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            {reminders.map((template, idx) => (
              <Template
                title={template.title}
                type={template.type}
                templateDetail={template.detail}
                template={template}
                handleEditTemplate={setEditTemplateModal}
                key={idx}
              />
            ))}
          </TabContainer>
        )}
        {value === 3 && (
          <TabContainer>
            {other.map((template, idx) => (
              <Template
                title={template.title}
                type={template.type}
                templateDetail={template.detail}
                template={template}
                handleEditTemplate={setEditTemplateModal}
                key={idx}
              />
            ))}
          </TabContainer>
        )}
      </div>
      {showEditTemplateModal && (
        <EditTemplateModal
          showModal={showEditTemplateModal}
          toggleModal={toggleModal}
          template={templateToEdit}
          reload={props.reload}
        />
      )}
    </div>
  );
}
export default MessageTabs;
