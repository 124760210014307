import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { toggleConfirmationModal } from "../store/actions/globalAction";

const ConfirmationModal = (props) => {
  const {
    toggleConfirmationModal,
    showConfirmationModal,
    title,
    description,
    confirmAction,
  } = props;

  const handleConfirm = () => {
    confirmAction();
    toggleConfirmationModal();
  };

  return (
    <div>
      <Dialog
        open={showConfirmationModal}
        onClose={toggleConfirmationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mb-2">
          <Button onClick={toggleConfirmationModal} color="primary">
            Cancel
          </Button>
          <Button
            style={{
              fontWeight: "bold",
              background: "#7BC807",
              color: "white",
            }}
            onClick={handleConfirm}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (store) => {
  return {
    showConfirmationModal: store.global.showConfirmationModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleConfirmationModal: () => dispatch(toggleConfirmationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
