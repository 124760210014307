import React from "react";
import { Link } from "react-router-dom";
import { Typography, Icon, Tooltip } from "@material-ui/core";
import StatusIconService from "./../services/StatusIconService";
import { formatPhoneNumber } from "../../helpers/helpers";
import "../../styles/components/people/people-list.scss";

function LogList(props) {
  return (
    <div className="services-wrapper">
      {props.logs.map((person, idx) => (
        <Link
          to={person.target}
          className={
            person.disabled ? "single-person disabled" : "single-person"
          }
          key={idx}
        >
          <div className="left-content">
            <Typography className="name">{person.name}</Typography>
            <Typography className="position">{person.position}</Typography>
            <Typography className="number">
              {formatPhoneNumber(person.phone)}
            </Typography>
          </div>
          {person.error_message && (
            <div className="right-content">
              <Tooltip title={person.error_message} placement="top">
                <Icon className="icon cross-icon">clear</Icon>
              </Tooltip>
            </div>
          )}
          {!person.message && (
            <div className="right-content">
              <StatusIconService status={person.status} />
            </div>
          )}
        </Link>
      ))}
    </div>
  );
}
export default LogList;
