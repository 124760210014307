import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  Hidden,
  Typography,
  Switch,
  TextField,
  IconButton,
  Icon,
  MenuItem,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import "./../../styles/components/more/confirmation-summary.scss";

function ConfirmationSummary() {
  const [state, setState] = React.useState({
    confirmRemind: false,
    timeDay: "asap",
    text:
      "Dear [firstname], can you join us [time]? Please reply YES/NO to this message.",
  });
  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleSelectChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.value });
  };

  return (
    <div className="confirmation-summary page-wrapper">
      <HeaderMore
        backView="/add-confirmation-text"
        title="Confirmation"
        actionButton="Save"
        nextView="/dashboard"
      />
      <div className="inner-content-wrapper">
        <Hidden smDown>
          <Typography className="desktop-title">
            Confirmation Summary
          </Typography>
        </Hidden>
        <div className="content">
          <Typography component="h3" className="title">
            Confirmation
          </Typography>
          <Typography className="time-interval">
            ASAP between <br /> 8:00 am and 10:00am
          </Typography>
          <div className="switch-control">
            <Typography component="h3" className="sub-title">
              Confirmation Reminder
            </Typography>
            <Switch
              checked={state.confirmRemind}
              onChange={handleChange("confirmRemind")}
              value="confirmRemind"
              inputProps={{ "aria-label": "secondary checkbox" }}
              disableRipple
              classes={{
                root: "custom-switch",
                thumb: "thumb",
                track: "track",
                switchBase: "switch-base",
                checked: "checked",
              }}
            />
          </div>
          {state.confirmRemind && (
            <div className="switched-content">
              <Grid container>
                <Grid item className="single-item" xs={12}>
                  <TextField
                    classes={{
                      root: "text-field",
                    }}
                    InputLabelProps={{
                      classes: {
                        root: "label",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "input-wrapper",
                        input: "input",
                      },
                    }}
                    id="filled-uncontrolled"
                    select
                    label="When"
                    margin="normal"
                    variant="filled"
                    value={state.timeDay}
                    onChange={handleSelectChange("timeDay")}
                    SelectProps={{
                      MenuProps: {
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                      },
                    }}
                  >
                    <MenuItem value="asap"> ASAP </MenuItem>
                    <MenuItem value="dayAfter"> A day after </MenuItem>
                    <MenuItem value="dayBefore"> A day before </MenuItem>
                  </TextField>
                  <div className="text">
                    <Typography className="title">Text</Typography>
                    <div className="right-icons">
                      <IconButton className="icon-button">
                        <Icon>code</Icon>
                      </IconButton>
                      <IconButton
                        className="icon-button"
                        component={Link}
                        to="/message-templates"
                      >
                        <Icon>dashboard</Icon>
                      </IconButton>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className="textfield-wrapper">
                    <TextField
                      multiline
                      fullWidth
                      rowsMax="4"
                      value={state.text}
                      onChange={handleChange("text")}
                      margin="normal"
                      className="multiline-textfield"
                      InputProps={{
                        classes: {
                          root: "form-control",
                        },
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          )}
          <Hidden smDown>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/dashboard"
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary next-step",
              }}
            >
              Next
            </Button>
          </Hidden>
        </div>
      </div>
    </div>
  );
}

export default ConfirmationSummary;
