import React, { Component } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Routes from "./routes";
import "./styles/styles.scss";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#7BC807" }, // Purple and green play nicely together.
    secondary: { main: "#7BC807" }, // This is just green.A700 as hex.
    error: { main: "#D10202" }, // This is just red.
  },
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
class App extends Component {
	render() {
		return (
			<ThemeProvider theme={theme}>
				<Routes />
			</ThemeProvider>
		);
	}
}

export default App;
