import React from "react";
import { withStyles } from "@material-ui/core/styles";
import cn from "classnames";
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import { authHeader } from "./../helpers/AuthHeader";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import "./../../styles/components/more/billing.scss";

const styles = (theme) => ({
  modalContent: {
    margin: "20px 0 10px",
  },
  details: {
    color: "#131313",
    fontSize: "14px",
    fontWeight: 500,
  },
  boldText: {
    fontWeight: 600,
  },
  deleteButton: {
    backgroundColor: "#F52F2F",
    "&:hover": {
      backgroundColor: "#F52F2F",
    },
  },
  "@media(max-width:768px)": {
    actionBtns: {
      paddingTop: 70,
    },
  },
});

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
class DeletePaymentMethod extends React.Component {
  constructor(props) {
    super(props);

    console.log(props);

    this.state = {
      classes: props.classes,
      open: false,
      cardId: props.card ? props.card.id : null,
      card: props.card,
      defaultCard: props.defaultCard,
      brandLabel: props.card
        ? props.card.brand + " ending in " + props.card.last4
        : "",
      loading: false,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.inline) {
      this.props.handleClose();
    }
  };

  handleDelete = () => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify({ card_id: this.state.cardId }),
    };

    this.setState({ open: false });
    if (this.props.inline) {
      this.props.handleClose();
      this.props.handleLoading(true);
    } else {
      this.setState({ loading: true });
    }

    return fetch(`${API_URL}delete-card`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        this.props.changeCards().then((data) => {
          if (this.props.inline) {
            this.props.handleLoading(false);
          } else {
            this.setState({ loading: false });
          }
        });
      })
      .catch(console.log);
  };

  componentWillReceiveProps(props) {
    if (props.selected) {
      console.log(props.selected);
      this.setState({
        cardId: props.selected.getAttribute("id"),
        brandLabel:
          props.selected.getAttribute("brand") +
          " ending in " +
          props.selected.getAttribute("last4"),
        card: { id: props.selected.getAttribute("id") },
      });
    }
  }

  render() {
    const {
      classes,
      open,
      brandLabel,
      defaultCard,
      card,
      loading,
    } = this.state;
    return (
      <div>
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>

        {defaultCard && defaultCard.id !== card.id && (
          <div onClick={this.handleClickOpen} style={{ display: "contents" }}>
            {this.props.toggler}
          </div>
        )}
        <Dialog
          fullScreen={window.innerWidth < 768 && true}
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          classes={{
            root: "add-new-card delete",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <div className="header">
            <Typography component={"h2"} className={cn("title", classes.title)}>
              Delete Payment Method
            </Typography>
            <IconButton className="close-icon" onClick={this.handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          <div className={cn("modal-content", classes.modalContent)}>
            <Typography className={cn("details", classes.details)}>
              You are deleting:{" "}
              <span className={cn("bold-text", classes.boldText)}>
                {brandLabel}
              </span>
            </Typography>
          </div>
          <div className={cn("action-buttons", classes.actionBtns)}>
            <Button
              color="primary"
              variant="text"
              onClick={this.handleClose}
              classes={{
                root: "btn",
                label: "label",
                textPrimary: "text-primary",
              }}
            >
              <span className="forgot-pw">Cancel</span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={this.handleDelete}
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: cn("contained-primary", classes.deleteButton),
              }}
            >
              Confirm
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}
export default withStyles(styles)(DeletePaymentMethod);
