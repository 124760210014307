import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import {
  Button,
  Dialog,
  Icon,
  IconButton,
  Typography,
  Slide,
  TableRow,
  Table,
  TableBody,
  TableCell,
  TableHead,
} from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../icons/download-icon.svg";
import "../../styles/components/more/billing.scss";

Moment.globalFormat = "DD.MM.YYYY";
Moment.globalLocal = true;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TransactionHistory(props) {
  const [open, setOpen] = React.useState(false);
  const [dateFocus, setDateFocus] = React.useState(false);
  const [amountFocus, setAmountFocus] = React.useState(false);
  const [paidFocus, setPaidFocus] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleToggleDateFocus = () => {
    setDateFocus(!dateFocus);
  };
  const handleToggleAmountFocus = () => {
    setAmountFocus(!amountFocus);
  };
  const handleTogglePaidFocus = () => {
    setPaidFocus(!paidFocus);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        color="primary"
        variant="text"
        onClick={handleClickOpen}
        classes={{
          root: "btn",
          label: "label",
        }}
      >
        <span className="forgot-pw">View all</span>
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{
          root: "transaction-history-page",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <div className="sticky-header">
          <Typography className="page-title">Transaction history</Typography>
          <IconButton className="close-icon" onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
        <div className="content mt-4">
          <Table stickyHeader aria-label="simple table" className="cards-table">
            <TableHead className="table-head">
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell style={{ display: "table-cell" }}>
                  Date
                  <Button
                    onClick={handleToggleDateFocus}
                    className={dateFocus === true && "on-focus"}
                  >
                    <Icon className="drop-arrow">arrow_drop_down</Icon>
                  </Button>
                </TableCell>
                <TableCell>Description</TableCell>
                <TableCell style={{ display: "table-cell" }}>
                  <span style={{ display: "flex" }}>
                    {" "}
                    Amount
                    <Button
                      onClick={handleToggleAmountFocus}
                      className={amountFocus === true && "on-focus"}
                    >
                      <Icon className="drop-arrow">arrow_drop_down</Icon>{" "}
                    </Button>
                  </span>
                </TableCell>
                <TableCell style={{ display: "table-cell" }}>
                  Paid
                  <Button
                    onClick={handleTogglePaidFocus}
                    className={paidFocus === true && "on-focus"}
                  >
                    <Icon className="drop-arrow">arrow_drop_down</Icon>
                  </Button>
                </TableCell>
                <TableCell align="right" />
              </TableRow>
            </TableHead>
            <TableBody className="table-body">
              {props.transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell scope="row">
                    {transaction.receipt_number}
                  </TableCell>
                  <TableCell>
                    <Moment tz={props.timezone} format="MM.DD.YYYY">
                      {transaction.created_at_utc}
                    </Moment>
                  </TableCell>
                  <TableCell>{transaction.description}</TableCell>
                  <TableCell>${transaction.total}</TableCell>
                  {transaction.charge != null ? (
                    <TableCell>
                      {transaction.charge.payment_method_details.card.brand}{" "}
                      ****{" "}
                      {transaction.charge.payment_method_details.card.last4}
                    </TableCell>
                  ) : (
                    <TableCell></TableCell>
                  )}
                  <TableCell align="right">
                    {transaction.receipt_url && (
                      <IconButton
                        className="icon-button"
                        component={Link}
                        to={{ pathname: transaction.receipt_url }}
                        target="_blank"
                      >
                        <DownloadIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Dialog>
    </div>
  );
}
