import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Icon,
} from "@material-ui/core";
import "../../styles/components/choose-number-modal.scss";

function BitleyNoticeModal(props) {
  const { open, handleClose } = props;
  return (
    <div>
      <Dialog
        fullScreen={window.innerWidth < 600 && true}
        open={open}
        onClose={handleClose}
        aria-labelledby="connect-account-dialog-title"
        aria-describedby="connect-account-dialog-description"
        classes={{
          root: "connect-account-modal",
          paper: "paper",
        }}
        BackdropProps={{
          classes: {
            root: "backdrop",
          },
        }}
      >
        <DialogTitle className="title">
          {" "}
          <div>
            <img src="./images/bitly-integration.svg" alt="" width="28" />{" "}
            Notice
          </div>
          <Icon onClick={handleClose} className="close-icon">
            close
          </Icon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="connect-account-dialog-description"
            className="modal-content"
          >
            Changes to US mobile carrier filtering mean that messages with links
            shortened by shared public URL shorteners will be blocked. Consider
            upgrading to the paid version of Bitly which allows use of a domain
            name to replace the Bitly domain name.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default BitleyNoticeModal;
