import React from "react";
import {
  Button,
  Dialog,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import CustomSwitch from "../../atoms/CustomSwitch";
import { authHeader } from "../helpers/AuthHeader";
import "../../styles/components/more/billing.scss";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;
var valid = require("card-validator");

class EditCard extends React.Component {
  constructor(props) {
    super(props);

    var user = JSON.parse(localStorage.getItem("user"));
    this.state = {
      open: false,
      changePayment: false,
      paymentType: "card",
      cardNumber: props.card ? "****" + props.card.last4 : "", //'4242424242424242',
      expiry: props.card
        ? props.card.exp_month + "/" + props.card.exp_year
        : "", //'12/24',
      cvc: "", //'123',
      firstName: props.card
        ? props.card.name.split(" ")[0]
        : user.success.user.first_name,
      lastName: props.card
        ? props.card.name.split(" ")[1]
        : user.success.user.last_name,
      email: props.email ? props.email : user.success.user.email,
      address:
        props.card && props.card.address_line1 ? props.card.address_line1 : "", //'Nepal',
      city:
        props.card && props.card.address_city ? props.card.address_city : "", //'Butwal',
      state:
        props.card && props.card.address_state ? props.card.address_state : "", //'Lumbini',
      zipcode:
        props.card && props.card.address_zip ? props.card.address_zip : "", //'12345',
      country:
        props.card && props.card.address_country
          ? props.card.address_country
          : "US",
      loading: false,
      defaultPayment: props.card && props.card.id === props.defaultCard.id,
      cardId: props.card ? props.card.id : null,
      typingTimeout: 0,
      error: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (prop) => (event) => {
    var value = event.target.value;
    var name = prop;
    this.setState({
      [prop]:
        name === "expiry" && !isNaN(value) && value.length === 2
          ? value + "/"
          : value,
    });

    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    var _this = this;
    this.setState({
      typingTimeout: setTimeout(function () {
        var errors = _this.state.error;

        if (
          name === "expiry" &&
          value.length > 0 &&
          !valid.expirationDate(value).isValid
        ) {
          errors["expiry"] = "Invalid expiry";
        } else if (name === "expiry") {
          errors["expiry"] = "";
        }

        _this.setState({ error: errors });
      }, 1000),
    });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.inline) {
      this.props.handleClose();
    }
  };

  handleSwitch = () => {
    this.setState({ defaultPayment: !this.state.defaultPayment });
  };

  handleSubmit = () => {
    this.setState({ error: {} });

    var expValidation = valid.expirationDate(this.state.expiry);

    if (!expValidation.isValid) {
      this.setState({ error: { expiry: "Invalid expiry" } });
      return;
    }

    this.setState({ open: false });

    if (this.props.inline) {
      this.props.handleClose();
      this.props.handleLoading(true, "");
    }
    this.setState({ loading: true });
    const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(this.state),
    };
    return fetch(`${API_URL}create-update-card`, requestOptions)
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          this.setState({ open: true });
          if (data.error) {
            if (typeof data.error === "string") {
              this.setState({ error: { errorText: data.error } });
            } else {
              this.setState({ error: data.error });
            }
          }
          this.setState({ loading: false });
          this.props.handleLoading(false, "");
        } else {
          this.props.handler().then((data) => {
            this.setState({ loading: false });
            this.props.handleLoading(false, "Card changed successfully");
          });
        }
      })
      .catch(console.log);
  };

  componentWillReceiveProps(props) {
    if (props.selected) {
      console.log(props.selected);
      this.setState({
        cardId: props.selected.getAttribute("id"),
        cardNumber: "****" + props.selected.getAttribute("last4"),
        expiry:
          props.selected.getAttribute("exp_month") +
          "/" +
          props.selected.getAttribute("exp_year"),
        firstName: props.selected.getAttribute("name").split(" ")[0],
        lastName: props.selected.getAttribute("name").split(" ")[1],
        address: props.selected.getAttribute("address_line1"),
        city: props.selected.getAttribute("address_city"),
        state: props.selected.getAttribute("address_state"),
        zipcode: props.selected.getAttribute("address_zip"),
        country: props.selected.getAttribute("address_country"),
      });
    }
  }

  render() {
    const {
      cardNumber,
      expiry,
      cvc,
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      zipcode,
      open,
      defaultPayment,
      loading,
      error,
    } = this.state;
    return (
      <div>
        <div className={loading ? "sweet-loading" : ""}>
          <ClipLoader
            css={override}
            sizeUnit={"px"}
            size={150}
            color={"#7BC807"}
            loading={loading}
          />
        </div>

        {!this.props.inline && (
          <Button
            color="primary"
            variant="text"
            type="submit"
            onClick={this.handleClickOpen}
            classes={{
              root: "btn",
              label: "label",
              textPrimary: "text-primary",
            }}
          >
            <span className="forgot-pw" style={{ color: "#7BC807" }}>
              Edit
            </span>
          </Button>
        )}

        {this.props.inline && (
          <div onClick={this.handleClickOpen} style={{ display: "contents" }}>
            {this.props.toggler}
          </div>
        )}

        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
          classes={{
            root: "add-new-card",
            paper: "paper",
          }}
          BackdropProps={{
            classes: {
              root: "backdrop",
            },
          }}
        >
          <div className="header">
            <Typography component={"h2"} className="title">
              Edit card
            </Typography>
            <IconButton className="close-icon" onClick={this.handleClose}>
              <Icon>close</Icon>
            </IconButton>
          </div>
          <div className="modal-content">
            {typeof error.errorText === "string" && (
              <div
                className={"error alt alert-danger"}
                dangerouslySetInnerHTML={{ __html: error.errorText }}
              />
            )}
            <Grid container spacing={2} className="container">
              {/*<Grid item style={{ width: '100%' }}>
            <TextField
                id="payment-type-select"
                select
                label="Select Payment Type"
                value={paymentType}
                onChange={this.handleChange('paymentType')}
                variant='filled'
                fullWidth
                classes={{
                  root: 'text-field',
                }}
                InputLabelProps={{
                  classes: {
                    root: 'label'
                  }
                }}
                InputProps={{
                  classes: {
                    root: 'input-wrapper',
                    input: 'input'
                  }
                }}
                SelectProps={{
                   MenuProps: {
                    getContentAnchorEl: null,
                    anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left', }
                  }
                }}
            >
                  <MenuItem value='card'>Add new card</MenuItem>
                  <MenuItem value='paypal'>Add Paypal</MenuItem>
                  <MenuItem value='visa'>Add Visa card</MenuItem>
              </TextField>
              </Grid>*/}
              <Grid item style={{ width: "40%" }}>
                <TextField
                  id="card-number"
                  label="Credit Card Number"
                  fullWidth
                  variant="filled"
                  value={cardNumber}
                  disabled
                  onChange={this.handleChange("cardNumber")}
                  error={typeof error.cardNumber === "object"}
                  helperText={error.cardNumber ? error.cardNumber : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ width: "30%" }}>
                <TextField
                  id="exp-date"
                  label="Exp.date (MM/YY)"
                  fullWidth
                  variant="filled"
                  value={expiry}
                  onChange={this.handleChange("expiry")}
                  error={typeof error.expiry === "object"}
                  helperText={error.expiry ? error.expiry : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ width: "30%" }}>
                <TextField
                  id="cvc-code"
                  label="CVC CODE"
                  fullWidth
                  variant="filled"
                  value={cvc}
                  disabled
                  onChange={this.handleChange("cvc")}
                  error={typeof error.cvc === "object"}
                  helperText={error.cvc ? error.cvc : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ width: "50%" }}>
                <TextField
                  id="first-name"
                  label="Cardholder Name"
                  value={firstName}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("firstName")}
                  error={typeof error.firstName === "object"}
                  helperText={error.firstName ? error.firstName : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ width: "50%" }}>
                <TextField
                  id="lastName"
                  label="Cardholder Last Name"
                  value={lastName}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("lastName")}
                  error={typeof error.lastName === "object"}
                  helperText={error.lastName ? error.lastName : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography className="switch">
                  <CustomSwitch
                    onClick={this.handleSwitch}
                    checked={defaultPayment}
                  />{" "}
                  Set as default payment method
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  id="email"
                  label="Email invoices to"
                  value={email}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("email")}
                  error={typeof error.email === "object"}
                  helperText={error.email ? error.email : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className="container">
              <Typography className="address-title">Billing address</Typography>
              <Grid item style={{ width: "100%" }}>
                <TextField
                  id="address"
                  label="Address"
                  value={address}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("address")}
                  error={typeof error.address === "object"}
                  helperText={error.address ? error.address : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ width: "45%" }}>
                <TextField
                  id="city"
                  label="City"
                  value={city}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("city")}
                  error={typeof error.city === "object"}
                  helperText={error.city ? error.city : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ width: "30%" }}>
                <TextField
                  id="state"
                  label="State"
                  value={state}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("state")}
                  error={typeof error.state === "object"}
                  helperText={error.state ? error.state : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
              <Grid item style={{ width: "25%" }}>
                <TextField
                  id="zip-code"
                  label="Zipcode"
                  value={zipcode}
                  fullWidth
                  variant="filled"
                  onChange={this.handleChange("zipcode")}
                  error={typeof error.zipcode === "object"}
                  helperText={error.zipcode ? error.zipcode : ""}
                  classes={{
                    root: "text-field",
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "label",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: "input-wrapper",
                      input: "input",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="action-buttons">
            <Button
              color="primary"
              variant="text"
              onClick={this.handleClose}
              classes={{
                root: "btn",
                label: "label",
                textPrimary: "text-primary",
              }}
            >
              <span className="forgot-pw">Cancel</span>
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={this.handleSubmit}
              classes={{
                root: "btn",
                label: "label",
                containedPrimary: "contained-primary",
              }}
            >
              Save
            </Button>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default EditCard;
