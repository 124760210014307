import React, { Fragment } from "react";
import { Grid, RadioGroup } from "@material-ui/core";
import ServiceList from "./ServiceList";
import HeaderServices from "./HeaderServices";
import ServiceListDesktop from "./ServiceListDesktop";
import PeopleListDesktop from "./PeopleListDesktop";
import { authHeader } from "../helpers/AuthHeader";
import { userService } from "./UserService";
import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
import queryString from "query-string";
import "../../styles/components/services/service-tabs.scss";

const API_URL = process.env.REACT_APP_API_URL;
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
class ServiceStatus extends React.Component {
  constructor(props) {
    super(props);

    const values = queryString.parse(this.props.location.search);

    this.state = {
      type: values.type ? values.type : "A",
      serviceId: values.service ? values.service : 0,
      service: values.serviceName ? values.serviceName : "",
      planId: values.plan ? values.plan : 0,
      plan: values.planName ? values.planName : "",
      people: values.personName ? values.personName : "",
      personId: values.personId ? values.personId : "",
      service_loading: false,
      serviceTypes: [],
      plan_loading: false,
      planList: [],
      people_loading: false,
      peoplePerDay: [],
      loading: false,
    };
    this.handleService = this.handleService.bind(this);
    this.handlePlan = this.handlePlan.bind(this);
  }

  handleFolder = (event) => {
    this.setState({ folder: event.target.value });
  };
  handleService = (event) => {
    var arr = event.target.value.split("::");
    this.setState({ service: arr[0], serviceId: arr[1] });
    this.refreshWeeklyService(false, arr[1], true);
  };
  handlePlan = (event) => {
    var arr = event.target.value.split("::");
    this.setState({ plan: arr[0], planId: arr[1] });
    this.refreshLog(false, arr[1], true);
  };
  handlePeople = (event) => {
    var arr = event.target.value.split("::");
    this.setState({ people: arr[0] });
    window.location.href = arr[1];
  };

  refreshServices(reload) {
    if (reload) {
      this.props.showSync();
    }
    var _this = this;
    const { type } = this.state;
    // cache.getItem('serviceStatus' + type, function (err, data) {
    // 	if (!reload && data) {
    // 		_this.setState({ serviceTypes: data });
    // 	} else {
    _this.setState({ service_loading: true });

    fetch(API_URL + "scheduling/services?type=" + type, {
      headers: authHeader(),
    })
      .then((res) => res.json())
      .then(
        (data) => {
          if (data.error) {
            userService.refreshToken();
            _this.refreshServices(true);
            return;
          }
          //console.log(data);
          _this.setState({ serviceTypes: data, service_loading: false });
          if (data.length > 0) {
            _this.setState({
              service: data[0]["title"],
              serviceId: data[0]["id"],
            });
            //if(data[0]['confirmed'] > 0) {
            _this.refreshWeeklyService(true, null, true);
            //}
          }
          // cache.setItem('serviceStatus' + type, data, function (err) {});
        },
        (error) => {
          _this.setState({ service_loading: false });
        }
      )
      .catch(console.log);
    // 	}
    // });
  }

  refreshWeeklyService(reload, serviceId, auto) {
    if (!auto) {
      this.props.showSync();
    }
    serviceId = serviceId ? serviceId : this.state.serviceId;
    const { type } = this.state;
    var _this = this;
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    _this.setState({ plan_loading: true });
    return fetch(
      `${API_URL}scheduling/plans/${serviceId}?type=${type}`,
      requestOptions
    )
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          userService.refreshToken();
          _this.refreshWeeklyService(true, null, true);
          _this.setState({ plan_loading: false });
          return;
        }
        _this.setState({ planList: data, plan_loading: false });
        if (data.length > 0) {
          _this.setState({ plan: data[0]["title"], planId: data[0]["id"] });
          _this.refreshLog(true, data[0]["id"], true);
        }
      })
      .catch(console.log);
  }

  refreshLog(reload, planId, auto) {
    if (!auto) {
      this.props.showSync();
    }
    planId = planId ? planId : this.state.planId;
    const { serviceId, service, type } = this.state;
    var _this = this;
    // cache.getItem(
    // 	'logPeople' + serviceId + planId + type,
    // 	function (err, data) {
    // 		if (!reload && data) {
    // 			_this.setState({ peoplePerDay: data });
    // 		} else {
    _this.setState({ people_loading: true });
    fetch(
      API_URL +
        "logs-people/" +
        planId +
        "/" +
        type +
        "?serviceId=" +
        serviceId +
        "&serviceName=" +
        service,
      {
        headers: authHeader(),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        _this.setState({ peoplePerDay: data, people_loading: false });
        // cache.setItem(
        // 	'logPeople' + serviceId + planId + type,
        // 	data,
        // 	function (err) {}
        // );
      })
      .catch(console.log);
    // 		}
    // 	}
    // );
  }

  componentDidMount() {
    this.refreshServices();
  }

  render() {
    const {
      loading,
      type,
      service,
      serviceId,
      plan,
      planId,
      people,
      serviceTypes,
      service_loading,
      planList,
      plan_loading,
      peoplePerDay,
      people_loading,
    } = this.state;
    var preHeader = "Services";
    switch (type) {
      case "C":
        preHeader = "Accepted " + preHeader;
        break;
      case "F":
        preHeader = "Failed " + preHeader;
        break;
      case "A":
        preHeader = "Sent " + preHeader;
        break;
      case "U":
        preHeader = "Unconfirmed " + preHeader;
        break;
      default:
        preHeader = "Services";
    }
    var header = "Dashboard > " + preHeader;
    return (
      <Fragment>
        <div className="page-wrapper">
          <div className="desktop-design services">
            <Grid container className="service-container-wrapper">
              <Grid item md={4} className="service-content service-types">
                <HeaderServices
                  hasReload
                  reload={() => this.refreshServices(true)}
                />
                <div className={service_loading ? "sweet-loading" : ""}>
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={"#7BC807"}
                    loading={service_loading}
                  />
                </div>
                <div className="lists">
                  <RadioGroup
                    className="services-wrapper"
                    aria-label="service"
                    name="service"
                    value={service + "::" + serviceId}
                    onChange={this.handleService}
                  >
                    {serviceTypes.map((data, idx) => (
                      <ServiceListDesktop
                        title={data.title}
                        id={data.id}
                        confirmed={data.confirmed}
                        total={data.total}
                        key={idx}
                        //status={data.status}
                      />
                    ))}
                  </RadioGroup>
                </div>
              </Grid>

              <Grid item md={4} className="service-content service-plans">
                <HeaderServices
                  title={service}
                  //hasSearch
                  hasReload
                  reload={() => this.refreshWeeklyService(true)}
                />
                <div className={plan_loading ? "sweet-loading" : ""}>
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={"#7BC807"}
                    loading={plan_loading}
                  />
                </div>
                <div className="lists">
                  <RadioGroup
                    className="services-wrapper"
                    aria-label="plan"
                    name="plan"
                    value={plan + "::" + planId}
                    onChange={this.handlePlan}
                  >
                    {planList.map((data, idx) => (
                      <ServiceListDesktop
                        title={data.title}
                        id={data.id}
                        confirmed={data.confirmed}
                        total={data.total}
                        key={idx}
                        //status={data.status}
                      />
                    ))}
                  </RadioGroup>
                </div>
              </Grid>

              <Grid item md={4} className="service-content service-people">
                <HeaderServices
                  title={plan}
                  //hasSearch
                  hasReload
                  reload={() => this.refreshLog(true)}
                />
                <div className={people_loading ? "sweet-loading" : ""}>
                  <ClipLoader
                    css={override}
                    sizeUnit={"px"}
                    size={150}
                    color={"#7BC807"}
                    loading={people_loading}
                  />
                </div>
                <div className="lists">
                  <RadioGroup
                    className="services-wrapper"
                    aria-label="people"
                    name="people"
                    value={people}
                    onChange={this.handlePeople}
                  >
                    {peoplePerDay.map((data, idx) => (
                      <PeopleListDesktop
                        type={type}
                        plan={planId}
                        personId={data.id}
                        name={data.name}
                        status={data.status}
                        position={data.position}
                        number={data.phone}
                        message={data.message ? data.message : false}
                        error_message={
                          data.error_message ? data.error_message : false
                        }
                        key={idx}
                      />
                    ))}
                  </RadioGroup>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="mobile-design">
            <HeaderServices
              backView="/dashboard"
              title={header}
              bread={["/dashboard"]}
            />
            <div className={loading ? "sweet-loading" : ""}>
              <ClipLoader
                css={override}
                sizeUnit={"px"}
                size={150}
                color={"#7BC807"}
                loading={loading}
              />
            </div>
            <ServiceList services={serviceTypes} status={type} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ServiceStatus;
