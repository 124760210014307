import React, { useCallback } from "react";
import PropType from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import MergeFields from "../MergeFields/MergeFields";
import { connect } from "react-redux";
import SmileIcon from "../../icons/editor-toolbar-icons/smile.svg";
import UndoIcon from "../../icons/editor-toolbar-icons/undo.svg";
import RedoIcon from "../../icons/editor-toolbar-icons/redo.svg";
import { ReactComponent as CodeIcon } from "../../icons/editor-toolbar-icons/code.svg";
import {
  updateMessageTemplate,
  resetMessageTemplate,
} from "../../services/api";
import { toggleConfirmationModal } from "../../store/actions/globalAction";
import ConfirmationModal from "../ConfirmationModal";
import MessageTemplatePreviewBox from "../MessageTemplatePreviewBox/MessageTemplatePreviewBox";

const CustomComponent = (props) => {
  const {
    onClick,
    keywords,
    type,
    showMergeFields,
    identity,
    toggleMergeField,
    showEditBody,
    toggleEditBodyPanel,
    charactersCount,
  } = props;

  const handleMergeFieldToggling = useCallback(() => {
    if (showMergeFields === identity) {
      toggleMergeField("");
    } else {
      toggleMergeField(identity);
    }
  }, [identity, showMergeFields, toggleMergeField]);

  return (
    <div>
      <div className="editor-toolbar-icons code-icon">
        <Tooltip title={`${showMergeFields ? "Hide" : "Add"} merge fields`}>
          <CodeIcon
            onClick={handleMergeFieldToggling}
            className="mb-1 cursor-pointer"
          />
        </Tooltip>
      </div>
      <div className="ml-2">
        {showMergeFields === identity && (
          <MergeFields onClick={onClick} keywords={keywords} type={type} />
        )}
      </div>
      <div className="character-count-container">
        <span>Characters: {charactersCount}</span>
        {identity === "multiple" && !showEditBody && (
          <strong onClick={toggleEditBodyPanel}>Edit [Body]</strong>
        )}
      </div>
    </div>
  );
};

class EditTemplateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      multipleBody: EditorState.createEmpty(),
      multipleBodyPart: EditorState.createEmpty(),
      additionalPart: EditorState.createEmpty(),
      template: "",
      multipleBodyTemplate: "",
      multipleBodyPartTemplate: "",
      additionalPartTemplate: "",
      templateTitle: "",
      templateObject: props.template,
      errors: {},
      showMergeFields: "",
      showEditBody: true,
      confirmationModalTitle: "",
      confirmationModalDesc: "",
      confirmationAction: null,
    };
  }

  componentDidMount() {
    this.resetTemplateData();
  }

  setConfirmationModalData = () => {
    const { toggleConfirmationModal } = this.props;
    const title = `Reset to Original`;
    const desc = `Are you sure you want to reset to original?`;
    this.setState(
      {
        confirmationModalTitle: title,
        confirmationModalDesc: desc,
        confirmationAction: this.resetTemplate,
      },
      () => {
        toggleConfirmationModal();
      }
    );
  };

  onEditorStateChange = (editorState) => {
    let contentState = editorState.getCurrentContent();
    let html = stateToHTML(contentState);
    this.setState({
      editorState,
      template: html,
    });
  };

  onMultlpeBodyChange = (multipleBody) => {
    let contentState = multipleBody.getCurrentContent();
    let html = stateToHTML(contentState);
    this.setState({
      multipleBody,
      multipleBodyTemplate: html,
    });
  };

  onMultlpeBodyPartChange = (multipleBodyPart) => {
    let contentState = multipleBodyPart.getCurrentContent();
    let html = stateToHTML(contentState);
    this.setState({
      multipleBodyPart,
      multipleBodyPartTemplate: html,
    });
  };

  onAdditionalPartChange = (additionalPart) => {
    let contentState = additionalPart.getCurrentContent();
    let html = stateToHTML(contentState);
    this.setState({
      additionalPart,
      additionalPartTemplate: html,
    });
  };

  handleAppendMergeFields = (mergeField, type) => {
    const {
      template,
      multipleBodyTemplate,
      multipleBodyPartTemplate,
      additionalPartTemplate,
    } = this.state;
    if (type === "single") {
      var modifyMergeField = template.replace("</p>", `${mergeField}</p>`);
      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(modifyMergeField))
        ),
        template: modifyMergeField,
      });
    } else if (type === "multiple") {
      const modifyMergeField = multipleBodyTemplate.replace(
        "</p>",
        `${mergeField}</p>`
      );
      this.setState({
        multipleBody: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(modifyMergeField))
        ),
        multipleBodyTemplate: modifyMergeField,
      });
    } else if (type === "multiple_part") {
      const modifyMergeField = multipleBodyPartTemplate.replace(
        "</p>",
        `${mergeField}</p>`
      );
      this.setState({
        multipleBodyPart: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(modifyMergeField))
        ),
        multipleBodyPartTemplate: modifyMergeField,
      });
    } else if (type === "additional_part") {
      const modifyMergeField = additionalPartTemplate.replace(
        "</p>",
        `${mergeField}</p>`
      );
      this.setState({
        additionalPart: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(modifyMergeField))
        ),
        additionalPartTemplate: modifyMergeField,
      });
    }
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  resetTemplateData = () => {
    const { template } = this.props;
    const changeTemplate = template.detail?.startsWith("<p>")
      ? template.detail
      : `<p>${template.detail}</p>`;
    const multipleBody = template.multiple_body?.startsWith("<p>")
      ? template.multiple_body
      : `<p>${template.multiple_body}</p>`;
    const multipleBodyPart = template.multiple_body_part?.startsWith("<p>")
      ? template.multiple_body_part
      : `<p>${template.multiple_body_part}</p>`;
    const additionalPart = template.additional_part?.startsWith("<p>")
      ? template.additional_part
      : `<p>${template.additional_part}</p>`;
    if (template.id) {
      this.setState({
        editorState: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(changeTemplate))
        ),
        multipleBody: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(multipleBody))
        ),
        multipleBodyPart: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(multipleBodyPart))
        ),
        additionalPart: EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(additionalPart))
        ),
        template: changeTemplate,
        multipleBodyTemplate: multipleBody,
        multipleBodyPartTemplate: multipleBodyPart,
        additionalPartTemplate: additionalPart,
        templateTitle: template.title,
      });
    }
  };

  resetTemplate = async () => {
    const { templateObject } = this.state;
    await resetMessageTemplate(templateObject.id).then(
      (data) => {
        console.log(data);

        const template = {
          id: data.id,
          type: data.category,
          message_type: data.message_type,
          title: data.name,
          detail: data.body,
          multiple_body: data.multiple_body_html,
          additional_part: data.additional_part,
          multiple_body_part: data.multiple_body_part,
          keywords: data.keywords,
        };
        const changeTemplate = template.detail?.startsWith("<p>")
          ? template.detail
          : `<p>${template.detail}</p>`;
        const multipleBody = template.multiple_body?.startsWith("<p>")
          ? template.multiple_body
          : `<p>${template.multiple_body}</p>`;
        const multipleBodyPart = template.multiple_body_part?.startsWith("<p>")
          ? template.multiple_body_part
          : `<p>${template.multiple_body_part}</p>`;
        const additionalPart = template.additional_part?.startsWith("<p>")
          ? template.additional_part
          : `<p>${template.additional_part}</p>`;
        if (template.id) {
          this.setState({
            editorState: EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(changeTemplate))
            ),
            multipleBody: EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(multipleBody))
            ),
            multipleBodyPart: EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(multipleBodyPart)
              )
            ),
            additionalPart: EditorState.createWithContent(
              ContentState.createFromBlockArray(convertFromHTML(additionalPart))
            ),
            template: changeTemplate,
            multipleBodyTemplate: multipleBody,
            multipleBodyPartTemplate: multipleBodyPart,
            additionalPartTemplate: additionalPart,
            templateTitle: template.title,
          });
          this.props.reload();
          window.location.reload(false);
        }
      },
      (error) => {
        console.log(error);
        if (error.response) {
          this.setState({ errors: error.response.data.error });
        }
      }
    );
  };

  upadteTemplate = async () => {
    var {
      templateObject,
      template,
      multipleBodyTemplate,
      multipleBodyPartTemplate,
      additionalPartTemplate,
    } = this.state;
    if (template === "<p><br></p>") {
      return this.setState({
        errors: {
          body: "Template details cannot be empty",
        },
      });
    } else {
      if (
        multipleBodyTemplate === "<p>null</p>" ||
        multipleBodyTemplate === "<p></p>"
      ) {
        multipleBodyTemplate = "";
      }
      if (
        multipleBodyPartTemplate === "<p>null</p>" ||
        multipleBodyPartTemplate === "<p></p>"
      ) {
        multipleBodyPartTemplate = "";
      }
      if (
        additionalPartTemplate === "<p>null</p>" ||
        additionalPartTemplate === "<p></p>"
      ) {
        additionalPartTemplate = "";
      }
      this.setState({ errors: {} });
      // call API here
      await updateMessageTemplate(
        templateObject.id,
        template,
        multipleBodyTemplate,
        multipleBodyPartTemplate,
        additionalPartTemplate
      ).then(
        (data) => {
          console.log(data);
          if (data.id) {
            this.props.reload();
            window.location.reload(false);
          }
        },
        (error) => {
          console.log(error);
          if (error.response) {
            this.setState({ errors: error.response.data.error });
          }
        }
      );
    }
  };

  toggleMergeField = (identity) => {
    this.setState({ showMergeFields: identity });
  };

  getWordCount = (editorState) => {
    const plainText = editorState.getCurrentContent().getPlainText("");
    return plainText ? plainText.length : 0;
  };

  render() {
    const { showModal, toggleModal, showConfirmationModal } = this.props;
    const {
      editorState,
      templateTitle,
      templateObject,
      multipleBody,
      multipleBodyPart,
      additionalPart,
      template,
      multipleBodyTemplate,
      multipleBodyPartTemplate,
      additionalPartTemplate,
      errors,
      showMergeFields,
      showEditBody,
      confirmationModalDesc,
      confirmationModalTitle,
      confirmationAction,
    } = this.state;
    return (
      <div>
        <Dialog
          open={showModal}
          onClose={toggleModal}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <DialogTitle id="form-dialog-title" className="pb-0 flexer">
            <strong>Edit Template</strong>
          </DialogTitle>
          <IconButton
            className="close-icon"
            style={{ position: "absolute", right: 0 }}
            onClick={toggleModal}
          >
            <Icon>close</Icon>
          </IconButton>
          <DialogContent>
            <form name="form" onSubmit={this.handleSubmit}>
              <div className="editor-inner-wrapper">
                <div className="flexer-space-between">
                  <div style={{ width: "60%", alignSelf: "flex-start" }}>
                    <label className="editor-label">{templateTitle}</label>
                    <Editor
                      editorState={editorState}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName={`toolbar-class ${
                        showMergeFields === "single"
                          ? ""
                          : "change-toolbar-order"
                      }`}
                      onEditorStateChange={this.onEditorStateChange}
                      toolbarCustomButtons={[
                        <CustomComponent
                          toggleMergeField={this.toggleMergeField}
                          showMergeFields={this.state.showMergeFields}
                          identity={"single"}
                          onClick={(e) =>
                            this.handleAppendMergeFields(e, "single")
                          }
                          keywords={templateObject.keywords}
                          type={templateObject.type}
                          charactersCount={this.getWordCount(editorState)}
                        />,
                      ]}
                      toolbar={{
                        options: ["emoji", "history"],
                        emoji: {
                          icon: SmileIcon,
                          className: "editor-toolbar-icons",
                        },
                        history: {
                          className: "editor-toolbar-icons",
                          undo: { icon: UndoIcon },
                          redo: { icon: RedoIcon },
                        },
                      }}
                    />
                  </div>
                  <MessageTemplatePreviewBox
                    multiple={false}
                    body={template}
                    parts={multipleBodyPartTemplate}
                    additional={additionalPartTemplate}
                    keywords={templateObject.keywords}
                  />
                </div>

                {errors?.body && (
                  <p className="text-danger mt-1">{errors?.body}</p>
                )}
              </div>
              <div className="editor-inner-wrapper">
                <div className="flexer-space-between">
                  <div style={{ width: "60%", alignSelf: "flex-start" }}>
                    {(templateObject.multiple_body ||
                      templateObject.additional_part) && (
                      <label className="editor-label">
                        Template used for multiple services:
                      </label>
                    )}
                    {templateObject.multiple_body && (
                      <div>
                        <Editor
                          editorState={multipleBody}
                          wrapperClassName="wrapper-class"
                          editorClassName="editor-class"
                          toolbarClassName={`toolbar-class ${
                            showMergeFields === "multiple"
                              ? ""
                              : "change-toolbar-order"
                          }`}
                          onEditorStateChange={this.onMultlpeBodyChange}
                          toolbarCustomButtons={[
                            <CustomComponent
                              toggleMergeField={this.toggleMergeField}
                              showMergeFields={this.state.showMergeFields}
                              identity={"multiple"}
                              onClick={(e) =>
                                this.handleAppendMergeFields(e, "multiple")
                              }
                              keywords={templateObject.keywords}
                              type={templateObject.type}
                              showEditBody={showEditBody}
                              toggleEditBodyPanel={() =>
                                this.setState({ showEditBody: true })
                              }
                              charactersCount={this.getWordCount(multipleBody)}
                            />,
                          ]}
                          toolbar={{
                            options: ["emoji", "history"],
                            emoji: {
                              icon: SmileIcon,
                              className: "editor-toolbar-icons",
                            },
                            history: {
                              className: "editor-toolbar-icons",
                              undo: { icon: UndoIcon },
                              redo: { icon: RedoIcon },
                            },
                          }}
                        />
                        {errors?.multiple_body && (
                          <p className="text-danger mt-1">
                            {errors?.multiple_body}
                          </p>
                        )}

                        {/* Edit multiple body part editor */}

                        {showEditBody && templateObject.multiple_body_part && (
                          <>
                            <label className="editor-label mt-3">
                              Part of Multiple [Body] list:
                            </label>
                            <Editor
                              editorState={multipleBodyPart}
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName={`toolbar-class ${
                                showMergeFields === "multiple_part"
                                  ? ""
                                  : "change-toolbar-order"
                              }`}
                              onEditorStateChange={this.onMultlpeBodyPartChange}
                              toolbarCustomButtons={[
                                <CustomComponent
                                  toggleMergeField={this.toggleMergeField}
                                  showMergeFields={this.state.showMergeFields}
                                  identity={"multiple_part"}
                                  onClick={(e) =>
                                    this.handleAppendMergeFields(
                                      e,
                                      "multiple_part"
                                    )
                                  }
                                  keywords={templateObject.keywords}
                                  type={templateObject.type}
                                  charactersCount={this.getWordCount(
                                    multipleBodyPart
                                  )}
                                />,
                              ]}
                              toolbar={{
                                options: ["emoji", "history"],
                                emoji: {
                                  icon: SmileIcon,
                                  className: "editor-toolbar-icons",
                                },
                                history: {
                                  className: "editor-toolbar-icons",
                                  undo: { icon: UndoIcon },
                                  redo: { icon: RedoIcon },
                                },
                              }}
                            />
                            {errors?.multiple_body_part && (
                              <p className="text-danger mt-1">
                                {errors?.multiple_body_part}
                              </p>
                            )}
                          </>
                        )}

                        {templateObject.additional_part && (
                          <>
                            <label className="editor-label">
                              Part of Multiple [Body] additional section:
                            </label>
                            <Editor
                              editorState={additionalPart}
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName={`toolbar-class ${
                                showMergeFields === "additional_part"
                                  ? ""
                                  : "change-toolbar-order"
                              }`}
                              onEditorStateChange={this.onAdditionalPartChange}
                              toolbarCustomButtons={[
                                <CustomComponent
                                  toggleMergeField={this.toggleMergeField}
                                  showMergeFields={this.state.showMergeFields}
                                  identity={"additional_part"}
                                  onClick={(e) =>
                                    this.handleAppendMergeFields(
                                      e,
                                      "additional_part"
                                    )
                                  }
                                  keywords={templateObject.keywords}
                                  type={templateObject.type}
                                  charactersCount={this.getWordCount(
                                    additionalPart
                                  )}
                                />,
                              ]}
                              toolbar={{
                                options: ["emoji", "history"],
                                emoji: {
                                  icon: SmileIcon,
                                  className: "editor-toolbar-icons",
                                },
                                history: {
                                  className: "editor-toolbar-icons",
                                  undo: { icon: UndoIcon },
                                  redo: { icon: RedoIcon },
                                },
                              }}
                            />
                            {errors?.additional_part && (
                              <p className="text-danger mt-1">
                                {errors?.additional_part}
                              </p>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                  <MessageTemplatePreviewBox
                    multiple={true}
                    body={multipleBodyTemplate}
                    parts={multipleBodyPartTemplate}
                    additional={additionalPartTemplate}
                    keywords={templateObject.keywords}
                  />
                </div>
              </div>
            </form>
          </DialogContent>
          <DialogActions className="flexer-space-between p-4">
            <Button
              color="primary"
              variant="outlined"
              type="submit"
              classes={{
                containedPrimary: "contained-primary",
              }}
              onClick={this.setConfirmationModalData}
            >
              Reset to Original
            </Button>
            <div>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                classes={{
                  root: "btn editor-btn",
                  containedPrimary: "contained-primary",
                }}
                onClick={this.upadteTemplate}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
        {showConfirmationModal && (
          <ConfirmationModal
            title={confirmationModalTitle}
            description={confirmationModalDesc}
            confirmAction={confirmationAction}
          />
        )}
      </div>
    );
  }
}

EditTemplateModal.propTypes = {
  toggleModal: PropType.func,
  showModal: PropType.bool,
};

const mapStateToProps = (store) => {
  return {
    showConfirmationModal: store.global.showConfirmationModal,
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleConfirmationModal: () => dispatch(toggleConfirmationModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTemplateModal);
