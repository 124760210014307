import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/core";
import queryString from "query-string";
import HeaderServices from "./HeaderServices";
import ServiceList from "./ServiceList";
import { authHeader } from "../helpers/AuthHeader";
import { userService } from "../../components/services/UserService";
import "../../styles/components/services/weekly-worship.scss";

// var config = require("config");
const API_URL = process.env.REACT_APP_API_URL;

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: #7bc807;
`;
class WeeklyWorship extends React.Component {
  constructor(props) {
    super(props);

    //console.log(this.props.location);
    const values = queryString.parse(this.props.location.search);

    this.state = {
      type: values.type ? values.type : "",
      service: values.service ? values.service : 0,
      serviceName: values.serviceName ? values.serviceName : "",
      planList: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.refreshWeeklyService();
  }

  refreshWeeklyService(reload) {
    const { service, type } = this.state;
    var _this = this;
    // cache.getItem('weeklyWorship' + service + type, function (err, data) {
    // 	if (!reload && data) {
    // 		_this.setState({ planList: data });
    // 	} else {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    _this.setState({ loading: true });
    return fetch(
      `${API_URL}scheduling/plans/${service}?type=${type}`,
      requestOptions
    )
      .then((res) => {
        if (!res.ok) {
          //console.log(res);
          if (res.status !== 401) return;
        }
        return res.json();
      })
      .then((data) => {
        if (data.error) {
          userService.refreshToken();
          _this.refreshWeeklyService(true);
          _this.setState({ loading: false });
          return;
        }
        _this.setState({ planList: data, loading: false });
        // cache.setItem(
        // 	'weeklyWorship' + service + type,
        // 	data,
        // 	function (err) {}
        // );
      })
      .catch(console.log);
    // 	}
    // });
  }
  render() {
    const { planList, serviceName, loading, type } = this.state;
    var header = "Services";
    switch (type) {
      case "C":
        header = "Accepted " + header;
        break;
      case "F":
        header = "Failed" + header;
        break;
      case "A":
        header = "Sent" + header;
        break;
      case "U":
        header = "Unconfirmed" + header;
        break;
      default:
        header = "Services";
    }
    header = serviceName;
    return (
      <div>
        <div className="page-wrapper weekly-worship">
          <div className={loading ? "sweet-loading" : ""}>
            <ClipLoader
              css={override}
              sizeUnit={"px"}
              size={150}
              color={"#7BC807"}
              loading={loading}
            />
          </div>
          <HeaderServices
            backView={type ? "/services?type=" + type : "/dashboard?value=1"}
            title={header}
            hasReload
            /*hasSearch
        	  hasReload
        	  lists={[
        		{ id: 1, edit: true, title: 'Switch default template' },
        		{ id: 2, edit: true, title: 'Resend confirmation now', hasBorderBottom: true },
        		{ id: 3, switch: true, title: 'Confirmations' },
        		{ id: 4, switch: true, title: 'Reminders' },
        	  ]}
        	  bottomModalContent={
        		<div>
        		<ChangeTemplate label="Switch default template" />
        		<Button className="content-single">Resend confirmation now</Button>
        		<div className="switches">
        		  <FormControlLabel
        		  control={
        			<CustomSwitch />
        		  }
        		  label="Confirmations On"
        		  />
        		  <FormControlLabel
        		  control={
        			<CustomSwitch />
        		  }
        		  label="Followup On"
        		  />
        		</div>
        		</div>
        	  }*/
          />
          <div>
            <ServiceList services={planList} status={type} />
          </div>
        </div>
      </div>
    );
  }
}

export default WeeklyWorship;
