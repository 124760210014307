import React from "react";
import { FormControlLabel, Icon, Radio, Typography } from "@material-ui/core";
import "./../../styles/components/services/service-list.scss";
import "./../../styles/components/responsive-design.scss";

export default function ServiceList(props) {
  return (
    <FormControlLabel
      value={props.title + "::" + props.id}
      control={
        <Radio
          disableRipple
          icon=""
          checkedIcon=""
          classes={{
            root: "radio",
            checked: "active",
          }}
        />
      }
      classes={{
        root: "single-service",
        label: "label",
      }}
      label={
        <React.Fragment>
          <div className="status">
            {props.status === "open" && (
              <Icon className="icon">folder_open</Icon>
            )}
            {props.status === "close" && <Icon className="icon">folder</Icon>}
          </div>
          <div className="left-content">
            <Typography>{props.title}</Typography>
          </div>
        </React.Fragment>
      }
    />
  );
}
