import React from "react";
import { Hidden } from "@material-ui/core";
import MoreRouter from "./../../MoreRouter";
import PagesSubMenu from "./PagesSubMenu";
import "./../../styles/components/more/more-main.scss";

const More = () => (
  <div className="more-main-wrapper">
    <Hidden smDown>
      <MoreRouter />
    </Hidden>
    <Hidden mdUp>
      <PagesSubMenu />
    </Hidden>
  </div>
);

export default More;
