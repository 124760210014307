import React from "react";
import Switch from "@material-ui/core/Switch";

class CustomSwitch extends React.Component {
  render() {
    return (
      <Switch
        checked={this.props.checked}
        onClick={this.props.onClick}
        value="checked"
        inputProps={{ "aria-label": "secondary checkbox" }}
        disableRipple
        classes={{
          root: "custom-switch",
          thumb: "thumb",
          track: "track",
          switchBase: "switch-base",
          checked: "checked",
        }}
      />
    );
  }
}

export default CustomSwitch;
