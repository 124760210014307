import React from "react";
import { Button } from "@material-ui/core";
import HeaderPeople from "./HeaderPeople";
import PeopleList from "./PeopleList";

const WeeklyService = () => (
  <div className="page-wrapper">
    <HeaderPeople
      backView="/personal-service"
      title="Barack Obama / Summer in the city"
      lists={[
        { id: 1, title: "Turn on confirmations for all" },
        { id: 2, title: "Turn off confirmations for all" },
      ]}
      bottomModalContent={
        <div>
          <Button className="content-single">
            Turn on confirmations for all
          </Button>
          <Button className="content-single">
            Turn off confirmations for all
          </Button>
        </div>
      }
    />
    <PeopleList
      persons={[
        { id: 1, name: "11 May 2019", on: true },
        { id: 2, name: "18 May 2019" },
        { id: 3, name: "25 May 2019", on: true },
        { id: 4, name: "2 June 2019", on: true },
      ]}
    />
  </div>
);

export default WeeklyService;
