import React from "react";
import { Typography } from "@material-ui/core";
import HeaderForAll from "./../components/HeaderForAll";

class Verification extends React.Component {
  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.success?.token) {
      return this.props.history.push(`/dashboard`);
    }
  }

  render() {
    return (
      <div className="page-wrapper create-acc">
        <HeaderForAll title="Pending Manual Verification" />
        <div className="auth-wrapper verify-email">
          <Typography component={"h2"} className="page-title">
            Pending Manual Verification
          </Typography>
          <Typography className="verify-content">
            Thank you for registering with us. Please wait while we manually
            review your account. This will take 1-2 business days. Once we
            finish our approval process, we will email you the status of your
            account (approved, need for more information, etc.). We appreciate
            your patience!
          </Typography>
        </div>
        <div className="auth-banner">
          <img src="images/auth-banner.png" alt="" />
        </div>
      </div>
    );
  }
}

export default Verification;
