import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, Typography, Grid, Button } from "@material-ui/core";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import HeaderMore from "./HeaderMore";
import SingleInvoice from "./SingleInvoice";
import "./../../styles/components/more/billing.scss";

const bgBilling = "./images/bg-billing.svg";
function BillingDesktop() {
  return (
    <div className="page-wrapper">
      <HeaderMore backView="/dashboard" title="Billing" />
      <div className="inner-content-wrapper">
        <div className="billing desktop">
          <Typography className="title">
            Your Subscription
            <Button
              color="primary"
              variant="text"
              component={Link}
              to=""
              classes={{
                root: "btn",
                label: "label",
                textPrimary: "text-primary",
              }}
            >
              Add more credits
            </Button>
          </Typography>

          <Grid container spacing={2} className="cards-wrapper">
            <Grid item sm={6} lg={4}>
              <div className="offer-wrapper">
                <div
                  className="offer-card"
                  style={{ backgroundImage: `url(${bgBilling})` }}
                >
                  <div className="content">
                    <div>
                      <Typography className="bold-text">
                        Just Starting
                      </Typography>
                      <Typography className="normal-text">
                        100 credits a month
                      </Typography>
                    </div>
                    <div>
                      <Typography className="bold-text">$10</Typography>
                      <Button
                        color="primary"
                        variant="text"
                        component={Link}
                        to=""
                        classes={{
                          root: "btn",
                          label: "label",
                          textPrimary: "text-primary",
                        }}
                      >
                        Change
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item md={6} lg={5}>
              <div className="cards">
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Card className="billing-card">
                      <CardContent className="card-content">
                        <Progress
                          type="circle"
                          width={78}
                          percent={75}
                          strokeWidth={14}
                          status="declined"
                          theme={{
                            declined: {
                              color: "#b5b5b5",
                              trailColor: "#F1F1F1",
                              symbol: "37",
                            },
                          }}
                        />
                        <Typography className="label">
                          Credits left for this month
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item sm={6}>
                    <Card className="billing-card">
                      <CardContent className="card-content">
                        <Progress
                          type="circle"
                          width={78}
                          percent={12}
                          strokeWidth={14}
                          status="declined"
                          theme={{
                            declined: {
                              color: "#b5b5b5",
                              trailColor: "#F1F1F1",
                              symbol: "2",
                            },
                          }}
                        />
                        <Typography className="label">
                          Average credit used a month
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <div className="payment-method">
            <Typography className="title">Payment method</Typography>
            <Typography className="expiration">
              MasterCard ending in 5062{" "}
            </Typography>
          </div>
          <div className="invoices">
            <Typography className="title">Invoices</Typography>
            <SingleInvoice
              invoices={[
                {
                  id: 1,
                  date: "08.12.2020",
                  invoiceId: "05/123",
                  phase: "Just starting",
                  month: "may",
                },
                {
                  id: 2,
                  date: "08.12.2020",
                  invoiceId: "05/123",
                  phase: "Just starting",
                  month: "may",
                },
                {
                  id: 3,
                  date: "08.12.2020",
                  invoiceId: "05/123",
                  phase: "Just starting",
                  month: "may",
                },
                {
                  id: 4,
                  date: "08.12.2020",
                  invoiceId: "05/123",
                  phase: "Just starting",
                  month: "may",
                },
                {
                  id: 5,
                  date: "08.12.2020",
                  invoiceId: "05/123",
                  phase: "Just starting",
                  month: "may",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingDesktop;
