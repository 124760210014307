import React from "react";
import Swipeout from "rc-swipeout";
import queryString from "query-string";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Switch,
  Hidden,
  Tooltip,
  Icon,
  TextField,
  Grid,
  DialogContent,
} from "@material-ui/core";
import HeaderMore from "./HeaderMore";
import PagesSubMenu from "./PagesSubMenu";
import { ReactComponent as ConfirmationIcon } from "../../icons/confirmation-icon.svg";
import { ReactComponent as ReminderIcon } from "../../icons/reminder-icon.svg";
import { updateKeyword } from "../../services/api";
import "../../styles/components/more/schedule-templates.scss";

class EditKeyword extends React.Component {
  constructor(props) {
    super(props);
    const values = queryString.parse(this.props.location.search);
    const params = values.k.split("-");
    const type = params[0];
    const value = params.length > 1 ? params[1] : "";

    this.state = {
      keyword: type,
      value: value,
    };
  }

  handleEdit = (event) => {
    this.setState({ value: event.target.value });
  };

  handleUpdate = async () => {
    const { keyword, value } = this.state;
    await updateKeyword(keyword, value);
    this.props.history.push("/keywords");
  };

  render() {
    const { keyword, value } = this.state;

    let header = "Keywords";
    header = `${header} > ${keyword}`;
    return (
      <div className="edit-schedule page-wrapper my-profile">
        <PagesSubMenu />
        <Hidden mdUp>
          <HeaderMore
            backView="/keywords"
            title={`...> ${keyword}`}
            nextView="/dashboard"
          />
        </Hidden>
        <div
          className={
            window.innerWidth > 959 ? "desktop-design" : "inner-content-wrapper"
          }
        >
          <Hidden smDown>
            <Typography className="desktop-title" component={"h2"}>
              {header}
            </Typography>
          </Hidden>
          <div className="content">
            <Typography component="h3" className="switches-title mt-5">
              {keyword}
            </Typography>

            <Grid item className="item">
              <TextField
                label="Translation"
                classes={{
                  root: "text-field",
                }}
                InputLabelProps={{
                  classes: {
                    root: "label",
                  },
                }}
                InputProps={{
                  classes: {
                    root: "input-wrapper",
                    input: "input",
                  },
                }}
                id="filled-uncontrolled"
                placeholder="Translation"
                margin="normal"
                variant="filled"
                name="keyword"
                value={value}
                onChange={this.handleEdit}
                FormHelperTextProps={{
                  classes: {
                    root: "helper-text",
                  },
                }}
              />
            </Grid>
            <Button
              onClick={this.handleUpdate}
              variant="outlined"
              className="btn"
            >
              Update
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default EditKeyword;
